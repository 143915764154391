export const environment = {
  production: false,
  webUrl: 'dev.web.postedecontrole.alf31.fr',
  //host: 'https://www.web-api.postedecontrole.alf31.fr/',
  host: 'https://www.dev.web-api.postedecontrole.alf31.fr/',
  apiBaseUrl: 'https://www.dev.web-api.postedecontrole.alf31.fr/api/',
  //apiBaseUrl: 'https://www.web-api.postedecontrole.alf31.fr/api/',
  backendUrl: 'https://dev.web-api.postedecontrole.alf31.fr:44444/api/',
  streamUrl: 'wss://dev.web-api.postedecontrole.alf31.fr:9998',
  dispatcherUrl: 'wss://dev.web-api.postedecontrole.alf31.fr:11111',
};
