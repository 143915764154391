import { MapInfo } from '@lelab31/ngx-rodotic';
import { Map } from '../../../api/models/map';
import { environment } from '../../../../environments/environment';

export class MapAdapter {
  /**
   * Identifiant de la carte
   */
  public id: string | undefined;

  /**
   * Information sur la carte
   */
  public mapInfo: MapInfo | undefined;

  constructor(public map: Map) {
    if (map.id) {
      this.id = map.id.toString();
    }

    if (
      this.map.resolution &&
      this.map.origin &&
      this.map.height &&
      this.map.width
    ) {
      this.mapInfo = {
        dataOrUrl: environment.host + this.map.image,
        height: this.map.height,
        width: this.map.width,
        resolution: this.map.resolution,
        origin: this.map.origin,
      };
    }
  }
}
