import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Robot } from '../../../../api/models/robot';
import { RobotService } from '../../../../core/services/entities/robot.service';
import { RobotAdapter } from '../../../../core/models/entities/robot-adapter';
import { IListText } from '../../../../components/admin/models/i-list-text';
import { EntityListComponent } from '../../../../components/admin/entity-list/entity-list.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IEntityAdapter } from '../../../../components/admin/models/i-entity-adapter';
import { RoslibService } from '@lelab31/ngx-rodotic';
import { SessionService } from '../../../../core/services/session.service';

@Component({
  selector: 'webclient-robot-list',
  templateUrl: './robot-list.component.html',
  styleUrls: ['./robot-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RobotListComponent extends EntityListComponent implements OnInit {
  /**
   * Liste des robots
   */
  public robotAdapters: RobotAdapter[] = [];

  /**
   * Colonnes affichées
   */
  public override displayedColumns: string[] = ['name', 'customer', 'zone'];

  /**
   * Textes
   */
  public override text: IListText = {
    removeTitle: 'robot.removeTitle',
    removeMessage: 'robot.removeMessage',
  };

  constructor(
    protected override sessionService: SessionService,
    private robotService: RobotService,
    private rosLibService: RoslibService,
    protected override dialog: MatDialog,
    protected override translateService: TranslateService,
    protected override snackBar: MatSnackBar
  ) {
    super(sessionService, dialog, translateService, snackBar);

    this.robotService.getAll().subscribe((robots: Robot[]) => {
      this.robotAdapters = robots.map(
        (robot: Robot) => new RobotAdapter(robot, this.rosLibService)
      );
    });
  }

  /**
   * Suppression du robot
   *
   * @param entityAdapter le robot à supprimer
   */
  public onRemove(entityAdapter: IEntityAdapter) {
    const robotAdapter: RobotAdapter = entityAdapter as RobotAdapter;

    if (robotAdapter.id !== undefined) {
      this.robotService.delete(robotAdapter.id).subscribe(
        () => {
          const index: number = this.robotAdapters.findIndex(
            (robotAdapter: RobotAdapter) => robotAdapter.id === entityAdapter.id
          );
          const robotAdapters: RobotAdapter[] = [...this.robotAdapters];
          robotAdapters.splice(index, 1);

          this.robotAdapters = robotAdapters;

          this.displaySuccessSnackBar(
            robotAdapter.name,
            'robot.removeSuccessMessage'
          );
        },
        (error) => {
          this.displayErrorSnackBar(
            robotAdapter.name,
            'robot.removeErrorMessage'
          );
        }
      );
    }
  }
}
