<ngx-rodotic-video *ngIf="robotAdapter" [url]="robotAdapter.videoUrl" [autoplay]="autoplay" [shouldplay]="shouldplay" (isplaying)="getisplaying($event)" [arming]="robotAdapter.isarming" [fire]="robotAdapter.isfire">
  <ngx-rodotic-video-control
    *ngIf="robotAdapter"
    [disabled]="!robotAdapter.connected"
    (onLeft)="onMotionClick(MOTION.LEFT)"
    (onForward)="onMotionClick(MOTION.FRONT)"
    (onStop)="onMotionClick(MOTION.STOP)"
    (onBack)="onMotionClick(MOTION.REAR)"
    (onRight)="onMotionClick(MOTION.RIGHT)"
    [manual_control]="robotAdapter.ismanualcontrol"
  ></ngx-rodotic-video-control>
  <ngx-rodotic-video-toolbar>
    <webclient-robot-lighting
      *ngIf="robotAdapter"
      [state]="robotAdapter?.lightState"
      [disabled]="!robotAdapter.connected || !robotAdapter.lightState"
      offText="{{ 'robot.light.off' | translate }}"
      onText="{{ 'robot.light.on' | translate }}"
      (onState)="onLightClick()"
    ></webclient-robot-lighting>
    <webclient-robot-speed
      *ngIf="robotAdapter"
      [mode]="robotAdapter?.speedMode"
      [disabled]="!robotAdapter.connected || !robotAdapter.speedMode"
      slowText="{{ 'robot.speed.slow' | translate }}"
      fastText="{{ 'robot.speed.fast' | translate }}"
      (onMode)="onSpeedClick()"
    ></webclient-robot-speed>
    <webclient-robot-videoplay
      *ngIf="robotAdapter && !autoplay"
      [state]="isplaying || !robotAdapter.connected"
      [disabled]="!robotAdapter.connected"
      offText="{{ 'robot.video.off' | translate }}"
      onText="{{ 'robot.video.on' | translate }}"
      (onState)="playvideo()"
    ></webclient-robot-videoplay>
    <webclient-robot-arming
      *ngIf="robotAdapter"
      [state]="robotAdapter.isarming || !robotAdapter.connected"
      [disabled]="!robotAdapter.connected"
      offText="{{ 'robot.accessories.arming.off' | translate }}"
      onText="{{ 'robot.accessories.arming.on' | translate }}"
      (onState)="onArmingClick()"
    ></webclient-robot-arming>
    <webclient-robot-fire
      *ngIf="robotAdapter && robotAdapter.isarming"
      [state]="robotAdapter.isfire || !robotAdapter.connected"
      [disabled]="!robotAdapter.isarming || !robotAdapter.connected"
      offText="{{ 'robot.accessories.fire.off' | translate }}"
      onText="{{ 'robot.accessories.fire.on' | translate }}"
      (onState)="onFireClick()"
    ></webclient-robot-fire>
  </ngx-rodotic-video-toolbar>
</ngx-rodotic-video>
