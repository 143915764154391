import { Component, Input, ViewEncapsulation } from '@angular/core';
import { RobotAdapter } from '../../../core/models/entities/robot-adapter';
import { RobotState } from '../../../core/enums/robot-state.enum';
import { MultiState } from '../../../core/enums/multi-state.enum';
import { Role } from '../../../core/enums/role.enum';
import { StrategyName } from '../../../core/enums/strategy-name.enum';

@Component({
  selector: 'webclient-robot-control',
  templateUrl: './robot-control.component.html',
  styleUrls: ['./robot-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'webclient-robot-control',
  },
})
export class RobotControlComponent {
  /**
   * Robot
   */
  @Input() public robotAdapter: RobotAdapter | undefined;

  /**
   * Etat du robot
   */
  public ROBOT_STATE = RobotState;

  /**
   * Type de la stratégie EMERGENCY_STOP
   */
  public emergencyStrategyType = StrategyName.EMERGENCY_STOP;

  /**
   * Etat
   */
  public MULTI_STATE = MultiState;

  /**
   * Liste des rôles
   */
  public Role = Role;

  /**
   * Clique sur le bouton d'urgence
   *
   * @param value la valeur
   */
  public onEmergencyClick(value: boolean) {
    if (this.robotAdapter) {
      this.robotAdapter.emergencyCommand(!value);
    }
  }
}
