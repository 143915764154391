<webclient-entity-form
  [urlList]="'/pages/admin/zone/list/'"
  [entity]="zone"
  [form]="form"
  [createTitle]="'zone.create'"
  [editTitle]="'zone.edit'"
  [icon]="'bolt'"
  (onCreateEntity)="onCreate($event)"
  (onEditEntity)="onEdit($event)"
>
  <form
    [formGroup]="form"
    *ngIf="form"
    fxLayout="row"
    fxLayoutGap="20px"
    class="admin-form"
    fxFlex
  >
    <div fxFlex="40">
      <fieldset>
        <legend [translate]="'zone.informations'"></legend>

        <div
          fxLayout="column"
          fxLayout.md="column"
          fxLayout.sm="column"
          fxLayout.xs="column"
          fxLayoutGap="20px"
        >
          <mat-form-field fxFlex>
            <mat-icon matPrefix>person</mat-icon>
            <input
              matInput
              type="text"
              name="name"
              required="required"
              placeholder="{{ 'zone.name' | translate }}"
              formControlName="name"
            />
            <mat-error *ngFor="let validation of validationMessages.name">
              <ng-container
                *ngIf="
                  formControl?.name?.hasError(validation.type) &&
                  (formControl?.name?.dirty || formControl?.name?.touched)
                "
              >
                {{ validation.message | translate }}
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </fieldset>

      <fieldset>
        <legend [translate]="'zone.robotsAvailable'"></legend>

        <div>
          <webclient-robot-select
            [data]="robots"
            formControlName="robots"
          ></webclient-robot-select>
        </div>
      </fieldset>
    </div>

    <div fxFlex class="webclient-zone-admin-user-groups">
      <!-- Liste des groupes d'utilisateurs -->
      <webclient-user-group-select
        [data]="userGroups"
        formControlName="userGroups"
        [minSelected]="1"
      ></webclient-user-group-select>

      <mat-error *ngFor="let validation of validationMessages.userGroups">
        <ng-container
          *ngIf="
            form.get('userGroups')?.hasError(validation.type) &&
            (form.get('userGroups')?.dirty || form.get('userGroups')?.touched)
          "
        >
          {{ validation.message | translate }}
        </ng-container>
      </mat-error>
    </div>
  </form>
</webclient-entity-form>
