import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  ValidationErrors
} from '@angular/forms';
import { Robot } from '../../../../api/models/robot';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { EntityFormComponent } from '../../../../components/admin/entity-form/entity-form.component';
import { RobotService } from '../../../../core/services/entities/robot.service';
import { IEntity } from '../../../../components/admin/models/i-entity';
import { NotificationService } from '../../../../core/services/notification.service';
import { CustomValidators } from '../../../../core/validators/custom-validators';
import { UserGroup } from '../../../../api/models/user-group';
import { UserGroupService } from '../../../../core/services/entities/user-group.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { RobotAlarm } from '../../../../core/enums/robot-alarm.enum';
import { RobotAnomalie } from '../../../../core/enums/robot-anomalie.enum';
import { RobotAdapter } from '../../../../core/models/entities/robot-adapter';
import { Utils } from '../../../../core/utils/utils';
import { DispatcherService } from '../../../../core/services/dispatcher.service';
import { combineLatest, take } from 'rxjs';
import { SessionService } from '../../../../core/services/session.service';
import { RoslibService } from '@lelab31/ngx-rodotic';
import { MatSelectChange } from '@angular/material/select';
import { Customer } from '../../../../api/models/customer';
import { Zone } from '../../../../api/models/zone';
import { CustomerService } from '../../../../core/services/entities/customer.service';
import { ZoneService } from 'apps/client/src/app/core/services/entities/zone.service';

@Component({
  selector: 'webclient-robot-form',
  templateUrl: './robot-form.component.html',
  styleUrls: ['./robot-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'webclient-robot-form',
  },
})
export class RobotFormComponent extends EntityFormComponent {
  /**
   * Robot
   */
  public robotAdapter: RobotAdapter | undefined;

  /**
   * Groupes d'utilisateurs
   */
  public userGroups: UserGroup[] = [];
  public currentZone: Zone | undefined;

  /**
   * Liste des clients
   */
  public customers: Customer[] = [];

  /**
   * Messages d'erreur
   */
  public validationMessages = {
    customer: [
      {
        type: 'required',
        message: 'robot.validationMessages.customer.required',
      },
    ],
    email: [
      { type: 'email', message: 'robot.validationMessages.email.invalid' },
      {
        type: 'maxlength',
        message: 'robot.validationMessages.email.maxlength',
        params: { maxlength: 255 },
      },
      { type: 'required', message: 'robot.validationMessages.email.required' },
    ],
    phone: [
      { type: 'phone', message: 'robot.validationMessages.phone.invalid' },
    ],
    ip: [
      { type: 'required', message: 'robot.validationMessages.ip.required' },
      { type: 'pattern', message: 'robot.validationMessages.ip.invalid' },
    ],
    name: [
      { type: 'required', message: 'robot.validationMessages.name.required' },
      {
        type: 'maxlength',
        message: 'robot.validationMessages.name.maxlength',
        params: { maxlength: 255 },
      },
    ],
    onvifHostname: [
      {
        type: 'required',
        message: 'robot.validationMessages.onvif.hostname.required',
      },
      {
        type: 'maxlength',
        message: 'robot.validationMessages.onvif.hostname.maxlength',
        params: { maxlength: 255 },
      },
    ],
    onvifRtspPort: [
      {
        type: 'range',
        message: 'robot.validationMessages.onvif.rtspPort.range',
        params: { min: 0, max: 65535 },
      },
      {
        type: 'required',
        message: 'robot.validationMessages.onvif.rtspPort.required',
      },
    ],
    onvifHttpPort: [
      {
        type: 'range',
        message: 'robot.validationMessages.onvif.httpPort.range',
        params: { min: 0, max: 65535 },
      },
      {
        type: 'required',
        message: 'robot.validationMessages.onvif.httpPort.required',
      },
    ],
    onvifUsername: [
      {
        type: 'required',
        message: 'robot.validationMessages.onvif.username.required',
      },
      {
        type: 'maxlength',
        message: 'robot.validationMessages.onvif.username.maxlength',
        params: { maxlength: 255 },
      },
    ],
    onvifPassword: [
      {
        type: 'rangelength',
        message: 'robot.validationMessages.onvif.password.rangelength',
        params: { minlength: 8, maxlength: 255 },
      },
      {
        type: 'required',
        message: 'robot.validationMessages.onvif.password.required',
      },
      {
        type: 'pattern',
        message: 'robot.validationMessages.onvif.password.pattern',
      },
    ],
    port: [
      {
        type: 'range',
        message: 'robot.validationMessages.port.range',
        params: { min: 0, max: 65535 },
      },
      { type: 'required', message: 'robot.validationMessages.port.required' },
    ],
    sftpDelayBetweenRecord: [
      {
        type: 'required',
        message: 'robot.validationMessages.sftp.delayBetweenRecord.required',
      },
    ],
    sftpHostname: [
      {
        type: 'maxlength',
        message: 'robot.validationMessages.sftp.hostname.maxlength',
        params: { maxlength: 255 },
      },
      {
        type: 'required',
        message: 'robot.validationMessages.sftp.hostname.required',
      },
    ],
    sftpPassword: [
      {
        type: 'rangelength',
        message: 'robot.validationMessages.sftp.password.rangelength',
        params: { minlength: 8, maxlength: 255 },
      },
      {
        type: 'required',
        message: 'robot.validationMessages.sftp.password.required',
      },
      {
        type: 'pattern',
        message: 'robot.validationMessages.sftp.password.pattern',
      },
    ],
    sftpPort: [
      {
        type: 'range',
        message: 'robot.validationMessages.sftp.port.range',
        params: { min: 0, max: 65535 },
      },
      {
        type: 'required',
        message: 'robot.validationMessages.sftp.port.required',
      },
    ],
    sftpUsername: [
      {
        type: 'maxlength',
        message: 'robot.validationMessages.sftp.username.maxlength',
        params: { maxlength: 255 },
      },
      {
        type: 'required',
        message: 'robot.validationMessages.sftp.username.required',
      },
    ],
    sftpRecordDuration: [
      {
        type: 'required',
        message: 'robot.validationMessages.sftp.recordDuration.required',
      },
    ],
    sftpRemoteFolder: [
      {
        type: 'maxlength',
        message: 'robot.validationMessages.sftp.remoteFolder.maxlength',
        params: { maxlength: 255 },
      },
      {
        type: 'required',
        message: 'robot.validationMessages.sftp.remoteFolder.required',
      },
    ],
    userGroups: [
      {
        type: 'requireOne',
        message: 'robot.validationMessages.userGroups.requireOne',
      },
    ],
    wifiSsid: [
      {
        type: 'required',
        message: 'robot.validationMessages.wifi.ssid.required',
      },
      {
        type: 'maxlength',
        message: 'robot.validationMessages.wifi.ssid.maxlength',
        params: { maxlength: 255 },
      },
    ],
    wifiPassword: [
      {
        type: 'rangelength',
        message: 'robot.validationMessages.wifi.password.rangelength',
        params: { minlength: 8, maxlength: 255 },
      },
      {
        type: 'required',
        message: 'robot.validationMessages.wifi.password.required',
      },
      {
        type: 'pattern',
        message: 'robot.validationMessages.wifi.password.pattern',
      },
    ],
    wifiIp: [
      {
        type: 'required',
        message: 'robot.validationMessages.wifi.ip.required',
      },
      { type: 'pattern', message: 'robot.validationMessages.wifi.ip.invalid' },
      { type: 'invalidIpMaskGateway', message: 'L\'adresse ip de sous-réseau ne correspond pas au masque et la passerelle.' },
    ],
    wifiMask: [
      {
        type: 'required',
        message: 'robot.validationMessages.wifi.mask.required',
      },
      {
        type: 'pattern',
        message: 'robot.validationMessages.wifi.mask.invalid',
      },
      { type: 'invalidIpMaskGateway', message: 'Le masque de sous-réseau ne correspond pas à l\'adresse IP et la passerelle.' },
    ],
    wifiRouterIp: [
      {
        type: 'required',
        message: 'robot.validationMessages.wifi.routerip.required',
      },
      {
        type: 'pattern',
        message: 'robot.validationMessages.wifi.routerip.invalid',
      },
      { type: 'invalidIpMaskGateway', message: 'La passerelle ne correspond pas à l\'adresse IP et le masque de sous-réseau.' },
    ],
  };

  /**
   * Voir le mot de passe wifi
   */
  public showWifiPassword = false;

  /**
   * Voir le mot de passe SFTP
   */
  public showSftpPassword = false;

  /**
   * Voir le mot de passe onvif
   */
  public showonvifPassword = false;

  public outputGroups: any[] = [
    {
      name: 'robot.alarms',
      prefix: 'robot.alarm.',
      values: Object.values(RobotAlarm),
    },
    {
      name: 'robot.anomalies',
      prefix: 'robot.anomalie.',
      values: Object.values(RobotAnomalie),
    },
  ];

  public compareCustomer(ug1: UserGroup, ug2: UserGroup): boolean {
    return ug1 && ug2 ? ug1.id === ug2.id : ug1 === ug2;
  }

  constructor(
    protected override route: ActivatedRoute,
    protected override sessionService: SessionService,
    private robotService: RobotService,
    private customerService: CustomerService,
    private roslibService: RoslibService,
    private rosLibService: RoslibService,
    private dispatcherService: DispatcherService,
    private formBuilder: FormBuilder,
    protected override translateService: TranslateService,
    protected override notificationService: NotificationService,
    protected override location: Location,
    private userGroupService: UserGroupService,
    private zoneService: ZoneService
  ) {
    super(
      route,
      sessionService,
      translateService,
      notificationService,
      location
    );

    if (
      this.validationMessages.name[1].params &&
      this.validationMessages.port[0].params
    ) {
      this.form = this.formBuilder.group({
        customer: [null, [Validators.required]],
        email: [{ value: '', disabled: true }, []],
        emailAlert: [false, [Validators.required]],
        externalInterfaces: [false, []],
        fireAlarm: [{ value: true, disabled: true }, [Validators.required]],
        id: [{ value: '', disabled: true }, []],
        ip: [
          { value: '', disabled: !this.sessionService.isSuperAdmin() },
          [
            Validators.required,
            Validators.pattern(
              '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'
            ),
          ],
        ],
        name: [
          '',
          [
            Validators.required,
            Validators.maxLength(
              this.validationMessages.name[1].params.maxlength
            ),
          ],
        ],
        onvif: [false, []],
        onvifHostname: [
          { value: null, disabled: !this.sessionService.isSuperAdmin() },
          [],
        ],
        onvifRtspPort: [
          { value: null, disabled: !this.sessionService.isSuperAdmin() },
          [],
        ],
        onvifHttpPort: [
          { value: null, disabled: !this.sessionService.isSuperAdmin() },
          [],
        ],
        onvifUsername: [null, []],
        onvifPassword: [null, []],
        outputs: new FormArray(
          [],
          []
        ),
        patrolAnomaliesAlert: [
          { value: true, disabled: true },
          [Validators.required],
        ],
        phone: [{ value: '', disabled: true }, []],
        phoneAlert: [false, [Validators.required]],
        port: [
          { value: '', disabled: !this.sessionService.isSuperAdmin() },
          [
            Validators.required,
            CustomValidators.range(
              this.validationMessages.port[0].params.min,
              this.validationMessages.port[0].params.max
            ),
          ],
        ],
        rfJammingAlert: [
          { value: false, disabled: true },
          [Validators.required],
        ],
        sftp: [false, []],
        sftpHostname: [null, []],
        sftpPort: [null, []],
        sftpUsername: [null, []],
        sftpPassword: [null, []],
        sftpRemoteFolder: [null, []],
        sftpRecordDuration: [null, []],
        sftpDelayBetweenRecord: [null, []],
        userGroups: [[], []],
        wifiSsid: [null, []],
        wifiPassword: [null, []],
        wifiIp: ['', [Validators.required, this.ipMaskGatewayValidator()]],
        wifiMask: ['', [Validators.required, this.ipMaskGatewayValidator()]],
        wifiRouterIp: ['', [Validators.required, this.ipMaskGatewayValidator()]],
      });

      this.customerService.getAll().subscribe((customers: Customer[]) => {
        this.customers = customers;
      });

      if (this.id) {
        this.robotService.getById(this.id).subscribe((robot: Robot) => {
          this.robotAdapter = new RobotAdapter(robot, this.rosLibService);

          this.userGroupService
            .getAll('customer=' + this.robotAdapter.robot.customer?.id)
            .subscribe((userGroups: UserGroup[]) => {
              this.userGroups = userGroups;
            });

          if(this.robotAdapter.robot.zone.id){
            this.zoneService.getById(this.robotAdapter.robot.zone?.id).subscribe((zone: Zone) => {
              this.currentZone = zone;
            });
          }

          this.setEmailAlertValidator(robot.emailAlert);
          this.setEmailPhoneValidator(robot.phoneAlert);
          this.setOnvifValidator(robot.onvif);
          this.setSftpValidator(robot.sftp);
        });
      }
    }
  }

  /**
   * Création d'un robot
   *
   * @param entity le robot à créer
   */
  public onCreate(entity: IEntity) {
    const robot: Robot = entity as Robot;

    this.robotService.create(robot).subscribe(
      (robot: Robot) => {
        this.displaySuccessSnackBar(robot.name, 'robot.createSuccessMessage');

        combineLatest([
          this.dispatcherService.create(robot),
          this.dispatcherService.bind(robot),
        ]).subscribe(
          (result: [bindMsg: string, createMsg: string]) => {},
          () => {
            this.displayErrorSnackBar(
              robot.name,
              'robot.createOrBindErrorMessage'
            );
          }
        );
      },
      () => {
        this.displayErrorSnackBar(robot.name, 'robot.createErrorMessage');
      }
    );
  }

  /**
   * Edition d'un robot
   *
   * @param entity le robot à éditer
   */
  public onEdit(entity: IEntity) {
    const robot: Robot = entity as Robot;

    //Si on a changé le client
    if(this.isCustomerChanged() === true){

      if(this.currentZone && this.currentZone?.robots){

        //Recherche de la zone du robot
        const indexzone: number = this.currentZone.robots.findIndex(
          (r: Robot) => r.id === this.robotAdapter?.robot?.id
        );

        //Reset robot.zone_id
        if(indexzone !== -1){

          //On enlève le robot de la zone
          this.currentZone.robots.splice(indexzone, 1);

          this.zoneService.update(this.currentZone?.id, this.currentZone).subscribe(
            (zone: Zone) => {
              this.currentZone = zone;
            }
          );
        }

      }

      //Reset robot.active_map_id
      robot.activeMap = null;
      //Reset robot.active_map_configuration_id
      robot.activeMapConfiguration = null;

    }

    combineLatest([
      this.robotService.update(this.id, robot),
      this.dispatcherService.create(robot),
      this.dispatcherService.bind(robot),
    ])
      .pipe(take(1))
      .subscribe(
        (result: [robot: Robot, bindMsg: string, createMsg: string]) => {
          this.robotAdapter = new RobotAdapter(result[0], this.roslibService);

          if (this.robotAdapter) {
            this.robotAdapter.setConfigurationRobotCommand(
              this.robotAdapter.robot
            );
            this.displaySuccessSnackBar(robot.name, 'robot.editSuccessMessage');
          } else {
            this.displayErrorSnackBar(robot.name, 'robot.editErrorMessage');
          }
        },
        () => {
          this.displayErrorSnackBar(robot.name, 'robot.editErrorMessage');
        }
      );
  }

  /**
   * Validateur des alertes email
   *
   * @param has indique s'il faut positionner le validateur
   * @private
   */
  private setEmailAlertValidator(has: boolean): void {
    if (has) {
      this.formControl.email.enable();
      this.formControl.email.setValidators([
        Validators.required,
        Validators.email,
      ]);
      if (this.validationMessages?.email[1]?.params?.maxlength) {
        this.formControl.email.addValidators(
          Validators.maxLength(
            this.validationMessages.email[1].params.maxlength
          )
        );
      }
    } else {
      this.formControl.email.setValue(null);
      this.formControl.email.clearValidators();
      this.formControl.email.disable();
    }

    this.formControl.email.updateValueAndValidity();
  }

  /**
   * Validateur des alertes téléphone
   *
   * @param has indique s'il faut positionner le validateur
   * @private
   */
  private setEmailPhoneValidator(has: boolean): void {
    if (has) {
      this.formControl.phone.enable();
      this.formControl.phone.setValidators([Validators.required]);
    } else {
      this.formControl.phone.setValue(null);
      this.formControl.phone.clearValidators();
      this.formControl.phone.disable();
    }

    this.formControl.phone.updateValueAndValidity();
  }

  /**
  * Validateur de l'ipWifi, le masque sous réseaux et le routeur wifi
  */
  private ipMaskGatewayValidator(): ValidatorFn {
    return (formControl: AbstractControl): ValidationErrors | null => {
      const ipAddress = formControl.parent?.get('wifiIp')?.value;
      const maskAddress = formControl.parent?.get('wifiMask')?.value;
      const gatewayAddress = formControl.parent?.get('wifiRouterIp')?.value;


      if (!ipAddress || !maskAddress || !gatewayAddress) {
        return null; // La validation ne s'applique que si les 3 champs ne  sont pas renseignés.
      }
      // Validation : Vérifier si l'adresse IP appartient bien au sous-réseau défini par le masque et la passerelle.
      if (!this.isSameSubnet(ipAddress, maskAddress, gatewayAddress)) {
        formControl.parent?.get('wifiIp')?.setErrors({ invalidIpMaskGateway: true })
        formControl.parent?.get('wifiIp')?.markAsTouched();
        formControl.parent?.get('wifiMask')?.setErrors({ invalidIpMaskGateway: true })
        formControl.parent?.get('wifiMask')?.markAsTouched();
        formControl.parent?.get('wifiRouterIp')?.setErrors({ invalidIpMaskGateway: true })
        formControl.parent?.get('wifiRouterIp')?.markAsTouched();
        return { invalidIpMaskGateway: true };
      }

      // Si la validation réussit, retournez null (pas d'erreur de validation).
      formControl.parent?.get('wifiIp')?.setErrors(null)
      formControl.parent?.get('wifiMask')?.setErrors(null)
      formControl.parent?.get('wifiRouterIp')?.setErrors(null)
      return null;
    };
  }


  /**
  * Vérifie si le wifiIp, le masque et le routeur wifi appartiennent au même sous réseau
  *
  * @param  ipAddress - L'adresse IP à vérifier.
  * @param  maskAddress - Le masque de sous-réseau associé à l'adresse IP.
  * @param  gatewayAddress - L'adresse du routeur à comparer avec l'adresse IP.
  */
  private isSameSubnet(ipAddress: string, maskAddress: string, gatewayAddress: string): boolean {
    const ipParts = ipAddress.split('.').map(part => parseInt(part, 10));
    const maskParts = maskAddress.split('.').map(part => parseInt(part, 10));
    const gatewayParts = gatewayAddress.split('.').map(part => parseInt(part, 10));

    // calcul des parties de l'adresse IP et du routeur dans le réseau
    if((ipParts.includes(NaN)) || (maskParts.includes(NaN)) || (gatewayParts.includes(NaN))){
      return false;
    }

    const networkIpParts = ipParts.map((part, index) => part & maskParts[index]);
    const networkGatewayParts = gatewayParts.map((part, index) => part & maskParts[index]);

    // comparaison des parties de l'adresse IP et du routeur dans le réseau pour vérifier s'ils sont identiques.
    for (let i = 0; i < 4; i++) {
      if (networkIpParts[i] !== networkGatewayParts[i]) {
        return false;
      }
    }
    return true;
  }

  /**
   * Validateur onvif
   *
   * @param has indique s'il faut positionner le validateur
   * @private
   */
  private setOnvifValidator(has: boolean): void {
    if (has) {
      if (this.sessionService.isSuperAdmin()) {
        this.formControl.onvifHostname.enable();
        this.formControl.onvifHostname.setValidators([Validators.required]);
        if (this.validationMessages?.onvifHostname[0]?.params?.maxlength) {
          this.formControl.onvifHostname.addValidators(
            Validators.maxLength(
              this.validationMessages.onvifHostname[0].params.maxlength
            )
          );
        }

        this.formControl.onvifRtspPort.enable();
        this.formControl.onvifRtspPort.setValidators([Validators.required]);
        if (
          this.validationMessages?.onvifRtspPort[0]?.params?.min &&
          this.validationMessages?.onvifRtspPort[0]?.params?.max
        ) {
          this.formControl.onvifRtspPort.addValidators(
            CustomValidators.range(
              this.validationMessages.onvifRtspPort[0].params.min,
              this.validationMessages.onvifRtspPort[0].params.max
            )
          );
        }

        this.formControl.onvifHttpPort.enable();
        this.formControl.onvifHttpPort.setValidators([Validators.required]);
        if (
          this.validationMessages?.onvifHttpPort[0]?.params?.min &&
          this.validationMessages?.onvifHttpPort[0]?.params?.max
        ) {
          this.formControl.onvifHttpPort.addValidators(
            CustomValidators.range(
              this.validationMessages.onvifHttpPort[0].params.min,
              this.validationMessages.onvifHttpPort[0].params.max
            )
          );
        }
      }

      this.formControl.onvifUsername.enable();
      this.formControl.onvifUsername.setValidators([Validators.required]);
      if (this.validationMessages?.onvifUsername[0]?.params?.maxlength) {
        this.formControl.onvifUsername.addValidators(
          Validators.maxLength(
            this.validationMessages.onvifUsername[0].params.maxlength
          )
        );
      }

      this.formControl.onvifPassword.enable();
      this.formControl.onvifPassword.setValidators([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9]+$'),
      ]);
      if (
        this.validationMessages?.onvifPassword[0]?.params?.minlength &&
        this.validationMessages?.onvifPassword[0]?.params?.maxlength
      ) {
        this.formControl.onvifPassword.addValidators(
          CustomValidators.rangeLength(
            this.validationMessages.onvifPassword[0].params.minlength,
            this.validationMessages.onvifPassword[0].params.maxlength
          )
        );
      }
    } else {
      if (this.sessionService.isSuperAdmin()) {
        this.formControl.onvifHostname.clearValidators();
        this.formControl.onvifRtspPort.clearValidators();
        this.formControl.onvifHttpPort.clearValidators();
        this.formControl.onvifHostname.disable();
        this.formControl.onvifRtspPort.disable();
        this.formControl.onvifHttpPort.disable();
      }

      this.formControl.onvifUsername.setValue(null);
      this.formControl.onvifPassword.setValue(null);
      this.formControl.onvifUsername.clearValidators();
      this.formControl.onvifPassword.clearValidators();
      this.formControl.onvifUsername.disable();
      this.formControl.onvifPassword.disable();
    }

    this.formControl.onvifHostname.updateValueAndValidity();
    this.formControl.onvifRtspPort.updateValueAndValidity();
    this.formControl.onvifHttpPort.updateValueAndValidity();
    this.formControl.onvifUsername.updateValueAndValidity();
    this.formControl.onvifPassword.updateValueAndValidity();
  }

  /**
   * Validateur sftp
   *
   * @param has indique s'il faut positionner le validateur
   * @private
   */
  private setSftpValidator(has: boolean): void {
    if (has) {
      this.formControl.sftpHostname.enable();
      this.formControl.sftpHostname.setValidators([Validators.required]);
      if (this.validationMessages?.sftpHostname[0]?.params?.maxlength) {
        this.formControl.sftpHostname.addValidators(
          Validators.maxLength(
            this.validationMessages.sftpHostname[0].params.maxlength
          )
        );
      }

      this.formControl.sftpPort.enable();
      this.formControl.sftpPort.setValidators([Validators.required]);
      if (
        this.validationMessages?.sftpPort[0]?.params?.min &&
        this.validationMessages?.sftpPort[0]?.params?.max
      ) {
        this.formControl.sftpPort.addValidators(
          CustomValidators.range(
            this.validationMessages.sftpPort[0].params.min,
            this.validationMessages.sftpPort[0].params.max
          )
        );
      }

      this.formControl.sftpUsername.enable();
      this.formControl.sftpUsername.setValidators([Validators.required]);
      if (this.validationMessages?.sftpUsername[0]?.params?.maxlength) {
        this.formControl.sftpUsername.addValidators(
          Validators.maxLength(
            this.validationMessages.sftpUsername[0].params.maxlength
          )
        );
      }

      this.formControl.sftpPassword.enable();
      this.formControl.sftpPassword.setValidators([
        Validators.required,
        Validators.pattern(Utils.patternPassword()),
      ]);
      if (
        this.validationMessages?.sftpPassword[0]?.params?.minlength &&
        this.validationMessages?.sftpPassword[0]?.params?.maxlength
      ) {
        this.formControl.sftpPassword.addValidators(
          CustomValidators.rangeLength(
            this.validationMessages.sftpPassword[0].params.minlength,
            this.validationMessages.sftpPassword[0].params.maxlength
          )
        );
      }

      this.formControl.sftpRemoteFolder.enable();
      this.formControl.sftpRemoteFolder.setValidators([Validators.required]);
      if (this.validationMessages?.sftpRemoteFolder[0]?.params?.maxlength) {
        this.formControl.sftpRemoteFolder.addValidators(
          Validators.maxLength(
            this.validationMessages.sftpRemoteFolder[0].params.maxlength
          )
        );
      }

      this.formControl.sftpRecordDuration.enable();
      this.formControl.sftpRecordDuration.setValidators([Validators.required]);

      this.formControl.sftpDelayBetweenRecord.enable();
      this.formControl.sftpDelayBetweenRecord.setValidators([
        Validators.required,
      ]);
    } else {
      this.formControl.sftpHostname.setValue(null);
      this.formControl.sftpPort.setValue(null);
      this.formControl.sftpUsername.setValue(null);
      this.formControl.sftpPassword.setValue(null);
      this.formControl.sftpRemoteFolder.setValue(null);
      this.formControl.sftpRecordDuration.setValue(null);
      this.formControl.sftpDelayBetweenRecord.setValue(null);
      this.formControl.sftpHostname.clearValidators();
      this.formControl.sftpPort.clearValidators();
      this.formControl.sftpUsername.clearValidators();
      this.formControl.sftpPassword.clearValidators();
      this.formControl.sftpRemoteFolder.clearValidators();
      this.formControl.sftpRecordDuration.clearValidators();
      this.formControl.sftpDelayBetweenRecord.clearValidators();
      this.formControl.sftpHostname.disable();
      this.formControl.sftpPort.disable();
      this.formControl.sftpUsername.disable();
      this.formControl.sftpPassword.disable();
      this.formControl.sftpRemoteFolder.disable();
      this.formControl.sftpRecordDuration.disable();
      this.formControl.sftpDelayBetweenRecord.disable();
    }

    this.formControl.sftpHostname.updateValueAndValidity();
    this.formControl.sftpPort.updateValueAndValidity();
    this.formControl.sftpUsername.updateValueAndValidity();
    this.formControl.sftpPassword.updateValueAndValidity();
    this.formControl.sftpRemoteFolder.updateValueAndValidity();
    this.formControl.sftpRecordDuration.updateValueAndValidity();
    this.formControl.sftpDelayBetweenRecord.updateValueAndValidity();
  }

  /**
   * Positionne les alertes par email ainsi que les validateurs de ses paramètres
   *
   * @param event le statut du bouton toggle
   */
  public onChangeEmailAlert(event: MatSlideToggleChange): void {
    this.setEmailAlertValidator(event.checked);
  }

  /**
   * Positionne les alertes par téléphone ainsi que les validateurs de ses paramètres
   *
   * @param event le statut du bouton toggle
   */
  public onChangePhoneAlert(event: MatSlideToggleChange) {
    this.setEmailPhoneValidator(event.checked);
  }

  /**
   * Positionne le protocole onvif ainsi que les validateurs de ses paramètres
   *
   * @param event le statut du bouton toggle
   */
  public onChangeonvifProtocol(event: MatSlideToggleChange) {
    this.setOnvifValidator(event.checked);
  }

  /**
   * Positionne le module sftp ainsi que les validateurs de ses paramètres
   *
   * @param event le statut du bouton toggle
   */
  public onChangeSftpModule(event: MatSlideToggleChange) {
    this.setSftpValidator(event.checked);
  }

  /**
   * Positionne les interfaces externes
   *
   * @param event le statut du bouton toggle
   */
  public onChangeExternalInterfaces(event: MatSlideToggleChange) {
    if (!event.checked) {
      this.formControl.outputs.setValue([]);
    }
  }

  /**
   * Indique s'il y a une erreur dans les paramètres onvif
   */
  public hasOnvifError(): boolean {
    let hasError = false;

    this.validationMessages.onvifHostname.forEach((validation) => {
      if (
        this.formControl.onvifHostname.hasError(validation.type) &&
        (this.formControl.onvifHostname.dirty ||
          this.formControl.onvifHostname.touched)
      ) {
        hasError = true;
      }
    });

    this.validationMessages.onvifRtspPort.forEach((validation) => {
      if (
        this.formControl.onvifRtspPort.hasError(validation.type) &&
        (this.formControl.onvifRtspPort.dirty ||
          this.formControl.onvifRtspPort.touched)
      ) {
        hasError = true;
      }
    });

    this.validationMessages.onvifHttpPort.forEach((validation) => {
      if (
        this.formControl.onvifHttpPort.hasError(validation.type) &&
        (this.formControl.onvifHttpPort.dirty ||
          this.formControl.onvifHttpPort.touched)
      ) {
        hasError = true;
      }
    });

    this.validationMessages.onvifUsername.forEach((validation) => {
      if (
        this.formControl.onvifUsername.hasError(validation.type) &&
        (this.formControl.onvifUsername.dirty ||
          this.formControl.onvifUsername.touched)
      ) {
        hasError = true;
      }
    });

    this.validationMessages.onvifPassword.forEach((validation) => {
      if (
        this.formControl.onvifPassword.hasError(validation.type) &&
        (this.formControl.onvifPassword.dirty ||
          this.formControl.onvifPassword.touched)
      ) {
        hasError = true;
      }
    });

    return hasError;
  }

  /**
   * Indique s'il y a une erreur dans les paramètres sftp
   */
  public hasSftpError() {
    let hasError = false;

    this.validationMessages.sftpHostname.forEach((validation) => {
      if (
        this.formControl.sftpHostname.hasError(validation.type) &&
        (this.formControl.sftpHostname.dirty ||
          this.formControl.sftpHostname.touched)
      ) {
        hasError = true;
      }
    });

    this.validationMessages.sftpPort.forEach((validation) => {
      if (
        this.formControl.sftpPort.hasError(validation.type) &&
        (this.formControl.sftpPort.dirty || this.formControl.sftpPort.touched)
      ) {
        hasError = true;
      }
    });

    this.validationMessages.sftpUsername.forEach((validation) => {
      if (
        this.formControl.sftpUsername.hasError(validation.type) &&
        (this.formControl.sftpUsername.dirty ||
          this.formControl.sftpUsername.touched)
      ) {
        hasError = true;
      }
    });

    this.validationMessages.sftpPassword.forEach((validation) => {
      if (
        this.formControl.sftpPassword.hasError(validation.type) &&
        (this.formControl.sftpPassword.dirty ||
          this.formControl.sftpPassword.touched)
      ) {
        hasError = true;
      }
    });

    this.validationMessages.sftpRemoteFolder.forEach((validation) => {
      if (
        this.formControl.sftpRemoteFolder.hasError(validation.type) &&
        (this.formControl.sftpRemoteFolder.dirty ||
          this.formControl.sftpRemoteFolder.touched)
      ) {
        hasError = true;
      }
    });

    this.validationMessages.sftpRecordDuration.forEach((validation) => {
      if (
        this.formControl.sftpRecordDuration.hasError(validation.type) &&
        (this.formControl.sftpRecordDuration.dirty ||
          this.formControl.sftpRecordDuration.touched)
      ) {
        hasError = true;
      }
    });

    this.validationMessages.sftpDelayBetweenRecord.forEach((validation) => {
      if (
        this.formControl.sftpDelayBetweenRecord.hasError(validation.type) &&
        (this.formControl.sftpDelayBetweenRecord.dirty ||
          this.formControl.sftpDelayBetweenRecord.touched)
      ) {
        hasError = true;
      }
    });

    return hasError;
  }

  /**
   * Indique si un groupe d'ulisateur n'a pas été sélectionné
   */
  public hasUserGroupsError() {
    let hasError = false;

    this.validationMessages.userGroups.forEach((validation) => {
      if (this.formControl.userGroups.hasError(validation.type)) {
        hasError = true;
      }
    });

    return hasError;
  }

  /**
   * Changement de client
   *
   * @param selected
   */
  public onChangeCustomer(selected: MatSelectChange) {
    this.userGroupService
      .getAll('customer=' + selected.value.id)
      .subscribe((userGroups: UserGroup[]) => {
        this.userGroups = userGroups;
      });
  }

  /**
   * Indique si un champ du formulaire a été modifié.
   */
  public getChangedProperties(): string[] {
    let changedProperties: any = [];
    Object.keys(this.formControl).forEach((name) => {
      const currentControl = this.formControl[name];
      if (currentControl.dirty) {
        changedProperties.push(name);
      }
    });
    return changedProperties;
  }

  /**
   * Indique si on doit désactiver le bouton Submit du formulaire
   * Les paramètres qui peuvent être modifiés et sauvegardés sans que le robot soit connecté sont:
   *   - Client
   *   - IP (ligne configuration, pas l'IP du wifi)
   *   - Groupes d'utilisateurs
   */
  public shouldDisableSubmit(): any {
    const changedProperties = this.getChangedProperties();
    const changedPropertiesNotAllowed = changedProperties.find(
      (property: string) =>
        property !== 'customer' &&
        property !== 'ip' &&
        property !== 'userGroups'
    );

    if(this.form?.valid){
      // Si les propriétes modifiés nécessitent que le robot soit connecté
      if (
        changedPropertiesNotAllowed !== null &&
        changedPropertiesNotAllowed !== undefined
      ) {
        return this.robotAdapter?.robot?.id && !this.robotAdapter?.connected;
      }

      // Sinon les propriétes modifiés ne nécessite pas que le robot soit connecté
      else if (
        changedProperties !== null &&
        changedProperties !== undefined &&
        changedProperties.length > 0
      ) {
        return false;
      }

      // Sinon il n'y a pas de changement à enregistrer.
      else {
        return true;
      }
    }
    else {
      return true;
    }
  }

  /**
   * Indique si on a modifié le champ 'client'
   */
  public isCustomerChanged(): any {

    if(this.robotAdapter && this.robotAdapter?.robot && this.robotAdapter.robot.customer && this.robotAdapter.robot.customer.id){
      if(this.formControl.customer.value.id === this.robotAdapter.robot.customer.id){
        return false;
      }
      else{
        return true;
      }
    }
  }
}
