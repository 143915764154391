import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { RobotAdapter } from '../../../core/models/entities/robot-adapter';

@Component({
  selector: 'webclient-robot-logger',
  templateUrl: './robot-logger.component.html',
  styleUrls: ['./robot-logger.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'webclient-robot-logger',
  },
})
export class RobotLoggerComponent implements OnInit {
  /**
   * Le robot
   */
  @Input() public robotAdapter: RobotAdapter | undefined;

  constructor() {}

  ngOnInit(): void {}

  /**
   * Afface les logs
   */
  public onClear() {
    if (this.robotAdapter) {
      this.robotAdapter.logs = [];
    }
  }
}
