import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RobotAdminRoutingModule } from './robot-admin-routing.module';
import { RobotListComponent } from './robot-list/robot-list.component';
import { RobotFormComponent } from './robot-form/robot-form.component';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ComponentsModule } from '../../../components/components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexModule } from '@angular/flex-layout';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ConnectionStatusModule, LedModule } from '@lelab31/ngx-rodotic';
import { MatSelectModule } from '@angular/material/select';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [RobotListComponent, RobotFormComponent],
  imports: [
    CommonModule,
    RobotAdminRoutingModule,
    MatTableModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ComponentsModule,
    ReactiveFormsModule,
    MatTabsModule,
    FlexModule,
    MatSlideToggleModule,
    LedModule,
    MatSelectModule,
    ConnectionStatusModule,
    NgxMaskModule.forRoot(),

  ],
})
export class RobotAdminModule {}
