import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ThemeModule } from './theme/theme.module';
import { AngularMaterialModule } from './angular-material.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import {
  HashLocationStrategy,
  LocationStrategy,
  registerLocaleData,
} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AuthService } from './core/services/security/auth.service';
import { JwtInterceptor } from './core/interceptors/jwt-interceptor';
import { appInitializer } from './app.initializer';
import { ErrorInterceptor } from './core/interceptors/error-interceptor';
import {
  NgxUiLoaderConfig,
  NgxUiLoaderHttpConfig,
  NgxUiLoaderHttpModule,
  NgxUiLoaderModule,
} from 'ngx-ui-loader';
import { environment } from '../environments/environment';
import { FormModule } from './components/form/form.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PagesModule } from './pages/pages.module';
import { ComponentsModule } from './components/components.module';
import { SharedService } from './core/services/shared.service';

registerLocaleData(localeFr);

export function httpTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  pbColor: '#3a3174',
  fgsColor: '#3a3174',
  bgsPosition: 'center-center',
  fgsType: 'ball-scale-multiple',
  fgsSize: 300,
};

const ngxUiLoaderHttpConfig: NgxUiLoaderHttpConfig = {
  showForeground: true,
  exclude: [
    `${environment.apiBaseUrl}token/refresh`,
    `${environment.apiBaseUrl}checkpoints`,
    `${environment.apiBaseUrl}patrols`,
    `${environment.apiBaseUrl}patrol_checkpoints`,
    `${environment.apiBaseUrl}strategies`,
    `${environment.apiBaseUrl}map_configurations`,
    `${environment.apiBaseUrl}timeslots`,
  ],
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ThemeModule,
    AngularMaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot(ngxUiLoaderHttpConfig),
    ComponentsModule,
    PagesModule,
    FormModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    SharedService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
