import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IEntity } from '../models/i-entity';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { NotificationService } from '../../../core/services/notification.service';
import { SessionService } from '../../../core/services/session.service';
import { User } from '../../../api/models/user';
import { Role } from '../../../core/enums/role.enum';

@Component({
  selector: 'webclient-entity-form',
  templateUrl: './entity-form.component.html',
  styleUrls: ['./entity-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'webclient-entity-form',
  },
})
export class EntityFormComponent implements OnChanges {
  /**
   * Données
   */
  @Input() public entity: IEntity | undefined;

  /**
   * Formulaire
   */
  @Input() public form: FormGroup | undefined;

  /**
   * URL vers la page du formulaire
   */
  @Input() public urlForm = '';

  /**
   * URL vers la page de la liste des entités
   */
  @Input() public urlList = '';

  /**
   * Titre de création d'une entité
   */
  @Input() public createTitle = '';

  /**
   * Titre d'édition d'une entité
   */
  @Input() public editTitle = '';

  /**
   * Icône
   */
  @Input() public icon = '';

  /**
   * Icône
   */
  @Input() public svgIcon = '';

  /**
   * Désactive le bouton de sauvegarde
   */
  @Input() public disabledSubmitButton: boolean | undefined = false;

  /**
   * Evènement lors de la création d'une entité
   */
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onCreateEntity = new EventEmitter<IEntity>();

  /**
   * Evènement lors de l'édition d'une entité
   */
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onEditEntity = new EventEmitter<IEntity>();

  /**
   * Identifiant
   */
  public id: number | undefined;

  /**
   * Indique si on est super admin
   */
  public isSuperAdmin = false;

  /**
   * Indique si on est admin
   */
  public isAdmin = false;

  /**
   * Utilisateur en cours
   */
  public currentUser: User | undefined;

  /**
   * Liste des rôles
   */
  public roles: Role[] = [];

  /**
   * Rôles
   */
  public ROLES = Role;

  constructor(
    protected route: ActivatedRoute,
    protected sessionService: SessionService,
    protected translateService: TranslateService,
    protected notificationService: NotificationService,
    protected location: Location
  ) {
    this.id = this.route.snapshot.params['id'];
    this.currentUser = this.sessionService.getCurrentUser();
    this.isSuperAdmin = this.sessionService.isSuperAdmin();
    this.isAdmin = this.sessionService.isAdmin();
    this.roles = Object.values<Role>(Role);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const entity = changes['entity'].currentValue as IEntity;

    if (entity !== undefined && entity !== null && this.form) {
      this.form?.patchValue(entity);
    }
  }

  /**
   * Retourne le contrôleur du formulaire
   */
  public get formControl(): any {
    return this.form?.controls;
  }

  /**
   * Sauvegarde des données
   */
  public onSubmit(): void {
    if (this.form?.valid && this.id === undefined) {
      this.onCreateEntity.emit(this.form.value);
    } else if (this.form?.valid && this.id !== undefined) {
      this.onEditEntity.emit(this.form.value);
    } else if (this.form) {
      this.form.markAllAsTouched();
    }
  }

  /**
   * Affichage de la snackbar de succés
   *
   * @param name le nom de l'entité
   * @param successMessage le message de succès
   *
   * @protected
   */
  protected displaySuccessSnackBar(
    name: string | undefined,
    successMessage: string
  ): void {
    const title: string = this.translateService.instant('entity.success');
    const message: string = this.translateService.instant(`${successMessage}`, {
      name: name,
    });

    this.notificationService.displaySuccessSnackBar(title, message);
    this.location.back();
  }

  /**
   * Affichage de la snackbar d'erreur
   *
   * @param name le nom de l'entité
   * @param errorMessage le message d'erreur
   *
   * @protected
   */
  protected displayErrorSnackBar(
    name: string | undefined,
    errorMessage: string
  ): void {
    const title: string = this.translateService.instant('entity.error');
    const message: string = this.translateService.instant(`${errorMessage}`, {
      name: name,
    });

    this.notificationService.displayErrorSnackBar(title, message);
  }
}
