import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { CheckpointListComponent } from './checkpoint-list/checkpoint-list.component';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { CardModule } from '../card/card.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormModule } from '../form/form.module';

@NgModule({
  declarations: [CheckpointListComponent],
  exports: [CheckpointListComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    TranslateModule,
    CardModule,
    MatDividerModule,
    MatButtonModule,
    DragDropModule,
    FlexLayoutModule,
    FormModule,
  ],
})
export class CheckpointModule {}
