export class Coordinate {
  public abscissa: number;

  public orderly: number;

  constructor(abscissa: number, orderly: number) {
    this.abscissa = abscissa;
    this.orderly = orderly;
  }
}
