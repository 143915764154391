import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { CustomValidators } from '../../../core/validators/custom-validators';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { RobotAlarm } from '../../../core/enums/robot-alarm.enum';
import { RobotAnomalie } from '../../../core/enums/robot-anomalie.enum';
import { RobotAdapter } from '../../../core/models/entities/robot-adapter';
import { Robot, RobotOutput } from '../../../api/models/robot';
import { RobotService } from '../../../core/services/entities/robot.service';
import { NotificationService } from '../../../core/services/notification.service';
import { Utils } from '../../../core/utils/utils';
import { switchMap } from 'rxjs';
import { DispatcherService } from '../../../core/services/dispatcher.service';
import { SessionService } from '../../../core/services/session.service';
import { RoslibService } from '@lelab31/ngx-rodotic';
import { TriggerConf } from '../../../api/models/trigger-conf';
import { RobotAlert } from '../../../core/enums/robot-alert.enum';
import { RobotOutputFormGroup, RobotSettingsFormGroup, RobotUserGroupsFormGroup } from './robot-settings.form';
import {
  robotInputForm,
  RobotInputFormGroup,
  robotInputsForm,
  robotInputsFormArray,
  RobotInputsFormGroup
} from './robot-inputs.form';
import { RobotInputs } from '../../../api/models/robot-inputs';

@Component({
  selector: 'webclient-robot-settings',
  templateUrl: './robot-settings.component.html',
  styleUrls: ['./robot-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'webclient-robot-settings'
  }
})
export class RobotSettingsComponent implements OnChanges {

  protected form?: FormGroup<RobotSettingsFormGroup>;

  protected robotInputsForm: FormGroup<RobotInputsFormGroup> = robotInputsForm();

  private isInitializing = true;

  /**
   * Robot
   */
  @Input() public robotAdapter: RobotAdapter | undefined;

  /**
   * Indique si on est super admin
   */
  public isSuperAdmin = false;

  protected maxExternalInputsOutputs = 4;

  /**
   * Messages d'erreur
   */
  public validationMessages = {
    email: [
      { type: 'email', message: 'robot.validationMessages.email.invalid' },
      {
        type: 'maxlength',
        message: 'robot.validationMessages.email.maxlength',
        params: { maxlength: 255 }
      },
      { type: 'required', message: 'robot.validationMessages.email.required' }
    ],
    phone: [
      { type: 'phone', message: 'robot.validationMessages.phone.invalid' }
    ],
    ip: [
      { type: 'required', message: 'robot.validationMessages.ip.required' },
      { type: 'pattern', message: 'robot.validationMessages.ip.invalid' }
    ],
    name: [
      { type: 'required', message: 'robot.validationMessages.name.required' },
      {
        type: 'maxlength',
        message: 'robot.validationMessages.name.maxlength',
        params: { maxlength: 255 }
      }
    ],
    genetecServerIp: [
      { type: 'required', message: 'robot.validationMessages.genetec.serverip.required' },
      { type: 'pattern', message: 'robot.validationMessages.genetec.serverip.invalid' }
    ],
    genetecServerPort: [
      { type: 'pattern', message: 'robot.validationMessages.genetec.serverport.range', params: { min: 0, max: 65535 } },
      { type: 'range', message: 'robot.validationMessages.genetec.serverport.range', params: { min: 0, max: 65535 } },
      { type: 'required', message: 'robot.validationMessages.genetec.serverport.required' }
    ],
    genetecUsername: [
      { type: 'maxlength', message: 'robot.validationMessages.username.maxlength', params: { maxlength: 255 } },
      { type: 'required', message: 'robot.validationMessages.genetec.username.required' }
    ],
    genetecPassword: [
      {
        type: 'rangelength',
        message: 'robot.validationMessages.genetec.password.rangelength',
        params: { minlength: 8, maxlength: 255 }
      },
      { type: 'required', message: 'robot.validationMessages.genetec.password.required' }
    ],
    genetecProtocol: [
      { type: 'required', message: 'robot.validationMessages.genetec.protocol.required' }
    ],
    genetecCertificate: [
      { type: 'required', message: 'robot.validationMessages.genetec.certificate.required' }
    ],
    onvifHostname: [
      {
        type: 'required',
        message: 'robot.validationMessages.onvif.hostname.required'
      },
      {
        type: 'maxlength',
        message: 'robot.validationMessages.onvif.hostname.maxlength',
        params: { maxlength: 255 }
      }
    ],
    onvifRtspPort: [
      {
        type: 'range',
        message: 'robot.validationMessages.onvif.rtspPort.range',
        params: { min: 0, max: 65535 }
      },
      {
        type: 'required',
        message: 'robot.validationMessages.onvif.rtspPort.required'
      }
    ],
    onvifHttpPort: [
      {
        type: 'range',
        message: 'robot.validationMessages.onvif.httpPort.range',
        params: { min: 0, max: 65535 }
      },
      {
        type: 'required',
        message: 'robot.validationMessages.onvif.httpPort.required'
      }
    ],
    onvifUsername: [
      {
        type: 'required',
        message: 'robot.validationMessages.onvif.username.required'
      },
      {
        type: 'maxlength',
        message: 'robot.validationMessages.onvif.username.maxlength',
        params: { maxlength: 255 }
      }
    ],
    onvifPassword: [
      {
        type: 'rangelength',
        message: 'robot.validationMessages.onvif.password.rangelength',
        params: { minlength: 8, maxlength: 255 }
      },
      {
        type: 'required',
        message: 'robot.validationMessages.onvif.password.required'
      },
      {
        type: 'pattern',
        message: 'robot.validationMessages.onvif.password.pattern'
      }
    ],
    port: [
      {
        type: 'range',
        message: 'robot.validationMessages.port.range',
        params: { min: 0, max: 65535 }
      },
      { type: 'required', message: 'robot.validationMessages.port.required' }
    ],
    sftpDelayBetweenRecord: [
      {
        type: 'required',
        message: 'robot.validationMessages.sftp.delayBetweenRecord.required'
      }
    ],
    sftpHostname: [
      {
        type: 'maxlength',
        message: 'robot.validationMessages.sftp.hostname.maxlength',
        params: { maxlength: 255 }
      },
      {
        type: 'required',
        message: 'robot.validationMessages.sftp.hostname.required'
      }
    ],
    sftpPassword: [
      {
        type: 'rangelength',
        message: 'robot.validationMessages.sftp.password.rangelength',
        params: { minlength: 8, maxlength: 255 }
      },
      {
        type: 'required',
        message: 'robot.validationMessages.sftp.password.required'
      },
      {
        type: 'pattern',
        message: 'robot.validationMessages.sftp.password.pattern'
      }
    ],
    sftpPort: [
      {
        type: 'range',
        message: 'robot.validationMessages.sftp.port.range',
        params: { min: 0, max: 65535 }
      },
      {
        type: 'required',
        message: 'robot.validationMessages.sftp.port.required'
      }
    ],
    sftpUsername: [
      {
        type: 'maxlength',
        message: 'robot.validationMessages.sftp.username.maxlength',
        params: { maxlength: 255 }
      },
      {
        type: 'required',
        message: 'robot.validationMessages.sftp.username.required'
      }
    ],
    sftpRecordDuration: [
      {
        type: 'required',
        message: 'robot.validationMessages.sftp.recordDuration.required'
      }
    ],
    sftpRemoteFolder: [
      {
        type: 'maxlength',
        message: 'robot.validationMessages.sftp.remoteFolder.maxlength',
        params: { maxlength: 255 }
      },
      {
        type: 'required',
        message: 'robot.validationMessages.sftp.remoteFolder.required'
      }
    ],
    wifiSsid: [
      {
        type: 'required',
        message: 'robot.validationMessages.wifi.ssid.required'
      },
      {
        type: 'maxlength',
        message: 'robot.validationMessages.wifi.ssid.maxlength',
        params: { maxlength: 255 }
      }
    ],
    wifiPassword: [
      {
        type: 'rangelength',
        message: 'robot.validationMessages.wifi.password.rangelength',
        params: { minlength: 8, maxlength: 255 }
      },
      {
        type: 'required',
        message: 'robot.validationMessages.wifi.password.required'
      },
      {
        type: 'pattern',
        message: 'robot.validationMessages.wifi.password.pattern'
      }
    ],
    wifiIp: [
      {
        type: 'required',
        message: 'robot.validationMessages.wifi.ip.required'
      },
      { type: 'pattern', message: 'robot.validationMessages.wifi.ip.invalid' },
      {
        type: 'invalidIpMaskGateway',
        message: 'L\'adresse ip de sous-réseau ne correspond pas au masque et la passerelle.'
      }
    ],
    wifiMask: [
      {
        type: 'required',
        message: 'robot.validationMessages.wifi.mask.required'
      },
      {
        type: 'pattern',
        message: 'robot.validationMessages.wifi.mask.invalid'
      },
      {
        type: 'invalidIpMaskGateway',
        message: 'Le masque de sous-réseau ne correspond pas à l\'adresse IP et la passerelle.'
      }
    ],
    wifiRouterIp: [
      {
        type: 'required',
        message: 'robot.validationMessages.wifi.routerip.required'
      },
      {
        type: 'pattern',
        message: 'robot.validationMessages.wifi.routerip.invalid'
      },
      {
        type: 'invalidIpMaskGateway',
        message: 'La passerelle ne correspond pas à l\'adresse IP et le masque de sous-réseau.'
      }
    ]
  };

  /**
   * Voir le mot de passe wifi
   */
  public showWifiPassword = false;

  /**
   * Voir le mot de passe SFTP
   */
  public showSftpPassword = false;

  /**
   * Voir le mot de passe onvif
   */
  public showonvifPassword = false;

  public outputGroups: any[] = [
    {
      name: 'Alarmes',
      prefix: 'robot.alarm.',
      values: Object.values(RobotAlarm)
    },
    {
      name: 'Anomalies',
      prefix: 'robot.anomalie.',
      values: Object.values(RobotAnomalie)
    }
  ];

  constructor(
    private fb: NonNullableFormBuilder,
    private robotService: RobotService,
    private roslibService: RoslibService,
    private sessionService: SessionService,
    private dispatcherService: DispatcherService,
    private notificationService: NotificationService
  ) {
    this.isSuperAdmin = this.sessionService.isSuperAdmin();

    if (!this.validationMessages.name[1].params ||
      !this.validationMessages.port[0].params ||
      !this.validationMessages.genetecPassword[0].params
    ) {
      return;
    }

    this.form = this.fb.group<RobotSettingsFormGroup>({
      email: this.fb.control({ value: '', disabled: true }),
      emailAlert: this.fb.control(false, [Validators.required]),
      externalInterfaces: this.fb.control(false, [Validators.required]),
      fireAlarm: this.fb.control({ value: true, disabled: true }, [Validators.required]),
      id: this.fb.control({ value: null, disabled: true }, []),
      ip: this.fb.control(
        { value: '', disabled: !this.sessionService.isSuperAdmin() },
        [
          Validators.required,
          Validators.pattern(
            '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'
          )
        ]
      ),
      name: this.fb.control(
        '',
        [
          Validators.required,
          Validators.maxLength(
            this.validationMessages.name[1].params.maxlength
          )
        ]
      ),
      onvif: this.fb.control(false, []),
      onvifHostname: this.fb.control(
        { value: null, disabled: !this.sessionService.isSuperAdmin() },
        []
      ),
      onvifRtspPort: this.fb.control(
        { value: null, disabled: !this.sessionService.isSuperAdmin() },
        []
      ),
      onvifHttpPort: this.fb.control(
        { value: null, disabled: !this.sessionService.isSuperAdmin() },
        []
      ),
      onvifUsername: this.fb.control('', []),
      onvifPassword: this.fb.control('', []),
      outputs: this.fb.array<FormGroup<RobotOutputFormGroup>>([]),
      patrolAnomaliesAlert: this.fb.control(
        { value: true, disabled: true },
        [Validators.required]
      ),
      phone: this.fb.control({ value: '', disabled: true }, []),
      phoneAlert: this.fb.control(false, [Validators.required]),
      port: this.fb.control(
        { value: null, disabled: !this.sessionService.isSuperAdmin() },
        [
          Validators.required,
          CustomValidators.range(
            this.validationMessages.port[0].params.min,
            this.validationMessages.port[0].params.max
          )
        ]
      ),
      rfJammingAlert: this.fb.control(
        { value: false, disabled: true },
        [Validators.required]
      ),
      sftp: this.fb.control(false, []),
      sftpHostname: this.fb.control(null, []),
      sftpPort: this.fb.control(null, []),
      sftpUsername: this.fb.control(null, []),
      sftpPassword: this.fb.control(null, []),
      sftpRemoteFolder: this.fb.control(null, []),
      sftpRecordDuration: this.fb.control(null, []),
      sftpDelayBetweenRecord: this.fb.control(null, []),
      userGroups: this.fb.array<FormGroup<RobotUserGroupsFormGroup>>([], []),
      wifiSsid: this.fb.control('', []),
      wifiPassword: this.fb.control('', []),
      wifiIp: this.fb.control('', [Validators.required, this.ipMaskGatewayValidator()]),
      wifiMask: this.fb.control('', [Validators.required, this.ipMaskGatewayValidator()]),
      wifiRouterIp: this.fb.control('', [Validators.required, this.ipMaskGatewayValidator()]),
      genetec: this.fb.control(false, []),
      genetecServerIp: this.fb.control('',
        [
          Validators.required,
          Validators.pattern(
            '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'
          )
        ]
      ),
      genetecServerPort: this.fb.control(
        null,
        [
          Validators.required,
          Validators.pattern('^[0-9]+$'),  // Ajout du validateur pour s'assurer que la valeur est numérique
          CustomValidators.range(
            this.validationMessages.genetecServerPort[0].params?.min ?? 0,
            this.validationMessages.genetecServerPort[0].params?.max ?? 65535
          )
        ]
      ),
      genetecUsername: this.fb.control('',
        [
          Validators.required,
          Validators.maxLength(
            this.validationMessages.genetecUsername[0].params?.maxlength ?? 255
          )
        ]),
      genetecPassword: this.fb.control('', [Validators.required, CustomValidators.rangeLength(
        this.validationMessages.genetecPassword[0].params.minlength,
        this.validationMessages.genetecPassword[0].params.maxlength
      )]),
      genetecProtocol: this.fb.control('https', [Validators.required]),
      genetecCertificate: this.fb.control('', [Validators.required]),
      genetecOutputs: this.fb.array([], []),
      genetecInputs: this.fb.array([], [])
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['robotAdapter']?.currentValue) {
      const robot: Robot = (
        changes['robotAdapter']?.currentValue as RobotAdapter
      ).robot;
      this.form?.patchValue({
        ...robot,
        genetecProtocol: robot.genetecProtocol === 'https' ? 'https' : 'http'
      });

      this.setEmailAlertValidator(robot.emailAlert);
      this.setEmailPhoneValidator(robot.phoneAlert);
      this.setOnvifValidator(robot.onvif);
      this.setGenetecValidator(robot.genetec);
      this.setSftpValidator(robot.sftp);

      // Initialize outputs FormArray with existing values
      this.initializeOutputs(robot.outputs);
      this.initializeRobotInputsForm(robot.id, robot.triggerConfs);
    }
  }

  protected get genetecOutputs(): FormGroup<RobotOutputFormGroup>[] {
    return this.form?.controls.outputs.controls.filter((outputGroup) => {
      return outputGroup.controls.type.value === 2;
    }) ?? [];
  }

  protected get externalOutputs(): FormGroup<RobotOutputFormGroup>[] {
    return this.form?.controls.outputs.controls.filter((outputGroup) => {
      return outputGroup.controls.type.value === 1;
    }) ?? [];
  }

  private addOutput(id: string, name: string, alerts: (RobotAlert | RobotAlarm)[], type: number) {
    this.form?.controls.outputs.push(
      this.fb.group({
        id: new FormControl<string>(id ?? '', { validators: [Validators.required], nonNullable: true }),
        name: new FormControl<string>(name, { validators: [Validators.required], nonNullable: true }),
        type: new FormControl<number>(type, { nonNullable: true }),
        alerts: new FormControl<(RobotAlert | RobotAlarm)[]>(alerts, {
          validators: [Validators.required],
          nonNullable: true
        })
      })
    );
    if (!this.isInitializing) {
        this.form?.controls.outputs.markAsDirty();
    }
  }

  protected addGenetecOutput(id: string = '', name: string = '', alerts: (RobotAlert | RobotAlarm)[] = []): void {
    this.addOutput(id, name, alerts, 2);
  }

  protected addExternalOutput(id: string, alerts: (RobotAlert | RobotAlarm)[] = []): void {
    this.addOutput(id, `BIE OUTPUT ${id}`, alerts, 1);
  }

  protected addExternalRobotInput(value: string) {
    if (!this.robotInputsForm.controls.inputs.controls.find((inputControl) =>
      inputControl.controls.triggerType.value === 1 && inputControl.controls.value.value === value
    )) {
      this.addRobotInput(1, `BIE INPUT ${value}`, value);
    }
  }

  public removeOutput(output: FormGroup<RobotOutputFormGroup>): void {
    if (!this.form) {
      return;
    }

    this.form.controls.outputs.controls = this.form.controls.outputs.controls.filter((robotOutput) =>
      robotOutput !== output
    );
    this.form.controls.outputs.markAsDirty();
  }

  protected addGenetecInput(): void {
    this.addRobotInput(2);
  }

  public removeRobotInput(input: FormGroup<RobotInputFormGroup>): void {
    this.robotInputsForm.controls.inputs.controls = this.robotInputsForm.controls.inputs.controls.filter((robotInput) =>
      robotInput !== input);
  }

  private initializeOutputs(outputs: RobotOutput[]): void {
    this.isInitializing = true;
    const formArray = this.form?.controls.outputs;
    formArray?.clear();
    outputs.forEach(output => {
      if (!output.type) {
        return;
      }
      this.addOutput(output.id, output.name, output.alerts, output.type);
    });
    this.initializeExternalOuputs();
    this.isInitializing = false;
  }

  private initializeRobotInputsForm(robotId: number, triggerConfs: TriggerConf[]) {
    this.robotInputsForm.patchValue({
      robotId: robotId
    });

    this.robotInputsForm.controls.inputs = robotInputsFormArray(triggerConfs.map((triggerConf) => ({
      triggerConfId: triggerConf.id,
      value: triggerConf.value,
      name: triggerConf.name,
      triggerType: triggerConf.trigger.type
    })));

    this.initializeExternalInputs();
  }

  private initializeExternalInputs() {
    for (let i = 0; i < this.maxExternalInputsOutputs; i++) {
      if (!this.robotInputsForm?.controls.inputs.controls.find((inputGroup) => inputGroup.controls.triggerType.value === 1 && parseInt(inputGroup.controls.value.value) === i)) {
        this.addExternalRobotInput(i.toString());
      }
    }

    const processedValue: string[] = [];
    this.robotInputsForm?.controls.inputs.controls.forEach((inputGroup, index) => {
      if (inputGroup.controls.triggerType.value === 1) {
        if (processedValue.includes(inputGroup.controls.value.value) || parseInt(inputGroup.controls.value.value) >= this.maxExternalInputsOutputs) {
          this.robotInputsForm?.controls.inputs.removeAt(index);
        }
        processedValue.push(inputGroup.controls.value.value);
      }
    });
  }

  private initializeExternalOuputs() {
    for (let i = 0; i < this.maxExternalInputsOutputs; i++) {
      if (!this.form?.controls.outputs.controls.find((outputGroup) => outputGroup.controls.type.value === 1 && parseInt(outputGroup.controls.id.value) === i)) {
        this.addExternalOutput(i.toString());
      }
    }

    this.form?.controls.outputs.controls.forEach((outputGroup, index) => {
      if (outputGroup.controls.type.value === 1 && parseInt(outputGroup.controls.id.value) >= this.maxExternalInputsOutputs) {
        this.form?.controls.outputs.removeAt(index);
      }
    });
  }

  protected get genetecInputs(): FormGroup<RobotInputFormGroup>[] {
    return this.robotInputsForm?.controls.inputs.controls.filter((inputGroup) => {
      return inputGroup.controls.triggerType.value === 2;
    }) ?? [];
  }

  protected get externalInputs(): FormGroup<RobotInputFormGroup>[] {
    return this.robotInputsForm?.controls.inputs.controls.filter((inputGroup) => {
      return inputGroup.controls.triggerType.value === 1;
    }) ?? [];
  }

  private addRobotInput(triggerType: number, name?: string, value?: string, triggerConfId?: number) {
    this.robotInputsForm.controls.inputs.push(robotInputForm(triggerType, name, value, triggerConfId));
    this.robotInputsForm.controls.inputs.markAsDirty();
  }

  /**
   * Validateur de l'ipWifi, le masque sous réseaux et le routeur wifi
   */
  private ipMaskGatewayValidator(): ValidatorFn {
    return (formControl: AbstractControl): ValidationErrors | null => {
      const ipAddress = formControl.parent?.get('wifiIp')?.value;
      const maskAddress = formControl.parent?.get('wifiMask')?.value;
      const gatewayAddress = formControl.parent?.get('wifiRouterIp')?.value;


      if (!ipAddress || !maskAddress || !gatewayAddress) {
        return null; // La validation ne s'applique que si les 3 champs ne  sont pas renseignés.
      }
      // Validation : Vérifier si l'adresse IP appartient bien au sous-réseau défini par le masque et la passerelle.
      if (!this.isSameSubnet(ipAddress, maskAddress, gatewayAddress)) {
        formControl.parent?.get('wifiIp')?.setErrors({ invalidIpMaskGateway: true });
        formControl.parent?.get('wifiIp')?.markAsTouched();
        formControl.parent?.get('wifiMask')?.setErrors({ invalidIpMaskGateway: true });
        formControl.parent?.get('wifiMask')?.markAsTouched();
        formControl.parent?.get('wifiRouterIp')?.setErrors({ invalidIpMaskGateway: true });
        formControl.parent?.get('wifiRouterIp')?.markAsTouched();
        return { invalidIpMaskGateway: true };
      }

      // Si la validation réussit, retournez null (pas d'erreur de validation).
      formControl.parent?.get('wifiIp')?.setErrors(null);
      formControl.parent?.get('wifiMask')?.setErrors(null);
      formControl.parent?.get('wifiRouterIp')?.setErrors(null);
      return null;
    };
  }


  /**
   * Vérifie si le wifiIp, le masque et le routeur wifi appartiennent au même sous réseau
   *
   * @param  ipAddress - L'adresse IP à vérifier.
   * @param  maskAddress - Le masque de sous-réseau associé à l'adresse IP.
   * @param  gatewayAddress - L'adresse du routeur à comparer avec l'adresse IP.
   */
  private isSameSubnet(ipAddress: string, maskAddress: string, gatewayAddress: string): boolean {
    const ipParts = ipAddress.split('.').map(part => parseInt(part, 10));
    const maskParts = maskAddress.split('.').map(part => parseInt(part, 10));
    const gatewayParts = gatewayAddress.split('.').map(part => parseInt(part, 10));

    // calcul des parties de l'adresse IP et du routeur dans le réseau
    if ((ipParts.includes(NaN)) || (maskParts.includes(NaN)) || (gatewayParts.includes(NaN))) {
      return false;
    }

    const networkIpParts = ipParts.map((part, index) => part & maskParts[index]);
    const networkGatewayParts = gatewayParts.map((part, index) => part & maskParts[index]);

    // comparaison des parties de l'adresse IP et du routeur dans le réseau pour vérifier s'ils sont identiques.
    for (let i = 0; i < 4; i++) {
      if (networkIpParts[i] !== networkGatewayParts[i]) {
        return false;
      }
    }
    return true;
  }

  /**
   * Indique si un champ du formulaire a été modifié.
   */
  public getChangedProperties(): string[] {
    const changedProperties: any = [];
    Object.keys(this.formControl).forEach((name) => {
      const currentControl = this.formControl[name];

      if (currentControl.dirty) {
        changedProperties.push(name);
      }
    });
    return changedProperties;
  }

  /**
   * Indique si on doit désactiver le bouton Submit du formulaire
   * Les paramètres qui peuvent être modifiés et sauvegardés sans que le robot soit connecté sont:
   *   - Client
   *   - IP (ligne configuration, pas l'IP du wifi)
   *   - Groupes d'utilisateurs
   */
  public shouldDisableSubmit(): any {
    const changedProperties = this.getChangedProperties();
    const changedPropertiesNotAllowed = changedProperties.find(
      (property: string) =>
        property !== 'customer' &&
        property !== 'ip' &&
        property !== 'userGroups'
    );

    if (this.form?.valid) {

      // Si les propriétes modifiés nécessitent que le robot soit connecté
      if (
        changedPropertiesNotAllowed !== null &&
        changedPropertiesNotAllowed !== undefined
      ) {
        return this.robotAdapter?.robot?.id && !this.robotAdapter?.connected;
      }

      // Sinon les propriétes modifiés ne nécessite pas que le robot soit connecté
      else if (
        changedProperties !== null &&
        changedProperties !== undefined &&
        changedProperties.length > 0
      ) {
        return false;
      }

      // Sinon il n'y a pas de changement à enregistrer.
      else {
        return true;
      }
    } else {
      return true;
    }
  }

  /**
   * Retourne le contrôleur du formulaire
   */
  public get formControl(): any {
    return this.form?.controls;
  }

  /**
   * Validateur des alertes email
   *
   * @param has indique s'il faut positionner le validateur
   * @private
   */
  private setEmailAlertValidator(has: boolean): void {
    if (has) {
      this.formControl.email.enable();
      this.formControl.email.setValidators([
        Validators.required,
        Validators.email
      ]);
      if (this.validationMessages?.email[1]?.params?.maxlength) {
        this.formControl.email.addValidators(
          Validators.maxLength(
            this.validationMessages.email[1].params.maxlength
          )
        );
      }
    } else {
      this.formControl.email.setValue(null);
      this.formControl.email.clearValidators();
      this.formControl.email.disable();
    }

    this.formControl.email.updateValueAndValidity();
  }

  /**
   * Validateur des alertes téléphone
   *
   * @param has indique s'il faut positionner le validateur
   * @private
   */
  private setEmailPhoneValidator(has: boolean): void {
    if (has) {
      this.formControl.phone.enable();
      this.formControl.phone.setValidators([Validators.required]);
    } else {
      this.formControl.phone.setValue(null);
      this.formControl.phone.clearValidators();
      this.formControl.phone.disable();
    }

    this.formControl.phone.updateValueAndValidity();
  }

  /**
   * Validateur onvif
   *
   * @param has indique s'il faut positionner le validateur
   * @private
   */
  private setOnvifValidator(has: boolean): void {
    if (has) {
      if (this.sessionService.isSuperAdmin()) {
        this.formControl.onvifHostname.enable();
        this.formControl.onvifHostname.setValidators([Validators.required]);
        if (this.validationMessages?.onvifHostname[0]?.params?.maxlength) {
          this.formControl.onvifHostname.addValidators(
            Validators.maxLength(
              this.validationMessages.onvifHostname[0].params.maxlength
            )
          );
        }

        this.formControl.onvifRtspPort.enable();
        this.formControl.onvifRtspPort.setValidators([Validators.required]);
        if (
          this.validationMessages?.onvifRtspPort[0]?.params?.min &&
          this.validationMessages?.onvifRtspPort[0]?.params?.max
        ) {
          this.formControl.onvifRtspPort.addValidators(
            CustomValidators.range(
              this.validationMessages.onvifRtspPort[0].params.min,
              this.validationMessages.onvifRtspPort[0].params.max
            )
          );
        }

        this.formControl.onvifHttpPort.enable();
        this.formControl.onvifHttpPort.setValidators([Validators.required]);
        if (
          this.validationMessages?.onvifHttpPort[0]?.params?.min &&
          this.validationMessages?.onvifHttpPort[0]?.params?.max
        ) {
          this.formControl.onvifHttpPort.addValidators(
            CustomValidators.range(
              this.validationMessages.onvifHttpPort[0].params.min,
              this.validationMessages.onvifHttpPort[0].params.max
            )
          );
        }
      }

      this.formControl.onvifUsername.enable();
      this.formControl.onvifUsername.setValidators([Validators.required]);
      if (this.validationMessages?.onvifUsername[0]?.params?.maxlength) {
        this.formControl.onvifUsername.addValidators(
          Validators.maxLength(
            this.validationMessages.onvifUsername[0].params.maxlength
          )
        );
      }

      this.formControl.onvifPassword.enable();
      this.formControl.onvifPassword.setValidators([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9]+$')
      ]);
      if (
        this.validationMessages?.onvifPassword[0]?.params?.minlength &&
        this.validationMessages?.onvifPassword[0]?.params?.maxlength
      ) {
        this.formControl.onvifPassword.addValidators(
          CustomValidators.rangeLength(
            this.validationMessages.onvifPassword[0].params.minlength,
            this.validationMessages.onvifPassword[0].params.maxlength
          )
        );
      }
    } else {
      if (this.sessionService.isSuperAdmin()) {
        this.formControl.onvifHostname.clearValidators();
        this.formControl.onvifRtspPort.clearValidators();
        this.formControl.onvifHttpPort.clearValidators();
        this.formControl.onvifHostname.disable();
        this.formControl.onvifRtspPort.disable();
        this.formControl.onvifHttpPort.disable();
      }

      this.formControl.onvifUsername.setValue(null);
      this.formControl.onvifPassword.setValue(null);
      this.formControl.onvifUsername.clearValidators();
      this.formControl.onvifPassword.clearValidators();
      this.formControl.onvifUsername.disable();
      this.formControl.onvifPassword.disable();
    }

    this.formControl.onvifHostname.updateValueAndValidity();
    this.formControl.onvifRtspPort.updateValueAndValidity();
    this.formControl.onvifHttpPort.updateValueAndValidity();
    this.formControl.onvifUsername.updateValueAndValidity();
    this.formControl.onvifPassword.updateValueAndValidity();
  }

  /**
   * Validateur genetec
   *
   * @param has indique s'il faut positionner le validateur
   * @private
   */
  private setGenetecValidator(has: boolean): void {
    if (has) {

      this.formControl.genetecServerIp.enable();
      this.formControl.genetecServerPort.enable();
      this.formControl.genetecUsername.enable();
      this.formControl.genetecPassword.enable();
      this.formControl.genetecProtocol.enable();
      this.formControl.genetecCertificate.enable();

    } else {
      this.formControl.genetecServerIp.setValue(null);
      this.formControl.genetecServerIp.disable();

      this.formControl.genetecServerPort.setValue(null);
      this.formControl.genetecServerPort.disable();

      this.formControl.genetecUsername.setValue(null);
      this.formControl.genetecUsername.disable();

      this.formControl.genetecPassword.setValue(null);
      this.formControl.genetecPassword.disable();

      this.formControl.genetecProtocol.disable();

      this.formControl.genetecCertificate.setValue(null);
      this.formControl.genetecCertificate.disable();
    }

    this.formControl.genetecServerIp.updateValueAndValidity();
    this.formControl.genetecServerPort.updateValueAndValidity();
    this.formControl.genetecUsername.updateValueAndValidity();
    this.formControl.genetecPassword.updateValueAndValidity();
    this.formControl.genetecProtocol.updateValueAndValidity();
    this.formControl.genetecCertificate.updateValueAndValidity();

  }


  /**
   * Validateur sftp
   *
   * @param has indique s'il faut positionner le validateur
   * @private
   */
  private setSftpValidator(has: boolean): void {
    if (has) {
      this.formControl.sftpHostname.enable();
      this.formControl.sftpHostname.setValidators([Validators.required]);
      if (this.validationMessages?.sftpHostname[0]?.params?.maxlength) {
        this.formControl.sftpHostname.addValidators(
          Validators.maxLength(
            this.validationMessages.sftpHostname[0].params.maxlength
          )
        );
      }

      this.formControl.sftpPort.enable();
      this.formControl.sftpPort.setValidators([Validators.required]);
      if (
        this.validationMessages?.sftpPort[0]?.params?.min &&
        this.validationMessages?.sftpPort[0]?.params?.max
      ) {
        this.formControl.sftpPort.addValidators(
          CustomValidators.range(
            this.validationMessages.sftpPort[0].params.min,
            this.validationMessages.sftpPort[0].params.max
          )
        );
      }

      this.formControl.sftpUsername.enable();
      this.formControl.sftpUsername.setValidators([Validators.required]);
      if (this.validationMessages?.sftpUsername[0]?.params?.maxlength) {
        this.formControl.sftpUsername.addValidators(
          Validators.maxLength(
            this.validationMessages.sftpUsername[0].params.maxlength
          )
        );
      }

      this.formControl.sftpPassword.enable();
      this.formControl.sftpPassword.setValidators([
        Validators.required,
        Validators.pattern(Utils.patternPassword())
      ]);
      if (
        this.validationMessages?.sftpPassword[0]?.params?.minlength &&
        this.validationMessages?.sftpPassword[0]?.params?.maxlength
      ) {
        this.formControl.sftpPassword.addValidators(
          CustomValidators.rangeLength(
            this.validationMessages.sftpPassword[0].params.minlength,
            this.validationMessages.sftpPassword[0].params.maxlength
          )
        );
      }

      this.formControl.sftpRemoteFolder.enable();
      this.formControl.sftpRemoteFolder.setValidators([Validators.required]);
      if (this.validationMessages?.sftpRemoteFolder[0]?.params?.maxlength) {
        this.formControl.sftpRemoteFolder.addValidators(
          Validators.maxLength(
            this.validationMessages.sftpRemoteFolder[0].params.maxlength
          )
        );
      }

      this.formControl.sftpRecordDuration.enable();
      this.formControl.sftpRecordDuration.setValidators([Validators.required]);

      this.formControl.sftpDelayBetweenRecord.enable();
      this.formControl.sftpDelayBetweenRecord.setValidators([
        Validators.required
      ]);
    } else {
      this.formControl.sftpHostname.setValue(null);
      this.formControl.sftpPort.setValue(null);
      this.formControl.sftpUsername.setValue(null);
      this.formControl.sftpPassword.setValue(null);
      this.formControl.sftpRemoteFolder.setValue(null);
      this.formControl.sftpRecordDuration.setValue(null);
      this.formControl.sftpDelayBetweenRecord.setValue(null);
      this.formControl.sftpHostname.clearValidators();
      this.formControl.sftpPort.clearValidators();
      this.formControl.sftpUsername.clearValidators();
      this.formControl.sftpPassword.clearValidators();
      this.formControl.sftpRemoteFolder.clearValidators();
      this.formControl.sftpRecordDuration.clearValidators();
      this.formControl.sftpDelayBetweenRecord.clearValidators();
      this.formControl.sftpHostname.disable();
      this.formControl.sftpPort.disable();
      this.formControl.sftpUsername.disable();
      this.formControl.sftpPassword.disable();
      this.formControl.sftpRemoteFolder.disable();
      this.formControl.sftpRecordDuration.disable();
      this.formControl.sftpDelayBetweenRecord.disable();
    }

    this.formControl.sftpHostname.updateValueAndValidity();
    this.formControl.sftpPort.updateValueAndValidity();
    this.formControl.sftpUsername.updateValueAndValidity();
    this.formControl.sftpPassword.updateValueAndValidity();
    this.formControl.sftpRemoteFolder.updateValueAndValidity();
    this.formControl.sftpRecordDuration.updateValueAndValidity();
    this.formControl.sftpDelayBetweenRecord.updateValueAndValidity();
  }

  /**
   * Positionne les alertes par email ainsi que les validateurs de ses paramètres
   *
   * @param event le statut du bouton toggle
   */
  public onChangeEmailAlert(event: MatSlideToggleChange): void {
    this.setEmailAlertValidator(event.checked);
  }

  /**
   * Positionne les alertes par téléphone ainsi que les validateurs de ses paramètres
   *
   * @param event le statut du bouton toggle
   */
  public onChangePhoneAlert(event: MatSlideToggleChange) {
    this.setEmailPhoneValidator(event.checked);
  }

  /**
   * Positionne le protocole onvif ainsi que les validateurs de ses paramètres
   *
   * @param event le statut du bouton toggle
   */
  public onChangeonvifProtocol(event: MatSlideToggleChange) {
    this.setOnvifValidator(event.checked);
  }

  /**
   * Positionne le module sftp ainsi que les validateurs de ses paramètres
   *
   * @param event le statut du bouton toggle
   */
  public onChangeSftpModule(event: MatSlideToggleChange) {
    this.setSftpValidator(event.checked);
  }

  /**
   * Positionne le module sftp ainsi que les validateurs de ses paramètres
   *
   * @param event le statut du bouton toggle
   */
  public onChangeGenetecInterfaces(event: MatSlideToggleChange) {
    this.setGenetecValidator(event.checked);
  }

  /**
   * Positionne les interfaces externes
   *
   * @param event le statut du bouton toggle
   */
  public onChangeExternalInterfaces(event: MatSlideToggleChange) {
    if (!event.checked) {
      this.formControl.outputs.setValue([]);
    }
  }

  private saveRobotWithInputs(robotId: number) {
    return this.robotService.updateInputs(this.robotInputsForm.getRawValue() as RobotInputs).pipe(
      switchMap((robot) => {
        return this.robotService.update(robotId, {...robot, ...this.form?.getRawValue()} as Robot)
      })
    );
  }

  /**
   * Sauvegarde des données
   */
  public onSubmit(): void {
    if (this.form?.valid && this.robotInputsForm.valid) {
      if (this.robotAdapter) {
        this.saveRobotWithInputs(this.robotAdapter.robot.id).subscribe((robot: Robot) => {
          this.dispatcherService.create(robot).subscribe(
            (msg: string) => {
              this.robotAdapter = new RobotAdapter(
                robot,
                this.roslibService
              );

              if (this.robotAdapter) {
                // comment this only for debug to no send topic
                this.robotAdapter.setConfigurationRobotCommand(
                 this.robotAdapter.robot
                );
                this.notificationService.displaySuccessSnackBar(
                  this.robotAdapter.robot.name,
                  'robot.createSuccessMessage'
                );
              } else {
                this.notificationService.displayErrorSnackBar(
                  this.formControl.name.value,
                  'robot.editErrorMessage'
                );
              }
            },
            () => {
              this.notificationService.displayErrorSnackBar(
                this.formControl.name.value,
                'robot.editErrorMessage'
              );
            }
          );
        });
      } else {
        this.notificationService.displayErrorSnackBar(
          this.formControl.name.value,
          'robot.editErrorMessage'
        );
      }
    } else if (this.form) {
      this.form.markAllAsTouched();
    }
  }
}
