<mat-card fxFlex="100" fxLayout="column">
  <mat-card-header>
    <!-- Nom du robot -->
    <mat-card-title>
      <h2 fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon svgIcon="alf31"></mat-icon>
        <span> {{ robotAdapter?.robot?.name }} </span>
      </h2>
    </mat-card-title>

    <!-- Etat du robot -->
    <mat-card-subtitle>
      <p
        fxLayout="row"
        fxLayoutAlign="start center"
        *ngIf="robotAdapter?.state"
        [ngClass]="{
          'current-navigation':
            robotAdapter?.state === ROBOT_STATE.CURRENT_NAVIGATION
        }"
      >
        <mat-icon>signal_wifi_statusbar_null</mat-icon>
        <i translate="robot.state.{{ robotAdapter?.state }}"></i>
      </p>
    </mat-card-subtitle>

    <div fxLayout="row" class="buttons-container">
      <!-- Lien vers la liste des cartes du robot -->
      <button
        mat-mini-fab
        color="accent"
        [routerLink]="[
          '/pages/map-list',
          robotAdapter?.robot?.zone?.id,
          robotAdapter?.robot?.id
        ]"
        *hasRole="[Role.ROLE_SUPER_ADMIN, Role.ROLE_ADMIN]"
        [disabled]="robotAdapter?.robot?.zone === undefined"
      >
        <mat-icon>map</mat-icon>
      </button>

      <!-- Lien vers la page live du robot -->
      <button
        mat-mini-fab
        color="accent"
        [routerLink]="['/pages/robot-viewer', robotAdapter?.robot?.id]"
      >
        <mat-icon>remove_red_eye</mat-icon>
      </button>

      <!-- Lien vers la page du robot -->
      <button
        mat-mini-fab
        color="accent"
        [routerLink]="['/pages/robot-home', robotAdapter?.robot?.id]"
        *hasRole="[Role.ROLE_SUPER_ADMIN, Role.ROLE_ADMIN]"
      >
        <mat-icon>settings</mat-icon>
      </button>
    </div>
  </mat-card-header>

  <mat-card-content fxFlex fxLayout="column">
    <mat-divider></mat-divider>

    <div
      fxLayout="row"
      fxLayoutAlign="space-between"
      class="webclient-robot-control-indicators"
    >
      <!-- Etat de la connection -->
      <!-- Niveau de la batterie -->
      <div
        fxLayout="row"
        fxLayoutAlign="center"
        fxLayoutGap="10px"
        *ngIf="robotAdapter"
      >
        <ngx-rodotic-connection-status
          [connected]="robotAdapter.connected"
          [size]="'medium'"
        ></ngx-rodotic-connection-status>
        <ngx-rodotic-battery
          [maxVoltage]="12"
          [size]="'medium'"
          [incharge]="robotAdapter.incharge"
          [value]="robotAdapter.battery"
          [displayPercent]="true"
          [disabled]="
            robotAdapter.battery === undefined || !robotAdapter.connected
          "
        ></ngx-rodotic-battery>
        <webclient-robot-zone
          [name]="robotAdapter.robot?.zone?.name"
          [disabled]="!robotAdapter.connected"
        ></webclient-robot-zone>
      </div>

      <!-- Bouton d'arrêt d'urgence -->
      <div *ngIf="robotAdapter">
        <ngx-rodotic-emergency-button
          [size]="'medium'"
          [type]="'square'"
          [active]="robotAdapter.currentStrategyType !== emergencyStrategyType"
          [disabled]="!robotAdapter.connected"
          (onClick)="onEmergencyClick($event)"
        ></ngx-rodotic-emergency-button>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div
      fxLayout="row"
      fxLayoutAlign="space-between"
      fxFlex
      class="webclient-robot-control-video"
    >
      <webclient-robot-video
        fxFlex
        [robotAdapter]="robotAdapter"
        [autoplay]="false"
      ></webclient-robot-video>
    </div>

    <mat-divider></mat-divider>

    <webclient-robot-state
      [robotAdapter]="robotAdapter"
      [displayRemainingRotations]="false"
      [displayEndOfPatrol]="false"
      class="webclient-robot-control-state"
    ></webclient-robot-state>

    <mat-divider></mat-divider>

    <div
      fxLayout="row"
      fxLayoutAlign="space-between;"
      fxLayoutGap="10px"
      class="webclient-robot-control-extra-state"
    >
      <ngx-rodotic-led
        fxFlex
        [textLedPosition]="'right'"
        [size]="'medium'"
        [ledState]="'on'"
        [text]="'robot.configurationState'"
        [ledColor]="
          robotAdapter &&
          robotAdapter.configurationState === MULTI_STATE.SUCCESS
            ? 'green'
            : 'red'
        "
      ></ngx-rodotic-led>
      <ngx-rodotic-led
        fxFlex
        [textLedPosition]="'right'"
        [size]="'medium'"
        [ledState]="
          robotAdapter && robotAdapter.bieState === MULTI_STATE.SUCCESS
            ? 'on'
            : 'off'
        "
        [text]="'robot.externalInterfacesState'"
        [ledColor]="'green'"
      ></ngx-rodotic-led>
    </div>
  </mat-card-content>
</mat-card>
