import { Injectable } from '@angular/core';
import { Robot } from '../../api/models/robot';
import { DispatcherService } from './dispatcher.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public robotBinded: Robot[] = [];

  constructor(
    private dispatcherService: DispatcherService,
    private notificationService: NotificationService
  ) {}

  public bindRobot(robots: Robot[], bindtime: number = 63072000): void {
    robots.forEach((robot: Robot) => {
      const finded: Robot | undefined = this.robotBinded.find(
        (r: Robot) => r.id === robot.id
      );

      if (!finded) {
        this.dispatcherService.bind(robot, bindtime).subscribe(
          (result: string) => {
            this.robotBinded.push(robot);
          },
          (error) => {
            this.notificationService.displayErrorSnackBar(
              robot.name,
              'robot.bindErrorMessage'
            );
          }
        );
      }
    });
  }
}
