import { PatrolCheckpoint } from '../../../api/models/patrol-checkpoint';
import { BackStatus } from '../../enums/back-status.enum';
import { MapPoint } from '@lelab31/ngx-rodotic';
import { Utils } from '../../utils/utils';

export class PatrolCheckpointAdapter {
  /**
   * Uid
   */
  public uid: string | undefined;

  /**
   * Index
   */
  public index: number | undefined;

  /**
   * Point sur la carte
   */
  public mapPoint: MapPoint | undefined;

  /**
   * Indique si l'item point de contrôle est sauvegardé en BDD
   */
  public status: BackStatus = BackStatus.SAVED;

  constructor(public patrolCheckpoint: PatrolCheckpoint, group: string) {
    if (patrolCheckpoint.id) {
      this.uid = patrolCheckpoint.id.toString();
    }

    if (patrolCheckpoint.position) {
      this.index = patrolCheckpoint.position;
    }

    if (patrolCheckpoint.pose && group) {
      this.mapPoint = new MapPoint(
        patrolCheckpoint.pose,
        patrolCheckpoint.id ? patrolCheckpoint.id.toString() : null
      );
      this.mapPoint.group = group;
      this.mapPoint.displayObject = Utils.createMapPatrolCheckpointNotOriented();
    }
  }
}
