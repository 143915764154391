<div class="webclient-activated-button-container">
  <div
    class="webclient-activated-spinner-container"
    fxLayout="row"
    fxLayoutAlign="start center"
    *ngIf="state === activatedButtonState.LOADING"
  >
    <mat-spinner diameter="24"></mat-spinner>
  </div>
  <button
    mat-raised-button
    (mouseenter)="onMouseenter()"
    (mouseleave)="onMouseleave()"
    (click)="onActivate()"
    [color]="
      state === activatedButtonState.ACTIVE ? activatedColor : desactivatedColor
    "
    [disabled]="disabled || state === activatedButtonState.LOADING"
  >
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="webclient-activated-icon-container">
        <mat-icon [@rotateInOnEnter] [@rotateOutOnLeave] *ngIf="!isMouseenter">
          {{ currentIcon }}
        </mat-icon>
        <mat-icon [@rotateInOnEnter] [@rotateOutOnLeave] *ngIf="isMouseenter">
          {{ currentIcon }}
        </mat-icon>
      </div>
      <div class="webclient-activated-text-container">
        <span [@fadeInOnEnter] [@fadeOutOnLeave] *ngIf="!isMouseenter">
          {{ currentText | translate }}
        </span>
        <span [@fadeInOnEnter] [@fadeOutOnLeave] *ngIf="isMouseenter">
          {{ currentText | translate }}
        </span>
      </div>
    </div>
  </button>
</div>
