import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { PatrolAdapter } from '../../../core/models/entities/patrol-adapter';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { PatrolCheckpointAdapter } from '../../../core/models/entities/patrol-checkpoint-adapter';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { BackStatus } from '../../../core/enums/back-status.enum';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Patrol } from '../../../api/models/patrol';
import { MapConfigurationAdapter } from '../../../core/models/entities/map-configuration-adapter';
import { MapPoint, MapPointService } from '@lelab31/ngx-rodotic';
import { MapSettingsService } from '../../../core/services/map-settings.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../../core/services/notification.service';
import { Strategy } from '../../../api/models/strategy';

@Component({
  selector: 'webclient-patrol-list',
  templateUrl: './patrol-list.component.html',
  styleUrls: ['./patrol-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'webclient-patrol-list',
  },
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class PatrolListComponent implements OnInit, OnChanges, OnDestroy {

  /**
   * Ce Subject permet de fermer les subscribe pour eviter les fuites lors de la fermeture de la page
   */
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  /**
   * Liste des patrouilles
   */
  @Input() public patrolAdapters: PatrolAdapter[] = [];

  /**
   * Possibilité ou non de développer les patrouilles
   */
  @Input() expandable = true;

  /**
   * Source des données
   */
  public dataSource: MatTableDataSource<PatrolAdapter> =
    new MatTableDataSource<PatrolAdapter>([]);

  /**
   * Colonnes affichées pour les patrouilles
   */
  public displayedPatrolColumns: string[] = ['indicator', 'name'];

  /**
   * Détecte le changement du champ nom de la patrouille ou du changement de champs d'un point de contrôle de la patrouille
   */
  private propertyInput: Subject<PatrolAdapter> = new Subject();

  /**
   * Statut en BDD
   */
  public BACK_STATUS = BackStatus;

  @ViewChild('outerSort', { static: true }) sort: MatSort | undefined;
  @ViewChildren('innerSort') innerSort: QueryList<MatSort> | undefined;
  @ViewChildren('innerTables') innerTables:
    | QueryList<MatTable<PatrolCheckpointAdapter>>
    | undefined;

  expandedElement: PatrolAdapter | null = null;

  /**
   * Points de patrouilles
   *
   * @private
   */
  private mapPoints: MapPoint[] = [];

  /**
   * Configuration courante
   */
  public currentMapConfigurationAdapter: MapConfigurationAdapter | undefined;

  constructor(
    private cd: ChangeDetectorRef,
    private mapSettingsService: MapSettingsService,
    private mapPointService: MapPointService,
    private translateService: TranslateService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.listenChangeProperties();
    this.listenMapConfigurationAdapter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['expandable']) {
      this.expandedElement = null;
    }

    if (changes['patrolAdapters'] && changes['patrolAdapters'].currentValue) {
      this.patrolAdapters.map(
        (patrolAdapter: PatrolAdapter, index: number) =>
          (patrolAdapter.index = index)
      );
      this.dataSource = new MatTableDataSource<PatrolAdapter>(
        this.patrolAdapters
      );
    }
  }

  /**
   * Ecoute les changements des propriétés des patrouilles
   *
   * @private
   */
  private listenChangeProperties(): void {
    this.propertyInput
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(300))
      .subscribe((patrolAdapter: PatrolAdapter) => {
        this.mapSettingsService.updatePatrols(patrolAdapter)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (patrolAdapter: PatrolAdapter | PatrolAdapter[]) => {},
          (httpErrorResponse: HttpErrorResponse) => {
            patrolAdapter.status = BackStatus.NOT_SAVED;
            const title: string = this.translateService.instant('entity.error');
            const message: string = this.translateService.instant(
              `${httpErrorResponse.error.detail}`
            );
            this.notificationService.displayErrorSnackBar(title, message);
          }
        );
      });
  }

  /**
   * Changement d'une propriété d'une patrouille
   *
   * @param propriété à modifier
   * @param value valeur de la propriété à modifier
   * @param patrolAdapter la patrouille à mettre à jour
   */
  public onChangeProperty(
    property: string,
    value: string | boolean,
    patrolAdapter: PatrolAdapter
  ) {
    patrolAdapter.patrol[property as keyof Patrol] = <never>value;
    patrolAdapter.status = BackStatus.PENDING;
    this.propertyInput.next(patrolAdapter);
  }

  /**
   * Evènement lors du dépliment de la liste des points de patrouille
   *
   * @param event l'évènement de la souris
   * @param patrolAdapter la patrouille dépliée
   */
  public toggleRow(event: MouseEvent, patrolAdapter: PatrolAdapter) {
    const togglePatrolAdapter: PatrolAdapter | null = (this.expandedElement =
      this.expandedElement === patrolAdapter ? null : patrolAdapter);
    event.stopPropagation();

    this.mapSettingsService.updateSelectedPatrol(togglePatrolAdapter);

    if (togglePatrolAdapter && patrolAdapter.uid) {
      this.patrolAdapters.forEach((patrolAdapter: PatrolAdapter) => {
        this.mapPointService.setVisiblePoint(patrolAdapter.uid, false);
      });
      this.mapPointService.setVisiblePoint(patrolAdapter.uid, true);
    } else if (togglePatrolAdapter === null && patrolAdapter.patrol.id) {
      this.mapPointService.setVisiblePoint(patrolAdapter.uid.toString(), false);
    }

    return togglePatrolAdapter;
  }

  /**
   * Création d'une patrouille
   */
  public onAddPatrol() {
    const currentIndex: number = this.patrolAdapters.length + 1;
    const patrol = new Patrol();
    patrol.name =
      this.translateService.instant('patrol.patrol') + ' ' + currentIndex;

    const patrolAdapter = new PatrolAdapter(patrol);
    patrolAdapter.index = this.patrolAdapters.length;
    patrolAdapter.draggable = false;
    patrolAdapter.patrolCheckpointAdapters = [];

    this.patrolAdapters.push(patrolAdapter);

    this.mapSettingsService.updatePatrols(patrolAdapter)
    .pipe(takeUntil(this.destroyed$))
    .subscribe(
      (patrolAdapter: PatrolAdapter | PatrolAdapter[]) => {},
      (httpErrorResponse: HttpErrorResponse) => {
        patrolAdapter.status = BackStatus.NOT_SAVED;
        const title: string = this.translateService.instant('entity.error');
        const message: string = this.translateService.instant(
          `${httpErrorResponse.error.detail}`
        );
        this.notificationService.displayErrorSnackBar(title, message);
      }
    );

    this.dataSource._updateChangeSubscription();
  }

  /**
   * Suppression d'une patrouille
   *
   * @param patrolAdapter la patrouille à supprimer
   */
  public onDeletePatrol(deletedPatrolAdapter: PatrolAdapter) {
    deletedPatrolAdapter.deleted = true;

    this.mapSettingsService.updatePatrols(deletedPatrolAdapter)
    .pipe(takeUntil(this.destroyed$))
    .subscribe(
      (patrolAdapter: PatrolAdapter | PatrolAdapter[]) => {
        if (
          deletedPatrolAdapter.index !== undefined &&
          deletedPatrolAdapter.uid
        ) {
          this.patrolAdapters.splice(deletedPatrolAdapter.index, 1);
          this.patrolAdapters.map(
            (patrolAdapter: PatrolAdapter, index: number) =>
              (patrolAdapter.index = index)
          );

          deletedPatrolAdapter.patrolCheckpointAdapters.forEach(
            (patrolCheckpointAdapter: PatrolCheckpointAdapter) => {
              if (patrolCheckpointAdapter.mapPoint) {
                this.mapPointService.removePoint(
                  patrolCheckpointAdapter.mapPoint
                );
              }
            }
          );

          this.dataSource.data = this.patrolAdapters;
          this.dataSource._updateChangeSubscription();
        }
      },
      (httpErrorResponse: HttpErrorResponse) => {
        this.patrolAdapters.map(
          (patrolAdapter: PatrolAdapter) =>
            (patrolAdapter.status = BackStatus.NOT_SAVED)
        );
        const title: string = this.translateService.instant('entity.error');
        const message: string = this.translateService.instant(
          `${httpErrorResponse.error.detail}`
        );
        this.notificationService.displayErrorSnackBar(title, message);
      }
    );
  }

  private listenMapConfigurationAdapter(): void {
    this.mapSettingsService.mapConfiguration$
    .pipe(takeUntil(this.destroyed$))
    .subscribe(
      (mapConfigurationAdapter: MapConfigurationAdapter | null) => {
        if (
          mapConfigurationAdapter &&
          mapConfigurationAdapter.mapConfiguration.checkpoints
        ) {
          this.currentMapConfigurationAdapter = mapConfigurationAdapter;
        }
      }
    );
  }

  /**
   * Empeche de supprimer une patrouille qui est utilisé dans une stratégie
   */
  public canDelete(id: number): boolean {
    let result: boolean = true;
    this.currentMapConfigurationAdapter?.mapConfiguration.strategies?.forEach((strategy: Strategy) => {
      if(strategy.patrol?.id === id){
        result = false;
      }
    })
    return result;    
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
