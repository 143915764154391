import { User } from '../../../api/models/user';
import { IEntityAdapter } from '../../../components/admin/models/i-entity-adapter';
import { Role } from '../../enums/role.enum';

export class UserAdapter implements IEntityAdapter {
  /**
   * Identifiant de l'utilisateur
   */
  public id: number | undefined;

  /**
   * Nom de l'utilisateur
   */
  public name: string | undefined;

  /**
   * Rôles
   */
  public roles: (Role | null)[] = [];

  /**
   * Est éditable
   */
  public editable = true;

  /**
   * Est supprimable
   */
  public removable = true;

  constructor(public user: User) {
    this.id = user.id;
    this.name = user.lastname + ' ' + user.firstname;
    this.roles = user.roles;
  }
}
