import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ItemFlatNode } from './model/item-flat-node';
import { ItemNode } from './model/item-node';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { UserGroup } from '../../../api/models/user-group';

@Component({
  selector: 'webclient-user-group-select',
  templateUrl: './user-group-select.component.html',
  styleUrls: ['./user-group-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'webclient-user-group-select',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UserGroupSelectComponent,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: UserGroupSelectComponent,
      multi: true,
    },
  ],
})
export class UserGroupSelectComponent
  implements OnChanges, ControlValueAccessor
{
  /**
   * Données
   */
  @Input() public data: UserGroup[] = [];

  /**
   * Minimum à sélectionner
   */
  @Input() public minSelected = 0;

  /**
   * Les items sélectionnés
   * @private
   */
  public userGroups: UserGroup[] = [];

  public flatNodeMap = new Map<ItemFlatNode, ItemNode>();

  public nestedNodeMap = new Map<ItemNode, ItemFlatNode>();

  public treeControl: FlatTreeControl<ItemFlatNode>;

  public treeFlattener: MatTreeFlattener<ItemNode, ItemFlatNode, ItemFlatNode>;

  public dataSource:
    | MatTreeFlatDataSource<ItemNode, ItemFlatNode, ItemFlatNode>
    | undefined;

  public itemSelection = new SelectionModel<ItemFlatNode>(true /* multiple */);

  public hasChild = (_: number, nodeData: ItemFlatNode) => nodeData.expandable;

  constructor() {
    this.treeFlattener = new MatTreeFlattener<ItemNode, ItemFlatNode>(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<ItemFlatNode>(
      this.getLevel,
      this.isExpandable
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const userGroups: UserGroup[] = changes['data']
      ?.currentValue as UserGroup[];

    if (userGroups) {
      this.dataSource = new MatTreeFlatDataSource(
        this.treeControl,
        this.treeFlattener
      );

      let childrens: UserGroup[] = [];

      userGroups.forEach((userGroup: UserGroup) => {
        childrens = childrens.concat(userGroup.children);
      });

      childrens = this.flatten(childrens);

      this.dataSource.data = this.buildTree(
        userGroups.filter((userGroup: UserGroup) => {
          const findInChild: UserGroup | undefined = this.findChild(
            childrens,
            userGroup.id
          );

          if (findInChild && userGroup.parent) {
            return false;
          }

          return true;
        }),
        0
      );
      this.setValue();
    }
  }

  private flatten(userGroups: UserGroup[]) {
    return userGroups.reduce((r: UserGroup[], { children, ...rest }) => {
      r.push(rest as UserGroup);
      if (children) r.push(...this.flatten(children));
      return r;
    }, []);
  }

  private findChild(
    userGroups: UserGroup[],
    id: number | undefined
  ): UserGroup | undefined {
    for (const userGroup of userGroups) {
      if (userGroup.id === id) {
        return userGroup;
      }
      if (userGroup.children) {
        const child: UserGroup | undefined = this.findChild(
          userGroup.children,
          id
        );

        if (child) {
          return child;
        }
      }
    }

    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (userGroups: UserGroup[]) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = (userGroups: UserGroup[]) => {};

  writeValue(userGroups: UserGroup[]): void {
    this.userGroups = userGroups === null ? [] : userGroups;
    this.setValue();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnChange(onChange: (userGroups: UserGroup[]) => {}): void {
    this.onChange = onChange;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnTouched(onTouched: (userGroups: UserGroup[]) => {}): void {
    this.onTouched = onTouched;
  }

  /**
   * Positionne-les checkbox sélectionnées
   *
   * @private
   */
  private setValue(): void {
    if (this.userGroups && this.dataSource) {
      this.userGroups.forEach((userGroup: UserGroup) => {
        const selectedNode: ItemFlatNode | undefined =
          this.treeControl.dataNodes.find(
            (node: ItemFlatNode) => node.item.id === userGroup.id
          );
        if (selectedNode) {
          this.itemSelection.select(selectedNode);

          if (selectedNode.expandable) {
            this.treeControl.expand(selectedNode);
          }
        }
      });
    }
  }

  /**
   * Retourne le niveau du noeud
   * @param node
   */
  private getLevel = (node: ItemFlatNode): number => node.level;

  /**
   * Retourne les noeud enfant d'un noeud
   * @param node
   */
  private getChildren = (node: ItemNode): ItemNode[] => node.children;

  /**
   * Indique si le noeud est extensible
   * @param node
   */
  private isExpandable = (node: ItemFlatNode) => node.expandable;

  /**
   * Transforme un noeud en flat noaeud
   *
   * @param node le noeud
   * @param level le niveau de la branche de l'arbre
   */
  private transformer = (node: ItemNode, level: number): ItemFlatNode => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item
        ? existingNode
        : new ItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /**
   * Evènement lors de la sélection d'un noeud
   *
   * @param node le noeud sélectionné
   */
  public itemSelectionToggle(node: ItemFlatNode): void {
    this.itemSelection.toggle(node);
    this.userGroups = this.getData(this.itemSelection.selected);
    this.onChange(this.userGroups);
  }

  /**
   * Construction de l'arbre
   *
   * @param userGroups les données d'entrées
   * @param level le niveau de la branche de l'arbre
   * @private
   */
  private buildTree(userGroups: UserGroup[], level: number): ItemNode[] {
    const result: ItemNode[] = [];

    if (userGroups) {
      userGroups.forEach((userGroup: UserGroup) => {
        const node = new ItemNode();
        node.item = userGroup;

        node.children = this.buildTree(userGroup.children, level + 1);
        result.push(node);
      });

      return result;
    }

    return [];
  }

  /**
   * Transforme la liste de noeud de l'arbre en liste d'items
   *
   * @param nodes la liste des noeuds de l'arbre
   * @private
   */
  private getData(nodes: ItemFlatNode[]): UserGroup[] {
    const userGroups: UserGroup[] = [];

    nodes.forEach((node: ItemFlatNode) => {
      const find: UserGroup | undefined = this.findChild(
        this.data,
        node.item.id
      );

      if (find) {
        userGroups.push(find);
      }
    });

    return userGroups;
  }

  public validate({ value }: FormControl) {
    const isNotValid = this.userGroups.length < this.minSelected;

    return (
      isNotValid && {
        requireOne: true,
      }
    );
  }
}
