import { PatrolCheckpoint } from './patrol-checkpoint';
import { MapConfiguration } from './map-configuration';

export class Patrol {
  /**
   * Identifiant
   */
  public id?: number | undefined;

  /**
   * Position
   */
  public position: number | undefined;

  /**
   * Nom
   */
  public name = '';

  /**
   * Configurations des cartes
   */
  public mapConfiguration: MapConfiguration | undefined;

  /**
   * Liste des points de patrouille
   */
  public checkpoints: PatrolCheckpoint[] = [];
}
