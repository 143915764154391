<div fxLayout="row" fxLayout.xs="column">
  <webclient-card-title
    title="patrol.patrols"
    icon="local_police"
  ></webclient-card-title>

  <div fxFlex></div>

  <div fxLayoutAlign="center center">
    <!-- Bouton de création d'une patrouille -->
    <button
      mat-icon-button
      color="accent"
      title="{{ 'strategy.add' | translate }}"
      (click)="onAddPatrol()"
    >
      <mat-icon [color]="'accent'" aria-label="add">add</mat-icon>
    </button>
  </div>
</div>

<mat-table [dataSource]="dataSource" multiTemplateDataRows>
  <!-- Colonne indicateur -->
  <ng-container matColumnDef="indicator">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell
      *matCellDef="let patrolAdapter"
      [ngClass]="{
        saved: patrolAdapter.status === BACK_STATUS.SAVED,
        pending: patrolAdapter.status === BACK_STATUS.PENDING,
        'not-saved': patrolAdapter.status === BACK_STATUS.NOT_SAVED
      }"
    ></mat-cell>
  </ng-container>

  <!-- Colonne nom -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>{{
      'patrol.name' | translate
    }}</mat-header-cell>
    <mat-cell
      fxFlex
      fxLayout="row"
      fxLayoutAlign="space-between center"
      *matCellDef="let patrolAdapter"
    >
      <mat-form-field>
        <input
          matInput
          placeholder="{{ 'patrol.namePlaceholder' | translate }}"
          [ngModel]="patrolAdapter.patrol.name!"
          (ngModelChange)="onChangeProperty('name', $event, patrolAdapter)"
        />
      </mat-form-field>

      <i
        fxFlex
        fxLayoutAlign="center center"
        [translate]="'patrol.checkpointMessage'"
        [translateParams]="{
          number: patrolAdapter.patrol?.checkpoints?.length
        }"
      ></i>

      <button
        mat-icon-button
        color="accent"
        (click)="onDeletePatrol(patrolAdapter)"
        [disabled]="!canDelete(patrolAdapter.patrol.id)"
      >
        <mat-icon>remove_circle_outline</mat-icon>
      </button>

      <button
        mat-icon-button
        aria-label="expand row"
        (click)="toggleRow($event, patrolAdapter)"
        [disabled]="!patrolAdapter.patrol.id"
      >
        <mat-icon *ngIf="expandedElement !== patrolAdapter"
          >keyboard_arrow_down</mat-icon
        >
        <mat-icon *ngIf="expandedElement === patrolAdapter"
          >keyboard_arrow_up</mat-icon
        >
      </button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="checkpoints">
    <td
      mat-cell
      *matCellDef="let patrolAdapter"
      [attr.colspan]="displayedPatrolColumns.length"
    >
      <div
        class="patrol-element-detail"
        [@detailExpand]="
          patrolAdapter === expandedElement ? 'expanded' : 'collapsed'
        "
      >
        <div class="checkpoints-table mat-elevation-z8" *ngIf="expandedElement">
          <webclient-patrol-checkpoint-list
            [patrolAdapter]="patrolAdapter"
            [displayHeader]="false"
            [editable]="true"
            [canReorder]="true"
            [canAction]="true"
            *ngIf="patrolAdapter.patrol.id"
          ></webclient-patrol-checkpoint-list>
        </div>
      </div>
    </td>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedPatrolColumns"></mat-header-row>
  <mat-row
    *matRowDef="let patrolAdapter; columns: displayedPatrolColumns"
    [class.patrol-element-row]="patrolAdapter.patrolCheckpointAdapters?.length"
  ></mat-row>
  <mat-row
    *matRowDef="let patrolAdapter; columns: ['checkpoints']"
    class="checkpoints-row"
  ></mat-row>
</mat-table>
