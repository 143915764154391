import { NgModule } from '@angular/core';
import { PagesRoutingModule } from './pages-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { TranslateModule } from '@ngx-translate/core';
import { AngularMaterialModule } from '../angular-material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RobotViewerComponent } from './robot-viewer/robot-viewer.component';
import { MapListComponent } from './map-list/map-list.component';
import { MapSettingsComponent } from './map-settings/map-settings.component';
import { FormModule } from '../components/form/form.module';
import { RobotAdminModule } from './admin/robot-admin/robot-admin.module';
import { Ros2dModule } from '@lelab31/ngx-rodotic';
import { ComponentsModule } from '../components/components.module';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UserGroupAdminModule } from './admin/user-group-admin/user-group-admin.module';
import { UserAdminModule } from './admin/user-admin/user-admin.module';
import { ZoneAdminModule } from './admin/zone-admin/zone-admin.module';
import { TriggerAdminModule } from './admin/trigger-admin/trigger-admin.module';
import { RobotHomeComponent } from './robot-home/robot-home.component';
import { CardModule } from '../components/card/card.module';
import { Utils } from '../core/utils/utils';
import { MapPointType } from '../core/enums/map-point-type.enum';
import { CoreModule } from '../core/core.module';
import { ForbiddenComponent } from './forbidden/forbidden.component';

@NgModule({
  declarations: [
    DashboardComponent,
    LoginComponent,
    RobotViewerComponent,
    MapListComponent,
    MapSettingsComponent,
    RobotHomeComponent,
    ForbiddenComponent,
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    TranslateModule,
    FlexLayoutModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormModule,
    RobotAdminModule,
    UserGroupAdminModule,
    UserAdminModule,
    ZoneAdminModule,
    TriggerAdminModule,
    Ros2dModule,
    ComponentsModule,
    DragDropModule,
    CardModule,
    CoreModule,
  ],
  providers: [
    {
      provide: 'mapPointShapes',
      useValue: [
        {
          group: MapPointType.CHECKPOINT,
          displayObject: Utils.createMapCheckpoint(),
        },
        {
          group: MapPointType.PATROL_CHECKPOINT,
          displayObject: Utils.createMapPatrolCheckpoint(),
        },
        {
          group: MapPointType.PATROL_CHECKPOINT,
          displayObject: Utils.createMapPatrolCheckpointNotOriented(),
        },
        {
          group: MapPointType.NICHE,
          displayObject: Utils.createNiche(),
        },
      ],
    },
  ],
})
export class PagesModule {}
