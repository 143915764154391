export class ItemNode {
  /**
   * Noeuds enfants
   */
  children: ItemNode[] = [];

  /**
   * Item
   */
  item: any;
}
