import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { EntityService } from './entity.service';
import { Zone } from '../../../api/models/zone';

@Injectable({
  providedIn: 'root',
})
export class ZoneService extends EntityService<Zone> {
  public resourceUrl = environment.apiBaseUrl + 'zones';
}
