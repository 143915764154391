import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TriggerAdminRoutingModule } from './trigger-admin-routing.module';
import { TriggerFormComponent } from './trigger-form/trigger-form.component';
import { TriggerListComponent } from './trigger-list/trigger-list.component';
import { ComponentsModule } from '../../../components/components.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

@NgModule({
  declarations: [TriggerFormComponent, TriggerListComponent],
  imports: [
    CommonModule,
    TriggerAdminRoutingModule,
    ComponentsModule,
    TranslateModule,
    MatTableModule,
    MatSlideToggleModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [FormGroupDirective],
})
export class TriggerAdminModule {}
