import { UserGroup } from '../../../api/models/user-group';
import { IEntityAdapter } from '../../../components/admin/models/i-entity-adapter';

export class UserGroupAdapter implements IEntityAdapter {
  /**
   * Identifiant du groupe d'utilisateurs
   */
  public id: number | undefined;

  /**
   * Nom du groupe d'utilisateurs
   */
  public name: string | undefined;

  /**
   * Groupes enfants
   */
  public children: UserGroupAdapter[] = [];

  /**
   * Est éditable
   */
  public editable = true;

  /**
   * Est supprimable
   */
  public removable = true;

  constructor(public userGroup: UserGroup) {
    this.id = userGroup.id;
    this.name = userGroup.name;
    this.children = this.setChildren(userGroup);
  }

  private setChildren(userGroup: UserGroup): UserGroupAdapter[] {
    const userGroupAdapters: UserGroupAdapter[] = [];

    userGroup.children.forEach((userGroup: UserGroup) => {
      userGroupAdapters.push(new UserGroupAdapter(userGroup));
    });

    return userGroupAdapters;
  }
}
