import { Injectable } from '@angular/core';
import { SecureStorageService } from './secure-storage.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export abstract class StorageService {
  constructor(
    private storage: Storage,
    private secureStorageService: SecureStorageService
  ) {
    if (environment.production) {
      this.storage = this.secureStorageService.getSecureStorage(storage);
    }
  }

  /**
   * Définit la valeur de la paire identifiée par clé à valeur, créant une nouvelle paire clé/valeur si aucune n'existait auparavant pour la clé.
   *
   * @param key la clé donnée
   * @param object la valeur associée à la clé
   *
   * @protected
   */
  public setItem(key: string, object: any): void {
    this.storage.setItem(key, JSON.stringify(object));
  }

  /**
   * Renvoie la valeur actuelle associée à la clé donnée, ou null si la clé donnée n'existe pas dans la liste associée à l'objet.
   * @param key
   * @protected
   */
  public getItem(key: string): any | null {
    try {
      const item = this.storage.getItem(key);

      if (item !== null) {
        return JSON.parse(item);
      }
    } catch (error) {
      console.log('Parsing error : ', error);
    }

    return null;
  }

  /**
   * Supprime la paire clé/valeur avec la clé donnée de la liste associée à l'objet, s'il existe une paire clé/valeur avec la clé donnée.
   *
   * @param key la clé donnée
   *
   * @protected
   */
  public removeItem(key: string): void {
    this.storage.removeItem(key);
  }
}
