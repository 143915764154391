import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserGroupAdminRoutingModule } from './user-group-admin-routing.module';
import { UserGroupListComponent } from './user-group-list/user-group-list.component';
import { UserGroupFormComponent } from './user-group-form/user-group-form.component';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '../../../components/components.module';
import { MatBadgeModule } from '@angular/material/badge';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [UserGroupListComponent, UserGroupFormComponent],
  imports: [
    CommonModule,
    UserGroupAdminRoutingModule,
    MatTableModule,
    TranslateModule,
    ComponentsModule,
    MatBadgeModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    FlexLayoutModule,
  ],
})
export class UserGroupAdminModule {}
