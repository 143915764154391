import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { YesNo } from '../../../core/enums/yes-no.enum';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss'],
})
export class DialogConfirmComponent implements OnInit {
  /**
   * Titre de la dialogue
   */
  public title: string = '';

  /**
   * Texte de la dialogue
   */
  public text: string = '';

  constructor(
    private dialogRef: MatDialogRef<DialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; text: string }
  ) {}

  ngOnInit(): void {
    this.title = this.data.title;
    this.text = this.data.text;
  }

  /**
   * Click sur non
   */
  onNoClick(): void {
    this.dialogRef.close(YesNo.NO);
  }

  /**
   * Click sur oui
   */
  onYesClick(): void {
    this.dialogRef.close(YesNo.YES);
  }
}
