import { TriggerItem } from './trigger-item';
import { Timeslot } from './timeslot';
import { Patrol } from './patrol';
import { StrategyMode } from '../../core/enums/strategy-mode.enum';
import { MapConfiguration } from './map-configuration';

export class Strategy {
  /**
   * Identifiant
   */
  public id: number | undefined;

  /**
   * Position
   */
  public position: number | undefined;

  /**
   * Nom
   */
  public name = '';

  /**
   * Mode aléatoire
   */
  public randomMode: boolean | undefined;

  /**
   * Nombre de rotation
   */
  public rotationNumber: number | undefined;

  /**
   * Durée de patrouille
   */
  public patrolDuration: number | undefined;

  /**
   * Durée de pause
   */
  public breakDuration: number | undefined;

  /**
   * mode
   */
  public mode: StrategyMode | undefined;

  /**
   * Couleur
   */
  public color = '';

  /**
   * Patrouille
   */
  public patrol: Patrol | undefined;

  /**
   * Elements déclencheurs
   */
  public triggerItem?: TriggerItem | null;

  /**
   * Créneau horaire
   */
  public timeslots: Timeslot[] = [];

  /**
   * Configurations des cartes
   */
  public mapConfiguration: MapConfiguration | undefined;
}
