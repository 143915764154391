import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../core/services/security/auth.service';
import { User } from '../../../api/models/user';
import { SessionService } from '../../../core/services/session.service';
import { Robot } from '../../../api/models/robot';
import { RobotService } from '../../../core/services/entities/robot.service';
import { DispatcherService } from '../../../core/services/dispatcher.service';

@Component({
  selector: 'webclient-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ToolbarComponent {
  /**
   * Utilisateur connecté
   */
  public currentUser: User | undefined;

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private authService: AuthService,
    private robotService: RobotService,
    private dispatcherService: DispatcherService
  ) {
    this.currentUser = this.sessionService.getCurrentUser();
  }

  /**
   * Déconnexion
   */
  public logout(): void {
    this.authService.logout().subscribe(() => {
      this.robotService.getAll().subscribe((robots: Robot[]) => {
        robots.forEach((robot: Robot) => {
          this.dispatcherService.bind(robot, 0).subscribe((data: string) => {});
        });
      });
      this.router.navigate(['/login']);
    });
  }
}
