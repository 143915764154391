import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MapConfiguration } from '../../../api/models/map-configuration';
import { MapAdapter } from '../../../core/models/entities/map-adapter';

@Component({
  selector: 'webclient-map-configuration-dialog',
  templateUrl: './map-configuration-dialog.component.html',
  styleUrls: ['./map-configuration-dialog.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class MapConfigurationDialogComponent {
  /**
   * Formulaire
   */
  public form: FormGroup | undefined;

  /**
   * Titre
   */
  public title = '';

  /**
   * Messages d'erreur
   */
  public mapConfigurationValidationMessages = {
    name: [
      {
        type: 'required',
        message: 'mapConfiguration.validationMessages.name.required',
      },
    ],
  };

  /**
   * Carte sur laquelle créer la nouvelle configuration
   *
   * @private
   */
  private mapAdapter: MapAdapter | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<MapConfigurationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      mapAdapter: MapAdapter;
      title: string;
      mapConfiguration: MapConfiguration;
    }
  ) {
    this.mapAdapter = data.mapAdapter;
    this.title = data.title;

    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
    });

    if (data.mapConfiguration) {
      this.form.patchValue({
        name: data.mapConfiguration.name,
      });
    }
  }

  /**
   * Retourne le contrôleur du formulaire
   */
  public get formControl(): any {
    return this.form?.controls;
  }

  /**
   * Click sur oui
   */
  public onOkClick(): void {
    const mapConfiguration: MapConfiguration = {
      name: this.formControl?.['name'].value as string,
      map: this.mapAdapter?.map,
    };

    if (this.data.mapConfiguration && this.data.mapConfiguration.id) {
      mapConfiguration.id = this.data.mapConfiguration.id;
    }

    this.dialogRef.close({
      mapConfiguration: mapConfiguration,
    });
  }

  /**
   * Click sur non
   */
  public onCancelClick(): void {
    this.dialogRef.close();
  }
}
