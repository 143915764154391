import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MapService } from '../../core/services/entities/map.service';
import { Map } from '../../api/models/map';
import { MapAdapter } from '../../core/models/entities/map-adapter';
import { MatDialog } from '@angular/material/dialog';
import { MapDialogComponent } from '../../components/map/map-dialog/map-dialog.component';
import { NotificationService } from '../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { RobotAdapter } from '../../core/models/entities/robot-adapter';
import { RobotService } from '../../core/services/entities/robot.service';
import { Robot } from '../../api/models/robot';
import { InputMapCreationStatusMessage } from '../../core/models/ros/input/input-map-creation-status-message';
import { MapStatus } from '../../core/enums/map-status.enum';
import { environment } from '../../../environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RoslibService } from '@lelab31/ngx-rodotic';
import { StrategyName } from '../../core/enums/strategy-name.enum';

@Component({
  selector: 'webclient-map-list',
  templateUrl: './map-list.component.html',
  styleUrls: ['./map-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'webclient-map-list',
  },
})
export class MapListComponent implements OnInit {
  /**
   * Robot
   */
  public robotAdapter: RobotAdapter | undefined;

  /**
   * Liste des cartes
   */
  public mapAdapters: MapAdapter[] = [];

  /**
   * Identifiant de la zone
   */
  public zoneId: number | undefined;

  constructor(
    private route: ActivatedRoute,
    private mapService: MapService,
    private robotService: RobotService,
    private rosLibService: RoslibService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.zoneId = this.route.snapshot.params['zoneId'];
    const robotId = this.route.snapshot.params['robotId'];

    this.robotService.getById(robotId).subscribe((robot: Robot) => {
      this.robotAdapter = new RobotAdapter(robot, this.rosLibService);
    });

    this.mapService.getAll('zone=' + this.zoneId).subscribe((maps: Map[]) => {
      this.mapAdapters = maps.map((map: Map) => new MapAdapter(map));
    });
  }

  /**
   * Création d'une carte
   */
  public onAddMap() {
    const dialogRef = this.dialog.open(MapDialogComponent, {
      disableClose: true,
      data: {
        zoneId: this.zoneId,
      },
    });

    dialogRef.afterClosed().subscribe((result: { map: Map }) => {
      if (result !== undefined) {
        result.map.status = MapStatus.BEING_CREATED;

        this.mapService.create(result.map).subscribe(
          (map: Map) => {
            if (this.robotAdapter && map) {
              // Supprimer les stratégies en cas d'exploration
              // Si une exploration est lancée, alors la configuration actuelle des stratégies doit être supprimée car elle correspond à une autre carte.
              this.robotAdapter.desactivateMapCommand();

              // Ajout d'un delai avant de démarrer l'exploration pour que le robot ai le temps de traiter la commande précédente.
              setTimeout(() => {
                if (this.robotAdapter) {
                  this.robotAdapter.explorationMapCommand(map);
                }
              }, 1000);

              // Reset l'activeMap et l'activeMapConfiguration à null pour désactiver l'affichage de la map dans le robot-viewer
              if (this.robotAdapter) {
                this.robotAdapter.robot.activeMap = null;
                this.robotAdapter.robot.activeMapConfiguration = null;
              }
              if (this.robotAdapter) {
                this.robotService
                  .update(this.robotAdapter.robot.id, this.robotAdapter.robot)
                  .subscribe((robot: Robot) => {});
              }

              this.robotAdapter
                .mapCreationStatus()
                .subscribe(
                  (
                    inputMapCreationStatusMessage: InputMapCreationStatusMessage
                  ) => {
                    const find: MapAdapter | undefined = this.mapAdapters.find(
                      (mapAdapter: MapAdapter) =>
                        mapAdapter.map.id ===
                        inputMapCreationStatusMessage.mapId
                    );
                    if (this.robotAdapter) {
                      this.robotAdapter.robot.activeMap = map;
                    }

                    if (find && find.map.id) {
                      this.mapService
                        .getById(find.map.id)
                        .subscribe((map: Map) => {
                          if (
                            find &&
                            map.resolution &&
                            map.origin &&
                            map.height &&
                            map.width
                          ) {
                            find.map = map;
                            find.map.status = map.status;
                            find.mapInfo = {
                              dataOrUrl: environment.host + map.image,
                              height: map.height,
                              width: map.width,
                              resolution: map.resolution,
                              origin: map.origin,
                            };

                            // Mise à jour du mapAdapters pour récuperer la nouvelle carte
                            this.mapService
                              .getAll('zone=' + this.zoneId)
                              .subscribe((maps: Map[]) => {
                                this.mapAdapters = maps.map(
                                  (map: Map) => new MapAdapter(map)
                                );
                              });
                          }
                        });
                    }
                  }
                );
            }

            const title: string =
              this.translateService.instant('entity.success');
            const message: string = this.translateService.instant(
              `map.createSuccessMessage`,
              { name: result.map.name }
            );
            this.notificationService.displaySuccessSnackBar(title, message);
            this.mapAdapters.push(new MapAdapter(map));
          },
          (error) => {
            const title: string = this.translateService.instant('entity.error');
            const message: string = this.translateService.instant(
              `map.createErrorMessage`,
              { name: result.map.name }
            );
            this.notificationService.displayErrorSnackBar(title, message);
          }
        );
      }
    });
  }

  /**
   * Suppression d'une carte et de ses configurations
   *
   * @param mapAdapter la carte à supprimer
   */
  public onRemoveMap(mapAdapter: MapAdapter) {
    if (mapAdapter.map.id) {
      this.mapService.delete(mapAdapter.map.id).subscribe(
        () => {
          const index: number = this.mapAdapters.findIndex(
            (ma: MapAdapter) => ma.map.id === mapAdapter.map.id
          );
          this.mapAdapters.splice(index, 1);

          const title: string = this.translateService.instant('entity.success');
          const message: string = this.translateService.instant(
            `map.removeSuccessMessage`,
            { name: mapAdapter.map.name }
          );
          this.notificationService.displaySuccessSnackBar(title, message);
        },
        (error) => {
          const title: string = this.translateService.instant('entity.error');
          const message: string = this.translateService.instant(
            `map.removeErrorMessage`,
            { name: mapAdapter.map.name }
          );
          this.notificationService.displayErrorSnackBar(title, message);
        }
      );
    }
  }

  /**
   * Indique si l'on peux créer une nouvelle carte
   *
   * Si le robot n'est pas connecté ou s'il n'est pas dans la niche, on ne peut pas créer de carte.
   */
  public canAddMap() {
    return !this.robotAdapter?.connected || !this.robotAdapter?.inniche;
  }
}
