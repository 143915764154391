<webclient-entity-form
  [urlList]="'/pages/admin/usergroup/list/'"
  [entity]="usergroup"
  [form]="form"
  [createTitle]="'usergroup.create'"
  [editTitle]="'usergroup.edit'"
  [icon]="'bolt'"
  (onCreateEntity)="onCreate($event)"
  (onEditEntity)="onEdit($event)"
>
  <form
    [formGroup]="form"
    *ngIf="form"
    fxLayout="row"
    fxLayoutGap="20px"
    class="admin-form"
    fxFlex
  >
    <div fxFlex="40">
      <fieldset>
        <legend [translate]="'usergroup.informations'"></legend>

        <div
          fxLayout="column"
          fxLayout.md="column"
          fxLayout.sm="column"
          fxLayout.xs="column"
          fxLayoutGap="20px"
        >
          <!-- Champ nom -->
          <div fxLayout="row" fxLayoutGap="10px">
            <div
              fxLayout="row"
              fxLayout.sm="column"
              fxLayout.xs="column"
              fxLayoutGap="20px"
              fxFlex="100"
            >
              <mat-form-field fxFlex="100">
                <mat-icon matPrefix>person</mat-icon>
                <input
                  matInput
                  type="text"
                  name="name"
                  required="required"
                  placeholder="{{ 'usergroup.name' | translate }}"
                  formControlName="name"
                />
                <mat-error *ngFor="let validation of validationMessages.name">
                  <ng-container
                    *ngIf="formControl?.['name']?.hasError(validation.type) && (formControl?.name?.dirty || formControl?.name?.touched)"
                  >
                    {{ validation.message | translate }}
                  </ng-container>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset *ngIf="canChangeCustomer()">
        <legend [translate]="'user.customer'"></legend>

        <div
          fxLayout="column"
          fxLayout.md="column"
          fxLayout.sm="column"
          fxLayout.xs="column"
          fxLayoutGap="20px"
        >
          <!-- Liste des clients -->
          <div
            fxLayout="row"
            fxLayout.sm="column"
            fxLayout.xs="column"
            fxLayoutAlign="start end"
            fxLayoutAlign.sm="start"
            fxLayoutAlign.xs="start"
            fxLayoutGap="20px"
            fxFlex="50"
          >
            <mat-form-field fxFlex="100">
              <mat-select
                matInput
                formControlName="customer"
                [compareWith]="compareUserGroup"
              >
                <mat-option></mat-option>
                <mat-option
                  *ngFor="let customer of customers"
                  [value]="customer"
                >
                  {{ customer.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <mat-error *ngFor="let validation of validationMessages.customer">
            <ng-container
              *ngIf="
                form.get('customer')?.hasError(validation.type) &&
                (form.get('customer')?.dirty || form.get('customer')?.touched)
              "
            >
              {{ validation.message | translate }}
            </ng-container>
          </mat-error>
        </div>
      </fieldset>
    </div>
  </form>
</webclient-entity-form>
