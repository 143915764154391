import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StrategyAdapter } from '../../../core/models/entities/strategy-adapter';

@Component({
  selector: 'webclient-strategy-dialog',
  templateUrl: './strategy-dialog.component.html',
  styleUrls: ['./strategy-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'webclient-strategy-dialog',
  },
})
export class StrategyDialogComponent implements OnInit {
  /**
   * Liste des stratégies
   */
  public strategyAdapters: StrategyAdapter[] = [];

  /**
   * Stratégie sélectionnée
   */
  public selectedStrategyAdapter: StrategyAdapter | undefined;

  /**
   * Date de début
   */
  public start: string = '';

  /**
   * Date de fin
   */
  public end: string = '';

  constructor(
    private dialogRef: MatDialogRef<StrategyDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { start: Date; end: Date; strategyAdapters: StrategyAdapter[] }
  ) {}

  ngOnInit(): void {
    this.strategyAdapters = this.data.strategyAdapters;

    if (this.data.start) {
      this.start =
        this.data.start.getHours() + ':' + this.data.start.getMinutes();
    }

    if (this.data.end) {
      this.end = this.data.end.getHours() + ':' + this.data.end.getMinutes();
    }
  }

  /**
   * Click sur non
   */
  onOkClick(): void {
    this.dialogRef.close({
      start: this.start,
      end: this.end,
      strategyAdapter: this.selectedStrategyAdapter,
    });
  }

  /**
   * Click sur oui
   */
  onCancelClick(): void {
    this.dialogRef.close();
  }

  /**
   * Changement de l'heure de début
   *
   * @param start heure de début
   */
  public onStartChanged(start: string): void {
    this.start = start;
  }

  /**
   * Changement de l'heure de fin
   *
   * @param end heure de fin
   */
  public onEndChanged(end: string): void {
    this.end = end;
  }
}
