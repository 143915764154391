<div
  fxLayout="row"
  fxLayoutAlign="start center"
  class="webclient-robot-zone-container outline {{
    disabled ? 'disabled' : ''
  }}"
>
  <div>
    <mat-icon fxLayout fxLayoutAlign="center center">share_location</mat-icon>
  </div>
  <div fxLayout="row" fxLayoutAlign="end" *ngIf="!disabled">
    <div>{{ name }}</div>
  </div>
</div>
