import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RobotModule } from './robot/robot.module';
import { CheckpointModule } from './checkpoint/checkpoint.module';
import { PatrolModule } from './patrol/patrol.module';
import { StrategyModule } from './strategy/strategy.module';
import { MapConfigurationModule } from './map-configuration/map-configuration.module';
import { FormModule } from './form/form.module';
import { AdminModule } from './admin/admin.module';
import { InfoModule } from './info/info.module';
import { PatrolCheckpointModule } from './patrol-checkpoint/patrol-checkpoint.module';
import { MapModule } from './map/map.module';
import { UserGroupModule } from './user-group/user-group.module';

@NgModule({
  declarations: [],
  exports: [
    RobotModule,
    CheckpointModule,
    PatrolModule,
    StrategyModule,
    MapConfigurationModule,
    FormModule,
    AdminModule,
    InfoModule,
    PatrolCheckpointModule,
    MapModule,
    UserGroupModule,
  ],
  imports: [
    CommonModule,
    RobotModule,
    CheckpointModule,
    PatrolModule,
    StrategyModule,
    MapConfigurationModule,
    FormModule,
    AdminModule,
    InfoModule,
    MapModule,
    UserGroupModule,
  ],
})
export class ComponentsModule {}
