export class RobotDTO {
  /**
   * Nom du robot
   */
  public robot_name: string | null = null;

  /**
   * Identifiant du robot
   */
  public robot_id: number | undefined;

  /**
   * Alerte email
   */
  public email_enable: boolean | undefined;

  /**
   * Alerte téléphone
   */
  public call_alert_enable: any;

  /**
   * Email
   */
  public email_address: string | null = null;

  /**
   * Téléphone
   */
  public phone_number: string | null = null;

  /**
   * Alerte anomalies de patrouille
   */
  public patrol_anomaly_enable: any;

  /**
   * Alerte brouillage RF
   */
  public jamming_enable: any;

  /**
   * Alerte incendie
   */
  public fire_detection_enable: any;

  /**
   * Interfaces externes
   */
  public BIE_enable: boolean | undefined;

  /**
   * Sortie 1
   */
  public BIE_output0?: number;

  /**
   * Sortie 2
   */
  public BIE_output1?: number;

  /**
   * Sortie 3
   */
  public BIE_output2?: number;

  /**
   * Sortie 4
   */
  public BIE_output3?: number;

  /**
   * Protocole onvif
   */
  public onvif_enable: boolean | undefined;

  /**
   * Port RTSP onvif
   */
  public onvif_port_rtsp: any;

  /**
   * Port HTTP onvif
   */
  public onvif_port_http: any;

  /**
   * Nom d'utilisateur onvif
   */
  public onvif_username: any;

  /**
   * Mot de passe onvif
   */
  public onvif_password: any;

  /**
   * Ssid du wifi
   */
  public ssid: string | null = null;

  /**
   * Mot de passe du wifi
   */
  public pwd: string | null = null;

  /**
   * IP du wifi
   */
  public ip: string | null = null;

  /**
   * Masque de sous-réseau du wifi
   */
  public mask: string | null = null;

  /**
   * Ip du routeur du wifi
   */
  public gateway: string | null = null;
}
