import { Injectable } from '@angular/core';
import { User } from '../../api/models/user';
import { Role } from '../enums/role.enum';
import { SessionStorageService } from './storage/session-storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  /**
   * Clé du token
   *
   * @private
   */
  private static TOKEN_KEY = 'token-alf31';

  /**
   * Clé du refresh token
   *
   * @private
   */
  private static REFRESH_TOKEN_KEY = 'refresh-token-alf31';

  /**
   * Clé de l'utilisateur
   *
   * @private
   */
  private static USER_KEY = 'user-alf31';

  /**
   * Le token
   */
  private token: string | undefined;

  /**
   * Le refresh token
   */
  private refreshToken: string | undefined;

  /**
   * L'utilisateur'
   */
  private user: User | undefined;

  constructor(private sessionStorageService: SessionStorageService) {
    this.token = this.sessionStorageService.getItem(SessionService.TOKEN_KEY);
    this.user = this.sessionStorageService.getItem(SessionService.USER_KEY);
  }

  public getToken(): string {
    return this.sessionStorageService.getItem(SessionService.TOKEN_KEY);
  }

  public setToken(token: string): void {
    this.token = token;

    this.sessionStorageService.setItem(SessionService.TOKEN_KEY, this.token);
  }

  public getRefreshToken(): string {
    return this.sessionStorageService.getItem(SessionService.REFRESH_TOKEN_KEY);
  }

  public setRefreshToken(refreshToken: string): void {
    this.refreshToken = refreshToken;

    this.sessionStorageService.setItem(
      SessionService.REFRESH_TOKEN_KEY,
      this.refreshToken
    );
  }

  public getCurrentUser(): User | undefined {
    return this.user;
  }

  public setCurrentUser(user: User): void {
    this.user = user;

    this.sessionStorageService.setItem(SessionService.USER_KEY, user);
  }

  public isTokenExpired(): boolean | Promise<boolean> {
    const jwtHelper = new JwtHelperService();

    const token = JSON.parse(atob(this.getToken().split('.')[1]));
    const expires = new Date(token.exp * 1000);

    return jwtHelper.isTokenExpired(this.token);
  }

  public destroy(): void {
    this.sessionStorageService.removeItem(SessionService.TOKEN_KEY);
    this.sessionStorageService.removeItem(SessionService.REFRESH_TOKEN_KEY);
    this.sessionStorageService.removeItem(SessionService.USER_KEY);

    this.user = undefined;
    this.token = undefined;
  }

  /**
   * Indique si l'utilisateur est SUPER ADMIN
   */
  public isSuperAdmin(): boolean {
    if (this.user) {
      return this.user.roles.find(
        (role) => role.toUpperCase() === Role.ROLE_SUPER_ADMIN
      ) === undefined
        ? false
        : true;
    }

    return false;
  }

  /**
   * Indique si l'utilisateur est ADMIN
   */
  public isAdmin(): boolean {
    if (this.user) {
      return this.user.roles.find(
        (role) => role.toUpperCase() === Role.ROLE_ADMIN
      ) === undefined
        ? false
        : true;
    }

    return false;
  }
}
