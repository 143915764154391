import { Zone } from '../../../api/models/zone';
import { IEntityAdapter } from '../../../components/admin/models/i-entity-adapter';

export class ZoneAdapter implements IEntityAdapter {
  /**
   * Identifiant de la zone
   */
  public id: number | undefined;

  /**
   * Nom de la zone
   */
  public name: string | undefined;

  /**
   * Est éditable
   */
  public editable = true;

  /**
   * Est supprimable
   */
  public removable = true;

  constructor(public zone: Zone) {
    this.id = zone.id;
    this.name = zone.name;
  }
}
