<div fxLayout="row" fxLayoutGap="20px">

  <!-- Erreur -->
  <div fxFlex="30" class="webclient-robot-state-error">
    <div class="fieldset">
      <h1>
        <span> {{ 'robot.error.error' | translate }} </span>
      </h1>
      
      <div fxLayout="row"  *ngIf="!isXSscreen()">
        <ngx-rodotic-led [textLedPosition]="'right'" [size]="isXSscreen() ? 'small' : 'medium'" [ledState]="robotAdapter && robotAdapter.errorState === ROBOT_ERROR.NO_ERROR ? 'off': 'on'" [text]="robotAdapter && robotAdapter.errorState ? 'robot.error.' + robotAdapter.errorState : 'robot.error.NO_ERROR'" [ledColor]="robotAdapter && robotAdapter.errorState === ROBOT_ERROR.MINOR_ERROR ? 'yellow': 'red'"> </ngx-rodotic-led>
      </div>
        <!--condition pour ne pas afficher [text] uniquement sur les écrans de smartphone-->
      <div fxLayout="row" class="led-container" *ngIf="isXSscreen()">
        <ngx-rodotic-led [textLedPosition]="'right'" [size]="isXSscreen() ? 'small' : 'medium'" [ledState]="robotAdapter && robotAdapter.errorState === ROBOT_ERROR.NO_ERROR ? 'off': 'on'" [ledColor]="robotAdapter && robotAdapter.errorState === ROBOT_ERROR.MINOR_ERROR ? 'yellow': 'red'"></ngx-rodotic-led>
      </div>
    </div>
  </div>

  <!-- Alerte -->
  <div fxFlex="40"  class="webclient-robot-state-alert">
    <div class="fieldset">
      <h1>
        <span> {{ 'robot.alert.alert' | translate }} </span>
      </h1>

      <div fxLayout="row"  *ngIf="!isXSscreen()">
        <ngx-rodotic-led [textLedPosition]="'right'" [size]="isXSscreen() ? 'small' : 'medium'" [ledState]="robotAdapter && robotAdapter.alertState === ROBOT_ALERT.NO_ALERT ? 'off': 'on'" [text]="robotAdapter && robotAdapter.alertState ? 'robot.alert.' + robotAdapter.alertState : 'robot.alert.NO_ALERT'" [ledColor]="'red'"></ngx-rodotic-led>
      </div>

      <!--condition pour ne pas afficher [text] uniquement sur les écrans de smartphone-->
      <div fxLayout="row" class="led-container" *ngIf="isXSscreen()">
        <ngx-rodotic-led [textLedPosition]="'right'" [size]="isXSscreen() ? 'small' : 'medium'" [ledState]="robotAdapter && robotAdapter.alertState === ROBOT_ALERT.NO_ALERT ? 'off': 'on'" [ledColor]="'red'"></ngx-rodotic-led>
      </div>
    </div>
  </div>
  

  <!-- Patrouille -->
  <div fxFlex="30" class="webclient-robot-state-patrol">
    <div class="fieldset">
      <h1>
        <span> {{ 'patrol.patrol' | translate }} </span>
      </h1>
      <div fxLayout="row">
        <ngx-rodotic-led [textLedPosition]="'right'" [size]="isXSscreen() ? 'small' : 'medium'" [ledState]="robotAdapter && robotAdapter.patrolState === MULTI_STATE.SUCCESS ? 'on': 'off'" [text]="patrolName" [ledColor]="'green'"></ngx-rodotic-led>
      </div>
    </div>
  </div>

  <!-- Rotation restantes -->
  <div fxFlex="30" class="webclient-robot-state-remaining-rotations" *ngIf="displayRemainingRotations">
    <div class="fieldset">
      <h1>
        <span> {{ 'robot.remainingRotations' | translate }} </span>
      </h1>
      <div fxLayout="row" fxLayoutAlign="center center">
        <span *ngIf="robotAdapter"> {{ robotAdapter.remainingRotation }} </span>
      </div>
    </div>
  </div>

  <!-- Fin de patrouille -->
  <div fxFlex="30" class="webclient-robot-state-endofpatrol" *ngIf="displayEndOfPatrol">
    <div class="fieldset">
      <h1>
        <span> {{ 'robot.endOfPatrol' | translate }} </span>
      </h1>
      <div fxLayout="row" fxLayoutAlign="center center">
        <span *ngIf="robotAdapter && robotAdapter.endOfPatrolmin !== undefined && robotAdapter.endOfPatrolmin >= 2"> {{ robotAdapter.endOfPatrolmin }} </span>
        <span *ngIf="robotAdapter && robotAdapter.endOfPatrolmin !== undefined && robotAdapter.endOfPatrolmin >= 2"> {{ 'minutes' | translate }} </span>
        <span *ngIf="robotAdapter && robotAdapter.endOfPatrolmin !== undefined && robotAdapter.endOfPatrolmin < 2"> {{ robotAdapter.endOfPatrolsec }} </span>
        <span *ngIf="robotAdapter && robotAdapter.endOfPatrolmin !== undefined && robotAdapter.endOfPatrolmin < 2"> {{ 'secondes' | translate }} </span>
      </div>
    </div>
  </div>

</div>
