import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Role } from '../../../core/enums/role.enum';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { IEntity } from '../models/i-entity';
import { IEntityChildren } from '../models/i-entity-children';
import { IEntityChildrenFlatNode } from '../models/i-entity-children-flat-node';
import { BaseEntityListComponent } from '../base-entity-list/base-entity-list.component';
import { IEntityAdapter } from '../models/i-entity-adapter';

@Component({
  selector: 'webclient-entity-nested-list',
  templateUrl: './entity-nested-list.component.html',
  styleUrls: ['./entity-nested-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'webclient-entity-nested-list',
  },
})
export class EntityNestedListComponent
  extends BaseEntityListComponent
  implements OnChanges
{
  public transformer = (node: IEntityChildren, level: number) => {
    return {
      ...{
        expandable: !!node.children && node.children.length > 0,
        level: level,
      },
      ...node,
    };
  };

  public treeControl = new FlatTreeControl<IEntityChildrenFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  public treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  /**
   * Source des données
   */
  @Input() public dataSource: MatTreeFlatDataSource<
    IEntity,
    IEntityChildrenFlatNode
  > = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['roles']?.currentValue) {
      this.roles.push(Role.ROLE_SUPER_ADMIN);
    }

    if (changes['entities']?.currentValue && this.dataSource) {
      this.dataSource.data = changes['entities']?.currentValue;
    }

    if (changes['urlForm'] && changes['urlForm'].currentValue) {
      this.urlForm = this.urlForm + '/form/';
    }
  }
}
