<webclient-entity-list
  [entities]="userAdapters"
  [displayedColumns]="displayedColumns"
  [urlForm]="'/pages/admin/user'"
  [title]="'user.users'"
  [icon]="'persons'"
  [text]="text"
  (onRemoveEntity)="onRemove($event)"
  [roles]="[Role.ROLE_ADMIN]"
>
  <!-- Colonne email -->
  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef>
      {{ 'user.email' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let userAdapter">
      {{ userAdapter.user.email }}
    </mat-cell>
  </ng-container>

  <!-- Colonne nom -->
  <ng-container matColumnDef="lastname">
    <mat-header-cell *matHeaderCellDef>
      {{ 'user.lastname' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let userAdapter">
      {{ userAdapter.user.lastname }}
    </mat-cell>
  </ng-container>

  <!-- Colonne prénom -->
  <ng-container matColumnDef="firstname">
    <mat-header-cell *matHeaderCellDef>
      {{ 'user.firstname' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let userAdapter">
      {{ userAdapter.user.firstname }}
    </mat-cell>
  </ng-container>

  <!-- Colonne client -->
  <ng-container matColumnDef="customer">
    <mat-header-cell *matHeaderCellDef>
      {{ 'user.customer' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let userAdapter">
      {{ userAdapter.user.customer.name }}
    </mat-cell>
  </ng-container>

  <!-- Colonne rôle -->
  <ng-container matColumnDef="roles">
    <mat-header-cell *matHeaderCellDef>
      {{ 'user.role.roles' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let userAdapter">
      <ng-container
        *ngFor="let role of userAdapter.user.roles; let isLast = last"
      >
        <span translate="user.role.{{ role }}"></span>
        <span> {{ isLast ? '' : ', ' }} </span>
      </ng-container>
    </mat-cell>
  </ng-container>
</webclient-entity-list>
