<div fxLayout="column" fxLayoutAlign="center start" class="message-snack-bar">
  <div
    class="message-snack-bar-container"
    fxLayout="row"
    fxLayoutAlign="space-between"
  >
    <div fxFlex="90%">
      <div>
        <mat-icon *ngIf="type === MessageType.SUCCESS">check_circle</mat-icon>
        <mat-icon *ngIf="type === MessageType.ERROR">error</mat-icon>
        <mat-icon *ngIf="type === MessageType.WARN">warning</mat-icon>
        <mat-icon *ngIf="type === MessageType.INFO">info</mat-icon>
      </div>
      <div>
        <h1>{{ title }}</h1>
      </div>
    </div>

    <div fxLayoutAlign="end" fxFlex="10%">
      <button
        (click)="snackBarRef.dismiss()"
        mat-button
        mat-icon-button
        aria-label="Clear"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <p>{{ message }}</p>
</div>
