<webclient-entity-list
  [entities]="zoneAdapters"
  [displayedColumns]="displayedColumns"
  [urlForm]="'/pages/admin/zone'"
  [title]="'zone.zones'"
  [icon]="'share_location'"
  [text]="text"
  (onRemoveEntity)="onRemove($event)"
  [roles]="[Role.ROLE_ADMIN]"
>
  <!-- Colonne nom -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>
      {{ 'zone.name' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let zoneAdapter">
      {{ zoneAdapter.zone.name }}
    </mat-cell>
  </ng-container>

  <!-- Colonne robots -->
  <ng-container matColumnDef="robots">
    <mat-header-cell *matHeaderCellDef class="center-align">
      {{ 'zone.robots' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let zoneAdapter" class="center-align">
      <span matBadge="{{ zoneAdapter.zone.robots.length }}"></span>
    </mat-cell>
  </ng-container>

  <!-- Colonne carte -->
  <ng-container matColumnDef="maps">
    <mat-header-cell *matHeaderCellDef class="center-align">
      {{ 'zone.maps' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let zoneAdapter" class="center-align">
      <span matBadge="{{ zoneAdapter.zone.maps.length }}"></span>
    </mat-cell>
  </ng-container>
</webclient-entity-list>
