import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Map } from '../../../api/models/map';

@Component({
  selector: 'webclient-map-dialog',
  templateUrl: './map-dialog.component.html',
  styleUrls: ['./map-dialog.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class MapDialogComponent {
  /**
   * Formulaire
   */
  public form: FormGroup | undefined;

  /**
   * Messages d'erreur
   */
  public mapValidationMessages = {
    name: [
      { type: 'required', message: 'map.validationMessages.name.required' },
    ],
  };

  /**
   * Identifiant de la zone sur laquelle créer la nouvelle carte
   *
   * @private
   */
  private zoneId: number | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<MapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { zoneId: number }
  ) {
    this.zoneId = data.zoneId;

    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
    });
  }

  /**
   * Retourne le contrôleur du formulaire
   */
  public get formControl(): any {
    return this.form?.controls;
  }

  /**
   * Click sur oui
   */
  onOkClick(): void {
    const map: Map = {
      name: this.formControl?.['name'].value as string,
      zone: {
        id: this.zoneId,
      },
    };

    this.dialogRef.close({
      map: map,
    });
  }

  /**
   * Click sur nom
   */
  onCancelClick(): void {
    this.dialogRef.close();
  }
}
