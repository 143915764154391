export class TimeslotDto {
  /**
   * Jour
   */
  public start_day?: number;

  /**
   * Heure de début
   */
  public start_time?: number;

  /**
   * Jour
   */
  public stop_day?: number;

  /**
   * Heure de fin
   */
  public stop_time?: number;
}
