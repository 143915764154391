import { EntityTransformer } from './entity-transformer';
import { CheckpointDTO } from '../dto/checkpoint-dto';
import { Checkpoint } from '../../../../api/models/checkpoint';

export class CheckpointTransformer
  implements EntityTransformer<Checkpoint, CheckpointDTO>
{
  to(from: Checkpoint): CheckpointDTO[] | CheckpointDTO {
    const checkpointDTO: CheckpointDTO = new CheckpointDTO();

    checkpointDTO.id = from.id;
    checkpointDTO.name = from.name;
    checkpointDTO.frame_id = 'map';

    if (from.pose) {
      checkpointDTO.x = from.pose.position.x;
      checkpointDTO.y = from.pose.position.y;
    }

    checkpointDTO.theta = 0.0;

    return checkpointDTO;
  }
}
