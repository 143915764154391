import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StrategyListComponent } from './strategy-list/strategy-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { CardModule } from '../card/card.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { FormModule } from '../form/form.module';
import { MatSelectModule } from '@angular/material/select';
import { StrategyCalendarComponent } from './strategy-calendar/strategy-calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { StrategyDialogComponent } from './strategy-dialog/strategy-dialog.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    StrategyListComponent,
    StrategyCalendarComponent,
    StrategyDialogComponent,
  ],
  exports: [
    StrategyListComponent,
    StrategyCalendarComponent,
    StrategyDialogComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    CardModule,
    MatDividerModule,
    MatTableModule,
    MatFormFieldModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    DragDropModule,
    FlexLayoutModule,
    MatButtonModule,
    FormModule,
    MatSelectModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxMaterialTimepickerModule,
    MatRadioModule,
    MatDialogModule,
    MatTooltipModule,
  ],
})
export class StrategyModule {}
