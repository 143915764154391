import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { EntityService } from './entity.service';
import { Timeslot } from '../../../api/models/timeslot';

@Injectable({
  providedIn: 'root',
})
export class TimeslotService extends EntityService<Timeslot> {
  public resourceUrl = environment.apiBaseUrl + 'timeslots';
}
