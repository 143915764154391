import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { SecureStorageService } from './secure-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService extends StorageService {
  constructor(secureStorageService: SecureStorageService) {
    super(window.sessionStorage, secureStorageService);
  }
}
