import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PatrolAdapter } from '../../../core/models/entities/patrol-adapter';

@Component({
  selector: 'webclient-patrol-dialog',
  templateUrl: './patrol-dialog.component.html',
  styleUrls: ['./patrol-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'webclient-patrol-dialog',
  },
})
export class PatrolDialogComponent {
  /**
   * Liste des patrouilles
   */
  public patrolAdapters: PatrolAdapter[] = [];

  /**
   * Patrouille sélectionnée
   */
  public selectedPatrolAdapter: PatrolAdapter | undefined;

  constructor(
    private dialogRef: MatDialogRef<PatrolDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { patrolAdapters: PatrolAdapter[] }
  ) {
    this.patrolAdapters = this.data.patrolAdapters;
  }

  /**
   * Click sur non
   */
  onOkClick(): void {
    this.dialogRef.close({
      patrolAdapter: this.selectedPatrolAdapter,
    });
  }

  /**
   * Click sur oui
   */
  onCancelClick(): void {
    this.dialogRef.close();
  }
}
