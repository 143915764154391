import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MessageSnackBarComponent } from './message-snack-bar/message-snack-bar.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [DialogConfirmComponent, MessageSnackBarComponent],
  exports: [DialogConfirmComponent, MessageSnackBarComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
    FlexLayoutModule,
    MatIconModule,
  ],
})
export class InfoModule {}
