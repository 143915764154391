import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Checkpoint } from '../../../api/models/checkpoint';
import { filter, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CheckpointAdapter } from '../../../core/models/entities/checkpoint-adapater';
import { BackStatus } from '../../../core/enums/back-status.enum';
import { MatTableDataSource } from '@angular/material/table';
import { MapPoint, MapPointService } from '@lelab31/ngx-rodotic';
import { MapConfigurationAdapter } from '../../../core/models/entities/map-configuration-adapter';
import { MapSettingsService } from '../../../core/services/map-settings.service';
import { TranslateService } from '@ngx-translate/core';
import { MapPointType } from '../../../core/enums/map-point-type.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../../../core/services/notification.service';
import { PatrolCheckpoint } from '../../../api/models/patrol-checkpoint';
import { Patrol } from '../../../api/models/patrol';

export type HideCheckpointButton = 'remove_red_eye' | 'visibility_off';

@Component({
  selector: 'webclient-checkpoint-list',
  templateUrl: './checkpoint-list.component.html',
  styleUrls: ['./checkpoint-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'webclient-checkpoint-list',
  },
})
export class CheckpointListComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * Liste des points de contrôle
   */
  @Input() public checkpointAdapters: CheckpointAdapter[] = [];

  /**
   * Source des données
   */
  public dataSource: MatTableDataSource<CheckpointAdapter> =
    new MatTableDataSource<CheckpointAdapter>([]);

  /**
   * Colonnes affichées
   */
  public displayedColumns: string[] = [
    'indicator',
    'name',
    'abscissa',
    'orderly',
    'actions',
    'draganddrop',
  ];

  /**
   * Détecte le changement de champs d'un point de contrôle
   */
  private propertyInput: Subject<CheckpointAdapter> = new Subject();

  /**
   * Statut en BDD
   */
  public BACK_STATUS = BackStatus;

  /**
   * Affichage ou non du bouton permettant de voir/cacher les points de contrôle
   */
  public hideCheckpointButton: HideCheckpointButton = 'remove_red_eye';

  /**
   * Configuration courante
   */
  public currentMapConfigurationAdapter: MapConfigurationAdapter | undefined;

  /**
   * Ce Subject permet de fermer les subscribe pour eviter les fuites lors de la fermeture de la page
   */
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private mapSettingsService: MapSettingsService,
    private mapPointService: MapPointService,
    private translateService: TranslateService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.listenChangeProperties();
    this.listenAddMapPoint();
    this.listenMapRendered();
    this.listenMapConfigurationAdapter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['checkpointAdapters'] &&
      changes['checkpointAdapters'].currentValue
    ) {
      this.checkpointAdapters.map(
        (checkpointAdapter: CheckpointAdapter, index: number) =>
          (checkpointAdapter.index = index)
      );
      this.dataSource = new MatTableDataSource<CheckpointAdapter>(
        this.checkpointAdapters
      );
    }
  }

  /**
   * Ecoute les changements des propriétés des points de contrôle
   *
   * @private
   */
  private listenChangeProperties(): void {
    this.propertyInput
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(300)
      )
      .subscribe((checkpointAdapter: CheckpointAdapter) => {
        this.mapSettingsService.updateCheckpoint(
          checkpointAdapter
        );
        this.mapSettingsService.updateCheckpoints(checkpointAdapter)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (checkpointAdapter: CheckpointAdapter | CheckpointAdapter[]) => {},
          (httpErrorResponse: HttpErrorResponse) => {
            checkpointAdapter.status = BackStatus.NOT_SAVED;
            const title: string = this.translateService.instant('entity.error');
            const message: string = this.translateService.instant(
              `${httpErrorResponse.error.detail}`
            );
            this.notificationService.displayErrorSnackBar(title, message);
          }
        );
      });
  }

  /**
   * Ecoute lorsqu'on ajoute un point de contrôle sur la carte
   *
   * @private
   */
  private listenAddMapPoint(): void {
    this.mapPointService
      .onAdd()
      .pipe(
        takeUntil(this.destroyed$),
        filter(
          (mapPoint: MapPoint) => mapPoint.group === MapPointType.CHECKPOINT
        )
      )
      .subscribe((mapPoint: MapPoint) => {
        const currentIndex: number = this.checkpointAdapters.length + 1;
        const checkpoint: Checkpoint = new Checkpoint(mapPoint.pose);
        checkpoint.name =
          this.translateService.instant('checkpoint.checkpoint') +
          ' ' +
          currentIndex;

        const checkpointAdapter = new CheckpointAdapter(checkpoint, mapPoint);
        checkpointAdapter.index = this.checkpointAdapters.length;

        this.checkpointAdapters.push(checkpointAdapter);
        this.dataSource._updateChangeSubscription();

        this.mapSettingsService.updateCheckpoints(checkpointAdapter)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (checkpointAdapter: CheckpointAdapter | CheckpointAdapter[]) => {},
          (httpErrorResponse: HttpErrorResponse) => {
            checkpointAdapter.status = BackStatus.NOT_SAVED;
            const title: string = this.translateService.instant('entity.error');
            const message: string = this.translateService.instant(
              `${httpErrorResponse.error.detail}`
            );
            this.notificationService.displayErrorSnackBar(title, message);
          }
        );
      });
  }

  /**
   * Evènement d'écoute indiquant quand la carte est rendue
   *
   * @private
   */
  private listenMapRendered(): void {
    this.mapPointService.onMapRendered()
    .pipe(takeUntil(this.destroyed$))
    .subscribe(() => {
      this.createMapPoints();
    });
  }

  /**
   * Ecoute le chargement de configuration
   *
   * On supprime tous les points de la carte
   *
   * @private
   */
  private listenMapConfigurationAdapter(): void {
    this.mapSettingsService.mapConfiguration$
    .pipe(takeUntil(this.destroyed$))
    .subscribe(
      (mapConfigurationAdapter: MapConfigurationAdapter | null) => {
        if (
          mapConfigurationAdapter &&
          mapConfigurationAdapter.mapConfiguration.checkpoints
        ) {
          this.checkpointAdapters =
            mapConfigurationAdapter.mapConfiguration.checkpoints.map(
              (checkpoint: Checkpoint) => new CheckpointAdapter(checkpoint)
            );
          this.mapPointService.removePoint(MapPointType.CHECKPOINT);
          this.createMapPoints();
          this.currentMapConfigurationAdapter = mapConfigurationAdapter;
        }
      }
    );
  }

  /**
   * Fonction de prédicat qui ne permet pas de déposer des points de patrouille dans la liste des points de contrôle
   */
  public noReturnPredicate() {
    return false;
  }

  /**
   * Création des points de contrôle sur la carte
   *
   * @private
   */
  private createMapPoints(): void {
    this.checkpointAdapters.map(
      (checkpointAdapter: CheckpointAdapter, index: number) => {
        if (checkpointAdapter.mapPoint) {
          this.mapPointService.addPoint(checkpointAdapter.mapPoint);
        }

        return checkpointAdapter;
      }
    );
  }

  /**
   * Changement d'une propriété d'un point de contrôle
   *
   * @param propriété à modifier
   * @param value valeur de la propriété à modifier
   * @param checkpointAdapter le point de contrôle à mettre à jour
   */
  public onChangeProperty(
    property: string,
    value: string | boolean,
    checkpointAdapter: CheckpointAdapter
  ) {
    checkpointAdapter.checkpoint[property as keyof Checkpoint] = <never>value;


    if(checkpointAdapter.mapPoint && checkpointAdapter.checkpoint.pose){  
      if((checkpointAdapter.checkpoint.pose.position !== checkpointAdapter.mapPoint.pose?.position)){
        checkpointAdapter.mapPoint.pose.position = checkpointAdapter.checkpoint.pose?.position
      }
    }

    if (property === 'abscissa' && checkpointAdapter.mapPoint) {
      this.mapPointService.updatePosition(
        checkpointAdapter.mapPoint,
        checkpointAdapter.mapPoint.pose.position.x,
        checkpointAdapter.mapPoint.pose.position.y
      );
    }

    if (property === 'orderly' && checkpointAdapter.mapPoint) {
      this.mapPointService.updatePosition(
        checkpointAdapter.mapPoint,
        checkpointAdapter.mapPoint.pose.position.x,
        checkpointAdapter.mapPoint.pose.position.y
      );
    }

    this.propertyInput.next(checkpointAdapter);
  }

  /**
   * Suppression d'un point de contrôle
   *
   * @param checkpointAdapter le point de contrôle à supprimer
   */
  public onDeleteCheckpoint(deletedCheckpointAdapter: CheckpointAdapter) {
    deletedCheckpointAdapter.deleted = true;

    this.mapSettingsService
      .updateCheckpoints(deletedCheckpointAdapter)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (checkpointAdapter: CheckpointAdapter | CheckpointAdapter[]) => {
          if (
            deletedCheckpointAdapter.index !== undefined &&
            deletedCheckpointAdapter.uid
          ) {
            this.checkpointAdapters.splice(deletedCheckpointAdapter.index, 1);
            this.checkpointAdapters.map(
              (patrolAdapter: CheckpointAdapter, index: number) =>
                (patrolAdapter.index = index)
            );
            if (deletedCheckpointAdapter.mapPoint) {
              this.mapPointService.removePoint(
                deletedCheckpointAdapter.mapPoint
              );
            }

            this.dataSource.data = this.checkpointAdapters;
            this.dataSource._updateChangeSubscription();
          }
        },
        (httpErrorResponse: HttpErrorResponse) => {
          this.checkpointAdapters.map(
            (checkpointAdapter: CheckpointAdapter) =>
              (checkpointAdapter.status = BackStatus.NOT_SAVED)
          );
          const title: string = this.translateService.instant('entity.error');
          const message: string = this.translateService.instant(
            `${httpErrorResponse.error.detail}`
          );
          this.notificationService.displayErrorSnackBar(title, message);
        }
      );
  }

  /**
   * Evènement permettant d'afficher ou cacher les points de contrôle
   */
  public onHide(): void {
    const hide = this.hideCheckpointButton === 'remove_red_eye' ? true : false;
    this.hideCheckpointButton =
      this.hideCheckpointButton === 'remove_red_eye'
        ? 'visibility_off'
        : 'remove_red_eye';
    this.mapPointService.setVisiblePoint(MapPointType.CHECKPOINT, !hide);
  }

  /**
   * Lors du survol d'un point de contrôle, ce dernier est affiché sur la carte avec une impulsion
   *
   * @param checkpointAdapter le point de contrôle
   */
  public onMouseenter(checkpointAdapter: CheckpointAdapter) {
    if (checkpointAdapter.mapPoint) {
      this.mapPointService.pulsePoint(checkpointAdapter.mapPoint, true);
    }
  }

  /**
   * Lorsqu'on ne survol plus le point de contrôle, ce dernier est affiché sur la carte sans impulsion
   *
   * @param checkpointAdapter le point de contrôle
   */
  public onMouseleave(checkpointAdapter: CheckpointAdapter) {
    if (checkpointAdapter.mapPoint) {
      this.mapPointService.pulsePoint(checkpointAdapter.mapPoint, false);
    }
  }

  /**
   * Empeche de supprimer un checkpoint qui est utilisé dans une patrouille
   */
  public canDelete(id: number): boolean {
    let result: boolean = true;
    this.currentMapConfigurationAdapter?.mapConfiguration.patrols?.forEach((patrol: Patrol) => {
      patrol.checkpoints.forEach((checkpointpatrol: PatrolCheckpoint) => {
        if((checkpointpatrol && checkpointpatrol.checkpoint) && (checkpointpatrol.checkpoint.id === id)){          
          result = false;
        }
      })
    })
    return result;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
