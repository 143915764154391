import { CalendarEvent } from 'angular-calendar';
import { Timeslot } from '../../../api/models/timeslot';
import { EventAction, EventColor } from 'calendar-utils';
import { BackStatus } from '../../enums/back-status.enum';
import { Subject } from 'rxjs';
import { addMinutes } from 'date-fns';

export class TimeslotAdapter implements CalendarEvent {
  public id: string | number | undefined;

  /**
   * Indique si l'item est supprimé
   */
  public deleted = false;

  public title: string;

  public start: Date;

  public end: Date | undefined;

  public actions: EventAction[] | undefined;

  public color: EventColor | undefined;

  public draggable: boolean = true;

  public timeslotCssClass: string = 'timeslot';

  public cssClass: string | undefined;

  public meta = {
    tmpEvent: true,
  };

  public resizable = {
    beforeStart: true,
    afterEnd: true,
  };

  public removeObserver = new Subject<TimeslotAdapter>();

  /**
   * Indique si l'item point de contrôle est sauvegardé en BDD
   */
  private _status: BackStatus = BackStatus.SAVED;

  constructor(public timeslot: Timeslot, title: string, color: string) {
    if (timeslot.id) {
      this.id = timeslot.id;
      this.cssClass = this.getCssClass(BackStatus.SAVED);
    } else {
      this.cssClass = this.getCssClass(BackStatus.PENDING);
    }
    this.title = title;

    const startDate: Date = new Date(timeslot.startDate);
    this.start = new Date(
      startDate.getTime() + startDate.getTimezoneOffset() * 60000
    );
    this.start = this.setDate(this.start);

    if (timeslot.endDate) {
      const endDate: Date = new Date(timeslot.endDate);
      this.end = new Date(
        endDate.getTime() + endDate.getTimezoneOffset() * 60000
      );
      this.end = this.setDate(this.end);
    }

    this.actions = this.addAction();
    this.color = this.addColor(color);
  }

  get status(): BackStatus {
    return this._status;
  }

  set status(value: BackStatus) {
    this._status = value;
    this.cssClass = this.getCssClass(value);
  }

  private getCssClass(status: BackStatus): string {
    return this.timeslotCssClass + ' ' + status.toLowerCase().replace('_', '-');
  }

  private addAction(): EventAction[] {
    return [
      {
        label: '<span class="material-icons mat-icon mat-accent">delete</span>',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.removeObserver.next(event as TimeslotAdapter);
        },
      },
    ];
  }

  private addColor(color: string): EventColor {
    return {
      primary: color,
      secondary: color,
    };
  }

  /**
   * Retourne la date du lundi de la semaine en cours
   *
   * @private
   */
  private getMondayOfCurrentWeek(): Date {
    const today = new Date();

    while (today.getDay() !== 1) {
      today.setDate(today.getDate() - 1);
    }

    return today;
  }

  /**
   * Positionne la date enregistrée en BDD sur la semaine en cours en ne tenant compte que du jour de la semaine et de l'horaire
   *
   * @param date la date en BDD
   *
   * @private
   */
  private setDate(date: Date): Date {
    const currentMonday: Date = this.getMondayOfCurrentWeek();

    // On positionne la date de fin du créneau horaire à partir du lundi en cours
    const scale = date.getDay() === 0 ? 6 : date.getDay() - 1;
    const d: Date = currentMonday;

    d.setDate(d.getDate() + scale);
    d.setHours(date.getHours());
    d.setMinutes(date.getMinutes());
    d.setSeconds(date.getSeconds());

    return d;
  }
}
