import {
  ChangeDetectorRef,
  Component,
  Input,
  Optional,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { fromEvent, Subject } from 'rxjs';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  CalendarEvent,
  CalendarEventTimesChangedEvent,
  DAYS_OF_WEEK,
} from 'angular-calendar';
import { WeekViewHourSegment } from 'calendar-utils';
import { finalize, first, map, takeUntil } from 'rxjs/operators';
import { addDays, addMinutes, endOfWeek, isSameDay } from 'date-fns';

@Component({
  selector: 'webclient-calendar-editor',
  templateUrl: './calendar-editor.component.html',
  styleUrls: ['./calendar-editor.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: CalendarEditorComponent,
    },
  ],
})
export class CalendarEditorComponent
  implements ControlValueAccessor, MatFormFieldControl<any>
{
  public viewDate: Date = new Date();

  /**
   * Evènements
   */
  public events: any[] = [];

  /**
   * Locale
   */
  public locale = 'fr';

  public weekStartsOn = DAYS_OF_WEEK.MONDAY;

  /**
   * Rafraichissement d'un créneau horaire
   */
  public refreshEvent: Subject<void> = new Subject();

  @Input('aria-describedby') userAriaDescribedBy: string = '';

  @Input() possibleEvent: string[] | undefined;

  controlType = 'calendar-editor';

  readonly stateChanges = new Subject<void>();

  @Input()
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    //this._id = value || this._uid;
    this.stateChanges.next();
  }
  private _id: string = '';

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string = '';

  get focused(): boolean {
    return this._focused; // || this._panelOpen;
  }
  private _focused = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    //this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  _value: any;

  get value(): any {
    return this._value;
  }
  set value(value) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  onChange = (delta: any) => {};

  onTouched = (delta: any) => {
    //this.touched = true;
  };

  writeValue(obj: any): void {
    this.value = obj;
    this.events = this.value;
    console.log('WRITE VALUE ', this.value);
    this.refresh();
  }

  registerOnChange(fn: (v: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (v: any) => void): void {
    this.onTouched = fn;
  }

  get shouldLabelFloat(): boolean {
    return false; //this._panelOpen || !this.empty || (this._focused && !!this._placeholder);
  }

  get errorState(): boolean {
    return false; //this.parts.invalid && this.touched;
  }

  get empty(): boolean {
    //let n = this.parts.value;
    //return !n.area && !n.exchange && !n.subscriber;
    return false;
  }

  get autofilled(): boolean {
    return false;
  }

  onContainerClick(event: MouseEvent): void {}

  setDescribedByIds(ids: string[]): void {}

  public eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {}

  public validateEventTimesChanged = ({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent) => {
    return true;
  };

  public startDragToCreate(
    segment: WeekViewHourSegment,
    mouseDownEvent: MouseEvent,
    segmentElement: HTMLElement
  ) {
    const dragToSelectEvent: CalendarEvent = {
      id: this.events.length,
      title: 'New event',
      start: segment.date,
      meta: {
        tmpEvent: true,
      },
    };
    this.events = [...this.events, dragToSelectEvent];
    const segmentPosition = segmentElement.getBoundingClientRect();
    //this.dragToCreateActive = true;
    const endOfView = endOfWeek(this.viewDate, {
      weekStartsOn: this.weekStartsOn,
    });

    fromEvent(document, 'mousemove')
      .pipe(
        finalize(() => {
          delete dragToSelectEvent.meta.tmpEvent;
          //this.dragToCreateActive = false;
          this.refresh();
        }),
        takeUntil(fromEvent(document, 'mouseup')),
        map((event: Event) => event as MouseEvent)
      )
      .subscribe((mouseMoveEvent: MouseEvent) => {
        const minutesDiff = this.ceilToNearest(
          mouseMoveEvent.clientY - segmentPosition.top,
          30
        );
        const daysDiff =
          this.floorToNearest(
            mouseMoveEvent.clientX - segmentPosition.left,
            segmentPosition.width
          ) / segmentPosition.width;

        const newEnd = addDays(addMinutes(segment.date, minutesDiff), daysDiff);
        if (newEnd > segment.date && newEnd < endOfView) {
          dragToSelectEvent.end = newEnd;
        }
        console.log('dragToSelectEvent', dragToSelectEvent);
        console.log('events', this.events);
        this.value = this.events;
        this.refresh();
      });

    fromEvent(document, 'mouseup')
      .pipe(first())
      .subscribe(() => {
        if (this.possibleEvent) {
          console.log('AFFICHAGE POPUP');
        }
      });
  }

  private floorToNearest(amount: number, precision: number): number {
    return Math.floor(amount / precision) * precision;
  }

  private ceilToNearest(amount: number, precision: number): number {
    return Math.ceil(amount / precision) * precision;
  }

  /**
   * Rafraichissement du planning
   *
   * @private
   */
  private refresh(): void {
    this.events = [...this.events];
    this.changeDetectorRef.detectChanges();
  }
}
