<h1 mat-dialog-title>{{ 'map.create' | translate }}</h1>

<mat-dialog-content class="mat-typography" fxLayout="column" fxLayoutGap="30px">
  <form [formGroup]="form" *ngIf="form" fxLayout="column" fxLayoutGap="20px">
    <!-- Champ nom -->
    <div fxLayout="row" fxLayoutGap="10px">
      <div
        fxLayout="row"
        fxLayout.sm="column"
        fxLayout.xs="column"
        fxLayoutGap="20px"
        fxFlex="100"
      >
        <mat-form-field fxFlex>
          <mat-icon matPrefix>person</mat-icon>
          <input
            matInput
            type="text"
            name="name"
            required="required"
            placeholder="{{ 'map.name' | translate }}"
            formControlName="name"
          />
          <mat-error *ngFor="let validation of mapValidationMessages.name">
            <ng-container
              *ngIf="
                formControl?.name?.hasError(validation.type) &&
                (formControl?.name?.dirty || formControl?.name?.touched)
              "
            >
              {{ validation.message | translate }}
            </ng-container>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <button mat-flat-button color="primary" (click)="onCancelClick()">
    {{ 'entity.cancel' | translate }}
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="onOkClick()"
    [disabled]="!form?.valid"
  >
    {{ 'entity.create' | translate }}
  </button>
</mat-dialog-actions>
