import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HasRoleDirective } from './directive/has-role.directive';

@NgModule({
  declarations: [HasRoleDirective],
  exports: [HasRoleDirective],
  imports: [CommonModule, TranslateModule],
})
export class CoreModule {}
