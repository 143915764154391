<div fxLayout="column" fxLayoutAlign="center center" class="login-wrapper">
  <div fxLayoutAlign="center center" class="logo">
    <mat-icon>account_circle</mat-icon>
  </div>

  <div fxLayout="column" fxLayoutAlign="center center" class="toolbar">
    <mat-icon svgIcon="alf31"></mat-icon>
    <span> {{ 'app.title' | translate }} </span>
  </div>

  <mat-divider color="primary"></mat-divider>

  <div class="mat-elevation-z0">
    <form
      [formGroup]="form"
      (ngSubmit)="onLogin(form.value.email, form.value.password)"
    >
      <!-- Champ email -->
      <div fxLayout="row" fxLayoutGap="10px">
        <div
          fxLayout="row"
          fxLayout.sm="column"
          fxLayout.xs="column"
          fxLayoutGap="20px"
          fxFlex="100"
        >
          <mat-form-field fxFlex>
            <mat-icon matPrefix>mail_outline</mat-icon>
            <input
              matInput
              type="text"
              name="email"
              required="required"
              placeholder="{{ 'login.email' | translate }}"
              formControlName="email"
            />
            <mat-error *ngFor="let validation of loginValidationMessages.email">
              <ng-container
                *ngIf="
                  form.get('email')?.hasError(validation.type) &&
                  (form.get('email')?.dirty || form.get('email')?.touched)
                "
              >
                {{ validation.message | translate }}
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- Champ mot de passe -->
      <div fxLayout="row" fxLayoutGap="10px">
        <div
          fxLayout="row"
          fxLayout.sm="column"
          fxLayout.xs="column"
          fxLayoutGap="20px"
          fxFlex="100"
        >
          <mat-form-field fxFlex>
            <mat-icon matPrefix>lock</mat-icon>
            <mat-icon
              matSuffix
              [svgIcon]="showPassword ? 'eye-on' : 'eye-off'"
              (click)="showPassword = !showPassword"
              class="eye"
            ></mat-icon>
            <input
              matInput
              [type]="showPassword ? 'text' : 'password'"
              name="password"
              required="required"
              placeholder="{{ 'login.password' | translate }}"
              formControlName="password"
            />
            <mat-error
              *ngFor="let validation of loginValidationMessages.password"
            >
              <ng-container
                *ngIf="
                  form.get('password')?.hasError(validation.type) &&
                  (form.get('password')?.dirty || form.get('password')?.touched)
                "
              >
                {{ validation.message | translate }}
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <p class="error">
        <mat-error *ngIf="loginError != undefined">
          {{ loginError | translate }}
        </mat-error>
      </p>

      <div fxLayoutAlign="center center">
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="form.invalid && form.touched"
          class="login-button"
        >
          <mat-icon>lock</mat-icon>
          <span> {{ 'login.connection' | translate }} </span>
        </button>
      </div>
    </form>
  </div>
</div>
