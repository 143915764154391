<div class="webclient-patrol-checkpoint-list-header" *ngIf="displayHeader">
  <div fxLayout="row" fxLayout.xs="column">
    <webclient-card-title
      [title]="title"
      icon="location_on"
    ></webclient-card-title>
  </div>

  <mat-divider></mat-divider>
</div>

<div class="webclient-patrol-checkpoint-list-content">
  <mat-table
    [dataSource]="dataSource"
    cdkDropList
    (cdkDropListDropped)="onDropTable($event)"
    [cdkDropListData]="patrolAdapter?.patrolCheckpointAdapters"
    [id]="patrolAdapterId"
  >
    <!-- Colonne indicateur -->
    <ng-container matColumnDef="indicator">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell
        *matCellDef="let patrolCheckpointAdapter"
        [ngClass]="{
          saved: patrolCheckpointAdapter.status === BACK_STATUS.SAVED,
          pending: patrolCheckpointAdapter.status === BACK_STATUS.PENDING,
          'not-saved': patrolCheckpointAdapter.status === BACK_STATUS.NOT_SAVED
        }"
      ></mat-cell>
    </ng-container>

    <!--  Colonne pour le drag and drop -->
    <ng-container matColumnDef="reorder">
      <mat-header-cell *matHeaderCellDef fxFlex="5"></mat-header-cell>
      <mat-cell *matCellDef="let patrolCheckpointAdapter" fxFlex="5">
        <mat-icon class="drag-and-drop" cdkDragHandle>reorder</mat-icon>
      </mat-cell>
    </ng-container>

    <!-- Colonne numéro -->
    <ng-container matColumnDef="number">
      <mat-header-cell *matHeaderCellDef>{{
        'checkpoint.number' | translate
      }}</mat-header-cell>
      <mat-cell *matCellDef="let patrolCheckpointAdapter">{{
        patrolCheckpointAdapter.patrolCheckpoint.position
      }}</mat-cell>
    </ng-container>

    <!-- Colonne nom -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>{{
        'checkpoint.name' | translate
      }}</mat-header-cell>
      <mat-cell *matCellDef="let patrolCheckpointAdapter">
        {{ patrolCheckpointAdapter.patrolCheckpoint.checkpoint.name }}
      </mat-cell>
    </ng-container>

    <!-- Colonne durée -->
    <ng-container matColumnDef="duration">
      <mat-header-cell *matHeaderCellDef>{{
        'checkpoint.duration' | translate
      }}</mat-header-cell>
      <mat-cell *matCellDef="let patrolCheckpointAdapter">
        <webclient-number-input
          [canEdit]="editable"
          [(ngModel)]="patrolCheckpointAdapter.patrolCheckpoint.duration"
          (ngModelChange)="
            onChangeProperty('duration', $event, patrolCheckpointAdapter)
          "
          [disabled]="!editable"
          [onlyIntegerDigit]="true"
        ></webclient-number-input>
      </mat-cell>
    </ng-container>

    <!-- Colonne orienté -->
    <ng-container matColumnDef="oriented">
      <mat-header-cell *matHeaderCellDef fxLayoutAlign="center center">{{
        'checkpoint.oriented' | translate
      }}</mat-header-cell>
      <mat-cell
        *matCellDef="let patrolCheckpointAdapter"
        fxLayoutAlign="center center"
      >
        <mat-slide-toggle
          [(ngModel)]="patrolCheckpointAdapter.patrolCheckpoint.oriented"
          (ngModelChange)="
            onChangeProperty('oriented', $event, patrolCheckpointAdapter)
          "
          [disabled]="!editable"
        ></mat-slide-toggle>
      </mat-cell>
    </ng-container>

    <!-- Colonne orientation -->
    <ng-container matColumnDef="orientation">
      <mat-header-cell *matHeaderCellDef>{{
        'checkpoint.orientation' | translate
      }}</mat-header-cell>
      <mat-cell *matCellDef="let patrolCheckpointAdapter">
        <webclient-angle-input
          [value]="patrolCheckpointAdapter.patrolCheckpoint.checkpoint.pose"
          [(ngModel)]="patrolCheckpointAdapter.patrolCheckpoint.pose"
          [disabled]="!editable || !patrolCheckpointAdapter.patrolCheckpoint.oriented"
          (changing)="onChangingOrientation($event, patrolCheckpointAdapter)"
          (editing)="onEditingOrientation($event, patrolCheckpointAdapter)"
          (ngModelChange)="
            onChangeProperty('orientation', $event, patrolCheckpointAdapter)
          "
        ></webclient-angle-input>
      </mat-cell>
    </ng-container>

    <!-- Colonne sentinelle -->
    <ng-container matColumnDef="sentinel">
      <mat-header-cell *matHeaderCellDef>{{
        'checkpoint.sentinel' | translate
      }}</mat-header-cell>
      <mat-cell *matCellDef="let patrolCheckpointAdapter">
        <mat-slide-toggle
          [(ngModel)]="patrolCheckpointAdapter.patrolCheckpoint.sentinel"
          (ngModelChange)="
            onChangeProperty('sentinel', $event, patrolCheckpointAdapter)
          "
          [disabled]="!editable"
        ></mat-slide-toggle>
      </mat-cell>
    </ng-container>

    <!-- Colonne rapide -->
    <ng-container matColumnDef="fast">
      <mat-header-cell *matHeaderCellDef>{{
        'checkpoint.fast' | translate
      }}</mat-header-cell>
      <mat-cell *matCellDef="let patrolCheckpointAdapter">
        <mat-slide-toggle
          [(ngModel)]="patrolCheckpointAdapter.patrolCheckpoint.fast"
          (ngModelChange)="
            onChangeProperty('fast', $event, patrolCheckpointAdapter)
          "
          [disabled]="!editable"
        ></mat-slide-toggle>
      </mat-cell>
    </ng-container>

    <!-- Colonne actions -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell
        *matCellDef="let patrolCheckpointAdapter; let index = dataIndex"
      >
        <button
          mat-icon-button
          color="accent"
          (click)="onDeletePatrolCheckpoint(patrolCheckpointAdapter)"
        >
          <mat-icon>remove_circle_outline</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="empty-row">
      <mat-cell *matCellDef="let element">{{
        'checkpoint.nocheckpoints' | translate
      }}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <ng-container *ngIf="editable">
      <mat-row
        *matRowDef="
          let patrolCheckpointAdapter;
          columns: (patrolAdapter !== undefined &&
            patrolAdapter.patrolCheckpointAdapters !== undefined &&
            patrolAdapter.patrolCheckpointAdapters.length) > 0
            ? displayedColumns
            : ['empty-row']
        "
        cdkDrag
        [cdkDragData]="patrolCheckpointAdapter"
        (mouseenter)="onMouseenter(patrolCheckpointAdapter)"
        (mouseleave)="onMouseleave(patrolCheckpointAdapter)"
      ></mat-row>
    </ng-container>
    <ng-container *ngIf="!editable">
      <mat-row
        *matRowDef="
          let patrolCheckpointAdapter;
          columns: (patrolAdapter !== undefined &&
            patrolAdapter.patrolCheckpointAdapters !== undefined &&
            patrolAdapter.patrolCheckpointAdapters.length) > 0
            ? displayedColumns
            : ['empty-row']
        "
        (mouseenter)="onMouseenter(patrolCheckpointAdapter)"
        (mouseleave)="onMouseleave(patrolCheckpointAdapter)"
      ></mat-row>
    </ng-container>
  </mat-table>
</div>
