<mat-nav-list>
  <h3 mat-subheader>{{ 'menu.public.home' | translate }}</h3>

  <a mat-list-item [routerLink]="['/dashboard']">
    <mat-icon mat-list-icon> dashboard </mat-icon>
    <p mat-line>{{ 'menu.public.dashboard' | translate }}</p>
  </a>

  <mat-divider></mat-divider>

  <h3 mat-subheader *hasRole="[Role.ROLE_SUPER_ADMIN, Role.ROLE_ADMIN]">
    {{ 'menu.admin.title' | translate }}
  </h3>

  <!-- Menu client -->
  <a
    mat-list-item
    [routerLink]="['/pages/admin/customer/list']"
    *hasRole="[Role.ROLE_SUPER_ADMIN]"
  >
    <mat-icon mat-list-icon>group</mat-icon>
    <p mat-line>{{ 'customer.customers' | translate }}</p>
  </a>

  <!-- Menu groupe d'utilisateur -->
  <a
    mat-list-item
    [routerLink]="['/pages/admin/usergroup/list']"
    *hasRole="[Role.ROLE_SUPER_ADMIN, Role.ROLE_ADMIN]"
  >
    <mat-icon mat-list-icon>groups</mat-icon>
    <p mat-line>{{ 'usergroup.usergroups' | translate }}</p>
  </a>

  <!-- Menu utilisateur -->
  <a
    mat-list-item
    [routerLink]="['/pages/admin/user/list']"
    *hasRole="[Role.ROLE_SUPER_ADMIN, Role.ROLE_ADMIN]"
  >
    <mat-icon mat-list-icon>person</mat-icon>
    <p mat-line>{{ 'user.users' | translate }}</p>
  </a>

  <!-- Menu robot -->
  <a
    mat-list-item
    [routerLink]="['/pages/admin/robot/list']"
    *hasRole="[Role.ROLE_SUPER_ADMIN, Role.ROLE_ADMIN]"
  >
    <mat-icon mat-list-icon svgIcon="alf31"></mat-icon>
    <p mat-line>{{ 'menu.admin.robots' | translate }}</p>
  </a>

  <!-- Menu zones -->
  <a
    mat-list-item
    [routerLink]="['/pages/admin/zone/list']"
    *hasRole="[Role.ROLE_SUPER_ADMIN, Role.ROLE_ADMIN]"
  >
    <mat-icon mat-list-icon>share_location</mat-icon>
    <p mat-line>{{ 'zone.zones' | translate }}</p>
  </a>

  <!-- Menu déclencheurs -->
  <a
    mat-list-item
    [routerLink]="['/pages/admin/trigger/list']"
    *hasRole="[Role.ROLE_SUPER_ADMIN]"
  >
    <mat-icon mat-list-icon>bolt</mat-icon>
    <p mat-line>{{ 'trigger.triggers' | translate }}</p>
  </a>
</mat-nav-list>
