import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RobotControlComponent } from './robot-control/robot-control.component';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import {
  BatteryModule,
  ConnectionStatusModule,
  EmergencyButtonModule,
  LedModule,
  VideoModule,
} from '@lelab31/ngx-rodotic';
import { RobotVideoComponent } from './robot-video/robot-video.component';
import { RobotToolbarComponent } from './robot-toolbar/robot-toolbar.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RobotZoneComponent } from './robot-zone/robot-zone.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { RobotLightingComponent } from './robot-lighting/robot-lighting.component';
import { RobotSpeedComponent } from './robot-speed/robot-speed.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RobotStateComponent } from './robot-state/robot-state.component';
import { RobotLoggerComponent } from './robot-logger/robot-logger.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { RobotSelectComponent } from './robot-select/robot-select.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RobotSettingsComponent } from './robot-settings/robot-settings.component';
import { CardModule } from '../card/card.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CoreModule } from '../../core/core.module';
import { NgxMaskModule } from 'ngx-mask';
import { RobotVideoPlayComponent } from './robot-videoplay/robot-videoplay.component';
import { RobotArmingComponent } from './robot-arming/robot-arming.component';
import { RobotFireComponent } from './robot-fire/robot-fire.component';

@NgModule({
  declarations: [
    RobotControlComponent,
    RobotVideoComponent,
    RobotVideoPlayComponent,
    RobotArmingComponent,
    RobotFireComponent,
    RobotToolbarComponent,
    RobotZoneComponent,
    RobotLightingComponent,
    RobotSpeedComponent,
    RobotStateComponent,
    RobotLoggerComponent,
    RobotSelectComponent,
    RobotSettingsComponent
  ],
  exports: [
    RobotControlComponent,
    RobotToolbarComponent,
    RobotVideoComponent,
    RobotVideoPlayComponent,
    RobotArmingComponent,
    RobotFireComponent,
    RobotLightingComponent,
    RobotSpeedComponent,
    RobotStateComponent,
    RobotLoggerComponent,
    RobotSelectComponent,
    RobotSettingsComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatDividerModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    MatInputModule,
    MatFormFieldModule,
    ConnectionStatusModule,
    BatteryModule,
    EmergencyButtonModule,
    RouterModule,
    LedModule,
    FlexModule,
    TranslateModule,
    MatButtonToggleModule,
    VideoModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatCheckboxModule,
    CardModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatSlideToggleModule,
    CoreModule,
    FlexLayoutModule,
    NgxMaskModule.forRoot(),
  ],
})
export class RobotModule {}
