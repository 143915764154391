<div fxLayout="row" fxLayout.xs="column">
  <webclient-card-title
    title="checkpoint.available"
    icon="location_on"
  ></webclient-card-title>

  <div fxFlex></div>

  <div fxLayoutAlign="center center">
    <!-- Bouton de création d'une patrouille -->
    <button
      mat-icon-button
      color="accent"
      title="{{ 'strategy.add' | translate }}"
      (click)="onHide()"
    >
      <mat-icon [color]="'accent'"> {{ hideCheckpointButton }}</mat-icon>
    </button>
  </div>
</div>

<mat-table
  [dataSource]="dataSource"
  multiTemplateDataRows
  cdkDropList
  [cdkDropListData]="dataSource"
  [cdkDropListEnterPredicate]="noReturnPredicate"
>
  <!-- Colonne indicateur -->
  <ng-container matColumnDef="indicator">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell
      *matCellDef="let checkpointAdapter"
      [ngClass]="{
        saved: checkpointAdapter.status === BACK_STATUS.SAVED,
        pending: checkpointAdapter.status === BACK_STATUS.PENDING,
        'not-saved': checkpointAdapter.status === BACK_STATUS.NOT_SAVED
      }"
    ></mat-cell>
  </ng-container>

  <!-- Colonne nom -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>{{
      'checkpoint.name' | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let checkpointAdapter">
      <mat-form-field>
        <input
          matInput
          placeholder="{{ 'checkpoint.namePlaceholder' | translate }}"
          [ngModel]="checkpointAdapter.checkpoint.name!"
          (ngModelChange)="onChangeProperty('name', $event, checkpointAdapter)"
        />
      </mat-form-field>
    </mat-cell>
  </ng-container>

  <!-- Colonne abscisse -->
  <ng-container matColumnDef="abscissa">
    <mat-header-cell *matHeaderCellDef>{{
      'checkpoint.abscissa' | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let checkpointAdapter">
      <webclient-number-input
        [step]="0.1"
        [canEdit]="true"
        [(ngModel)]="checkpointAdapter.checkpoint.pose.position.x"
        (ngModelChange)="
          onChangeProperty('abscissa', $event, checkpointAdapter)
        "
      ></webclient-number-input>
    </mat-cell>
  </ng-container>

  <!-- Colonne ordonnée -->
  <ng-container matColumnDef="orderly">
    <mat-header-cell *matHeaderCellDef>{{
      'checkpoint.orderly' | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let checkpointAdapter">
      <webclient-number-input
        [step]="0.1"
        [canEdit]="true"
        [(ngModel)]="checkpointAdapter.checkpoint.pose.position.y"
        (ngModelChange)="onChangeProperty('orderly', $event, checkpointAdapter)"
      ></webclient-number-input>
    </mat-cell>
  </ng-container>

  <!-- Colonne actions -->
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let checkpointAdapter; let index = dataIndex">
      <button
        mat-icon-button
        color="accent"
        [disabled]="!canDelete(checkpointAdapter.checkpoint.id)"
        (click)="onDeleteCheckpoint(checkpointAdapter)"
      >
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <!-- Colonne pour le drag and drop -->
  <ng-container matColumnDef="draganddrop">
    <mat-header-cell *matHeaderCellDef fxFlex="5"></mat-header-cell>
    <mat-cell *matCellDef="let checkpointAdapter" fxFlex="5">
      <mat-icon cdkDragHandle>drag_indicator</mat-icon>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row
    *matRowDef="let checkpointAdapter; columns: displayedColumns"
    cdkDrag
    [cdkDragData]="checkpointAdapter"
    (mouseenter)="onMouseenter(checkpointAdapter)"
    (mouseleave)="onMouseleave(checkpointAdapter)"
  ></mat-row>
</mat-table>
