import { Component, ViewEncapsulation } from '@angular/core';
import { IEntity } from '../../../../components/admin/models/i-entity';
import { ActivatedRoute } from '@angular/router';
import {
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { EntityFormComponent } from '../../../../components/admin/entity-form/entity-form.component';
import { UserService } from '../../../../core/services/entities/user.service';
import { User } from '../../../../api/models/user';
import { NotificationService } from '../../../../core/services/notification.service';
import { UserGroup } from '../../../../api/models/user-group';
import { Role } from '../../../../core/enums/role.enum';
import { SessionService } from '../../../../core/services/session.service';
import { CustomValidators } from '../../../../core/validators/custom-validators';
import { UserGroupService } from '../../../../core/services/entities/user-group.service';
import { UserAdapter } from '../../../../core/models/entities/user-adapter';
import { Utils } from '../../../../core/utils/utils';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CustomerService } from '../../../../core/services/entities/customer.service';
import { Customer } from '../../../../api/models/customer';
import { forkJoin } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'webclient-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'webclient-user-form',
  },
})
export class UserFormComponent extends EntityFormComponent {
  /**
   * Utilisateur
   */
  public userAdapter: UserAdapter | undefined;

  /**
   * Groupes d'utilisateurs
   */
  public userGroups: UserGroup[] = [];

  /**
   * Liste des clients
   */
  public customers: Customer[] = [];

  /**
   * Voir de mot de passe
   */
  public showPassword = false;

  /**
   * Voir de mot de passe de confirmation
   */
  public showConfirmationPassword = false;

  /**
   * Messages d'erreur
   */
  public validationMessages = {
    email: [
      { type: 'required', message: 'user.validationMessages.email.required' },
      { type: 'pattern', message: 'user.validationMessages.email.pattern' },
    ],
    firstname: [
      {
        type: 'required',
        message: 'user.validationMessages.firstname.required',
      },
    ],
    lastname: [
      {
        type: 'required',
        message: 'user.validationMessages.lastname.required',
      },
    ],
    password: [
      {
        type: 'required',
        message: 'user.validationMessages.password.required',
      },
      {
        type: 'minlength',
        message: 'user.validationMessages.password.minlength',
      },
      { type: 'pattern', message: 'user.validationMessages.password.pattern' },
    ],
    confirmationPassword: [
      {
        type: 'required',
        message: 'user.validationMessages.confirmationPassword.required',
      },
      {
        type: 'mustMatch',
        message: 'user.validationMessages.confirmationPassword.mustMatch',
      },
    ],
    customer: [
      {
        type: 'required',
        message: 'user.validationMessages.customer.required',
      },
    ],
    roles: [
      {
        type: 'minimumRequireCheckboxesToBeChecked',
        message:
          'user.validationMessages.roles.minimumRequireCheckboxesToBeChecked',
      },
      {
        type: 'maximumRequireCheckboxesToBeChecked',
        message:
          'user.validationMessages.roles.maximumRequireCheckboxesToBeChecked',
        params: { maximum: 1 },
      },
    ],
    userGroups: [
      {
        type: 'requireOne',
        message: 'user.validationMessages.userGroups.requireOne',
      },
    ],
  };

  /**
   * Rôle courant
   *
   * @private
   */
  public currentRole: Role | null = null;

  public compareCustomer(ug1: UserGroup, ug2: UserGroup): boolean {
    return ug1 && ug2 ? ug1.id === ug2.id : ug1 === ug2;
  }

  constructor(
    protected override sessionService: SessionService,
    protected override route: ActivatedRoute,
    private userService: UserService,
    private formBuilder: FormBuilder,
    protected override translateService: TranslateService,
    protected override notificationService: NotificationService,
    protected override location: Location,
    private userGroupService: UserGroupService,
    private customerService: CustomerService
  ) {
    super(
      route,
      sessionService,
      translateService,
      notificationService,
      location
    );

    if (this.currentUser) {
      if (!this.isSuperAdmin) {
        const index = this.roles.indexOf(Role.ROLE_SUPER_ADMIN, 0);
        if (index > -1) {
          this.roles.splice(index, 1);
        }
      }
    }

    this.form = this.formBuilder.group({
      lastname: ['', [Validators.required]],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ],
      ],
      firstname: ['', [Validators.required]],
      password: [
        '',
        [Validators.required, Validators.pattern(Utils.patternPassword())],
      ],
      confirmationPassword: [
        '',
        [Validators.required, Validators.pattern(Utils.patternPassword())],
      ],
      customer: [null, [Validators.required]],
      roles: new FormArray(
        this.roles.map(() => new FormControl(null)),
        [
          CustomValidators.minimumRequireCheckboxesToBeChecked(),
          CustomValidators.maximumRequireCheckboxesToBeChecked(1),
        ]
      ),
      userGroups: [[], []],
    });

    if (this.id) {
      forkJoin([
        this.customerService.getAll(),
        this.userService.getById(this.id),
      ]).subscribe((results) => {
        this.customers = results[0];
        this.userAdapter = new UserAdapter(results[1]);
        this.userAdapter.roles = this.roles.map((role) =>
          results[1].roles.includes(role) ? role : null
        );

        this.userGroupService
          .getAll('customer=' + this.userAdapter.user.customer?.id)
          .subscribe((userGroups: UserGroup[]) => {
            this.userGroups = userGroups;
          });

        this.formControl.password.disable();
        this.formControl.confirmationPassword.disable();

        if (this.form) {
          this.currentRole = this.userAdapter.user.roles[0];
          this.form.patchValue(this.userAdapter.user);
        }
      });
    } else {
      this.customerService.getAll().subscribe((customers: Customer[]) => {
        this.customers = customers;

        if (this.isAdmin && this.currentUser && this.form) {
          this.form.get('customer')?.setValue(this.currentUser.customer);
        }
      });

      this.userGroupService.getAll().subscribe((userGroups: UserGroup[]) => {
        this.userGroups = userGroups;
      });
    }
  }

  /**
   * Création d'un utilisateur
   *
   * @param entity l'utilisateur à créer
   */
  public onCreate(entity: IEntity) {
    const user: User = entity as User;
    user.roles = user.roles.filter((role: string) => role !== null);

    this.userService.create(user).subscribe(
      (user: User) => {
        this.displaySuccessSnackBar(
          user.lastname + ' ' + user.firstname,
          'user.createSuccessMessage'
        );
      },
      (error) => {
        this.displayErrorSnackBar(
          user.lastname + ' ' + user.firstname,
          'user.createErrorMessage'
        );
      }
    );
  }

  /**
   * Edition d'un déclencheur
   *
   * @param entity le déclencheur à éditer
   */
  public onEdit(entity: IEntity) {
    const user: User = entity as User;
    user.roles = this.currentRole !== null ? [this.currentRole] : [];

    this.userService.update(this.id, user).subscribe(
      (user: User) => {
        this.displaySuccessSnackBar(
          user.lastname + ' ' + user.firstname,
          'user.editSuccessMessage'
        );
      },
      (error) => {
        this.displayErrorSnackBar(
          user.lastname + ' ' + user.firstname,
          'user.editErrorMessage'
        );
      }
    );
  }

  /**
   * Evènement pour changer le mot de passe
   */
  public onChangePassword(): void {
    if (this.form) {
      this.formControl.password.enable();
      this.formControl.confirmationPassword.enable();
    }
  }

  /**
   * Changement de rôle
   *
   * @param event l'évènement du DOM
   * @param index l'index du rôle
   */
  public onRoleChange(event: MatCheckboxChange, index: number): void {
    const role: Role = event.source.value as Role;
    const checked: boolean = event.checked;
    const formArray: FormArray = this.formControl.roles as FormArray;
    this.currentRole = null;

    formArray.controls.forEach((control) => {
      control.setValue(null);
    });

    if (checked) {
      this.currentRole = role;
      formArray.controls.at(index)?.setValue(role);
      this.form?.controls['userGroups'].setValue(this.getUserGroupsRoot());
    }
  }

  private getUserGroupsRoot(): UserGroup[] {
    let childrens: UserGroup[] = [];

    this.userGroups.forEach((userGroup: UserGroup) => {
      childrens = childrens.concat(userGroup.children);
    });

    childrens = this.flatten(childrens);

    const userGroupsRoot: UserGroup[] = this.userGroups.filter(
      (userGroup: UserGroup) => {
        const findInChild: UserGroup | undefined = this.findChild(
          childrens,
          userGroup.id
        );

        if (findInChild && userGroup.parent) {
          return false;
        }

        return true;
      }
    );

    return userGroupsRoot;
  }

  private flatten(userGroups: UserGroup[]) {
    return userGroups.reduce((r: UserGroup[], { children, ...rest }) => {
      r.push(rest as UserGroup);
      if (children) r.push(...this.flatten(children));
      return r;
    }, []);
  }

  private findChild(
    userGroups: UserGroup[],
    id: number | undefined
  ): UserGroup | undefined {
    for (const userGroup of userGroups) {
      if (userGroup.id === id) {
        return userGroup;
      }
      if (userGroup.children) {
        const child: UserGroup | undefined = this.findChild(
          userGroup.children,
          id
        );

        if (child) {
          return child;
        }
      }
    }

    return undefined;
  }

  /**
   * Changement de client
   *
   * @param selected
   */
  public onChangeCustomer(selected: MatSelectChange) {
    this.userGroupService
      .getAll('customer=' + selected.value.id)
      .subscribe((userGroups: UserGroup[]) => {
        this.userGroups = userGroups;
      });
  }
}
