import {
  AfterViewChecked, AfterViewInit,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges, ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MapAdapter } from '../../../core/models/entities/map-adapter';
import { DialogConfirmComponent } from '../../info/dialog-confirm/dialog-confirm.component';
import { YesNo } from '../../../core/enums/yes-no.enum';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../../core/services/notification.service';
import { RobotAdapter } from '../../../core/models/entities/robot-adapter';
import { MapStatus } from '../../../core/enums/map-status.enum';
import { FormControl } from '@angular/forms';
import { RobotService } from '../../../core/services/entities/robot.service';
import { ActivatedButtonState } from '../../form/activated-button/activated-button-state.enum';
import { MapConfiguration } from '../../../api/models/map-configuration';
import { Role } from '../../../core/enums/role.enum';
import { Robot } from '../../../api/models/robot';
import { StrategyName } from '../../../core/enums/strategy-name.enum';

@Component({
  selector: 'webclient-map-item',
  templateUrl: './map-item.component.html',
  styleUrls: ['./map-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'webclient-map-item',
  },
})
export class MapItemComponent implements OnChanges, AfterViewInit {
  /**
   * Robot
   */
  @Input() public robotAdapter: RobotAdapter | undefined;

  /**
   * Carte
   */
  @Input() public mapAdapter: MapAdapter | undefined;

  /**
   * Etat du bouton d'activation
   */
  @Input() public activatedButtonState: ActivatedButtonState =
    ActivatedButtonState.UNACTIVE;

  /**
   * Evènement lors de la suppression
   */
  @Output() public remove = new EventEmitter<MapAdapter>();

  /**
   * Liste des rôles
   */
  public Role = Role;

  /**
   * Identifiant de la configuration sélectionnée
   */
  public selectedMapConfigurationId: number | undefined;

  /**
   * Statut de la carte
   */
  public MAP_STATUS = MapStatus;

  /**
   * Contrôleur
   */
  public configurations = new FormControl('');

  /**
   * Chargement de la carte
   */
  public loading = true;

  @ViewChild('card', {read: ElementRef, static:false}) card: ElementRef | undefined;

  constructor(
    private translateService: TranslateService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private robotService: RobotService
  ) {}

  ngAfterViewInit(): void {

    setTimeout(() => {

      const canvas = this.card?.nativeElement.querySelectorAll("canvas")[0];
      const height = this.card?.nativeElement.clientHeight;
      const width = this.card?.nativeElement.clientWidth;

      if (this.mapAdapter && this.mapAdapter.mapInfo) {
        let rapport = height / this.mapAdapter.mapInfo.height;

        let withMap = this.mapAdapter.mapInfo.width * rapport;
        let heightMap = this.mapAdapter.mapInfo.height * rapport;

        if (this.mapAdapter.mapInfo.height > this.mapAdapter.mapInfo.width) {

          if (withMap > width) {
            withMap = width;
            rapport = Math.floor(withMap / this.mapAdapter.mapInfo.width);
            heightMap = this.mapAdapter.mapInfo.height * rapport;
          }

          canvas.style.height = heightMap + "px";
        }

        canvas.style.height = heightMap + "px";
        canvas.style.width = withMap + "px";
      }

    }, 4000)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['robotAdapter'].currentValue &&
      changes['robotAdapter'].currentValue.activeMapConfigurationAdapter
        .mapConfiguration
    ) {
      // On sélectionne la configuration active si le robot en a une sinon la première configuration
      if (
        changes['robotAdapter'].currentValue.robot.activeMap.id ===
        this.mapAdapter?.map.id
      ) {
        this.selectedMapConfigurationId =
          this.robotAdapter?.activeMapConfigurationAdapter?.mapConfiguration.id;
        this.activatedButtonState = ActivatedButtonState.ACTIVE;
      } else if (
        this.mapAdapter?.map.mapConfigurations &&
        this.mapAdapter?.map.mapConfigurations.length > 0
      ) {
        this.selectedMapConfigurationId =
          this.mapAdapter?.map.mapConfigurations[0].id;
      }
    }

    // Si la carte n'est pas disponible on positionne le loading à false
    if (this.mapAdapter?.map.status !== MapStatus.AVAILABLE) {
      this.loading = false;
    }

    // On sélectionne la première configuration par défaut pour chaque carte
    if (
      changes['mapAdapter'] &&
      changes['mapAdapter'].currentValue &&
      changes['mapAdapter'].currentValue?.map.mapConfigurations[0]
    ) {
      this.selectedMapConfigurationId =
        changes['mapAdapter'].currentValue?.map.mapConfigurations[0].id;

      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  }

  /**
   * Indique si la carte a des configurations
   *
   */
  public hasConfigurations(): boolean {
    return this.mapAdapter &&
      this.mapAdapter.map &&
      this.mapAdapter.map.mapConfigurations &&
      this.mapAdapter.map.mapConfigurations.length > 0
      ? true
      : false;
  }

  /**
   * Indique si l'on peux activer la carte avec une configuration sur le robot
   *
   * S'il n'y a pas de robot positionné, que la carte n'est pas disponible, que le robot est dans la niche ou qu'aucune configuration n'a été sélectionnée il n'est pas possible d'activer la carte avec sa configuration
   */
  public canActivate(): boolean {
    if (this.robotAdapter === undefined) {
      return false;
    } else if (
      this.mapAdapter &&
      this.mapAdapter.map.status !== this.MAP_STATUS.AVAILABLE
    ) {
      return false;
    } else if (this.selectedMapConfigurationId === undefined) {
      return false;
    } else if (!this.robotAdapter.inniche) {
      return false;
    }

    return true;
  }

  /**
   * Indique si l'on peux annuler la création de la carte
   *
   * S'il n'y a pas de robot positionné et que la carte n'est pas en cours de création ou que le robot n'est pas connecté alors on ne peux pas annuler la création de la carte
   */
  public canCancelCreation(): boolean {
    return (
      this.robotAdapter === undefined ||
      this.mapAdapter?.map?.status !== this.MAP_STATUS.BEING_CREATED ||
      !this.robotAdapter.connected
    );
  }

  /**
   * Indique si l'on peux supprimer la carte
   *
   * Si le robot n'est pas dans la niche on ne peut pas supprimer la carte
   */
  public canRemoveMap() {
    return !this.robotAdapter?.inniche;
  }

  /**
   * Indique si l'on peux configurer une carte
   *
   * S'il n'y a pas de robot positionné et que la carte n'est pas en disponible on ne peux pas configurer la carte
   */
  public canSetting(): boolean {
    return (
      this.robotAdapter === undefined ||
      this.mapAdapter?.map?.status !== this.MAP_STATUS.AVAILABLE
    );
  }

  /**
   * Indique si la carte est active sur le robot
   */
  public isActivate(): ActivatedButtonState {
    if (
      this.robotAdapter &&
      this.mapAdapter &&
      this.robotAdapter.robot.activeMap &&
      this.robotAdapter.robot.activeMap.id === this.mapAdapter.map.id
    ) {
      this.activatedButtonState = ActivatedButtonState.ACTIVE;
    } else {
      this.activatedButtonState = ActivatedButtonState.UNACTIVE;
    }

    return this.activatedButtonState;
  }

  /**
   * Annulation de la création de la carte
   *
   * @param mapAdapter la carte à annuler la création
   */
  public onCancelCreateMap(mapAdapter: MapAdapter | undefined) {
    if (mapAdapter) {
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        panelClass: 'dialog-confirm',
        data: {
          title: this.translateService.instant(`map.cancelCreateTitle`),
          text: this.translateService.instant(`map.cancelCreateMessage`, {
            name: mapAdapter.map.name,
          }),
        },
      });

      dialogRef.afterClosed().subscribe((response: YesNo) => {
        if (response === YesNo.YES && mapAdapter.map.id && this.robotAdapter) {
          mapAdapter.map.status = MapStatus.BEING_CANCELED;

          this.robotAdapter.abortExplorationCommand();

          const title: string = this.translateService.instant('entity.success');
          const message: string = this.translateService.instant(
            `map.cancelCreateSuccessMessage`,
            { name: mapAdapter.map.name }
          );
          this.notificationService.displaySuccessSnackBar(title, message);
        } else if (response === YesNo.YES) {
          const title: string = this.translateService.instant('entity.error');
          const message: string = this.translateService.instant(
            `map.cancelCreateErrorMessage`,
            { name: mapAdapter.map.name }
          );
          this.notificationService.displayErrorSnackBar(title, message);
        }
      });
    }
  }

  /**
   * Suppression d'une carte et des ses configurations
   *
   * @param mapAdapter la carte à supprimer
   */
  public onRemoveMap(mapAdapter: MapAdapter | undefined) {
    if (mapAdapter) {
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        panelClass: 'dialog-confirm',
        data: {
          title: this.translateService.instant(`map.removeTitle`),
          text: this.translateService.instant(`map.removeMessage`, {
            name: mapAdapter.map.name,
          }),
        },
      });

      dialogRef.afterClosed().subscribe((response: YesNo) => {
        if (response === YesNo.YES && mapAdapter.map.id) {
          this.remove.emit(mapAdapter);
        }
      });
    }
  }

  /**
   * Activation d'une carte
   *
   * @param mapAdapter la carte
   */
  public onActivate(mapAdapter: MapAdapter | undefined) {
    const mapConfiguration: MapConfiguration | undefined =
      this.mapAdapter?.map.mapConfigurations?.find(
        (mapConfiguration: MapConfiguration) =>
          mapConfiguration.id === this.selectedMapConfigurationId
      );
    const activate =
      this.activatedButtonState === ActivatedButtonState.ACTIVE ? false : true;

    if (this.robotAdapter && !activate) {
      this.robotAdapter.robot.activeMap = null;
      this.robotAdapter.robot.activeMapConfiguration = null;
    } else if (
      this.robotAdapter &&
      activate &&
      mapAdapter &&
      mapConfiguration
    ) {
      this.robotAdapter.robot.activeMap = mapAdapter.map;
      this.robotAdapter.robot.activeMapConfiguration = mapConfiguration;
    }

    this.activatedButtonState = ActivatedButtonState.LOADING;

    if (this.robotAdapter) {
      this.robotService
        .update(this.robotAdapter.robot.id, this.robotAdapter.robot)
        .subscribe((robot: Robot) => {
          if (
            activate &&
            mapAdapter &&
            this.robotAdapter &&
            robot.activeMapConfiguration &&
            robot.activeMapConfiguration.id &&
            robot.activeMapConfiguration.strategies &&
            robot.activeMap &&
            robot.activeMap.id
          ) {
            this.robotAdapter.setCheckpointsCommand(
              robot.activeMapConfiguration,
              robot.activeMap.id,
              robot.activeMapConfiguration.id
            );
            this.robotAdapter.setStrategyCommand(
              robot.activeMapConfiguration.strategies,
              robot.activeMap.id,
              robot.activeMapConfiguration.id
            );
            this.robotAdapter.loadMapCommand(
              robot.activeMap.id,
              robot.activeMapConfiguration.id
            );
          } else if (!activate && mapAdapter && this.robotAdapter) {
            this.robotAdapter.desactivateMapCommand();
          }

          this.activatedButtonState = activate
            ? ActivatedButtonState.ACTIVE
            : ActivatedButtonState.UNACTIVE;
        });
    }
  }
}
