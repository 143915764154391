import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { environment } from '../../../../environments/environment';
import { Patrol } from '../../../api/models/patrol';

@Injectable({
  providedIn: 'root',
})
export class PatrolService extends EntityService<Patrol> {
  public resourceUrl = environment.apiBaseUrl + 'patrols';
}
