<mat-card>
  <mat-card-content fxLayout="row">

    <div fxFlex fxLayout="column">

      <div>
        <!-- Nom du robot -->
        <mat-card-title>
          <h2 fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <mat-icon svgIcon="alf31"></mat-icon>
            <span> {{ robotAdapter?.robot?.name }} </span>
          </h2>
        </mat-card-title>
      </div>

      <div fxLayoutGap="20px grid">
        <ngx-rodotic-connection-status [connected]="robotAdapter === undefined ? false : robotAdapter.connected" [size]="isSmallScreen() ? 'small' : 'medium'"></ngx-rodotic-connection-status>

        <ngx-rodotic-battery [maxVoltage]="12" [size]="isSmallScreen() ? 'small' : 'medium'" [incharge]="robotAdapter === undefined ? false : robotAdapter.incharge" [value]="robotAdapter?.battery" [displayPercent]="true" [disabled]="false"></ngx-rodotic-battery>

        <ngx-rodotic-emergency-button [size]="isSmallScreen() ? 'small' : 'medium'" [type]="'square'" [active]="robotAdapter?.currentStrategyType !== emergencyStrategyType" [disabled]="robotAdapter === undefined ? true : !robotAdapter.connected" (onClick)="onEmergencyClick($event)"></ngx-rodotic-emergency-button>

        <webclient-robot-state  fxFlex="50" [robotAdapter]="robotAdapter" class="webclient-robot-control-state"></webclient-robot-state>
      </div>

    </div>

    <div fxFlex="30" fxShow.gt-md fxHide>
      <webclient-robot-logger fxFlex [robotAdapter]="robotAdapter"></webclient-robot-logger>
    </div>



  </mat-card-content>
</mat-card>
