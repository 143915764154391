<div class="map-list" fxLayout="column" fxLayoutGap="16px">
  <div>
    <webclient-robot-toolbar></webclient-robot-toolbar>
  </div>

  <mat-card fxLayout="row" fxLayout.xs="column">
    <webclient-card-title title="map.maps" icon="map"></webclient-card-title>

    <div fxFlex></div>

    <div fxLayoutAlign="center center">
      <!-- Bouton de création d'une carte -->
      <button
        mat-icon-button
        color="accent"
        title="{{ 'strategy.add' | translate }}"
        (click)="onAddMap()"
        [disabled]="canAddMap()"
      >
        <mat-icon aria-label="add">add</mat-icon>
      </button>
    </div>
  </mat-card>

  <div fxLayout="row wrap" fxLayoutGap="16px grid">
    <div
      *ngFor="let mapAdapter of mapAdapters"
      fxFlex="25%"
      fxFlex.lg="50%"
      fxFlex.md="50%"
      fxFlex.sm="50%"
      fxFlex.xs="100%"
    >
      <webclient-map-item
        [mapAdapter]="mapAdapter"
        [robotAdapter]="robotAdapter"
        (remove)="onRemoveMap($event)"
      ></webclient-map-item>
    </div>
  </div>
</div>
