export class CheckpointDTO {
  /**
   * Identifiant
   */
  public id: number | undefined;

  /**
   * Nom
   */
  public name: string | undefined;

  public frame_id: string | undefined;

  /**
   * Abscisse
   */
  public x: number | undefined;

  /**
   * Ordonnée
   */
  public y: number | undefined;

  /**
   * Angle
   */
  public theta: number | undefined;
}
