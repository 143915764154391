<div
  fxLayout="row"
  fxLayout.sm="column"
  fxLayout.xs="column"
  fxLayoutAlign="start"
  fxLayoutAlign.sm="start"
  fxLayoutAlign.xs="start"
  fxLayoutGap="10px"
>
  <fieldset fxFlex>
    <legend [translate]="'user.usergroups'"></legend>

    <mat-chip-list fxFlex="15">
      <mat-chip color="primary" *ngFor="let userGroup of userGroups" selected>
        {{ userGroup.name }}
      </mat-chip>
    </mat-chip-list>

    <cdk-virtual-scroll-viewport itemSize="50" class="select-items-viewport">
      <mat-tree
        [dataSource]="dataSource ? dataSource : []"
        [treeControl]="treeControl"
      >
        <mat-tree-node
          *matTreeNodeDef="let node"
          matTreeNodeToggle
          matTreeNodePadding
        >
          <button mat-icon-button disabled></button>
          <mat-checkbox
            class="checklist-leaf-node"
            [checked]="itemSelection.isSelected(node)"
            (change)="itemSelectionToggle(node)"
          >
            {{ node.item.name }}
          </mat-checkbox>
        </mat-tree-node>

        <mat-tree-node
          *matTreeNodeDef="let node; when: hasChild"
          matTreeNodePadding
        >
          <button
            mat-icon-button
            matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.item"
          >
            <mat-icon class="mat-icon-rtl-mirror">
              {{
                treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'
              }}
            </mat-icon>
          </button>
          <mat-checkbox
            [checked]="itemSelection.isSelected(node)"
            (change)="itemSelectionToggle(node)"
          >
            {{ node.item.name }}
          </mat-checkbox>
        </mat-tree-node>
      </mat-tree>
    </cdk-virtual-scroll-viewport>
  </fieldset>
</div>
