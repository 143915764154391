import { Component, ViewEncapsulation } from '@angular/core';
import { RobotAdapter } from '../../core/models/entities/robot-adapter';
import { Robot } from '../../api/models/robot';
import { ActivatedRoute } from '@angular/router';
import { RobotService } from '../../core/services/entities/robot.service';
import { SharedService } from '../../core/services/shared.service';
import { RoslibService } from '@lelab31/ngx-rodotic';

@Component({
  selector: 'webclient-robot-home',
  templateUrl: './robot-home.component.html',
  styleUrls: ['./robot-home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RobotHomeComponent {
  /**
   * Le robot
   */
  public robotAdapter: RobotAdapter | undefined;

  constructor(
    private route: ActivatedRoute,
    private robotService: RobotService,
    private rosLibService: RoslibService,
    private sharedService: SharedService
  ) {
    const id: number = this.route.snapshot.params['id'];

    this.robotService.getById(id).subscribe((robot: Robot) => {
      this.robotAdapter = new RobotAdapter(robot, this.rosLibService);
      this.sharedService.bindRobot([robot]);
    });
  }
}
