import { EntityTransformer } from './entity-transformer';
import { PatrolCheckpointDto } from '../dto/patrol-checkpoint-dto';
import { PatrolCheckpoint } from '../../../../api/models/patrol-checkpoint';

export class PatrolCheckpointTransformer
  implements EntityTransformer<PatrolCheckpoint, PatrolCheckpointDto>
{
  to(from: PatrolCheckpoint): PatrolCheckpointDto[] | PatrolCheckpointDto {
    const checkpointDTO: PatrolCheckpointDto = new PatrolCheckpointDto();

    checkpointDTO.id = from.checkpoint?.id;
    checkpointDTO.oriented = from.oriented;
    if (from.pose) {
      checkpointDTO.orientation = this.rosQuaternionToDegree(
        from.pose.orientation
      );
    }
    checkpointDTO.pause = from.duration;
    checkpointDTO.tourelle = from.sentinel;
    checkpointDTO.fast_speed = from.fast;
    checkpointDTO.timeout = 180;

    return checkpointDTO;
  }

  private rosQuaternionToDegree(orientation: any): number {
    const q0 = orientation.w;
    const q1 = orientation.x;
    const q2 = orientation.y;
    const q3 = orientation.z;

    return Number(
      Math.atan2(2 * (q0 * q3 + q1 * q2), 1 - 2 * (q2 * q2 + q3 * q3)).toFixed(
        2
      )
    );
  }
}
