import { TriggerConf } from '../../../api/models/trigger-conf';
import { IEntityAdapter } from '../../../components/admin/models/i-entity-adapter';

export class TriggerConfAdapter implements IEntityAdapter {
  /**
   * Identifiant du TriggerConf
   */
  public id: number | undefined;

  /**
   * Nom du TriggerConf
   */
  public name: string | undefined;

  /**
   * Valeur du TriggerConf
   */
  public value: string | undefined;

  /**
   * Est éditable
   */
  public editable = true;

  /**
   * Est supprimable
   */
  public removable = true;

  constructor(public triggerConf: TriggerConf) {
    this.id = triggerConf.id;
    this.name = triggerConf.name;
    this.value = triggerConf.value;
  }
}
