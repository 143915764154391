import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Coordinate } from './coordinate';
import * as ROSLIB from 'roslib';
import { Pose } from 'roslib';
import { Utils } from '../../../core/utils/utils';

@Component({
  selector: 'webclient-angle-input',
  templateUrl: './angle-input.component.html',
  styleUrls: ['./angle-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AngleInputComponent,
    },
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'webclient-angle-input',
  },
})
export class AngleInputComponent implements ControlValueAccessor {
  @Input() public disabled = false;

  @Input() public value: Pose | undefined;

  public realAngle = 0;

  public angle = 0;

  private uniqueId: string;

  private currentUniqueId: string | null = null;

  /**
   * Evènement lors de l'édition de l'angle
   */
  @Output() public editing = new EventEmitter<boolean>();

  /**
   * Evènement lors du changement de valeur de l'angle
   */
  @Output() public changing = new EventEmitter<{
    angle: number | undefined;
    live: boolean;
  }>();

  private captureMouse = false;

  private inside = true;

  private edit = false;

  private coordinate: Coordinate | undefined;

  @HostListener('document:mousemove', ['$event'])
  public onMouseMove(event: MouseEvent): void {
    event.preventDefault();

    if (this.captureMouse && this.coordinate) {
      const thetaRadians = Math.atan2(
        event.clientX - this.coordinate.abscissa,
        -(event.clientY - this.coordinate.orderly)
      );

      this.angle = thetaRadians * (180.0 / Math.PI);
      this.realAngle = Math.round(this.angle);

      if (this.angle >= 0 && this.angle <= 180) {
        this.angle += 270;
      } else {
        this.angle -= 90;
      }

      if (this.value) {
        this.value = this.setRadianToPose(this.value, thetaRadians);
        this.realAngle = this.quaternionToGlobalTheta(this.value.orientation);
        this.propagateChange(this.value);
        this.changing.emit({ angle: this.angle, live: false });
      }
    }
  }

  @HostListener('document:click', ['$event'])
  public onMouseClick(event: MouseEvent): void {
    if (!this.inside) {
      this.captureMouse = false;
      this.edit = false;

      if (this.currentUniqueId !== null) {
        this.editing.emit(false);

        this.currentUniqueId = null;
      }
    }
    this.inside = false;
  }

  constructor() {
    this.uniqueId = Utils.uniqueId();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  propagateChange = (_: any) => {};

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(fn: any): void {}

  writeValue(pose: Pose): void {
    if (pose !== null) {
      this.value = pose;
      this.angle = this.quaternionToGlobalTheta(pose.orientation);
      this.realAngle = this.quaternionToGlobalTheta(pose.orientation);
    } else {
      //this.value = 0;
    }
  }

  public onDecrement(): void {
    this.realAngle--;

    if (this.realAngle >= 0 && this.realAngle <= 90) {
      this.angle = 90 - this.realAngle;
    } else if (this.realAngle > 90 && this.realAngle <= 180) {
      this.angle = -(this.realAngle - 90);
    } else if (this.realAngle >= -180 && this.realAngle <= -90) {
      this.angle = -(270 - -this.realAngle);
    } else if (this.realAngle > -90 && this.realAngle < 0) {
      this.angle = -this.realAngle + 90;
    }

    const thetaRadians = this.angle * (Math.PI / 180);

    if (this.angle >= 0 && this.angle <= 180) {
      this.angle += 270;
    } else {
      this.angle -= 90;
    }

    if (this.value) {
      this.value = this.setRadianToPose(this.value, thetaRadians);
      this.propagateChange(this.value);
      this.changing.emit({ angle: this.angle, live: true });
    }
  }

  public onIncrement(): void {
    this.realAngle++;

    if (this.realAngle >= 0 && this.realAngle <= 90) {
      this.angle = 90 - this.realAngle;
    } else if (this.realAngle > 90 && this.realAngle <= 180) {
      this.angle = -(this.realAngle - 90);
    } else if (this.realAngle >= -180 && this.realAngle <= -90) {
      this.angle = -(270 - -this.realAngle);
    } else if (this.realAngle > -90 && this.realAngle < 0) {
      this.angle = -this.realAngle + 90;
    }

    const thetaRadians = this.angle * (Math.PI / 180);

    if (this.angle >= 0 && this.angle <= 180) {
      this.angle += 270;
    } else {
      this.angle -= 90;
    }

    if (this.value) {
      this.value = this.setRadianToPose(this.value, thetaRadians);
    }
    this.propagateChange(this.value);
    this.changing.emit({ angle: this.angle, live: true });
  }

  public handleInput(event: Event) {
    const value = parseInt((event.target as HTMLTextAreaElement).value);

    if (!isNaN(value)) {
      this.realAngle = value;

      if (this.realAngle >= 0 && this.realAngle <= 90) {
        this.angle = 90 - this.realAngle;
      } else if (this.realAngle > 90 && this.realAngle <= 180) {
        this.angle = -(this.realAngle - 90);
      } else if (this.realAngle >= -180 && this.realAngle <= -90) {
        this.angle = -(270 - -this.realAngle);
      } else if (this.realAngle > -90 && this.realAngle < 0) {
        this.angle = -this.realAngle + 90;
      }

      const thetaRadians = this.angle * (Math.PI / 180);

      if (this.angle >= 0 && this.angle <= 180) {
        this.angle += 270;
      } else {
        this.angle -= 90;
      }

      if (this.value) {
        this.value = this.setRadianToPose(this.value, thetaRadians);
      }
      this.propagateChange(this.value);
      this.changing.emit({ angle: this.angle, live: true });
    }
  }

  public onButtonClick(event: MouseEvent): void {
    this.inside = true;
    this.captureMouse = !this.captureMouse;
    this.coordinate = new Coordinate(event.clientX, event.clientY);
    this.currentUniqueId = this.uniqueId;
    this.edit = !this.edit;
    this.editing.emit(this.edit);
  }

  public onInputClick(): void {
    this.captureMouse = false;
  }

  /**
   * Convertit un quaternion en degré
   *
   * @param orientation le quaternion
   * @private
   */
  private quaternionToGlobalTheta(orientation: any): number {
    const q0 = orientation.w;
    const q1 = orientation.x;
    const q2 = orientation.y;
    const q3 = orientation.z;

    return -Math.round(
      (-Math.atan2(2 * (q0 * q3 + q1 * q2), 1 - 2 * (q2 * q2 + q3 * q3)) *
        180.0) /
        Math.PI
    );
  }

  private setRadianToPose(pose: Pose, thetaRadians: number): Pose {
    if (thetaRadians >= 0 && thetaRadians <= Math.PI) {
      thetaRadians += (3 * Math.PI) / 2;
    } else {
      thetaRadians -= Math.PI / 2;
    }

    const qz = Math.sin(-thetaRadians / 2.0);
    const qw = Math.cos(-thetaRadians / 2.0);

    const orientation = new ROSLIB.Quaternion({ x: 0, y: 0, z: qz, w: qw });

    pose.orientation = orientation;

    return pose;
  }
}
