export class PatrolCheckpointDto {
  /**
   * Nom
   */
  public id: number | undefined;

  /**
   * Orienté
   */
  public oriented = false;

  /**
   * Angle
   */
  public orientation: any;

  /**
   * Timeout
   */
  public timeout: number | undefined;

  /**
   * Durée
   */
  public pause: number | undefined;

  /**
   * Sentinelle
   */
  public tourelle: boolean | undefined;

  /**
   * Rapide
   */
  public fast_speed: boolean | undefined;
}
