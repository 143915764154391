import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { EntityService } from './entity.service';
import { MapConfiguration } from '../../../api/models/map-configuration';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MapConfigurationService extends EntityService<MapConfiguration> {
  public resourceUrl = environment.apiBaseUrl + 'map_configurations';

  public duplicate(
    mapConfiguration: MapConfiguration
  ): Observable<MapConfiguration> {
    return this.http
      .post<MapConfiguration>(
        `${this.resourceUrl}/duplicate/${mapConfiguration.id}`,
        { name: mapConfiguration.name }
      )
      .pipe(catchError(this.handleError));
  }
}
