import {
  AfterViewChecked,
  Component, ContentChildren,AfterViewInit,
  ElementRef, HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RobotAdapter } from '../../core/models/entities/robot-adapter';
import { RobotService } from '../../core/services/entities/robot.service';
import { Robot } from '../../api/models/robot';
import { MapPoint, MapPointService, RoslibService } from '@lelab31/ngx-rodotic';
import { PatrolAdapter } from '../../core/models/entities/patrol-adapter';
import { StrategyAdapter } from '../../core/models/entities/strategy-adapter';
import { MapConfigurationAdapter } from '../../core/models/entities/map-configuration-adapter';
import { MapSettingsService } from '../../core/services/map-settings.service';
import { Strategy } from '../../api/models/strategy';
import { Pose } from 'roslib';
import { Utils } from '../../core/utils/utils';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Patrol } from '../../api/models/patrol';
import { PatrolCheckpoint } from '../../api/models/patrol-checkpoint';
import { PatrolCheckpointAdapter } from '../../core/models/entities/patrol-checkpoint-adapter';
import { SharedService } from '../../core/services/shared.service';
import { Role } from '../../core/enums/role.enum';
import { StrategyName } from '../../core/enums/strategy-name.enum';
import { takeUntil } from 'rxjs/operators';
import { InputMapCreationStatusMessage } from '../../core/models/ros/input/input-map-creation-status-message';

@Component({
  selector: 'webclient-robot-viewer',
  templateUrl: './robot-viewer.component.html',
  styleUrls: ['./robot-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'webclient-robot-viewer',
  },
})
export class RobotViewerComponent implements OnInit, OnDestroy, AfterViewChecked  {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  /**
   * Le robot
   */
  public robotAdapter: RobotAdapter | undefined;

  /**
   * Configuration courante du robot
   */
  public currentMapConfigurationAdapter: MapConfigurationAdapter | undefined;

  /**
   * Point de patrouille en cours
   */
  public patrolAdapter: PatrolAdapter | undefined;

  /**
   * Liste des stratégies
   */
  public strategyAdapters: StrategyAdapter[] = [];

  /**
   * Titre de la liste des points de patrouilles
   */
  public strategyTitle = '';

  /**
   * Indique l'identifiant du point de patrouille suivant
   *
   * @private
   */
  private nextPatrolCheckpointMapPoint: MapPoint | undefined;

  /**
   * Liste des rôles
   */
  public Role = Role;

  /**
   * Chargement de la carte
   */
  public loading = true;

  /**
   * Type de stratégie
   */
  public STRATEGY_NAME = StrategyName;

  /**
   * Indique si la carte à finie son rendu
   */
  private isMapRendered$: BehaviorSubject<boolean | null> =
    new BehaviorSubject<boolean | null>(null);

  @ViewChild('left', {read: ElementRef, static:false}) left: ElementRef | undefined;

  @ViewChild('right', {read: ElementRef, static:false}) right: ElementRef | undefined;

  @ContentChildren('canvas', {descendants: true}) canvas: ElementRef | undefined;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.resizeMapCard();
  }

  constructor(
    private route: ActivatedRoute,
    private robotService: RobotService,
    private rosLibService: RoslibService,
    private sharedService: SharedService,
    private mapSettingsService: MapSettingsService,
    private mapPointService: MapPointService,
    private translateService: TranslateService,
    private renderer: Renderer2
  ) {
    const id: number = this.route.snapshot.params['robotId'];

    this.translateService
      .get('strategy.current', { name: '-' })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((text: string) => {
        this.strategyTitle = text;
      });

    this.mapPointService
      .onMapRendered()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.strategyAdapters.forEach((strategyAdapter: StrategyAdapter) => {
          if (
            strategyAdapter.strategy.patrol &&
            strategyAdapter.strategy.patrol.id
          ) {
            strategyAdapter.strategy.patrol.checkpoints.forEach(
              (patrolCheckpoint: PatrolCheckpoint) => {
                if (
                  strategyAdapter.strategy.patrol &&
                  strategyAdapter.strategy.patrol.id
                ) {
                  const patrolCheckpointAdapter: PatrolCheckpointAdapter =
                    new PatrolCheckpointAdapter(
                      patrolCheckpoint,
                      strategyAdapter.strategy.patrol.id.toString()
                    );

                    if(patrolCheckpointAdapter.patrolCheckpoint.pose && patrolCheckpointAdapter?.patrolCheckpoint?.checkpoint?.pose && patrolCheckpointAdapter?.mapPoint?.pose){

                      // on synchronise patrolCheckpointAdapter.patrolCheckpoint.pose et patrolCheckpointAdapter.mapPoint.pose avec patrolCheckpointAdapter.patrolCheckpoint.checkpoint.pose
                      patrolCheckpointAdapter.patrolCheckpoint.pose.position.x = patrolCheckpointAdapter.patrolCheckpoint.checkpoint.pose.position.x
                      patrolCheckpointAdapter.patrolCheckpoint.pose.position.y = patrolCheckpointAdapter.patrolCheckpoint.checkpoint.pose.position.y
                      patrolCheckpointAdapter.mapPoint.pose.position.x = patrolCheckpointAdapter.patrolCheckpoint.checkpoint.pose.position.x
                      patrolCheckpointAdapter.mapPoint.pose.position.y = patrolCheckpointAdapter.patrolCheckpoint.checkpoint.pose.position.y
  
                      if(patrolCheckpointAdapter.patrolCheckpoint.oriented){
                        patrolCheckpointAdapter.mapPoint.pose = patrolCheckpointAdapter.patrolCheckpoint.pose
  
                        patrolCheckpointAdapter.mapPoint.displayObject = Utils.createMapPatrolCheckpoint();
                      } else{
                        patrolCheckpointAdapter.mapPoint.pose = patrolCheckpointAdapter.patrolCheckpoint.checkpoint.pose
  
                        patrolCheckpointAdapter.mapPoint.displayObject = Utils.createMapPatrolCheckpointNotOriented();
                      }
                    }
                    
                  if (patrolCheckpointAdapter.mapPoint) {
                    this.mapPointService.addOrUpdatePoint(
                      patrolCheckpointAdapter.mapPoint
                    );
                    this.mapPointService.setVisiblePoint(
                      patrolCheckpointAdapter.mapPoint,
                      false
                    );
                  }
                }
              }
            );
          }
        });

      // La map a fini son rendu.
      this.isMapRendered$.next(true);

      });

    this.robotService.getById(id).subscribe((robot: Robot) => {
      this.robotAdapter = new RobotAdapter(robot, this.rosLibService);
      this.sharedService.bindRobot([robot]);
      this.currentMapConfigurationAdapter =
        this.robotAdapter.activeMapConfigurationAdapter;

      if (
        this.currentMapConfigurationAdapter &&
        this.currentMapConfigurationAdapter.mapConfiguration &&
        this.currentMapConfigurationAdapter.mapConfiguration.strategies
      ) {
        this.strategyAdapters =
          this.currentMapConfigurationAdapter.mapConfiguration.strategies.map(
            (strategy: Strategy) => new StrategyAdapter(strategy)
          );
      }

      // Mise à jour dynamique lorsque l'exploration d'une carte est finie.
      this.robotAdapter
        .mapCreationStatus()
        .subscribe(
          (inputMapCreationStatusMessage: InputMapCreationStatusMessage) => {
            this.robotService.getById(id).subscribe((robot: Robot) => {
              if (this.robotAdapter) {
                this.robotAdapter = new RobotAdapter(robot, this.rosLibService);
              }
            });
          }
        );
    });

    // Quand la carte à fini son rendu, on peut récuperer les données du robots pour afficher la patrouille en cours (cf. issue #310).
    this.isMapRendered$
    .pipe(takeUntil(this.destroyed$))
    .subscribe(() => {

      if(this.robotAdapter){

        // current strategy callback
        this.robotAdapter.currentStrategyAdapter$
          .pipe(takeUntil(this.destroyed$))
          .subscribe((strategyAdapter) => {
            if (strategyAdapter && strategyAdapter.strategy.patrol) {
              this.patrolAdapter = new PatrolAdapter(
                strategyAdapter.strategy.patrol
              );

              this.translateService
                .get('strategy.current', { name: strategyAdapter.strategy.name })
                .pipe(takeUntil(this.destroyed$))
                .subscribe((text: string) => {
                  this.strategyTitle = text;
                });

              if (
                this.patrolAdapter &&
                this.patrolAdapter.patrol &&
                this.patrolAdapter.patrol.id
              ) {
                this.mapPointService.setVisiblePoint(
                  this.patrolAdapter.patrol.id.toString(),
                  true
                );
              }
            } else {
              this.translateService
                .get('strategy.current', { name: this.translateService.instant(`strategy.type.${this.robotAdapter?.currentStrategyType}`) })
                .pipe(takeUntil(this.destroyed$))
                .subscribe((text: string) => {
                  this.strategyTitle = text;
                });
              if (
                this.patrolAdapter &&
                this.patrolAdapter.patrol &&
                this.patrolAdapter.patrol.id
              ) {
                this.mapPointService.setVisiblePoint(
                  this.patrolAdapter.patrol.id.toString(),
                  false
                );
              }
              this.patrolAdapter = undefined;
            }
          });

        // current goal callback
        this.robotAdapter.currentGoal$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((patrolCheckpoint: PatrolCheckpoint | null) => {
          if (
            patrolCheckpoint &&
            patrolCheckpoint.checkpoint &&
            patrolCheckpoint.checkpoint.pose
          ) {
            const mapPoint: MapPoint = new MapPoint(
              patrolCheckpoint?.checkpoint?.pose,
              'next-checkpoint'
            );
            mapPoint.displayObject = Utils.createNextMapPatrolCheckpoint(true);
            mapPoint.group = 'next-checkpoint';
            this.mapPointService.addOrUpdatePoint(mapPoint);
          } else {
            this.mapPointService.removePoint('next-checkpoint');
          }
        });

        // current pose callback
        this.robotAdapter.currentPosition$
          .pipe(takeUntil(this.destroyed$))
          .subscribe((pose: Pose) => {
            const mapPoint: MapPoint = new MapPoint(pose, 'current');
            mapPoint.displayObject = Utils.createCurrentMapPatrolCheckpoint(true);
            mapPoint.group = 'current-' + this.robotAdapter?.robot.id;
            mapPoint.text = this.robotAdapter?.robot.name;
            this.mapPointService.addOrUpdatePoint(mapPoint);
          });
        }
      });
  }

  ngOnInit(): void {
    this.listenMapRendered();
  }

  ngAfterViewChecked(): void {
    this.resizeMapCard();
  }

  /**
   * Ecoute le chargement de la carte
   *
   * @private
   */
  private listenMapRendered(): void {
    this.mapPointService
      .onMapRendered()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.strategyAdapters
          .map(
            (strategyAdapter: StrategyAdapter) =>
              strategyAdapter.strategy.patrol
          )
          .map((patrol: Patrol | undefined) => {
            if (patrol !== undefined) {
              this.createMapPoints(new PatrolAdapter(patrol));
            }
          });
      });
  }

  /**
   * Création des points de contrôle des patrouilles sur la carte
   *
   * @param patrolAdapter la patrouille
   * @private
   */
  private createMapPoints(patrolAdapter: PatrolAdapter): void {
    patrolAdapter.patrol.checkpoints.map(
      (patrolCheckpoint: PatrolCheckpoint) => {
        return new PatrolCheckpointAdapter(patrolCheckpoint, patrolAdapter.uid);
      }
    );
  }

  /**
   * Indique si l'on peux afficher la carte
   *
   * Si la stratégie du system state est au statut exploration on n'affiche pas la carte
   */
  public canSeeMap(): boolean {
    if (
      this.robotAdapter &&
      (this.robotAdapter.currentStrategyType === undefined ||
        this.robotAdapter.currentStrategyType === null)
    ) {
      this.loading = true;
      return false;
    } else if (this.robotAdapter && !this.robotAdapter?.robot?.activeMap?.id) {
      this.loading = false;
      return false;
    } else if (this.robotAdapter && this.robotAdapter?.robot?.activeMap?.id) {
      this.loading = false;
      return true;
    }

    return false;
  }

  public onStrategyMouseEnter() {
    if (this.patrolAdapter && this.patrolAdapter.uid) {
      this.mapPointService.setVisiblePoint(this.patrolAdapter.uid, false);
    }
  }

  public onStrategyMouseLeave() {
    if (this.patrolAdapter && this.patrolAdapter.uid) {
      this.mapPointService.setVisiblePoint(this.patrolAdapter.uid, true);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    // try to kill all robotadapter connections
  }

  public linkConfigurationIsVisible() {
    return this.robotAdapter?.robot?.activeMap?.id;
  }

  private resizeMapCard(): void {
    const webclientRobotVideoHeight = document.getElementsByClassName("webclient-robot-video")[0].clientHeight;
    const webclientPatrolCheckpointListHeaderHeight = document.getElementsByClassName("webclient-patrol-checkpoint-list-header")[0].clientHeight;
    const webclientPatrolCheckpointListContentHeight = document.getElementsByClassName("webclient-patrol-checkpoint-list-content")[0].clientHeight;
    const height = webclientRobotVideoHeight + webclientPatrolCheckpointListHeaderHeight + webclientPatrolCheckpointListContentHeight;

    if (this.robotAdapter?.mapInfo) {
      let rapport = height / this.robotAdapter.mapInfo.height;
      let withMap = this.robotAdapter.mapInfo.width * rapport;
      let heightMap = this.robotAdapter.mapInfo.height * rapport;

      if (this.robotAdapter.mapInfo.height > this.robotAdapter.mapInfo.width) {

        if (withMap > this.left?.nativeElement.offsetWidth) {
          withMap = this.left?.nativeElement.offsetWidth;
          rapport = Math.floor(withMap / this.robotAdapter.mapInfo.width);
          heightMap = this.robotAdapter.mapInfo.height * rapport;
        }

        if (document.querySelectorAll("canvas")[1]) {
          document.querySelectorAll("canvas")[1].style.height = heightMap + "px";
        }
      }
    }

    this.renderer.setStyle(this.right?.nativeElement, "height", this.left?.nativeElement.offsetHeight);
  }
}
