import { Component, ViewEncapsulation } from '@angular/core';
import { UserGroup } from '../../../../api/models/user-group';
import { UserGroupService } from '../../../../core/services/entities/user-group.service';
import { IListText } from '../../../../components/admin/models/i-list-text';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { IEntity } from '../../../../components/admin/models/i-entity';
import { EntityNestedListComponent } from '../../../../components/admin/entity-nested-list/entity-nested-list.component';
import { UserGroupAdapter } from '../../../../core/models/entities/user-group-adapter';
import { IEntityAdapter } from '../../../../components/admin/models/i-entity-adapter';
import { SessionService } from '../../../../core/services/session.service';

@Component({
  selector: 'webclient-user-group-list',
  templateUrl: './user-group-list.component.html',
  styleUrls: ['./user-group-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserGroupListComponent extends EntityNestedListComponent {
  /**
   * Liste des groupes d'utilisateurs
   */
  public userGroupAdapters: UserGroupAdapter[] = [];

  /**
   * Colonnes affichées
   */
  public override displayedColumns: string[] = [
    'name',
    'customer',
    'users',
    'robots',
    'zones',
    'groups',
  ];

  /**
   * Textes
   */
  public override text: IListText = {
    removeTitle: 'usergroup.removeTitle',
    removeMessage: 'usergroup.removeMessage',
  };

  constructor(
    protected override sessionService: SessionService,
    private userGroupService: UserGroupService,
    protected override dialog: MatDialog,
    protected override translateService: TranslateService,
    protected override snackBar: MatSnackBar
  ) {
    super(sessionService, dialog, translateService, snackBar);

    this.userGroupService.getAll().subscribe((userGroups: UserGroup[]) => {
      let childrens: UserGroup[] = [];

      userGroups.forEach((userGroup: UserGroup) => {
        childrens = childrens.concat(userGroup.children);
      });

      childrens = this.flatten(childrens);

      this.userGroupAdapters = userGroups
        .filter((userGroup: UserGroup) => {
          const findInChild: UserGroup | undefined = this.findChild(
            childrens,
            userGroup.id
          );

          if (findInChild && userGroup.parent) {
            return false;
          }

          return true;
        })
        .map((userGroup: UserGroup) => {
          const userGroupAdapter: UserGroupAdapter = new UserGroupAdapter(
            userGroup
          );
          userGroupAdapter.editable = this.isSuperAdmin ? true : false;
          userGroupAdapter.removable = this.isSuperAdmin ? true : false;

          return userGroupAdapter;
        });
    });
  }

  private flatten(userGroups: UserGroup[]) {
    return userGroups.reduce((r: UserGroup[], { children, ...rest }) => {
      r.push(rest as UserGroup);
      if (children) r.push(...this.flatten(children));
      return r;
    }, []);
  }

  private findChild(
    userGroups: UserGroup[],
    id: number | undefined
  ): UserGroup | undefined {
    let result: UserGroup | undefined;

    userGroups.some(
      (child: UserGroup) =>
        (child.id === id && (result = child)) ||
        (result = this.findChild([], id))
    );

    return result;
  }

  /**
   * Suppression du groupe
   *
   * @param entity le groupe à supprimer
   */
  public onRemove(entity: IEntityAdapter) {
    const userGroupAdapter: UserGroupAdapter = entity as UserGroupAdapter;

    if (userGroupAdapter.id !== undefined) {
      this.userGroupService.delete(userGroupAdapter.id).subscribe(
        () => {
          const index: number = this.userGroupAdapters.findIndex(
            (userGroupAdapter: UserGroupAdapter) =>
              userGroupAdapter.id === userGroupAdapter.id
          );
          const userGroupAdapters: UserGroupAdapter[] = [
            ...this.userGroupAdapters,
          ];
          userGroupAdapters.splice(index, 1);

          this.userGroupAdapters = this.remove(
            this.userGroupAdapters,
            userGroupAdapter.id
          ).filter(
            (userGroupAdapter: UserGroupAdapter) =>
              userGroupAdapter.userGroup.parent === undefined
          );

          this.displaySuccessSnackBar(
            userGroupAdapter.name,
            'usergroup.removeSuccessMessage'
          );
        },
        (error) => {
          this.displayErrorSnackBar(
            userGroupAdapter.name,
            'usergroup.removeErrorMessage'
          );
        }
      );
    }
  }

  /**
   * Supprime un groupe de la liste des groupes d'utilisateurs
   *
   * @param userGroups la liste des groupes d'utilisateurs
   * @param id l'identifiant du groupe d'utilisateur à supprimer
   * @private
   */
  private remove(
    userGroupAdapters: UserGroupAdapter[],
    id: number | undefined
  ) {
    return userGroupAdapters
      .filter(
        (userGroupAdapter: UserGroupAdapter) => userGroupAdapter.id !== id
      )
      .map((userGroupAdapter: UserGroupAdapter) => {
        if (
          !userGroupAdapter.children ||
          !Array.isArray(userGroupAdapter.children)
        ) {
          return userGroupAdapter;
        }

        userGroupAdapter.children = this.remove(userGroupAdapter.children, id);

        return userGroupAdapter;
      });
  }
}
