export class ItemFlatNode {
  /**
   * Item
   */
  item: any;

  /**
   * Niveau
   */
  level: number = 0;

  /**
   * Noeud extensible ou non
   */
  expandable: boolean = false;
}
