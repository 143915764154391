import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Robot } from '../../api/models/robot';
import { User } from '../../api/models/user';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export abstract class DispatcherService {
  public resourceUrl = environment.dispatcherUrl;

  constructor(
    protected http: HttpClient,
    private sessionService: SessionService
  ) {}

  public create(robot: Robot): Observable<string> {
    const subject: WebSocketSubject<any> = webSocket(`${this.resourceUrl}`);

    subject.next({
      event: 'create',
      data: {
        uid: robot.id ? robot.id.toString() : 'new',
        robotip: robot.ip,
        robotport: robot.port,
      },
    });

    return subject.asObservable();
  }

  public bind(robot: Robot, bindtime: number = 63072000): Observable<string> {
    const user: User | undefined = this.sessionService.getCurrentUser();
    const subject: WebSocketSubject<any> = webSocket(`${this.resourceUrl}`);

    if (user) {
      subject.next({
        event: 'bind',
        data: {
          uid: robot.id ? robot.id.toString() : 'new',
          userip: user.ip,
          robotip: robot.ip,
          bindtime,
        },
      });
    }

    return subject.asObservable();
  }
}
