import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../core/services/security/auth.service';
import { UserService } from '../../core/services/entities/user.service';
import { SessionService } from '../../core/services/session.service';
import { MyipService } from '../../core/services/myip.service';
import { User } from '../../api/models/user';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../core/utils/utils';

@Component({
  selector: 'webclient-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class LoginComponent implements OnInit {
  /**
   * Formulaire
   */
  public form: FormGroup;

  /**
   * Messages de validation
   */
  public loginValidationMessages = {
    email: [
      { type: 'required', message: 'login.validationMessages.email.required' },
      { type: 'pattern', message: 'login.validationMessages.email.pattern' },
    ],
    password: [
      {
        type: 'required',
        message: 'login.validationMessages.password.required',
      },
      {
        type: 'minlength',
        message: 'login.validationMessages.password.minlength',
      },
      { type: 'pattern', message: 'login.validationMessages.password.pattern' },
    ],
  };

  /**
   * Voir de mot de passe
   */
  public showPassword: boolean | undefined;

  /**
   * Message d'erreur en cas d'échec de connexion
   */
  public loginError: string | undefined;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private sessionService: SessionService,
    private myipService: MyipService,
    private translateService: TranslateService,
    private router: Router
  ) {
    this.form = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.required,
        Validators.pattern(Utils.patternPassword()),
      ]),
    });
  }

  ngOnInit(): void {}

  /**
   * S'identifier
   *
   * @param email l'email
   * @param password le mot de passe
   */
  public onLogin(email: string, password: string): void {
    this.authService.login(email, password).subscribe(
      (userId: number | undefined) => {
        if (userId) {
          this.userService.getById(userId).subscribe((user: User) => {
            this.myipService.getIP().subscribe((ip: { ip: string }) => {
              user.ip = ip.ip;
              this.sessionService.setCurrentUser(user);
              this.router.navigate(['/']);
            });
          });
        }
      },
      (error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.loginError = this.translateService.instant('login.error');
        } else {
          this.loginError = this.translateService.instant('errors.technical');
        }
      }
    );
  }
}
