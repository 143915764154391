export enum StrategyName {
  VERY_LOWER_STOP = 0,
  EMERGENCY_STOP = 1,
  LOW_POWER = 2,
  GET_UP = 3,
  MANUAL_CONTROL = 4,
  EXPLORATION = 5,
  DESACTIVATE_MAP = 6,
  STRATEGIE_WEB = 20,
  RETOUR_BASE = 98,
  NICHE = 99,
  CHARGE_MONITORING = 100,
}
