<h1 mat-dialog-title>{{ 'patrol.select' | translate }}</h1>

<mat-dialog-content class="mat-typography" fxLayout="column" fxLayoutGap="30px">
  <div>
    <mat-radio-group fxLayout="column" [(ngModel)]="selectedPatrolAdapter">
      <mat-radio-button
        *ngFor="let patrolAdapter of patrolAdapters"
        [value]="patrolAdapter"
        >{{ patrolAdapter.patrol.name }}</mat-radio-button
      >
    </mat-radio-group>
  </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <button mat-flat-button color="primary" (click)="onCancelClick()">
    {{ 'cancel' | translate }}
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="onOkClick()"
    [disabled]="selectedPatrolAdapter === undefined"
  >
    {{ 'select' | translate }}
  </button>
</mat-dialog-actions>
