import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  rotateInOnEnterAnimation,
  rotateOutOnLeaveAnimation,
} from 'angular-animations';
import { ActivatedButtonState } from './activated-button-state.enum';

@Component({
  selector: 'webclient-activated-button',
  templateUrl: './activated-button.component.html',
  styleUrls: ['./activated-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'webclient-activated-button',
  },
  animations: [
    rotateInOnEnterAnimation(),
    rotateOutOnLeaveAnimation(),
    fadeInOnEnterAnimation(),
    fadeOutOnLeaveAnimation(),
  ],
})
export class ActivatedButtonComponent implements OnInit, OnChanges {
  /**
   * Icône lorsque le bouton est activé
   */
  @Input() public activatedIcon = 'check_circle';

  /**
   * Icône lorsque le bouton est désactivé
   */
  @Input() public desactivatedIcon = 'highlight_off';

  /**
   * Texte lorsque le bouton est activé
   */
  @Input() public activatedText = 'activate';

  /**
   * Texte lorsque le bouton est désactivé
   */
  @Input() public desactivatedText = 'desactivated';

  /**
   * Texte lors du chargement
   */
  @Input() public textLoading = 'waiting';

  /**
   * Etat du bouton
   */
  @Input() public state: ActivatedButtonState | undefined;

  /**
   * Couleur de l'état activé
   */
  @Input() public activatedColor: string = '';

  /**
   * Couleur de l'état désactivé
   */
  @Input() public desactivatedColor: string = '';

  /**
   * Désactivé
   */
  @Input() public disabled: boolean = false;

  /**
   * Evènement sur le click du bouton
   */
  @Output() buttonClick = new EventEmitter<void>();

  /**
   * Etats du bouton
   */
  public activatedButtonState = ActivatedButtonState;

  /**
   * Texte courant
   */
  public currentText: string = '';

  /**
   * Icône courant
   */
  public currentIcon: string = '';

  /**
   * Etat de la souris
   */
  public isMouseenter: boolean = false;

  ngOnInit(): void {
    this.isMouseenter = false;

    this.currentText =
      this.state === ActivatedButtonState.ACTIVE
        ? this.activatedText
        : this.desactivatedText;
    this.currentIcon =
      this.state === ActivatedButtonState.ACTIVE
        ? this.activatedIcon
        : this.desactivatedIcon;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['state']?.currentValue) {
      this.currentText =
        this.state === ActivatedButtonState.ACTIVE
          ? this.activatedText
          : this.desactivatedText;
      this.currentIcon =
        this.state === ActivatedButtonState.ACTIVE
          ? this.activatedIcon
          : this.desactivatedIcon;
    }
  }

  public onActivate(): void {
    this.isMouseenter = false;
    this.currentText = this.textLoading;
    this.currentIcon = '';
    this.buttonClick.emit();
  }

  public onMouseenter(): void {
    this.isMouseenter = true;
    this.currentText =
      this.state === ActivatedButtonState.ACTIVE
        ? this.desactivatedText
        : this.activatedText;
    this.currentIcon =
      this.state === ActivatedButtonState.ACTIVE
        ? this.desactivatedIcon
        : this.activatedIcon;
  }

  public onMouseleave(): void {
    this.isMouseenter = false;
    this.currentText =
      this.state === ActivatedButtonState.ACTIVE
        ? this.activatedText
        : this.desactivatedText;
    this.currentIcon =
      this.state === ActivatedButtonState.ACTIVE
        ? this.activatedIcon
        : this.desactivatedIcon;
  }
}
