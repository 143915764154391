import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { IListText } from '../models/i-list-text';
import { IEntityAdapter } from '../models/i-entity-adapter';
import { MatColumnDef, MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogConfirmComponent } from '../../info/dialog-confirm/dialog-confirm.component';
import { YesNo } from '../../../core/enums/yes-no.enum';
import { MessageSnackBarComponent } from '../../info/message-snack-bar/message-snack-bar.component';
import { MessageType } from '../../../core/enums/message-type.enum';
import { Role } from '../../../core/enums/role.enum';
import { SessionService } from '../../../core/services/session.service';

@Component({
  template: '',
})
export class BaseEntityListComponent implements OnInit, AfterContentInit {
  /**
   * Textes
   */
  @Input() public text: IListText = {
    removeTitle: 'entity.remove',
    removeMessage: 'entity.removeMessage',
  };

  /**
   * Données
   */
  @Input() public entities: IEntityAdapter[] = [];

  /**
   * URL vers la page du formulaire
   */
  @Input() public urlForm = '';

  /**
   * Titre
   */
  @Input() public title = '';

  /**
   * Icône
   */
  @Input() public icon = '';

  /**
   * Icône
   */
  @Input() public svgIcon = '';

  /**
   * Colonnes affichées
   */
  @Input() public displayedColumns: string[] = [];

  /**
   * Rôles
   */
  @Input() public roles: Role[] = [Role.ROLE_SUPER_ADMIN];

  /**
   * Evènement lors de la suppression d'une entité
   */
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onRemoveEntity = new EventEmitter<IEntityAdapter>();

  @ViewChild(MatTable, { static: true }) table:
    | MatTable<IEntityAdapter>
    | undefined;
  @ContentChildren(MatColumnDef) columnDefs:
    | QueryList<MatColumnDef>
    | undefined;

  /**
   * Liste des rôles
   */
  public Role = Role;

  /**
   * Indique si on est super admin
   */
  public isSuperAdmin = false;

  constructor(
    protected sessionService: SessionService,
    protected dialog: MatDialog,
    protected translateService: TranslateService,
    protected snackBar: MatSnackBar
  ) {
    this.isSuperAdmin = this.sessionService.isSuperAdmin();
  }

  ngOnInit(): void {
    if (!this.displayedColumns.includes('id')) {
      this.displayedColumns.unshift('id');
    }

    if (!this.displayedColumns.includes('actions')) {
      this.displayedColumns.push('actions');
    }
  }

  ngAfterContentInit() {
    if (this.columnDefs && this.table) {
      this.columnDefs.forEach((columnDef: MatColumnDef) => {
        if (this.table) {
          this.table.addColumnDef(columnDef);
        }
      });
    }
  }

  /**
   * Ouverture de la boîte de dialogue de confirmation de suppression d'un groupe
   *
   * @param entityAdapter l'entité à supprimer
   */
  public openDialog(entityAdapter: IEntityAdapter): void {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      panelClass: 'dialog-confirm',
      data: {
        title: this.translateService.instant(`${this.text.removeTitle}`),
        text: this.translateService.instant(`${this.text.removeMessage}`, {
          name: entityAdapter.name,
        }),
      },
    });

    dialogRef.afterClosed().subscribe((response: YesNo) => {
      if (response === YesNo.YES && entityAdapter.id) {
        this.onRemoveEntity.emit(entityAdapter);
      }
    });
  }

  /**
   * Affichage de la snackbar de succés
   *
   * @param name le nom de l'entité
   * @param removeSuccessMessage le message de succès
   *
   * @protected
   */
  protected displaySuccessSnackBar(
    name: string | undefined,
    removeSuccessMessage: string
  ): void {
    this.snackBar.openFromComponent(MessageSnackBarComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 5000,
      panelClass: 'success-snackbar',
      data: {
        title: this.translateService.instant('entity.success'),
        message: this.translateService.instant(`${removeSuccessMessage}`, {
          name: name,
        }),
        type: MessageType.SUCCESS,
      },
    });
  }

  /**
   * Affichage de la snackbar d'erreur
   *
   * @param name le nom de l'entité
   * @param removeSuccessMessage le message d'erreur
   *
   * @protected
   */
  protected displayErrorSnackBar(
    name: string | undefined,
    removeErrorMessage: string
  ): void {
    this.snackBar.openFromComponent(MessageSnackBarComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 5000,
      panelClass: 'error-snackbar',
      data: {
        title: this.translateService.instant('entity.error'),
        message: this.translateService.instant(`${removeErrorMessage}`, {
          name: name,
        }),
        type: MessageType.SUCCESS,
      },
    });
  }
}
