<div class="webclient-angle-input-wrapper" fxLayout="row">
  <div class="input-wrapper">
    <button
      mat-icon-button
      class="webclient-angle-input-minus"
      (click)="onDecrement()"
      [disabled]="disabled || this.realAngle === -180"
    >
      <mat-icon>remove_circle</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="onButtonClick($event)"
      [disabled]="disabled"
    >
      <mat-icon>my_location</mat-icon>
    </button>
    <input
      class="webclient-angle-input-input"
      type="number"
      [value]="realAngle"
      [disabled]="disabled"
      min="-180"
      max="180"
      (input)="handleInput($event)"
    /><span>°</span>
    <button
      mat-icon-button
      class="webclient-angle-input-plus"
      (click)="onIncrement()"
      [disabled]="disabled || this.realAngle === 180"
    >
      <mat-icon>add_circle</mat-icon>
    </button>
  </div>
</div>
