import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarEditorComponent } from './calendar-editor/calendar-editor.component';
import {
  CalendarModule,
  CalendarWeekModule,
  DateAdapter,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { NumberInputComponent } from './number-input/number-input.component';
import { FormsModule } from '@angular/forms';
import { AngleInputComponent } from './angle-input/angle-input.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ActivatedButtonComponent } from './activated-button/activated-button.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    CalendarEditorComponent,
    NumberInputComponent,
    AngleInputComponent,
    ActivatedButtonComponent,
  ],
  exports: [
    CalendarEditorComponent,
    NumberInputComponent,
    AngleInputComponent,
    ActivatedButtonComponent,
  ],
  imports: [
    CommonModule,
    CalendarWeekModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    MatTableModule,
    TranslateModule,
    MatIconModule,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
  ],
})
export class FormModule {}
