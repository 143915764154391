<div fxLayout="column" fxLayoutGap="20px">
  <div>
    <webclient-robot-toolbar></webclient-robot-toolbar>
  </div>

  <div fxLayoutGap="20px">

    <div fxFlex>
      <mat-card fxFlex fxLayout="column" #left>
        <webclient-robot-video [robotAdapter]="robotAdapter" [autoplay]="true"></webclient-robot-video>

        <!--"stratégies en cours" masquées sur des écrans en dessous de la taille médium et "stratégies" prend sa place-->

        <webclient-patrol-checkpoint-list fxHide.lt-md fxShow [patrolAdapter]="patrolAdapter" fxFlex="65" [title]="strategyTitle"></webclient-patrol-checkpoint-list>
        <webclient-strategy-list fxHide fxShow.lt-md [robotAdapter]="robotAdapter" [strategyAdapters]="strategyAdapters" (mouseEnter)="onStrategyMouseEnter()" (mouseLeave)="onStrategyMouseLeave()"></webclient-strategy-list>

      </mat-card>
    </div>

    <div fxFlex fxLayout="column" fxLayoutGap="20px">

      <mat-card fxFlex fxLayout="column">
        <div fxFlex fxLayout="column" fxLayoutAlign="center center">
          <ngx-rodotic-viewer *ngIf="canSeeMap()" [rosObject]="robotAdapter" [mapInfo]="robotAdapter?.mapInfo"></ngx-rodotic-viewer>

          <!-- Image en cours de chargement -->
          <div class="map-image-available" fxLayout="column" fxLayoutAlign="center center" *ngIf="loading">
            <mat-spinner *ngIf="loading"></mat-spinner>
          </div>

          <!-- Carte en cours de création -->
          <div class="map-image-being-created" *ngIf="robotAdapter?.currentStrategyType === STRATEGY_NAME.EXPLORATION">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            <p> {{ 'map.status.BEING_CREATED' | translate }} </p>
          </div>
        </div>

        <div fxFlex="5" fxLayout="row" fxLayoutAlign="end end">

          <!-- Lien vers la page de configuration de la carte -->
          <ng-container *ngIf="linkConfigurationIsVisible()">
            <button mat-mini-fab color="primary" title="{{ 'map.settings' | translate }}" [routerLink]="['/pages/map-settings', robotAdapter?.robot?.activeMap?.id, robotAdapter?.robot?.id]" *hasRole="[Role.ROLE_SUPER_ADMIN, Role.ROLE_ADMIN]">
              <mat-icon>track_changes</mat-icon>
            </button>
          </ng-container>

        </div>
      </mat-card>
    </div>

  </div>

  <div fxShow fxHide.lt-md >
    <mat-card fxFlex="100" #right>
      <mat-card-content>
        <webclient-strategy-list [robotAdapter]="robotAdapter" [strategyAdapters]="strategyAdapters" (mouseEnter)="onStrategyMouseEnter()" (mouseLeave)="onStrategyMouseLeave()"></webclient-strategy-list>
      </mat-card-content>
    </mat-card>
  </div>

</div>
