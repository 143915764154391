import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatrolListComponent } from './patrol-list/patrol-list.component';
import { CardModule } from '../card/card.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormModule } from '../form/form.module';
import { PatrolDialogComponent } from './patrol-dialog/patrol-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { PatrolCheckpointModule } from '../patrol-checkpoint/patrol-checkpoint.module';

@NgModule({
  declarations: [PatrolListComponent, PatrolDialogComponent],
  exports: [PatrolListComponent, PatrolDialogComponent],
  imports: [
    CommonModule,
    CardModule,
    MatDividerModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    MatIconModule,
    FormsModule,
    MatSlideToggleModule,
    MatButtonModule,
    FlexLayoutModule,
    DragDropModule,
    FormModule,
    MatDialogModule,
    MatRadioModule,
    PatrolCheckpointModule,
  ],
})
export class PatrolModule {}
