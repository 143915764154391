import { Pose } from 'roslib';
import { MapConfiguration } from './map-configuration';

export class Checkpoint {
  /**
   * Identifiant
   */
  public id?: number;

  /**
   * Nom
   */
  public name?: string;

  /**
   * Pose
   */
  public pose?: Pose;

  /**
   * Groupe
   */
  public group?: string | null = null;

  /**
   * Orienté
   */
  public oriented = false;

  /**
   * Configurations des cartes
   */
  public mapConfiguration?: MapConfiguration;

  constructor(pose?: Pose) {
    this.pose = pose;
  }
}
