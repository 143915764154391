import { SecureStorageOptions } from './secure-storage-options';
import * as CryptoJS from 'crypto-js';

export class SecureStorage implements Storage {
  /**
   * Méthode de stockage
   *
   * @private
   */
  private storage: Storage;

  /**
   * Fonction de hashage
   *
   * @private
   */
  private hash: (key: string) => string;

  /**
   * Fonction d'encryptage
   *
   * @private
   */
  private encrypt: (message: CryptoJS.lib.WordArray | string) => string;

  /**
   * Fonction de décryptage
   *
   * @private
   */
  private decrypt: (message: CryptoJS.lib.CipherParams | string) => string;

  /**
   * Renvoie le nombre de paires clé/valeur actuellement présentes dans la liste associée à l'objet
   */
  private _length: number = 0;

  constructor(storage: Storage, options: SecureStorageOptions) {
    this.storage = storage;
    this.hash = options.hash;
    this.encrypt = options.encrypt;
    this.decrypt = options.decrypt;
  }

  /**
   * Vide la liste associée à l'objet de toutes les paires clé/valeur, s'il y en a
   */
  public clear(): void {
    return this.storage.clear();
  }

  /**
   * Renvoie la valeur actuelle associée à la clé donnée, ou null si la clé donnée n'existe pas dans la liste associée à l'objet.
   *
   * @param key la clé donnée
   * @return la valeur associée à la clé
   */
  public getItem(key: string): string | null {
    key = this.hash(key);

    let value = this.storage.getItem(key);

    if (typeof value !== 'string') {
      return value;
    }

    value = this.decrypt(value);

    return JSON.parse(value);
  }

  /**
   * Renvoie le nom de la nième clé de la liste, ou null si n est supérieur ou égal au nombre de paires clé/valeur dans l'objet.
   *
   * @param index l'index de la clé de la liste
   * @return le nom de la nième clé de la liste
   */
  public key(index: number): string | null {
    return this.storage.key(index);
  }

  /**
   * Supprime la paire clé/valeur avec la clé donnée de la liste associée à l'objet, s'il existe une paire clé/valeur avec la clé donnée.
   *
   * @param key la clé donnée
   */
  public removeItem(key: string): void {
    key = this.hash(key);

    return this.storage.removeItem(key);
  }

  /**
   * Définit la valeur de la paire identifiée par clé à valeur, créant une nouvelle paire clé/valeur si aucune n'existait auparavant pour la clé.
   * Lève une exception DOMException « QuotaExceededError » si la nouvelle valeur n'a pas pu être définie. (Le paramètre peut échouer si, par exemple, l'utilisateur a désactivé le stockage pour le site ou si le quota a été dépassé.)
   *
   * @param key la clé
   * @param value la valeur
   */
  public setItem(key: string, value: string): void {
    key = this.hash(key);
    value = JSON.stringify(value);
    value = this.encrypt(value);

    return this.storage.setItem(key, value);
  }

  /**
   * Renvoie le nombre de paires clé/valeur actuellement présentes dans la liste associée à l'objet
   */
  get length(): number {
    this._length = this.storage.length;
    return this._length;
  }
}
