<div class="map-configuration-toolbar">
  <div fxLayout="row" fxLayout.xs="column">
    <webclient-card-title
      title="mapConfiguration.configuration"
      icon="settings"
    ></webclient-card-title>

    <div fxFlex></div>

    <div fxLayoutAlign="center center">
      <!-- Bouton de création d'une configuration -->
      <button
        mat-icon-button
        color="accent"
        title="{{ 'mapConfiguration.create' | translate }}"
        (click)="onAddMapConfiguration()"
      >
        <mat-icon [color]="'accent'" aria-label="add">add</mat-icon>
      </button>
    </div>
  </div>

  <div fxLayout="column" fxLayoutGap="30px">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-form-field class="webclient-map-configuration-toolbar-select">
        <mat-label translate="mapConfiguration.label"></mat-label>
        <mat-select
          [(ngModel)]="selectedMapConfigurationAdapter"
          [compareWith]="compareMapConfigurationAdapter"
          (selectionChange)="onChange()"
        >
          <mat-option
            [value]="mapConfigurationAdapter"
            *ngFor="let mapConfigurationAdapter of mapConfigurationAdapters"
          >
            {{ mapConfigurationAdapter.mapConfiguration.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button
        mat-fab
        color="accent"
        title="{{ 'mapConfiguration.edit' | translate }}"
        class="webclient-map-configuration-toolbar-edit-button"
        (click)="onEditMapConfiguration()"
      >
        <mat-icon>edit</mat-icon>
      </button>

      <button
        mat-fab
        color="accent"
        title="{{ 'mapConfiguration.remove' | translate }}"
        class="webclient-map-configuration-toolbar-remove-button"
        (click)="onDeleteMapConfiguration()"
        [disabled]="!canDelete()"
      >
        <mat-icon>remove_circle_outline</mat-icon>
      </button>

      <button
        mat-fab
        color="accent"
        title="{{ 'mapConfiguration.upload' | translate }}"
        class="webclient-map-configuration-toolbar-upload-button"
        (click)="onUploadMapConfiguration()"
        [disabled]="!isUploadable()"
      >
        <mat-icon>upload</mat-icon>
      </button>

      <button
        mat-fab
        color="accent"
        title="{{ 'mapConfiguration.duplicate' | translate }}"
        class="webclient-map-configuration-toolbar-duplicate-button"
        (click)="onDuplicateMapConfiguration()"
      >
        <mat-icon>content_copy</mat-icon>
      </button>

      <div
        fxLayout="row"
        fxFlex
        fxLayoutAlign="center"
        fxLayoutGap="30px"
      ></div>
    </div>

    <div fxLayout="row" fxLayoutGap="30px">
      <webclient-map-configuration-indicator
        [number]="
          selectedMapConfigurationAdapter?.mapConfiguration?.checkpoints?.length
        "
        [title]="'checkpoint.checkpoints'"
      ></webclient-map-configuration-indicator>
      <webclient-map-configuration-indicator
        [number]="
          selectedMapConfigurationAdapter?.mapConfiguration?.patrols?.length
        "
        [title]="'patrol.patrols'"
      ></webclient-map-configuration-indicator>
      <webclient-map-configuration-indicator
        [number]="
          selectedMapConfigurationAdapter?.mapConfiguration?.strategies?.length
        "
        [title]="'strategy.strategies'"
      ></webclient-map-configuration-indicator>
    </div>
  </div>
</div>
