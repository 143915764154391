import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../angular-material.module';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FlexModule } from '@angular/flex-layout';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '../core/core.module';

@NgModule({
  declarations: [MainLayoutComponent, SidebarComponent, ToolbarComponent],
  exports: [ToolbarComponent],
  imports: [
    CommonModule,
    RouterModule,
    AngularMaterialModule,
    FlexModule,
    TranslateModule,
    CoreModule,
  ],
})
export class ThemeModule {}
