import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { RobotInput } from '../../../api/models/robot-inputs';

export interface RobotInputsFormGroup {
  robotId: FormControl<number | null>;
  inputs: FormArray<FormGroup<RobotInputFormGroup>>;
}

export interface RobotInputFormGroup {
  triggerConfId?: FormControl<number>;
  triggerType: FormControl<number | undefined>;
  name: FormControl<string>;
  value: FormControl<string>;
}

export const robotInputsForm = () =>
  new FormGroup<RobotInputsFormGroup>({
    robotId: new FormControl(null, [Validators.required]),
    inputs: robotInputsFormArray([])
  });

export const robotInputsFormArray = (inputs: RobotInput[]) =>
  new FormArray<FormGroup<RobotInputFormGroup>>(
    inputs.map((input) => robotInputForm(input.triggerType, input.name, input.value, input.triggerConfId))
  );

export const robotInputForm = (triggerType?: number, name?: string, value?: string, triggerConfId?: number) => {
  const form = new FormGroup<RobotInputFormGroup>({
    triggerType: new FormControl(triggerType, { nonNullable: true, validators: [Validators.required] }),
    name: new FormControl(name ?? '', { nonNullable: true, validators: [Validators.required] }),
    value: new FormControl(value ?? '', { nonNullable: true, validators: [Validators.required] })
  });
  if (triggerConfId) {
    form.controls.triggerConfId = new FormControl(triggerConfId, { nonNullable: true });
  }
  return form;
};


