import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { EntityService } from './entity.service';
import { Robot } from '../../../api/models/robot';
import { catchError } from 'rxjs/operators';
import { RobotInputs } from '../../../api/models/robot-inputs';

@Injectable({
  providedIn: 'root'
})
export class RobotService extends EntityService<Robot> {
  public resourceUrl = environment.apiBaseUrl + 'robots';

  updateInputs(data: RobotInputs) {
    return this.http.put<Robot>(`${this.resourceUrl}/inputs`, data)
      .pipe(catchError(this.handleError))
  }
}
