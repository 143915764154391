import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageSnackBarComponent } from '../../components/info/message-snack-bar/message-snack-bar.component';
import { MessageType } from '../enums/message-type.enum';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  /**
   * Affichage de la snackbar de succés
   *
   * @param title le titre du message
   * @param message le contenu du message
   *
   * @protected
   */
  public displaySuccessSnackBar(title: string, message: string): void {
    this.snackBar.openFromComponent(MessageSnackBarComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 5000,
      panelClass: 'success-snackbar',
      data: {
        title: title,
        message: message,
        type: MessageType.SUCCESS,
      },
    });
  }

  /**
   * Affichage de la snackbar d'erreur
   *
   * @param title le titre du message
   * @param message le contenu du message
   *
   * @protected
   */
  public displayErrorSnackBar(title: string, message: string): void {
    this.snackBar.openFromComponent(MessageSnackBarComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 5000,
      panelClass: 'error-snackbar',
      data: {
        title: title,
        message: message,
        type: MessageType.SUCCESS,
      },
    });
  }
}
