<mat-card fxFlex fxLayout="column">
  <div>
    <webclient-card-title
      [title]="id === undefined ? createTitle : editTitle"
      [icon]="icon"
      [svgIcon]="svgIcon"
    ></webclient-card-title>
    <mat-divider></mat-divider>
  </div>

  <mat-card-content fxFlex fxLayout="row">
    <ng-content></ng-content>
  </mat-card-content>

  <mat-card-actions fxLayout="row" fxLayoutAlign="end">
    <button mat-flat-button color="primary" [routerLink]="urlList">
      {{ 'entity.back' | translate }}
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="onSubmit()"
      [disabled]="disabledSubmitButton"
    >
      {{ 'entity.save' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
