<mat-toolbar class="toolbar" color="primary">
  <button mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>

  <a class="toolbar-brand" [routerLink]="['/']">
    <h1>{{ 'app.title' | translate }}</h1>
  </a>

  <span class="toolbar-spacer"></span>

  <span> {{ currentUser?.lastname }} {{ currentUser?.firstname }} </span>

  <button mat-button (click)="logout()">
    <mat-icon>logout</mat-icon>
    <span fxShow fxHide.xs> {{ 'login.logout' | translate }} </span>
  </button>
</mat-toolbar>
