import { Component, ViewEncapsulation } from '@angular/core';
import { UserAdapter } from '../../../../core/models/entities/user-adapter';
import { UserService } from '../../../../core/services/entities/user.service';
import { User } from '../../../../api/models/user';
import { IListText } from '../../../../components/admin/models/i-list-text';
import { EntityListComponent } from '../../../../components/admin/entity-list/entity-list.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IEntityAdapter } from '../../../../components/admin/models/i-entity-adapter';
import { SessionService } from '../../../../core/services/session.service';

@Component({
  selector: 'webclient-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserListComponent extends EntityListComponent {
  /**
   * Liste des utilisateurs
   */
  public userAdapters: UserAdapter[] = [];

  /**
   * Colonnes affichées
   */
  public override displayedColumns: string[] = [
    'email',
    'lastname',
    'firstname',
    'customer',
    'roles',
  ];

  /**
   * Textes
   */
  public override text: IListText = {
    removeTitle: 'user.removeTitle',
    removeMessage: 'user.removeMessage',
  };

  constructor(
    protected override sessionService: SessionService,
    private userService: UserService,
    protected override dialog: MatDialog,
    protected override translateService: TranslateService,
    protected override snackBar: MatSnackBar
  ) {
    super(sessionService, dialog, translateService, snackBar);

    this.userService.getAll().subscribe((user: User[]) => {
      this.userAdapters = user.map((user: User) => new UserAdapter(user));
    });
  }

  /**
   * Suppression de l'utilisateur
   *
   * @param entityAdapter l'utilisateur à supprimer
   */
  public onRemove(entityAdapter: IEntityAdapter) {
    const userAdapter: UserAdapter = entityAdapter as UserAdapter;

    if (userAdapter.id !== undefined) {
      this.userService.delete(userAdapter.id).subscribe(
        () => {
          const index: number = this.userAdapters.findIndex(
            (userAdapter: UserAdapter) => userAdapter.id === entityAdapter.id
          );
          const userAdapters: UserAdapter[] = [...this.userAdapters];
          userAdapters.splice(index, 1);

          this.userAdapters = userAdapters;

          this.displaySuccessSnackBar(
            userAdapter.name,
            'user.removeSuccessMessage'
          );
        },
        (error) => {
          this.displayErrorSnackBar(
            userAdapter.name,
            'user.removeErrorMessage'
          );
        }
      );
    }
  }
}
