import { BackStatus } from '../../enums/back-status.enum';
import { Strategy } from '../../../api/models/strategy';

export class StrategyAdapter {
  /**
   * Identifiant
   */
  public uid: string | undefined;

  /**
   * Indique si l'item stratégie est sauvegardé en BDD
   */
  public status: BackStatus = BackStatus.SAVED;

  /**
   * Indique si l'item peux être déplaçable
   */
  public draggable = true;

  /**
   * Indique si l'item est supprimé
   */
  public deleted = false;

  constructor(public strategy: Strategy) {
    if (strategy.patrol && strategy.patrol.id) {
      this.uid = strategy.patrol.id.toString();
    }
  }
}
