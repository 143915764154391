<div
  fxLayout="row"
  class="robot-fire-container  {{ state }}"
  [ngClass]="{ disabled: disabled === true }"
>
  <button
    mat-flat-button
    matTooltip="{{ state === false ? offText : onText }}"
    matTooltipDisabled="{{ state === undefined }}"
    [disabled]="disabled"
    (click)="onClick(state)"
  >
    <mat-icon matSuffix [svgIcon]="'concierge'"></mat-icon>
  </button>
</div>