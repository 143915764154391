import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserGroupSelectComponent } from './user-group-select/user-group-select.component';
import { MatChipsModule } from '@angular/material/chips';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [UserGroupSelectComponent],
  exports: [UserGroupSelectComponent],
  imports: [
    CommonModule,
    MatChipsModule,
    ScrollingModule,
    MatTreeModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    TranslateModule,
  ],
})
export class UserGroupModule {}
