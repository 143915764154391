<webclient-entity-form
  [urlList]="'/pages/admin/user/list/'"
  [entity]="userAdapter"
  [form]="form"
  [createTitle]="'user.create'"
  [editTitle]="'user.edit'"
  [icon]="'bolt'"
  (onCreateEntity)="onCreate($event)"
  (onEditEntity)="onEdit($event)"
>
  <form
    [formGroup]="form"
    *ngIf="form"
    fxLayout="row"
    fxLayoutGap="20px"
    class="admin-form"
    fxFlex
  >
    <div fxFlex="40">
      <fieldset>
        <legend [translate]="'user.informations'"></legend>

        <div
          fxLayout="column"
          fxLayout.md="column"
          fxLayout.sm="column"
          fxLayout.xs="column"
          fxLayoutGap="20px"
        >
          <!-- Champ email -->
          <div
            fxLayout="row"
            fxLayout.sm="column"
            fxLayout.xs="column"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="100">
              <mat-icon matPrefix>mail_outline</mat-icon>
              <input
                matInput
                type="text"
                name="email"
                required="required"
                placeholder="{{ 'user.email' | translate }}"
                formControlName="email"
              />
              <mat-error *ngFor="let validation of validationMessages.email">
                <ng-container
                  *ngIf="
                    form.get('email')?.hasError(validation.type) &&
                    (form.get('email')?.dirty || form.get('email')?.touched)
                  "
                >
                  {{ validation.message | translate }}
                </ng-container>
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Champ nom et prénom -->
          <div fxLayout="row" fxLayoutGap="10px">
            <!-- Champ nom -->
            <div
              fxLayout="row"
              fxLayout.sm="column"
              fxLayout.xs="column"
              fxLayoutGap="20px"
              fxFlex="100"
            >
              <mat-form-field fxFlex>
                <mat-icon matPrefix>person</mat-icon>
                <input
                  matInput
                  type="text"
                  name="lastname"
                  required="required"
                  placeholder="{{ 'user.lastname' | translate }}"
                  formControlName="lastname"
                />
                <mat-error
                  *ngFor="let validation of validationMessages.lastname"
                >
                  <ng-container
                    *ngIf="
                      form.get('lastname')?.hasError(validation.type) &&
                      (form.get('lastname')?.dirty ||
                        form.get('lastname')?.touched)
                    "
                  >
                    {{ validation.message | translate }}
                  </ng-container>
                </mat-error>
              </mat-form-field>

              <!-- Champ prénom -->
              <mat-form-field fxFlex>
                <mat-icon matPrefix>person</mat-icon>
                <input
                  matInput
                  type="text"
                  name="firstname"
                  required="required"
                  placeholder="{{ 'user.firstname' | translate }}"
                  formControlName="firstname"
                />
                <mat-error
                  *ngFor="let validation of validationMessages.firstname"
                >
                  <ng-container
                    *ngIf="
                      form.get('firstname')?.hasError(validation.type) &&
                      (form.get('firstname')?.dirty ||
                        form.get('firstname')?.touched)
                    "
                  >
                    {{ validation.message | translate }}
                  </ng-container>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <!-- Champ mot de passe et confirmation de mot de passe -->
          <div class="change-password" *ngIf="userAdapter !== undefined">
            <button
              mat-flat-button
              color="primary"
              (click)="onChangePassword()"
            >
              Modifier le mot de passe
            </button>
          </div>

          <div fxLayout="row" fxLayoutGap="10px">
            <div
              fxLayout="row"
              fxLayout.sm="column"
              fxLayout.xs="column"
              fxLayoutGap="20px"
              fxFlex="100"
            >
              <!-- Champ mot de passe -->
              <mat-form-field fxFlex>
                <mat-icon matPrefix>lock</mat-icon>
                <mat-icon
                  matSuffix
                  [svgIcon]="showPassword ? 'eye-on' : 'eye-off'"
                  (click)="showPassword = !showPassword"
                ></mat-icon>
                <input
                  matInput
                  [type]="showPassword ? 'text' : 'password'"
                  name="password"
                  required="required"
                  placeholder="{{ 'user.password' | translate }}"
                  formControlName="password"
                />
                <mat-error
                  *ngFor="let validation of validationMessages.password"
                >
                  <ng-container
                    *ngIf="
                      form.get('password')?.hasError(validation.type) &&
                      (form.get('password')?.dirty ||
                        form.get('password')?.touched)
                    "
                  >
                    {{ validation.message | translate }}
                  </ng-container>
                </mat-error>
              </mat-form-field>

              <!-- Champ confirmation de mot de passe -->
              <mat-form-field fxFlex>
                <mat-icon matPrefix>lock</mat-icon>
                <mat-icon
                  matSuffix
                  [svgIcon]="showConfirmationPassword ? 'eye-on' : 'eye-off'"
                  (click)="showConfirmationPassword = !showConfirmationPassword"
                ></mat-icon>
                <input
                  matInput
                  [type]="showConfirmationPassword ? 'text' : 'password'"
                  name="confirmPassword"
                  required="required"
                  placeholder="{{ 'user.confirmationPassword' | translate }}"
                  formControlName="confirmationPassword"
                />
                <mat-error
                  *ngFor="
                    let validation of validationMessages.confirmationPassword
                  "
                >
                  <ng-container
                    *ngIf="
                      form
                        .get('confirmationPassword')
                        ?.hasError(validation.type) &&
                      (form.get('confirmationPassword')?.dirty ||
                        form.get('confirmationPassword')?.touched)
                    "
                  >
                    {{ validation.message | translate }}
                  </ng-container>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset *ngIf="isSuperAdmin">
        <legend [translate]="'user.customer'"></legend>

        <div
          fxLayout="column"
          fxLayout.md="column"
          fxLayout.sm="column"
          fxLayout.xs="column"
          fxLayoutGap="20px"
        >
          <!-- Liste des clients -->
          <div
            fxLayout="row"
            fxLayout.sm="column"
            fxLayout.xs="column"
            fxLayoutAlign="start end"
            fxLayoutAlign.sm="start"
            fxLayoutAlign.xs="start"
            fxLayoutGap="20px"
            fxFlex="50"
          >
            <mat-form-field fxFlex="100">
              <mat-icon matPrefix>group</mat-icon>
              <mat-label> {{ 'user.customer' | translate }} </mat-label>
              <mat-select
                matInput
                formControlName="customer"
                [compareWith]="compareCustomer"
                (selectionChange)="onChangeCustomer($event)"
              >
                <mat-option></mat-option>
                <mat-option
                  *ngFor="let customer of customers"
                  [value]="customer"
                >
                  {{ customer.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <mat-error *ngFor="let validation of validationMessages.customer">
            <ng-container
              *ngIf="
                form.get('customer')?.hasError(validation.type) &&
                (form.get('customer')?.dirty || form.get('customer')?.touched)
              "
            >
              {{ validation.message | translate }}
            </ng-container>
          </mat-error>
        </div>
      </fieldset>

      <fieldset>
        <legend [translate]="'user.role.roles'"></legend>

        <div
          fxLayout="column"
          fxLayout.md="column"
          fxLayout.sm="column"
          fxLayout.xs="column"
          fxLayoutGap="20px"
        >
          <!-- Liste des rôles -->
          <div
            fxLayout="row"
            fxLayout.sm="column"
            fxLayout.xs="column"
            fxLayoutAlign="start end"
            fxLayoutAlign.sm="start"
            fxLayoutAlign.xs="start"
            fxLayoutGap="20px"
            fxFlex="50"
          >
            <div
              fxLayout="row"
              fxLayout.sm="column"
              fxLayout.xs="column"
              fxLayoutAlign="start end"
              fxLayoutAlign.sm="start"
              fxLayoutAlign.xs="start"
              fxLayoutGap="20px"
              fxFlex="50"
            >
              <ng-container
                *ngFor="let role of roles; let i = index"
                formArrayName="roles"
              >
                <mat-checkbox
                  formControlName="{{ i }}"
                  [value]="role"
                  (change)="onRoleChange($event, i)"
                >
                  <span translate="user.role.{{ role }}"></span>
                </mat-checkbox>
              </ng-container>
            </div>
          </div>

          <mat-error *ngFor="let validation of validationMessages.roles">
            <ng-container
              *ngIf="
                form.get('roles')?.hasError(validation.type) &&
                (form.get('roles')?.dirty || form.get('roles')?.touched)
              "
            >
              <span
                [translate]="validation.message"
                [translateParams]="validation.params ? validation.params : ''"
              ></span>
            </ng-container>
          </mat-error>
        </div>
      </fieldset>
    </div>

    <div
      fxFlex
      class="webclient-user-admin-user-groups"
      *ngIf="currentRole !== ROLES.ROLE_SUPER_ADMIN"
    >
      <!-- Liste des groupes d'utilisateurs -->
      <webclient-user-group-select
        [data]="userGroups"
        formControlName="userGroups"
      ></webclient-user-group-select>

      <mat-error *ngFor="let validation of validationMessages.userGroups">
        <ng-container
          *ngIf="
            form.get('userGroups')?.hasError(validation.type) &&
            (form.get('userGroups')?.dirty || form.get('userGroups')?.touched)
          "
        >
          {{ validation.message | translate }}
        </ng-container>
      </mat-error>
    </div>
  </form>
</webclient-entity-form>
