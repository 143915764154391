import {
  Component,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'webclient-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: NumberInputComponent,
    },
  ],
  host: {
    class: 'webclient-number-input',
  },
})
export class NumberInputComponent
  implements MatFormFieldControl<number>, OnDestroy
{
  private nextUniqueId = 0;
  private _id: string = '';
  protected _uid = `webclient-number-input-${this.nextUniqueId++}`;
  private _required: boolean = false;
  private _placeholder: string = '';
  private _focused = false;

  readonly stateChanges = new Subject<void>();

  @Input() public step = 1;

  @Input() public canEdit = false;

  @Input() public onlyIntegerDigit = false;

  @Input() public digitsInfo = '1.2-2';

  @Input()
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value || this._uid;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  get focused(): boolean {
    return this._focused;
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);

    if (this.focused) {
      this._focused = false;
      this.stateChanges.next();
    }
  }
  private _disabled = false;

  _value: any;

  get value(): number {
    return this._value;
  }
  set value(value: number) {
    this._value = value;
    this.stateChanges.next();
  }

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  onChange = (_: number) => {};

  onTouched = (_: number) => {};

  registerOnChange(fn: (v: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (v: number) => void): void {
    this.onTouched = fn;
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  get errorState(): boolean {
    return this.ngControl.control != null ? !!this.ngControl.control : false;
  }

  get empty(): boolean {
    return false;
  }

  get autofilled(): boolean {
    return false;
  }

  onContainerClick(event: MouseEvent): void {}

  setDescribedByIds(ids: string[]): void {}

  writeValue(value: number): void {
    if (value !== undefined && value !== null) {
      this.value = value;
    } else {
      this.value = 0;
    }
  }

  public onDecrement(): void {
    this.value = this.value - this.step;
    this.onChange(this.value);
  }

  public onIncrement(): void {
    this.value = this.value + this.step;
    this.onChange(this.value);
  }

  public handleInput(event: Event) {
    const value = parseInt((event.target as HTMLTextAreaElement).value);

    if (!isNaN(value)) {
      this.value = value;
      this.onChange(this.value);
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }
}
