import { ColorFilter, DisplayObject } from 'createjs-module';
import { ImageShape } from '../models/shapes/image-shape';
import { Observable, skipWhile } from 'rxjs';

export class Utils {
  /**
   * Génère un code couleur
   */
  public static generateColor(): string {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  }

  /**
   * Génère un identifiant unique
   */
  public static uniqueId(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // tslint:disable-next-line:no-bitwise one-variable-per-declaration
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  public static quaternionToGlobalTheta(orientation: any): number {
    const q0 = orientation.w;
    const q1 = orientation.x;
    const q2 = orientation.y;
    const q3 = orientation.z;

    return -Math.round(
      (-Math.atan2(2 * (q0 * q3 + q1 * q2), 1 - 2 * (q2 * q2 + q3 * q3)) *
        180.0) /
        Math.PI
    );
  }

  /**
   * Retourne la regex pour les mots de passes
   */
  public static patternPassword(): string {
    return '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';
  }

  /**
   * Création de la forme du point de contrôle
   *
   * @param visible forme visible ou non
   */
  public static createMapCheckpoint(visible: boolean = true): DisplayObject {
    const imageUrl = '../../../../assets/shapes/checkpoint.png';

    const shape = new ImageShape({
      size: 1,
      imageUrl,
      regX: 22.5,
      regY: 45,
    });

    return shape;
  }

  /**
   * Création de la forme du point de patrouille
   *
   * @param visible forme visible ou non
   */
  public static createMapPatrolCheckpoint(
    visible: boolean = true
  ): DisplayObject {
    const imageUrl = '../../../../assets/shapes/oriented-checkpoint.png';

    const shape = new ImageShape({
      size: 1,
      imageUrl,
      regX: 0,
      regY: 22.5,
    });

    return shape;
  }

  /**
   * Création de la forme du point de patrouille non orienté
   *
   * @param visible forme visible ou non
   */
  public static createMapPatrolCheckpointNotOriented(
    visible: boolean = true
  ): DisplayObject {
    const imageUrl = '../../../../assets/shapes/red-cross.png';

    const shape = new ImageShape({
      size: 1,
      imageUrl,
      regX: 22.5,
      regY: 22.5,
    });

    return shape;
  }

  /**
   * Création de la forme de la niche
   *
   * @param visible forme visible ou non
   */
  public static createNiche(visible: boolean = true): DisplayObject {
    const imageUrl = '../../../../assets/shapes/niche.png';

    const shape = new ImageShape({
      size: 1,
      imageUrl,
      regX: 45,
      regY: 22.5,
    });

    return shape;
  }

  /**
   * Création de la forme du point de patrouille en cours
   *
   * @param visible forme visible ou non
   */
  public static createCurrentMapPatrolCheckpoint(
    visible: boolean = true
  ): DisplayObject {
    const imageUrl =
      '../../../../assets/shapes/current-oriented-checkpoint.png';

    const shape = new ImageShape({
      size: 1,
      imageUrl,
      regX: 0,
      regY: 22.5,
    });

    return shape;
  }

  /**
   * Création de la forme du point de patrouille suivant
   *
   * @param visible forme visible ou non
   */
  public static createNextMapPatrolCheckpoint(
    visible: boolean = true
  ): DisplayObject {
    const imageUrl = '../../../../assets/shapes/checkpoint.png';

    const shape = new ImageShape({
      size: 1,
      imageUrl,
      regX: 22.5,
      regY: 45,
    });

    return shape;
  }

  public static skipNull() {
    return <T>(source: Observable<T>): Observable<T> =>
      source.pipe(skipWhile((value) => value === null));
  }
}
