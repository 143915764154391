<div
  fxLayout="row"
  class="robot-speed-container {{ mode }}"
  [ngClass]="{ disabled: disabled === true }"
>
  <button
    mat-flat-button
    matTooltip="{{ mode === ROBOT_SPEED.SLOW ? slowText : fastText }}"
    matTooltipDisabled="{{ mode === undefined }}"
    [disabled]="disabled"
    (click)="onClick(mode)"
  >
    <mat-icon>speed</mat-icon>
  </button>
</div>
