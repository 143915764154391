import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'webclient-robot-fire',
  templateUrl: './robot-fire.component.html',
  styleUrls: ['./robot-fire.component.scss'],
})
export class RobotFireComponent implements OnInit {
  /**
   * Etat de la vidéo
   */
  @Input() public state: boolean | undefined;

  /**
   * Désactivation
   */
  @Input() public disabled: boolean = false;

  /**
   * Texte pour le mode éteint
   */
  @Input() public offText: string | undefined;

  /**
   * Texte pour le mode allumé
   */
  @Input() public onText: string | undefined;

  /**
   * Evènement lors du click sur le bouton
   */
  @Output() public onState = new EventEmitter<boolean>();

  public videoPlayState : boolean | undefined;

  constructor() {}

  ngOnInit(): void {}
  
  public onClick(state: boolean | undefined) {
    if (state !== undefined) {
      this.onState.emit(!state);
    }
  }
}
