import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable, of } from 'rxjs';
import { SessionService } from '../session.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private resourceUrl = environment.apiBaseUrl;

  private refreshTokenTimeout: any;

  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) {}

  /**
   * S'identifier
   *
   * @param email l'email
   * @param password le mot de passe
   */
  public login(
    email: string,
    password: string
  ): Observable<number | undefined> {
    return this.http
      .post<{ token: string; refresh_token: string; userId: number }>(
        `${this.resourceUrl}login`,
        { email, password },
        { observe: 'response' }
      )
      .pipe(
        map(
          (
            response: HttpResponse<{
              token: string;
              refresh_token: string;
              userId: number;
            }>
          ) => {
            const token: string | undefined = response?.body?.token;
            const refreshToken: string | undefined =
              response?.body?.refresh_token;
            const userId: number | undefined = response?.body?.userId;

            if (token && refreshToken) {
              this.sessionService.setToken(token);
              this.sessionService.setRefreshToken(refreshToken);
              this.startRefreshTokenTimer();
            }

            return userId;
          }
        )
      );
  }

  /**
   * Se déconnecter
   */
  public logout(): Observable<null> {
    this.sessionService.destroy();
    this.stopRefreshTokenTimer();
    return of(null);
  }

  /**
   * Est authentifié
   */
  public isAuthenticated(): boolean {
    return (
      this.sessionService.getToken() != null &&
      !this.sessionService.isTokenExpired()
    );
  }

  public refreshToken(): Observable<void> {
    return this.http
      .post<{ token: string; refresh_token: string }>(
        `${this.resourceUrl}token/refresh`,
        { refresh_token: this.sessionService.getRefreshToken() },
        { observe: 'response' }
      )
      .pipe(
        map(
          (
            response: HttpResponse<{ token: string; refresh_token: string }>
          ) => {
            const token: string | undefined = response?.body?.token;
            const refreshToken: string | undefined =
              response?.body?.refresh_token;

            return { token, refreshToken };
          }
        ),
        map(({ token, refreshToken }) => {
          if (token && refreshToken) {
            this.sessionService.setToken(token);
            this.sessionService.setRefreshToken(refreshToken);
            this.startRefreshTokenTimer();
          }
        })
      );
  }

  private startRefreshTokenTimer() {
    const token = JSON.parse(
      atob(this.sessionService.getToken().split('.')[1])
    );
    const expires = new Date(token.exp * 1000);
    // Rafraichi le token une minute avant qu'il expire
    const timeout = expires.getTime() - (Date.now() - 60 * 1000);
    this.refreshTokenTimeout = setTimeout(
      () => this.refreshToken().subscribe(),
      timeout
    );
  }

  private stopRefreshTokenTimer() {
    if (this.refreshTokenTimeout) {
      clearTimeout(this.refreshTokenTimeout);
    }
  }
}
