<mat-card fxFlex="100">
  <mat-card-header>
    <!-- Nom du robot -->
    <mat-card-title>
      <h2 fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon svgIcon="alf31"></mat-icon>
        <span> {{ mapAdapter?.map?.name }} </span>
      </h2>
    </mat-card-title>
  </mat-card-header>

  <mat-divider></mat-divider>

  <mat-card-content
    fxLayout="column"
    fxLayoutAlign="center center"
    class="webclient-map-list-map"
    #card
  >
    <!-- Carte disponible et chargée -->
    <ngx-rodotic-viewer
      [mapInfo]="mapAdapter?.mapInfo"
      *ngIf="mapAdapter?.map?.status === MAP_STATUS.AVAILABLE && !this.loading"
    ></ngx-rodotic-viewer>

    <!-- Image en cours de chargement -->
    <div
      class="map-image-available"
      fxLayout="column"
      fxLayoutAlign="center center"
      *ngIf="loading"
    >
      <mat-spinner *ngIf="loading"></mat-spinner>
    </div>

    <!-- Image de la carte non disponible -->
    <div
      class="map-image-not-available"
      fxLayout="column"
      fxLayoutAlign="center center"
      *ngIf="mapAdapter?.map?.status === MAP_STATUS.IMAGE_NOT_AVAILABLE"
    >
      <mat-icon color="warn">block</mat-icon>
      <p color="warn">
        {{ 'map.status.' + mapAdapter?.map?.status | translate }}
      </p>
    </div>

    <!-- Carte en cours de création -->
    <div
      class="map-image-being-created"
      *ngIf="mapAdapter?.map?.status === MAP_STATUS.BEING_CREATED"
    >
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      <p>{{ 'map.status.' + mapAdapter?.map?.status | translate }}</p>
    </div>

    <!-- Carte en cours d'annulation -->
    <div
      class="map-image-being-canceled"
      *ngIf="mapAdapter?.map?.status === MAP_STATUS.BEING_CANCELED"
    >
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      <p>{{ 'map.status.' + mapAdapter?.map?.status | translate }}</p>
    </div>

    <!-- Erreur de création de carte -->
    <div
      class="map-image-error"
      *ngIf="mapAdapter?.map?.status === MAP_STATUS.CREATION_ERROR"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <mat-icon color="warn">report_problem</mat-icon>
      <p color="warn">
        {{ 'map.status.' + mapAdapter?.map?.status | translate }}
      </p>
    </div>
  </mat-card-content>

  <mat-card-actions fxLayout="row" fxLayoutAlign="center center">
    <div>
      <mat-form-field
        class="webclient-map-list-select"
        *ngIf="hasConfigurations()"
      >
        <mat-label translate="mapConfiguration.label"></mat-label>
        <mat-select
          [(value)]="selectedMapConfigurationId"
          [disabled]="canSetting()"
        >
          <mat-option
            [value]="mapConfiguration.id"
            *ngFor="let mapConfiguration of mapAdapter?.map?.mapConfigurations"
          >
            {{ mapConfiguration.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <span
        *ngIf="!hasConfigurations()"
        [translate]="'map.noconfigurations'"
        class="map-item-noconfigurations"
      ></span>
    </div>

    <div fxFlex></div>

    <div fxLayout="row">
      <webclient-activated-button
        *ngIf="MAP_STATUS.IMAGE_NOT_AVAILABLE"
        [activatedIcon]="'check_circle'"
        [desactivatedIcon]="'highlight_off'"
        [activatedText]="'activated'"
        [desactivatedText]="'desactivated'"
        [textLoading]="'waiting'"
        [activatedColor]="'success'"
        [desactivatedColor]="'accent'"
        (buttonClick)="onActivate(mapAdapter)"
        [state]="isActivate()"
        [disabled]="!canActivate()"
      ></webclient-activated-button>

      <div class="webclient-map-list-buttons-container">
        <!-- Lien vers la page de configuration de la carte -->
        <button
          mat-mini-fab
          color="primary"
          title="{{ 'map.settings.setting' | translate }}"
          [routerLink]="[
            '/pages/map-settings',
            mapAdapter?.id,
            robotAdapter?.robot?.id
          ]"
          [disabled]="canSetting()"
          *hasRole="[Role.ROLE_SUPER_ADMIN, Role.ROLE_ADMIN]"
        >
          <mat-icon>track_changes</mat-icon>
        </button>

        <!-- Bouton pour interrompre la création de la carte -->
        <button
          mat-mini-fab
          color="accent"
          (click)="onCancelCreateMap(mapAdapter)"
          [disabled]="canCancelCreation()"
        >
          <mat-icon>pan_tool</mat-icon>
        </button>

        <!-- Bouton pour supprimer la carte -->
        <button
          mat-mini-fab
          color="accent"
          title="{{ 'map.removeTitle' | translate }}"
          (click)="onRemoveMap(mapAdapter)"
          [disabled]="canRemoveMap()"
        >
          <mat-icon>remove_circle_outline</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-actions>
</mat-card>
