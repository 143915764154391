<mat-card>
  <div>
    <webclient-card-title
      [title]="title"
      [icon]="icon"
      [svgIcon]="svgIcon"
    ></webclient-card-title>

    <div fxFlex></div>

    <div fxLayoutAlign="center start">
      <a
        mat-mini-fab
        color="accent"
        title="{{ 'entity.create' | translate }}"
        [routerLink]="urlForm"
        *hasRole="roles"
      >
        <mat-icon aria-label="add">add</mat-icon>
      </a>
    </div>
  </div>

  <mat-divider></mat-divider>

  <mat-card-content>
    <mat-table #table [dataSource]="dataSource" class="entity-list">
      <!-- Colonne id -->
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef>
          <span [style.paddingLeft.px]="20">
            {{ 'entity.id' | translate }}
          </span>
        </mat-header-cell>

        <mat-cell *matCellDef="let data" [style.paddingLeft.px]="0">
          <button
            mat-icon-button
            [style.visibility]="!data.expandable ? 'hidden' : ''"
            [style.marginLeft.px]="data.level * 32"
            (click)="treeControl.toggle(data)"
          >
            <mat-icon class="mat-icon-rtl-mirror">
              {{
                treeControl.isExpanded(data) ? 'expand_more' : 'chevron_right'
              }}
            </mat-icon>
          </button>

          {{ data.id }}
        </mat-cell>
      </ng-container>

      <ng-content></ng-content>

      <!-- Colonne actions -->
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>{{
          'actions' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let entityAdapter">
          <a
            mat-icon-button
            title="{{ 'entity.edit' | translate }}"
            [routerLink]="urlForm + entityAdapter.id"
          >
            <mat-icon>create</mat-icon>
          </a>
          <button
            mat-icon-button
            title="{{ 'entity.remove' | translate }}"
            color="accent"
            (click)="openDialog(entityAdapter)"
          >
            <mat-icon>remove_circle_outline</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let entityAdapter; columns: displayedColumns"
      ></mat-row>
    </mat-table>
  </mat-card-content>
</mat-card>
