import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'webclient-robot-zone',
  templateUrl: './robot-zone.component.html',
  styleUrls: ['./robot-zone.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'webclient-robot-zone',
  },
})
export class RobotZoneComponent implements OnInit {
  /**
   * Nom de la zone
   */
  @Input() public name: string | undefined;

  /**
   * Désactivation
   */
  @Input() public disabled: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
