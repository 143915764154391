<div
  fxLayout="column"
  class="main-container"
  [class.is-mobile]="mobileQuery.matches"
>
  <!-- Barre d'outil  -->
  <webclient-toolbar
    class="toolbar"
    (click)="snav.toggle()"
  ></webclient-toolbar>

  <mat-sidenav-container class="main-sidenav-container" autosize>
    <!-- Barre latérale de navigation -->
    <mat-sidenav
      #snav
      [opened]="!mobileQuery.matches"
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="56"
      class="sidenav"
    >
      <webclient-sidebar></webclient-sidebar>
    </mat-sidenav>

    <!-- Contenu principal -->
    <mat-sidenav-content class="sidenav-content">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
