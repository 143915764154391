import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapConfigurationToolbarComponent } from './map-configuration-toolbar/map-configuration-toolbar.component';
import { CardModule } from '../card/card.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MapConfigurationIndicatorComponent } from './map-configuration-indicator/map-configuration-indicator.component';
import { MatCardModule } from '@angular/material/card';
import { MapConfigurationDialogComponent } from './map-configuration-dialog/map-configuration-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    MapConfigurationToolbarComponent,
    MapConfigurationIndicatorComponent,
    MapConfigurationDialogComponent,
  ],
  exports: [MapConfigurationToolbarComponent],
  imports: [
    CommonModule,
    CardModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    MatIconModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule,
  ],
})
export class MapConfigurationModule {}
