import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ZoneAdapter } from '../../../../core/models/entities/zone-adapter';
import { ZoneService } from '../../../../core/services/entities/zone.service';
import { Zone } from '../../../../api/models/zone';
import { IListText } from '../../../../components/admin/models/i-list-text';
import { EntityListComponent } from '../../../../components/admin/entity-list/entity-list.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IEntityAdapter } from '../../../../components/admin/models/i-entity-adapter';
import { SessionService } from '../../../../core/services/session.service';

@Component({
  selector: 'webclient-zone-list',
  templateUrl: './zone-list.component.html',
  styleUrls: ['./zone-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ZoneListComponent extends EntityListComponent {
  /**
   * Liste des zones
   */
  public zoneAdapters: ZoneAdapter[] = [];

  /**
   * Colonnes affichées
   */
  public override displayedColumns: string[] = ['name', 'robots', 'maps'];

  /**
   * Textes
   */
  public override text: IListText = {
    removeTitle: 'zone.removeTitle',
    removeMessage: 'zone.removeMessage',
  };

  constructor(
    private zoneService: ZoneService,
    protected override sessionService: SessionService,
    protected override dialog: MatDialog,
    protected override translateService: TranslateService,
    protected override snackBar: MatSnackBar
  ) {
    super(sessionService, dialog, translateService, snackBar);

    this.zoneService.getAll().subscribe((zone: Zone[]) => {
      this.zoneAdapters = zone.map((zone: Zone) => new ZoneAdapter(zone));
    });
  }

  /**
   * Suppression de la zone
   *
   * @param entityAdapter la zone à supprimer
   */
  public onRemove(entityAdapter: IEntityAdapter) {
    const zoneAdapter: ZoneAdapter = entityAdapter as ZoneAdapter;

    if (zoneAdapter.id !== undefined) {
      this.zoneService.delete(zoneAdapter.id).subscribe(
        () => {
          const index: number = this.zoneAdapters.findIndex(
            (zoneAdapter: ZoneAdapter) => zoneAdapter.id === entityAdapter.id
          );
          const zoneAdapters: ZoneAdapter[] = [...this.zoneAdapters];
          zoneAdapters.splice(index, 1);

          this.zoneAdapters = zoneAdapters;

          this.displaySuccessSnackBar(
            zoneAdapter.name,
            'zone.removeSuccessMessage'
          );
        },
        (error) => {
          this.displayErrorSnackBar(
            zoneAdapter.name,
            'zone.removeErrorMessage'
          );
        }
      );
    }
  }
}
