import { Component, Input, ViewEncapsulation } from '@angular/core';
import { RobotAdapter } from '../../../core/models/entities/robot-adapter';
import { Motion } from '../../../core/enums/motion.enum';
import { RobotLight } from '../../../core/enums/robot-light.enum';
import { RobotSpeed } from '../../../core/enums/robot-speed.enum';

@Component({
  selector: 'webclient-robot-video',
  templateUrl: './robot-video.component.html',
  styleUrls: ['./robot-video.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'webclient-robot-video',
  },
})
export class RobotVideoComponent {
  /**
   * Le robot
   */
  @Input() public robotAdapter: RobotAdapter | undefined;

  /**
   * Allumage automatique de la caméra (true/false)
   */
  @Input() public autoplay: boolean = false;

  /**
   * Ordre d'allumage de la caméra (on/off)
   */
  public shouldplay: boolean = false;

  /**
   * Statu de la caméra (on/off)
   */
  public isplaying: boolean | undefined;

  /**
   * Statu de l'animation manual_control (off:0/on:1/ending:2)
   */
  public ismanualcontrol: number | undefined;

  /**
   * Mouvement
   */
  public MOTION = Motion;

  public onMotionClick(motion: Motion) {
    if (this.robotAdapter) {
      this.robotAdapter.forcedMotionCommand(motion);
    }
  }

  onLightClick() {
    if (this.robotAdapter) {
      const lightState: RobotLight =
        this.robotAdapter.lightState === RobotLight.ON
          ? RobotLight.OFF
          : RobotLight.ON;
      this.robotAdapter.lightCommand(lightState);
    }
  }

  onSpeedClick() {
    if (this.robotAdapter) {
      const speedMode: RobotSpeed =
        this.robotAdapter.speedMode === RobotSpeed.FAST
          ? RobotSpeed.SLOW
          : RobotSpeed.FAST;
      this.robotAdapter.speedCommand(speedMode);
    }
  }

  onArmingClick() {
    if (this.robotAdapter) {
      this.robotAdapter.armingCommand(!this.robotAdapter.isarming);
    }
  }

  onFireClick() {
    if (this.robotAdapter) {
      this.robotAdapter.fireCommand(!this.robotAdapter.isfire);
    }
  }

  public playvideo() {
    if (this.robotAdapter) {
      if(this.isplaying !== undefined && this.isplaying === true){
        this.shouldplay = false;
      } else {
        this.shouldplay = true;
      }
    }
  }

  public getisplaying(isvideoplaying: boolean) {
    this.isplaying = isvideoplaying;
  }

}
