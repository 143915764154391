import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Robot } from '../../../api/models/robot';

@Component({
  selector: 'webclient-robot-select',
  templateUrl: './robot-select.component.html',
  styleUrls: ['./robot-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RobotSelectComponent,
    },
  ],
})
export class RobotSelectComponent implements ControlValueAccessor {
  /**
   * Données
   */
  @Input() public data: Robot[] = [];

  /**
   * Les items sélectionnés
   * @private
   */
  public robots: Robot[] = [];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (robots: Robot[]) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = (robots: Robot[]) => {};

  writeValue(robots: Robot[]): void {
    this.robots = robots === null ? [] : robots;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnChange(onChange: (robots: Robot[]) => {}): void {
    this.onChange = onChange;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnTouched(onTouched: (robots: Robot[]) => {}): void {
    this.onTouched = onTouched;
  }

  /**
   * Evènement lors de la sélection/déselction d'un robot
   *
   * @param event
   * @param robot le robot sélectionné/désélectionné
   */
  public onToggle(event: any, robot: Robot): void {
    const index: number = this.robots.findIndex(
      (r: Robot) => r.id === robot.id
    );

    if (!event.checked) {
      this.robots.splice(index, 1);
    } else {
      this.robots.push(robot);
    }

    this.onChange(this.robots);
  }

  public isSelected(robot: Robot): boolean {
    return this.robots.find((r: Robot) => r.id === robot.id) === undefined
      ? false
      : true;
  }
}
