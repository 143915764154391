import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RobotLight } from '../../../core/enums/robot-light.enum';

@Component({
  selector: 'webclient-robot-lighting',
  templateUrl: './robot-lighting.component.html',
  styleUrls: ['./robot-lighting.component.scss'],
})
export class RobotLightingComponent implements OnInit {
  /**
   * Etat de la lumière
   */
  @Input() public state: RobotLight | undefined;

  /**
   * Désactivation
   */
  @Input() public disabled: boolean = false;

  /**
   * Texte pour le mode éteint
   */
  @Input() public offText: string | undefined;

  /**
   * Texte pour le mode allumé
   */
  @Input() public onText: string | undefined;

  /**
   * Evènement lors du click sur le bouton
   */
  @Output() public onState = new EventEmitter<RobotLight>();

  /**
   * Etat
   */
  public ROBOT_LIGHT = RobotLight;

  constructor() {}

  ngOnInit(): void {}

  public onClick(state: RobotLight | undefined) {
    if (state !== undefined) {
      this.onState.emit(state);
    }
  }
}
