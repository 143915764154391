import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { SecureStorage } from '../../models/secure-storage/secure-storage';

@Injectable({
  providedIn: 'root',
})
export class SecureStorageService {
  /**
   * Clé secrète
   */
  public static SECRET_KEY = 'alf31';

  /**
   * Retourne le système de stockage sécurisé
   *
   * @param storage le système de stockage sécurisé
   */
  public getSecureStorage(storage: Storage): SecureStorage {
    return new SecureStorage(storage, {
      hash: (key: string) => {
        return CryptoJS.SHA256(key).toString();
      },
      encrypt: (message: CryptoJS.lib.WordArray | string) => {
        return CryptoJS.AES.encrypt(
          message,
          SecureStorageService.SECRET_KEY
        ).toString();
      },
      decrypt: (message: CryptoJS.lib.CipherParams | string) => {
        return CryptoJS.AES.decrypt(
          message,
          SecureStorageService.SECRET_KEY
        ).toString(CryptoJS.enc.Utf8);
      },
    });
  }
}
