import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { SessionService } from '../session.service';
import { User } from '../../../api/models/user';
import { Role } from '../../enums/role.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private sessionService: SessionService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.isAuthenticated()) {
      const user: User | undefined = this.sessionService.getCurrentUser();
      const roles: Role[] = route.data['roles'] ? route.data['roles'] : [];

      if (
        user &&
        roles.length > 0 &&
        !user.roles.some((r) => roles.includes(r))
      ) {
        this.router.navigate(['/forbidden']);

        return false;
      }

      return true;
    }

    this.router.navigate(['/login']);

    return false;
  }
}
