<div
  fxLayout="row"
  class="robot-light-container {{ state }}"
  [ngClass]="{ disabled: disabled === true }"
>
  <button
    mat-flat-button
    matTooltip="{{ state === ROBOT_LIGHT.OFF ? offText : onText }}"
    matTooltipDisabled="{{ state === undefined }}"
    [disabled]="disabled"
    (click)="onClick(state)"
  >
  <mat-icon *ngIf="state === ROBOT_LIGHT.OFF">flashlight_off</mat-icon>
  <mat-icon *ngIf="state === ROBOT_LIGHT.ON">flashlight_on</mat-icon>
  </button>
</div>
