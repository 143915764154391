<h1 mat-dialog-title>{{ title }}</h1>

<div mat-dialog-content>
  <p>{{ text }}</p>
</div>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <button mat-flat-button color="primary" (click)="onNoClick()">
    {{ 'no' | translate }}
  </button>
  <button mat-flat-button color="primary" (click)="onYesClick()">
    {{ 'yes' | translate }}
  </button>
</mat-dialog-actions>
