import {
  Directive,
  ElementRef,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { User } from '../../api/models/user';
import { Role } from '../enums/role.enum';
import { SessionService } from '../services/session.service';

@Directive({
  selector: '[hasRole]',
})
export class HasRoleDirective {
  /**
   * Utilisateur courant
   *
   * @private
   */
  private currentUser: User | undefined;

  /**
   * Liste des rôles
   *
   * @private
   */
  private roles: Role[] = [];

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.sessionService.getCurrentUser();
    this.updateView();
  }

  @Input()
  set hasRole(roles: Role[]) {
    this.roles = roles;
    this.updateView();
  }

  private updateView(): void {
    if (this.checkRole()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkRole(): boolean {
    let hasRole = false;

    if (this.currentUser && this.currentUser.roles) {
      for (const checkRole of this.roles) {
        const roleFound = this.currentUser.roles.find(
          (x) => x.toUpperCase() === checkRole.toUpperCase()
        );

        if (roleFound) {
          hasRole = true;
        }
      }
    }

    return hasRole;
  }
}
