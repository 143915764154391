import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { environment } from '../../../../environments/environment';
import { Strategy } from '../../../api/models/strategy';

@Injectable({
  providedIn: 'root',
})
export class StrategyService extends EntityService<Strategy> {
  public resourceUrl = environment.apiBaseUrl + 'strategies';
}
