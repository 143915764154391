import { Checkpoint } from './checkpoint';
import { Patrol } from './patrol';

export class PatrolCheckpoint extends Checkpoint {
  /**
   * Checkpoint
   */
  public checkpoint?: Checkpoint;

  /**
   * Position
   */
  public position?: number;

  /**
   * Durée
   */
  public duration?: number;

  /**
   * Orienté
   */
  public override oriented = false;

  /**
   * Sentinelle
   */
  public sentinel?: boolean;

  /**
   * Rapide
   */
  public fast?: boolean;

  /**
   * Groupe
   */
  public override group = '';

  /**
   * Patrouille
   */
  public patrol?: Patrol;

  constructor(checkpoint: Checkpoint) {
    super(checkpoint.pose);
    this.checkpoint = checkpoint;
  }
}
