<ng-template
  #weekViewHourSegmentTemplate
  let-segment="segment"
  let-locale="locale"
  let-segmentHeight="segmentHeight"
  let-isTimeLabel="isTimeLabel"
>
  <div
    #segmentElement
    class="cal-hour-segment"
    [style.height.px]="segmentHeight"
    [class.cal-hour-start]="segment.isStart"
    [class.cal-after-hour-start]="!segment.isStart"
    [ngClass]="segment.cssClass"
    (mousedown)="startDragToCreate(segment, $event, segmentElement)"
  >
    <div class="cal-time" *ngIf="isTimeLabel">
      {{ segment.date | calendarDate : 'weekViewHour' : locale }}
    </div>
  </div>
</ng-template>

<mwl-calendar-week-view
  [viewDate]="viewDate"
  [events]="events"
  [locale]="locale"
  [hourSegmentTemplate]="weekViewHourSegmentTemplate"
  [weekStartsOn]="weekStartsOn"
  [refresh]="refreshEvent"
  (eventTimesChanged)="eventTimesChanged($event)"
  [validateEventTimesChanged]="validateEventTimesChanged"
>
</mwl-calendar-week-view>
