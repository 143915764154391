import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { environment } from '../../../../environments/environment';
import { TriggerConf } from '../../../api/models/trigger-conf';

@Injectable({
  providedIn: 'root',
})
export class TriggerConfService extends EntityService<TriggerConf> {
  public resourceUrl = environment.apiBaseUrl + 'trigger_confs';
}
