import { Checkpoint } from '../../../api/models/checkpoint';
import { BackStatus } from '../../enums/back-status.enum';
import { Utils } from '../../utils/utils';
import { MapPoint } from '@lelab31/ngx-rodotic';
import { MapPointType } from '../../enums/map-point-type.enum';

export class CheckpointAdapter {
  /**
   * Uid
   */
  public uid: string | undefined;

  /**
   * Index
   */
  public index: number | undefined;

  /**
   * Point sur la carte
   */
  public mapPoint: MapPoint | undefined;

  /**
   * Indique si l'item point de contrôle est sauvegardé en BDD
   */
  public status: BackStatus = BackStatus.SAVED;

  /**
   * Indique si l'item peux être déplaçable
   */
  public draggable = true;

  /**
   * Indique si l'item est supprimé
   */
  public deleted = false;

  constructor(public checkpoint: Checkpoint, mapPoint: MapPoint | null = null) {
    this.uid = checkpoint.id ? checkpoint.id.toString() : Utils.uniqueId();

    if (checkpoint.pose && !mapPoint) {
      this.mapPoint = new MapPoint(
        checkpoint.pose,
        checkpoint.id ? checkpoint.id.toString() : null
      );
      this.mapPoint.group = MapPointType.CHECKPOINT;
      this.mapPoint.displayObject = Utils.createMapCheckpoint();
    } else if (mapPoint) {
      this.mapPoint = mapPoint;
    }
  }
}
