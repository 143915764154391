import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { EntityService } from './entity.service';
import { Customer } from '../../../api/models/customer';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends EntityService<Customer> {
  public resourceUrl = environment.apiBaseUrl + 'customers';
}
