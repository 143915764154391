import { BackStatus } from '../../enums/back-status.enum';
import { Patrol } from '../../../api/models/patrol';
import { PatrolCheckpointAdapter } from './patrol-checkpoint-adapter';
import { PatrolCheckpoint } from '../../../api/models/patrol-checkpoint';
import { Utils } from '../../utils/utils';

export class PatrolAdapter {
  /**
   * Identifiant
   */
  public uid: string;

  /**
   * Index
   */
  public index: number | undefined;

  /**
   * Indique si l'item patrouille est sauvegardé en BDD
   */
  public status: BackStatus = BackStatus.SAVED;

  /**
   * Indique si l'item peux être déplaçable
   */
  public draggable = true;

  /**
   * Indique si l'item est supprimé
   */
  public deleted = false;

  /**
   * Liste des points de patrouille
   */
  public patrolCheckpointAdapters: PatrolCheckpointAdapter[] = [];

  constructor(public patrol: Patrol) {
    this.uid = patrol.id ? patrol.id.toString() : Utils.uniqueId();
    this.patrolCheckpointAdapters = this.patrol.checkpoints.map(
      (patrolCheckpoint: PatrolCheckpoint) =>
        new PatrolCheckpointAdapter(patrolCheckpoint, this.uid)
    );
  }

  public addPatrolCheckpointAdapter(
    patrolCheckpointAdapter: PatrolCheckpointAdapter
  ) {
    if (patrolCheckpointAdapter.patrolCheckpoint.position) {
      this.patrolCheckpointAdapters.splice(
        patrolCheckpointAdapter.patrolCheckpoint.position - 1,
        0,
        patrolCheckpointAdapter
      );
      this.patrol.checkpoints.splice(
        patrolCheckpointAdapter.patrolCheckpoint.position - 1,
        0,
        patrolCheckpointAdapter.patrolCheckpoint
      );
      this.refreshPosition();
    }
  }

  public removePatrolCheckpointAdapter(
    patrolCheckpointAdapter: PatrolCheckpointAdapter
  ) {
    if (patrolCheckpointAdapter.patrolCheckpoint.position) {
      this.patrolCheckpointAdapters.splice(
        patrolCheckpointAdapter.patrolCheckpoint.position - 1,
        1
      );        
      this.patrol.checkpoints.splice(
        patrolCheckpointAdapter.patrolCheckpoint.position - 1,
        1
      );      
      this.refreshPosition();
    }
  }
  
  private refreshPosition(): void {
    this.patrol.checkpoints.map(
      (patrolCheckpoint: PatrolCheckpoint, index: number) =>
        (patrolCheckpoint.position = index + 1)
    );
    this.patrolCheckpointAdapters.map(
      (patrolCheckpointAdapter: PatrolCheckpointAdapter, index: number) =>
        (patrolCheckpointAdapter.index = index)
    );
  }
}
