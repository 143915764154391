import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Zone } from '../../../../api/models/zone';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { IEntity } from '../../../../components/admin/models/i-entity';
import { EntityFormComponent } from '../../../../components/admin/entity-form/entity-form.component';
import { ZoneService } from '../../../../core/services/entities/zone.service';
import { NotificationService } from '../../../../core/services/notification.service';
import { Robot } from '../../../../api/models/robot';
import { RobotService } from '../../../../core/services/entities/robot.service';
import { UserGroup } from '../../../../api/models/user-group';
import { UserGroupService } from '../../../../core/services/entities/user-group.service';
import { SessionService } from '../../../../core/services/session.service';

@Component({
  selector: 'webclient-zone-form',
  templateUrl: './zone-form.component.html',
  styleUrls: ['./zone-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ZoneFormComponent extends EntityFormComponent implements OnInit {
  /**
   * Liste des robots
   */
  @Input() public robots: Robot[] = [];

  /**
   * Zone
   */
  public zone: Zone | undefined;

  /**
   * Groupes d'tilisateurs
   */
  public userGroups: UserGroup[] = [];

  /**
   * Messages d'erreur
   */
  public validationMessages = {
    name: [
      { type: 'required', message: 'zone.validationMessages.name.required' },
    ],
    userGroups: [
      {
        type: 'requireOne',
        message: 'zone.validationMessages.userGroups.requireOne',
      },
    ],
  };

  constructor(
    protected override route: ActivatedRoute,
    protected override sessionService: SessionService,
    private zoneService: ZoneService,
    private robotService: RobotService,
    private userGroupService: UserGroupService,
    private formBuilder: FormBuilder,
    protected override translateService: TranslateService,
    protected override notificationService: NotificationService,
    protected override location: Location
  ) {
    super(
      route,
      sessionService,
      translateService,
      notificationService,
      location
    );

    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      robots: [[], []],
      userGroups: [[], []],
    });

    if (this.id) {
      this.zoneService.getById(this.id).subscribe((zone: Zone) => {
        this.zone = zone;
      });
    }
  }

  ngOnInit(): void {
    this.robotService.getAll().subscribe((robots: Robot[]) => {
      this.robots = robots;
    });
    this.userGroupService.getAll().subscribe((userGroups: UserGroup[]) => {
      this.userGroups = userGroups;
    });
  }

  /**
   * Création d'une zone
   *
   * @param entity la zone à créer
   */
  public onCreate(entity: IEntity) {
    const zone: Zone = entity as Zone;
    zone.customer = this.sessionService.getCurrentUser()?.customer;

    this.zoneService.create(zone).subscribe(
      (zone: Zone) => {
        this.displaySuccessSnackBar(zone.name, 'zone.createSuccessMessage');
      },
      (error) => {
        this.displayErrorSnackBar(zone.name, 'zone.createErrorMessage');
      }
    );
  }

  /**
   * Edition d'une zone
   *
   * @param entity la zone à éditer
   */
  public onEdit(entity: IEntity) {
    const zone: Zone = entity as Zone;

    this.zoneService.update(this.id, zone).subscribe(
      (zone: Zone) => {
        this.displaySuccessSnackBar(zone.name, 'zone.editSuccessMessage');
      },
      (error) => {
        this.displayErrorSnackBar(zone.name, 'zone.editErrorMessage');
      }
    );
  }
}
