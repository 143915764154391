<h1 mat-dialog-title>{{ 'strategy.selectScheduled' | translate }}</h1>

<mat-dialog-content class="mat-typography" fxLayout="column" fxLayoutGap="30px">
  <div>
    <mat-radio-group fxLayout="column" [(ngModel)]="selectedStrategyAdapter">
      <mat-radio-button
        *ngFor="let strategyAdapter of strategyAdapters"
        [value]="strategyAdapter"
      >
        {{ strategyAdapter.strategy.name }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <mat-divider></mat-divider>

  <div fxLayout="row" fxLayoutGap="30px" class="times">
    <div>
      <mat-label> {{ 'timeslot.startDate' | translate }} </mat-label>
      <ngx-timepicker-field
        [format]="24"
        [defaultTime]="start"
        (timeChanged)="onStartChanged($event)"
      ></ngx-timepicker-field>
    </div>

    <div>
      <mat-label> {{ 'timeslot.endDate' | translate }} </mat-label>
      <ngx-timepicker-field
        [format]="24"
        [defaultTime]="end"
        (timeChanged)="onEndChanged($event)"
      ></ngx-timepicker-field>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <button mat-flat-button color="primary" (click)="onCancelClick()">
    {{ 'cancel' | translate }}
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="onOkClick()"
    [disabled]="selectedStrategyAdapter === undefined"
  >
    {{ 'select' | translate }}
  </button>
</mat-dialog-actions>
