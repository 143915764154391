<div
  fxLayout="row"
  class="robot-videoplay-container {{ state }}"
  [ngClass]="{ disabled: disabled === true }"
>
  <button
    mat-flat-button
    matTooltip="{{ state === false ? offText : onText }}"
    matTooltipDisabled="{{ state === undefined }}"
    [disabled]="disabled"
    (click)="onClick(state)"
  >
    <mat-icon *ngIf="state === false">videocam_off</mat-icon>
    <mat-icon *ngIf="state === true">videocam_on</mat-icon>
  </button>
</div>
