<mat-toolbar fxLayout="row" fxLayoutAlign="center center">
  <div
    fxFlex
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="viewer-control-wrapper"
  >
    <div>
      <mat-button-toggle-group>
        <mat-button-toggle (click)="onChangVisibility()">
          <mat-icon>visibility</mat-icon>
        </mat-button-toggle>

        <mat-button-toggle (click)="onChangeNicheMode()">
          <mat-icon>flight_class</mat-icon>
        </mat-button-toggle>

        <mat-button-toggle (click)="onChangeCheckpointMode()">
          <mat-icon>location_on</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div>
      <!-- Lien vers la page live du robot -->
      <button
        mat-mini-fab
        color="accent"
        [routerLink]="['/pages/robot-viewer', robotAdapter?.robot?.id]"
      >
        <mat-icon>remove_red_eye</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
