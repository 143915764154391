import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZoneAdminRoutingModule } from './zone-admin-routing.module';
import { ZoneFormComponent } from './zone-form/zone-form.component';
import { ZoneListComponent } from './zone-list/zone-list.component';
import { ComponentsModule } from '../../../components/components.module';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { MatBadgeModule } from '@angular/material/badge';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  declarations: [ZoneFormComponent, ZoneListComponent],
  imports: [
    CommonModule,
    ZoneAdminRoutingModule,
    ComponentsModule,
    MatTableModule,
    TranslateModule,
    MatBadgeModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FlexModule,
  ],
})
export class ZoneAdminModule {}
