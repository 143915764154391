import { Injectable } from '@angular/core';
import { CheckpointAdapter } from '../models/entities/checkpoint-adapater';
import { PatrolAdapter } from '../models/entities/patrol-adapter';
import { StrategyAdapter } from '../models/entities/strategy-adapter';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { MapConfigurationAdapter } from '../models/entities/map-configuration-adapter';
import { PatrolCheckpointAdapter } from '../models/entities/patrol-checkpoint-adapter';
import { BackStatus } from '../enums/back-status.enum';
import { Strategy } from '../../api/models/strategy';
import { StrategyService } from './entities/strategy.service';
import { catchError, map } from 'rxjs/operators';
import { MapConfigurationService } from './entities/map-configuration.service';
import { MapConfiguration } from '../../api/models/map-configuration';
import { CheckpointService } from './entities/checkpoint.service';
import { Checkpoint } from '../../api/models/checkpoint';
import { PatrolService } from './entities/patrol.service';
import { Patrol } from '../../api/models/patrol';
import { TimeslotAdapter } from '../models/entities/timeslot-adapater';
import { TimeslotService } from './entities/timeslot.service';
import { Timeslot } from '../../api/models/timeslot';
import { PatrolCheckpoint } from '../../api/models/patrol-checkpoint';

@Injectable({
  providedIn: 'root'
})
export class MapSettingsService {
  /**
   * Liste des configurations
   */
  private mapConfigurationsSource$: Subject<MapConfigurationAdapter[]>;
  public mapConfigurations$: Observable<MapConfigurationAdapter[]>;

  /**
   * Changement d'un point de contrôle
   */
  private checkpointSource$: BehaviorSubject<CheckpointAdapter | null>;
  public checkpoint$: Observable<CheckpointAdapter | null>;

  /**
   * Changement d'une stratégie
   */
  private strategySource$: BehaviorSubject<StrategyAdapter | null>;
  public strategy$: Observable<StrategyAdapter | null>;

  /**
   * Changement d'une configuration
   */
  private mapConfigurationSource$: BehaviorSubject<MapConfigurationAdapter | null>;
  public mapConfiguration$: Observable<MapConfigurationAdapter | null>;

  /**
   * Configuration sélectionnée
   */
  private selectedMapConfigurationSource$: BehaviorSubject<MapConfigurationAdapter | null>;
  public selectedMapConfiguration$: Observable<MapConfigurationAdapter | null>;

  /**
   * Patrouille sélectionnée
   */
  private selectedPatrolSource$: BehaviorSubject<PatrolAdapter | null>;
  public selectedPatrol$: Observable<PatrolAdapter | null>;

  public mapConfigurationAdapter: MapConfigurationAdapter | undefined;

  /**
   * Configuration sélectionnée
   */
  private strategyModeSource$: BehaviorSubject<StrategyAdapter | null>;
  public strategyMode$: Observable<StrategyAdapter | null>;

  constructor(
    private mapConfigurationService: MapConfigurationService,
    private checkpointService: CheckpointService,
    private patrolService: PatrolService,
    private strategyService: StrategyService,
    private timeslotService: TimeslotService
  ) {
    this.mapConfigurationsSource$ = new Subject<MapConfigurationAdapter[]>();
    this.mapConfigurations$ = this.mapConfigurationsSource$.asObservable();
    this.checkpointSource$ = new BehaviorSubject<CheckpointAdapter | null>(
      null
    );
    this.checkpoint$ = this.checkpointSource$.asObservable();
    this.strategySource$ = new BehaviorSubject<StrategyAdapter | null>(null);
    this.strategy$ = this.strategySource$.asObservable();
    this.mapConfigurationSource$ =
      new BehaviorSubject<MapConfigurationAdapter | null>(null);
    this.mapConfiguration$ = this.mapConfigurationSource$.asObservable();
    this.selectedMapConfigurationSource$ =
      new BehaviorSubject<MapConfigurationAdapter | null>(null);
    this.selectedMapConfiguration$ =
      this.selectedMapConfigurationSource$.asObservable();
    this.selectedPatrolSource$ = new BehaviorSubject<PatrolAdapter | null>(
      null
    );
    this.selectedPatrol$ = this.selectedPatrolSource$.asObservable();
    this.strategyModeSource$ = new BehaviorSubject<StrategyAdapter | null>(
      null
    );
    this.strategyMode$ = this.strategyModeSource$.asObservable();
  }

  /**
   * Mise à jour des points de contrôle
   *
   * Si on passe un point de contrôle qui n'a pas d'identifiant, on cré ce point de contrôle et on ajoute ce point de contrôle à la configuration de la carte
   * Si on passe un point de contrôle qui a un identifiant, on met à jour ce point de contrôle et on met à jour ce point de contrôle dans la configuration de la carte
   * Si on passe un point de contrôle qui a la propriété deleted, on supprime ce point de contrôle et on met à jour la liste des points de contrôle dans la configuration de la carte
   *
   * Sinon on déclenche une erreur
   *
   * @param checkpointAdapters le point de contrôle ou la liste des points de contrôle
   */
  public updateCheckpoints(
    ...checkpointAdapters: CheckpointAdapter[]
  ): Observable<CheckpointAdapter | CheckpointAdapter[]> {
    checkpointAdapters.map(
      (checkpointAdapter: CheckpointAdapter) =>
        (checkpointAdapter.status = BackStatus.PENDING)
    );

    if (
      this.mapConfigurationAdapter &&
      checkpointAdapters.length === 1 &&
      !checkpointAdapters[0].checkpoint.id &&
      !checkpointAdapters[0].deleted
    ) {
      checkpointAdapters[0].checkpoint.mapConfiguration =
        this.mapConfigurationAdapter?.mapConfiguration;

      return this.checkpointService
        .create(checkpointAdapters[0].checkpoint)
        .pipe(
          map((checkpoint: Checkpoint) => {
            if (this.mapConfigurationAdapter) {
              this.mapConfigurationAdapter.mapConfiguration.checkpoints?.push(
                checkpoint
              );
            }

            checkpointAdapters[0].status = BackStatus.SAVED;
            checkpointAdapters[0].checkpoint = checkpoint;

            return checkpointAdapters[0];
          })
        );
    } else if (
      this.mapConfigurationAdapter &&
      checkpointAdapters.length === 1 &&
      checkpointAdapters[0].checkpoint.id &&
      !checkpointAdapters[0].deleted
    ) {
      return this.checkpointService
        .update(
          checkpointAdapters[0].checkpoint.id,
          checkpointAdapters[0].checkpoint
        )
        .pipe(
          map((checkpoint: Checkpoint) => {
            if (
              this.mapConfigurationAdapter &&
              this.mapConfigurationAdapter.mapConfiguration.checkpoints
            ) {
              this.mapConfigurationAdapter.mapConfiguration.checkpoints.map(
                (c: Checkpoint) => (c.id === checkpoint.id ? checkpoint : c)
              );
              checkpointAdapters[0].status = BackStatus.SAVED;
            }

            return checkpointAdapters[0];
          })
        );
    } else if (
      this.mapConfigurationAdapter &&
      checkpointAdapters.length === 1 &&
      checkpointAdapters[0].checkpoint.id &&
      checkpointAdapters[0].deleted
    ) {
      return this.checkpointService
        .delete(checkpointAdapters[0].checkpoint.id)
        .pipe(
          map(() => {
            if (
              this.mapConfigurationAdapter &&
              this.mapConfigurationAdapter.mapConfiguration.checkpoints
            ) {
              this.mapConfigurationAdapter.mapConfiguration.checkpoints =
                this.mapConfigurationAdapter.mapConfiguration.checkpoints.filter(
                  (c: Checkpoint) =>
                    c.id !== checkpointAdapters[0].checkpoint.id
                );
            }

            return [];
          })
        );
    }

    return throwError(() => 'error');
  }

  /**
   * Mise à jour des patrouilles
   *
   * Si on passe une patrouille qui n'a pas d'identifiant, on cré cette patrouille et on ajoute cette patrouille à la configuration de la carte
   * Si on passe une patrouille qui a un identifiant, on met à jour cette patrouille et on met à jour cette patrouille dans la configuration de la carte
   * Si on passe une patrouille qui a la propriété deleted, on supprime cette patrouille et on met à jour la liste des patrouilles dans la configuration de la carte
   *
   * Sinon on déclenche une erreur
   *
   * @param patrolAdapters la patrouille ou la liste des patrouilles
   */
  public updatePatrols(
    ...patrolAdapters: PatrolAdapter[]
  ): Observable<PatrolAdapter | PatrolAdapter[]> {
    patrolAdapters.map(
      (patrolAdapter: PatrolAdapter) =>
        (patrolAdapter.status = BackStatus.PENDING)
    );

    if (
      this.mapConfigurationAdapter &&
      patrolAdapters.length === 1 &&
      !patrolAdapters[0].patrol.id &&
      !patrolAdapters[0].deleted
    ) {
      patrolAdapters[0].patrol.mapConfiguration =
        this.mapConfigurationAdapter?.mapConfiguration;

      return this.patrolService.create(patrolAdapters[0].patrol).pipe(
        map((patrol: Patrol) => {
          if (this.mapConfigurationAdapter) {
            this.mapConfigurationAdapter.mapConfiguration.patrols?.push(patrol);
          }

          patrolAdapters[0].status = BackStatus.SAVED;
          patrolAdapters[0].patrol = patrol;

          return patrolAdapters[0];
        })
      );
    } else if (
      this.mapConfigurationAdapter &&
      patrolAdapters.length === 1 &&
      patrolAdapters[0].patrol.id &&
      !patrolAdapters[0].deleted
    ) {
      return this.patrolService
        .update(patrolAdapters[0].patrol.id, patrolAdapters[0].patrol)
        .pipe(
          map((patrol: Patrol) => {
            if (
              this.mapConfigurationAdapter &&
              this.mapConfigurationAdapter.mapConfiguration.patrols
            ) {
              const index: number =
                this.mapConfigurationAdapter.mapConfiguration.patrols.findIndex(
                  (p: Patrol) => p.id === patrol.id
                );

              if (index !== -1) {
                this.mapConfigurationAdapter.mapConfiguration.patrols[index] =
                  patrol;
              }

              patrolAdapters[0].patrol = patrol;
              patrolAdapters[0].status = BackStatus.SAVED;

              patrolAdapters[0].patrol.checkpoints.forEach(
                (checkpoint: PatrolCheckpoint, index: number) => {
                  patrolAdapters[0].patrolCheckpointAdapters.forEach(
                    (patrolCheckpointAdapter: PatrolCheckpointAdapter) => {
                      if (
                        Number(patrolCheckpointAdapter.uid) === checkpoint.id
                      ) {
                        checkpoint = patrolCheckpointAdapter.patrolCheckpoint;
                        patrolAdapters[0].patrol.checkpoints[index] =
                          patrolCheckpointAdapter.patrolCheckpoint;
                      }
                    }
                  );
                }
              );
            }

            return patrolAdapters[0];
          })
        );
    } else if (
      this.mapConfigurationAdapter &&
      patrolAdapters.length === 1 &&
      patrolAdapters[0].patrol.id &&
      patrolAdapters[0].deleted
    ) {
      return this.patrolService.delete(patrolAdapters[0].patrol.id).pipe(
        map(() => {
          if (
            this.mapConfigurationAdapter &&
            this.mapConfigurationAdapter.mapConfiguration.patrols
          ) {
            this.mapConfigurationAdapter.mapConfiguration.patrols =
              this.mapConfigurationAdapter.mapConfiguration.patrols.filter(
                (p: Patrol) => p.id !== patrolAdapters[0].patrol.id
              );
          }

          return [];
        })
      );
    }

    return throwError(() => 'error');
  }

  /**
   * Mise à jour des stratégies
   *
   * Si on passe une stratégie qui n'a pas d'identifiant, on cré cette stratégie et on ajoute cette stratégie à la configuration de la carte
   * Si on passe une stratégie qui a un identifiant, on met à jour cette stratégie et on met à jour cette stratégie dans la configuration de la carte
   * Si on passe une liste de stratégies, on met à jour les stratégies via le service de configuration de la carte
   * Si on passe une stratégie qui a la propriété deleted, on supprime cette stratégie dans la liste des stratégies de la configuration de la carte et on met à jour cette stratégie dans la configuration de la carte
   *
   * Sinon on déclenche une erreur
   *
   * @param strategyAdapters la stratégie ou la liste des stratégies
   */
  public updateStrategies(
    ...strategyAdapters: StrategyAdapter[]
  ): Observable<StrategyAdapter | StrategyAdapter[]> {
    strategyAdapters.map(
      (strategyAdapter: StrategyAdapter) =>
        (strategyAdapter.status = BackStatus.PENDING)
    );

    if (
      this.mapConfigurationAdapter &&
      strategyAdapters.length === 1 &&
      !strategyAdapters[0].strategy.id &&
      !strategyAdapters[0].deleted
    ) {
      strategyAdapters[0].strategy.mapConfiguration =
        this.mapConfigurationAdapter?.mapConfiguration;

      return this.strategyService.create(strategyAdapters[0].strategy).pipe(
        map((strategy: Strategy) => {
          if (this.mapConfigurationAdapter) {
            this.mapConfigurationAdapter.mapConfiguration.strategies?.push(
              strategy
            );
          }

          strategyAdapters[0].status = BackStatus.SAVED;
          strategyAdapters[0].strategy = strategy;

          return strategyAdapters[0];
        })
      );
    } else if (
      this.mapConfigurationAdapter &&
      strategyAdapters.length === 1 &&
      strategyAdapters[0].strategy.id &&
      !strategyAdapters[0].deleted
    ) {
      return this.strategyService
        .update(strategyAdapters[0].strategy.id, strategyAdapters[0].strategy)
        .pipe(
          map((strategy: Strategy) => {
            if (
              this.mapConfigurationAdapter &&
              this.mapConfigurationAdapter.mapConfiguration.strategies
            ) {
              this.mapConfigurationAdapter.mapConfiguration.strategies.map(
                (s: Strategy) => (s.id === strategy.id ? strategy : s)
              );
              strategyAdapters[0].status = BackStatus.SAVED;
            }
            strategyAdapters[0].strategy = strategy;

            if (this.mapConfigurationAdapter?.mapConfiguration) {
              if (!this.mapConfigurationAdapter.mapConfiguration.strategies) {
                this.mapConfigurationAdapter.mapConfiguration.strategies = [strategy];
              } else {
                const strategyIndex = this.mapConfigurationAdapter.mapConfiguration.strategies?.findIndex((mapConfigurationStrategy) =>
                  mapConfigurationStrategy.id === strategy.id);
                if (strategyIndex) {
                  this.mapConfigurationAdapter.mapConfiguration.strategies[strategyIndex] = strategy;
                } else {
                  this.mapConfigurationAdapter.mapConfiguration.strategies.push(strategy);
                }
              }
            }

            return strategyAdapters[0];
          })
        );
    } else if (
      this.mapConfigurationAdapter &&
      this.mapConfigurationAdapter.mapConfiguration.strategies
    ) {
      const strategies: Strategy[] = [
        ...this.mapConfigurationAdapter.mapConfiguration.strategies
      ];
      const deleted: Strategy[] = strategyAdapters
        .filter(
          (strategyAdapter: StrategyAdapter) => strategyAdapter.deleted === true
        )
        .map((strategyAdapter: StrategyAdapter) => strategyAdapter.strategy);

      if (deleted.length > 0) {
        this.mapConfigurationAdapter.mapConfiguration.strategies =
          this.mapConfigurationAdapter.mapConfiguration.strategies
            .filter((strategy) => !deleted.includes(strategy))
            .map((strategy: Strategy, index: number) => {
              strategy.position = index + 1;

              return strategy;
            });
      } else {
        this.mapConfigurationAdapter.mapConfiguration.strategies =
          strategyAdapters.map(
            (strategyAdapter: StrategyAdapter) => strategyAdapter.strategy
          );
      }

      return this.mapConfigurationService
        .update(
          this.mapConfigurationAdapter.mapConfiguration.id,
          this.mapConfigurationAdapter.mapConfiguration
        )
        .pipe(
          map((mapConfiguration: MapConfiguration) => {
            strategyAdapters.map(
              (strategyAdapter: StrategyAdapter) =>
                (strategyAdapter.status = BackStatus.SAVED)
            );

            if (
              deleted.length > 0 &&
              mapConfiguration.strategies &&
              this.mapConfigurationAdapter
            ) {
              this.mapConfigurationAdapter.mapConfiguration.strategies =
                mapConfiguration.strategies;

              return mapConfiguration.strategies.map(
                (strategy: Strategy) => new StrategyAdapter(strategy)
              );
            } else if (
              deleted.length === 0 &&
              mapConfiguration.strategies &&
              this.mapConfigurationAdapter
            ) {
              this.mapConfigurationAdapter.mapConfiguration.strategies =
                mapConfiguration.strategies;

              return mapConfiguration.strategies.map(
                (strategy: Strategy) => new StrategyAdapter(strategy)
              );
            } else {
              return [];
            }
          }),
          catchError((error) => {
            if (this.mapConfigurationAdapter) {
              this.mapConfigurationAdapter.mapConfiguration.strategies =
                strategies;
            }

            return throwError(() => error);
          })
        );
    }

    return throwError(() => 'error');
  }

  /**
   * Mise à jour des créneaux horaire
   *
   * Si on passe un créneau horaire qui a un identifiant, on met à jour ce créneau horaire et on met à jour ce créneau horaire dans la configuration de la carte
   * Si on passe un créneau horaire qui a la propriété deleted, on supprime ce créneau horaire dans la liste des créneaux horaire de la stratégie de la configuration de la carte
   * Si on passe un créneau horaire qui n'a pas d'identifiant, on cré ce créneau horaire et on ajoute ce créneau horaire à la stratégie de la configuration de la carte
   *
   * @param timeslotAdapter le créneau horaire
   * @param strategyAdapter la stratégie à laquelle appartient le créneau horaire
   */
  public updateTimeslots(
    timeslotAdapter: TimeslotAdapter,
    strategyAdapter: StrategyAdapter | null = null
  ): Observable<TimeslotAdapter | void> {
    timeslotAdapter.status = BackStatus.PENDING;

    if (timeslotAdapter.timeslot.id && !timeslotAdapter.deleted) {
      return this.timeslotService
        .update(timeslotAdapter.timeslot.id, timeslotAdapter.timeslot)
        .pipe(
          map((timeslot: Timeslot) => {
            if (timeslot.strategy && timeslot.strategy.id) {
              this.mapConfigurationAdapter?.mapConfiguration.strategies
                ?.filter((s: Strategy) => s.id === timeslot.strategy?.id)
                .map((s: Strategy) =>
                  s.timeslots.map((t) => {
                    if (t.id === timeslot.id) {
                      t.startDate = timeslot.startDate;
                      t.endDate = timeslot.endDate;
                    }
                  })
                );
              timeslotAdapter.timeslot = timeslot;
            }

            timeslotAdapter.status = BackStatus.SAVED;

            return timeslotAdapter;
          })
        );
    } else if (timeslotAdapter.timeslot.id && timeslotAdapter.deleted) {
      return this.timeslotService.delete(timeslotAdapter.timeslot.id).pipe(
        map(() => {
          if (
            timeslotAdapter.timeslot.strategy &&
            timeslotAdapter.timeslot.strategy.id
          ) {
            const id = timeslotAdapter.timeslot.strategy.id;
            const strategy: Strategy | undefined =
              this.mapConfigurationAdapter?.mapConfiguration.strategies?.find(
                (s) => s.id === id
              );
            const indexTimeslot: number | undefined =
              strategy?.timeslots.findIndex(
                (s) => s.id === timeslotAdapter.timeslot.id
              );

            if (indexTimeslot) {
              this.mapConfigurationAdapter?.mapConfiguration.strategies
                ?.filter(
                  (strategy: Strategy) =>
                    strategy.id === timeslotAdapter.timeslot.strategy?.id
                )
                .map((strategy: Strategy) =>
                  strategy.timeslots.splice(indexTimeslot, 1)
                );
            }
          }
        })
      );
    } else if (strategyAdapter) {
      return this.timeslotService.create(timeslotAdapter.timeslot).pipe(
        map((timeslot: Timeslot) => {
          timeslotAdapter.timeslot = timeslot;
          timeslotAdapter.id = timeslot.id;
          timeslotAdapter.status = BackStatus.SAVED;

          strategyAdapter.strategy.timeslots.push(timeslot);

          return timeslotAdapter;
        })
      );
    }

    return throwError(() => 'error');
  }

  public updateConfigurations(
    mapConfigurationAdapters: MapConfigurationAdapter[]
  ): void {
    this.mapConfigurationsSource$.next(mapConfigurationAdapters);
  }

  public updateCheckpoint(checkpointAdapter: CheckpointAdapter): void {
    this.checkpointSource$.next(checkpointAdapter);
  }

  public updateConfiguration(
    mapConfigurationAdapter: MapConfigurationAdapter
  ): void {
    this.mapConfigurationAdapter = mapConfigurationAdapter;
    this.mapConfigurationSource$.next(mapConfigurationAdapter);
  }

  public updateStrategy(strategyAdapter: StrategyAdapter): void {
    this.strategySource$.next(strategyAdapter);
  }

  public updateSelectedPatrol(patrolAdapter: PatrolAdapter | null): void {
    this.selectedPatrolSource$.next(patrolAdapter);
  }

  public changeStrategyMode(strategyAdapter: StrategyAdapter | null): void {
    this.strategyModeSource$.next(strategyAdapter);
  }
}
