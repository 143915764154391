import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { Map } from '../../../api/models/map';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { MapStatus } from '../../enums/map-status.enum';

@Injectable({
  providedIn: 'root',
})
export class MapService extends EntityService<Map> {
  public resourceUrl = environment.apiBaseUrl + 'maps';

  /**
   * Changement du statut de la carte
   *
   * @param id identifiant de la carte
   * @param map la carte
   * @param status le statut de la carte
   */
  public changeStatus(
    id: number,
    map: Map,
    status: MapStatus
  ): Observable<Map> {
    const updatedMap: Map = { ...map };
    updatedMap.status = status;

    return this.update(id, updatedMap);
  }
}
