import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityListComponent } from './entity-list/entity-list.component';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { CardModule } from '../card/card.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { EntityFormComponent } from './entity-form/entity-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../../core/core.module';
import { EntityNestedListComponent } from './entity-nested-list/entity-nested-list.component';
import { BaseEntityListComponent } from './base-entity-list/base-entity-list.component';

@NgModule({
  declarations: [
    BaseEntityListComponent,
    EntityListComponent,
    EntityNestedListComponent,
    EntityFormComponent,
  ],
  exports: [
    EntityListComponent,
    EntityNestedListComponent,
    EntityFormComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    MatCardModule,
    MatDividerModule,
    CardModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    CoreModule,
  ],
})
export class AdminModule {}
