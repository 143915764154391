import { PatrolCheckpointDto } from './patrol-checkpoint-dto';
import { TimeslotDto } from './timeslot-dto';


export class StrategyDTO {
  /**
   * Identifiant de la stratégie
   */
  public id: number | undefined;

  /**
   * Déclencheur
   */
  public BIE_input0?: boolean;

  /**
   * Etat d'activation
   */
  public state_on0?: boolean;

  /**
   * Etat monostable
   */
  public monostable0?: boolean;

  /**
   * Déclencheur
   */
  public BIE_input1?: boolean;

  /**
   * Etat d'activation
   */
  public state_on1?: boolean;

  /**
   * Etat monostable
   */
  public monostable1?: boolean;

  /**
   * Déclencheur
   */
  public BIE_input2?: boolean;

  /**
   * Etat d'activation
   */
  public state_on2?: boolean;

  /**
   * Etat monostable
   */
  public monostable2?: boolean;

  /**
   * Déclencheur
   */
  public BIE_input3?: boolean;

  public activator_list?: StrategyActivatorDto[];

  /**
   * Etat d'activation
   */
  public state_on3?: boolean;

  /**
   * Etat monostable
   */
  public monostable3?: boolean;

  /**
   * Position
   */
  public web_order: boolean | undefined;

  /**
   * Mode plannifié ou déclenché
   */
  public plannified?: boolean;

  /**
   * Durée de patrouille
   */
  public patrol_duration: number | undefined;

  /**
   * Durée de pause
   */
  public pause_duration: number | undefined;

  /**
   * Nombre de rotation
   */
  public cycles: number | undefined;

  /**
   * Mode aléatoire
   */
  public random: boolean | undefined;

  /**
   * Liste des points de patrouilles
   */
  public patrol_list: PatrolCheckpointDto[] = [];

  /**
   * Liste des timeslots
   */
  public timeslot_list: TimeslotDto[] = [];

  /**
   * Premier point de patrouille
   */
  public starting_point: number | undefined;

  /**
   * Type
   */
  public type: number | undefined;

  public patrol_size?: any;

  public priority_index?: any;
}


export interface StrategyActivatorDto {
  type: number,
  id: string,
  enable: boolean,
  active_on: boolean,
  monostable: boolean,
}
