import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { environment } from '../../../../environments/environment';
import { Trigger } from '../../../api/models/trigger';

@Injectable({
  providedIn: 'root',
})
export class TriggerService extends EntityService<Trigger> {
  public resourceUrl = environment.apiBaseUrl + 'triggers';
}
