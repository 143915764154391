<div
  fxLayout="row"
  class="robot-arming-container  {{ state }}"
  [ngClass]="{ disabled: disabled === true }"
>
  <button
    mat-flat-button
    matTooltip="{{ state === false ? offText : onText }}"
    matTooltipDisabled="{{ state === undefined }}"
    [disabled]="disabled"
    (click)="onClick(state)"
  >
    <mat-icon *ngIf="state === false">vpn_key_off</mat-icon>
    <mat-icon *ngIf="state === true">vpn_key</mat-icon>
  </button>
</div>