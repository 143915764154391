<div fxLayout="row" fxFlex>
  <div fxFlex="45px" class="webclient-robot-logger-buttons">
    <button mat-icon-button (click)="onClear()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <div
    fxFlex
    #scrollMe
    [scrollTop]="scrollMe.scrollHeight"
    class="webclient-robot-logger-logs"
  >
    <ul *ngIf="robotAdapter">
      <li
        *ngFor="let log of robotAdapter.logs"
        class="{{ log.state | lowercase }}"
      >
        {{ log.date | date : 'dd-MM-YYYY h:mm:ss' }} >>
        {{ log.text | translate : log.parameters }}
      </li>
    </ul>
  </div>
</div>
