import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RobotListComponent } from './robot-list/robot-list.component';
import { RobotFormComponent } from './robot-form/robot-form.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list',
        component: RobotListComponent,
      },
      {
        path: 'form',
        component: RobotFormComponent,
      },
      {
        path: 'form/:id',
        component: RobotFormComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RobotAdminRoutingModule {}
