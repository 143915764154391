import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MainLayoutComponent } from '../theme/layouts/main-layout/main-layout.component';
import { RobotViewerComponent } from './robot-viewer/robot-viewer.component';
import { MapListComponent } from './map-list/map-list.component';
import { MapSettingsComponent } from './map-settings/map-settings.component';
import { AuthGuardService } from '../core/services/security/auth-guard.service';
import { RobotHomeComponent } from './robot-home/robot-home.component';
import { Role } from '../core/enums/role.enum';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'robot-home/:id',
        component: RobotHomeComponent,
      },
      {
        path: 'robot-viewer/:robotId',
        component: RobotViewerComponent,
      },
      {
        path: 'map-list/:zoneId/:robotId',
        component: MapListComponent,
      },
      {
        path: 'map-settings/:id/:robotId',
        component: MapSettingsComponent,
      },
      {
        path: 'admin/robot',
        loadChildren: () =>
          import('./admin/robot-admin/robot-admin.module').then(
            (m) => m.RobotAdminModule
          ),
        canActivate: [AuthGuardService],
        data: { roles: [Role.ROLE_SUPER_ADMIN, Role.ROLE_ADMIN] },
      },
      {
        path: 'admin/trigger',
        loadChildren: () =>
          import('./admin/trigger-admin/trigger-admin.module').then(
            (m) => m.TriggerAdminModule
          ),
        canActivate: [AuthGuardService],
        data: { roles: [Role.ROLE_SUPER_ADMIN] },
      },
      {
        path: 'admin/customer',
        loadChildren: () =>
          import('./admin/customer-admin/customer-admin.module').then(
            (m) => m.CustomerAdminModule
          ),
        canActivate: [AuthGuardService],
        data: { roles: [Role.ROLE_SUPER_ADMIN, Role.ROLE_ADMIN] },
      },
      {
        path: 'admin/user',
        loadChildren: () =>
          import('./admin/user-admin/user-admin.module').then(
            (m) => m.UserAdminModule
          ),
        canActivate: [AuthGuardService],
        data: { roles: [Role.ROLE_SUPER_ADMIN, Role.ROLE_ADMIN] },
      },
      {
        path: 'admin/usergroup',
        loadChildren: () =>
          import('./admin/user-group-admin/user-group-admin.module').then(
            (m) => m.UserGroupAdminModule
          ),
        canActivate: [AuthGuardService],
        data: { roles: [Role.ROLE_SUPER_ADMIN, Role.ROLE_ADMIN] },
      },
      {
        path: 'admin/zone',
        loadChildren: () =>
          import('./admin/zone-admin/zone-admin.module').then(
            (m) => m.ZoneAdminModule
          ),
        canActivate: [AuthGuardService],
        data: { roles: [Role.ROLE_SUPER_ADMIN, Role.ROLE_ADMIN] },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
