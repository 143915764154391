import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { WeekViewHourSegment } from 'calendar-utils';
import { CalendarEventTimesChangedEvent, DAYS_OF_WEEK } from 'angular-calendar';
import { addDays, addMinutes, endOfWeek } from 'date-fns';
import { filter, fromEvent, Observable, Subject } from 'rxjs';
import { finalize, first, map, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { StrategyAdapter } from '../../../core/models/entities/strategy-adapter';
import { StrategyDialogComponent } from '../strategy-dialog/strategy-dialog.component';
import { TimeslotAdapter } from '../../../core/models/entities/timeslot-adapater';
import { Timeslot } from '../../../api/models/timeslot';
import { StrategyMode } from '../../../core/enums/strategy-mode.enum';
import { TimeslotService } from '../../../core/services/entities/timeslot.service';
import { BackStatus } from '../../../core/enums/back-status.enum';
import { MapSettingsService } from '../../../core/services/map-settings.service';
import { MapConfigurationAdapter } from '../../../core/models/entities/map-configuration-adapter';
import { Strategy } from '../../../api/models/strategy';

@Component({
  selector: 'webclient-strategy-calendar',
  templateUrl: './strategy-calendar.component.html',
  styleUrls: ['./strategy-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'webclient-strategy-calendar',
  },
})
export class StrategyCalendarComponent implements OnChanges, OnInit {
  /**
   * Liste des stratégies
   */
  @Input() public strategyAdapters: StrategyAdapter[] = [];

  @Input() possibleEvent: string[] | undefined;

  /**
   * Liste des créneaux horaires
   */
  @Input() public timeslotAdapters: TimeslotAdapter[] = [];

  public viewDate: Date = new Date();

  /**
   * Locale
   */
  public locale = 'fr';

  public weekStartsOn = DAYS_OF_WEEK.MONDAY;

  /**
   * Rafraichissement d'un créneau horaire
   */
  public refreshEvent: Subject<void> = new Subject();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private timeslotService: TimeslotService,
    private mapSettingsService: MapSettingsService
  ) {}

  ngOnInit(): void {
    this.listenMapConfigurationAdapter();
    this.listenStrategyAdapter();
    this.listenStrategyAdapterMode();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['strategyAdapters']?.currentValue) {
      this.listenRemove();
    }
  }

  /**
   * Création des créneaux horaires de la stratégie
   *
   * @param strategy
   * @private
   */
  private createTimeslots(strategy: Strategy): void {
    strategy.timeslots.map((timeslot: Timeslot) => {
      const timeslotAdapter: TimeslotAdapter = new TimeslotAdapter(
        timeslot,
        strategy.name,
        strategy.color
      );
      this.timeslotAdapters.push(timeslotAdapter);
    });
  }

  /**
   * Ecoute le changement d'une configuration
   *
   * On supprime les timeslots si la stratégie est en mode TRIGGER
   *
   * @private
   */
  private listenMapConfigurationAdapter(): void {
    this.mapSettingsService.mapConfiguration$.subscribe(
      (mapConfigurationAdapter: MapConfigurationAdapter | null) => {
        this.timeslotAdapters = [];

        mapConfigurationAdapter?.mapConfiguration.strategies?.forEach(
          (strategy: Strategy) => {
            this.createTimeslots(strategy);
          }
        );

        this.refresh();
      }
    );
  }

  /**
   * Ecoute le changement d'une stratégie
   *
   * On supprime les timeslots si la stratégie est en mode TRIGGER
   *
   * @private
   */
  private listenStrategyAdapter(): void {
    this.mapSettingsService.strategy$
      .pipe(
        filter(
          (strategyAdapter: StrategyAdapter | null) =>
            strategyAdapter?.strategy.mode === StrategyMode.TRIGGERED
        )
      )
      .subscribe((strategyAdapter: StrategyAdapter | null) => {
        if (strategyAdapter) {
          const timeslotAdapters: TimeslotAdapter[] =
            this.timeslotAdapters.filter(
              (ta: TimeslotAdapter) =>
                ta.timeslot.strategy &&
                ta.timeslot.strategy.id === strategyAdapter.strategy.id
            );
          timeslotAdapters.forEach((timeslotAdapter: TimeslotAdapter) => {
            const index: number = this.timeslotAdapters.findIndex(
              (ta: TimeslotAdapter) => ta.id === timeslotAdapter.id
            );
            this.timeslotAdapters.splice(index, 1);
          });
          this.refresh();
        }
      });
  }

  /**
   * Ecoute le changement de mode d'une stratégie
   *
   * On supprime les timeslots si la stratégie est en mode TRIGGER
   *
   * @private
   */
  private listenStrategyAdapterMode(): void {
    this.mapSettingsService.strategyMode$.subscribe(
      (strategyAdapter: StrategyAdapter | null) => {
        this.timeslotAdapters = [];
        this.refresh();
      }
    );
  }

  /**
   * Evènement lors du redimensionnement d'un évènement
   *
   * @param calendarEventTimesChangedEvent
   */
  public eventTimesChanged(
    calendarEventTimesChangedEvent: CalendarEventTimesChangedEvent
  ): void {
    calendarEventTimesChangedEvent.event.start =
      calendarEventTimesChangedEvent.newStart;
    calendarEventTimesChangedEvent.event.end =
      calendarEventTimesChangedEvent.newEnd;

    if (
      calendarEventTimesChangedEvent.newStart &&
      calendarEventTimesChangedEvent.newEnd
    ) {
      (
        calendarEventTimesChangedEvent.event as TimeslotAdapter
      ).timeslot.startDate =
        calendarEventTimesChangedEvent.newStart.toLocaleDateString(['en-EN'], {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        });
      (
        calendarEventTimesChangedEvent.event as TimeslotAdapter
      ).timeslot.endDate =
        calendarEventTimesChangedEvent.newEnd.toLocaleDateString(['en-EN'], {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        });
    }

    (calendarEventTimesChangedEvent.event as TimeslotAdapter).status =
      BackStatus.PENDING;

    this.refresh();

    this.mapSettingsService
      .updateTimeslots(calendarEventTimesChangedEvent.event as TimeslotAdapter)
      .subscribe((timeslot) => {});
  }

  public validateEventTimesChanged = ({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent) => {
    return true;
  };

  /**
   * Création d'un créneau horaire
   *
   * @param segment le segment du créneau horaire
   * @param mouseDownEvent l'évènement down de la souris
   * @param segmentElement le segment HTML du créneau horaire
   */
  public startDragToCreate(
    segment: WeekViewHourSegment,
    mouseDownEvent: MouseEvent,
    segmentElement: HTMLElement
  ) {
    const startDateString = new Date(
      segment.date.getTime() - segment.date.getTimezoneOffset() * 60000
    ).toLocaleDateString(['en-EN'], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
    const dragToSelectTimeslotAdapater: TimeslotAdapter = new TimeslotAdapter(
      {
        id: undefined,
        startDate: startDateString,
        endDate: undefined,
        strategy: undefined,
      },
      '',
      ''
    );

    this.timeslotAdapters = [
      ...this.timeslotAdapters,
      dragToSelectTimeslotAdapater as TimeslotAdapter,
    ];
    const segmentPosition = segmentElement.getBoundingClientRect();
    const endOfView = endOfWeek(this.viewDate, {
      weekStartsOn: this.weekStartsOn,
    });
    let dragged = false;

    fromEvent(document, 'mousemove')
      .pipe(
        finalize(() => {
          //delete dragToSelectTimeslotAdapater.meta.tmpEvent;
          //this.dragToCreateActive = false;
          this.refresh();
        }),
        takeUntil(fromEvent(document, 'mouseup')),
        map((event: Event) => event as MouseEvent)
      )
      .subscribe((mouseMoveEvent: MouseEvent) => {
        const minutesDiff = this.ceilToNearest(
          mouseMoveEvent.clientY - segmentPosition.top,
          30
        );
        const daysDiff =
          this.floorToNearest(
            mouseMoveEvent.clientX - segmentPosition.left,
            segmentPosition.width
          ) / segmentPosition.width;

        const newEnd = addDays(addMinutes(segment.date, minutesDiff), daysDiff);
        dragged = true;

        if (newEnd > segment.date) {
          if (newEnd <= endOfView) {
            dragToSelectTimeslotAdapater.end = newEnd;
          } else {
            dragToSelectTimeslotAdapater.end = addMinutes(endOfView, 1);
          }
        }

        this.refresh();
      });

    fromEvent(document, 'mouseup')
      .pipe(first())
      .subscribe(() => {
        if (this.possibleEvent) {
          if (!dragged) {
            const minutesDiff = this.ceilToNearest(
              mouseDownEvent.clientY - segmentPosition.top,
              30
            );
            const daysDiff =
              this.floorToNearest(
                mouseDownEvent.clientX - segmentPosition.left,
                segmentPosition.width
              ) / segmentPosition.width;
            const newEnd = addDays(
              addMinutes(segment.date, minutesDiff),
              daysDiff
            );

            if (newEnd > segment.date) {
              if (newEnd <= endOfView) {
                dragToSelectTimeslotAdapater.end = newEnd;
              } else {
                dragToSelectTimeslotAdapater.end = addMinutes(endOfView, 1);
              }
            }
          }

          dragged = false;
          this.openStrategiesDialog(dragToSelectTimeslotAdapater);
        }
      });
  }

  private floorToNearest(amount: number, precision: number): number {
    return Math.floor(amount / precision) * precision;
  }

  private ceilToNearest(amount: number, precision: number): number {
    return Math.ceil(amount / precision) * precision;
  }

  /**
   * Ecoute quand on supprime un créneau horaire
   *
   * @private
   */
  private listenRemove(): void {
    const arr: Observable<any>[] = [];

    this.timeslotAdapters.map((timeslotAdapter: TimeslotAdapter) => {
      arr.push(timeslotAdapter.removeObserver);
      timeslotAdapter.removeObserver.subscribe(
        (timeslotAdapter: TimeslotAdapter) => {
          const index: number = this.timeslotAdapters.findIndex(
            (ta: TimeslotAdapter) => ta.id === timeslotAdapter.id
          );

          if (timeslotAdapter.timeslot.id) {
            timeslotAdapter.deleted = true;
            this.mapSettingsService
              .updateTimeslots(timeslotAdapter)
              .subscribe(() => {
                this.timeslotAdapters.splice(index, 1);
                this.refresh();
              });
          } else {
            this.timeslotAdapters.splice(index, 1);
            this.refresh();
          }
        }
      );
    });
  }

  /**
   * Rafraichissement du planning
   *
   * @private
   */
  private refresh(): void {
    this.timeslotAdapters = [...this.timeslotAdapters];
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Ouverture de la boîte de dialogue pour sélectionner la stratégie
   */
  private openStrategiesDialog(dragToSelectEvent: TimeslotAdapter): void {
    const dialogRef = this.dialog.open(StrategyDialogComponent, {
      disableClose: true,
      data: {
        start: dragToSelectEvent.start,
        end: dragToSelectEvent.end,
        strategyAdapters: this.strategyAdapters.filter(
          (strategyAdapter: StrategyAdapter) =>
            strategyAdapter.strategy.mode === StrategyMode.SCHEDULED
        ),
      },
    });

    dialogRef
      .afterClosed()
      .subscribe(
        (result: {
          start: string;
          end: string;
          strategyAdapter: StrategyAdapter;
        }) => {
          if (result !== undefined) {
            //dragToSelectEvent.id = result.strategyAdapater.uniqueId + dragToSelectEvent.id;

            const startHours = Number(result.start.split(':')[0]);
            const startMinutes = Number(result.start.split(':')[1]);
            const endHours = Number(result.end.split(':')[0]);
            const endMinutes = Number(result.end.split(':')[1]);

            const startDate: Date = dragToSelectEvent.start;
            let endDate: Date | undefined;

            startDate.setHours(startHours);
            startDate.setMinutes(startMinutes);

            if (dragToSelectEvent.end) {
              endDate = dragToSelectEvent.end;
              endDate.setHours(endHours);
              endDate.setMinutes(endMinutes);

              if (endDate.getHours() == 0 && endDate.getMinutes() == 0) {
                endDate = addMinutes(endDate, -1);
              }
            }

            const timeslot: Timeslot = {
              id: undefined,
              startDate: startDate.toLocaleDateString(['en-EN'], {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              }),
              endDate: endDate?.toLocaleDateString(['en-EN'], {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              }),
              strategy: result.strategyAdapter.strategy,
            };

            dragToSelectEvent.title = result.strategyAdapter.strategy.name
              ? result.strategyAdapter.strategy.name
              : '';
            dragToSelectEvent.timeslot = timeslot;
            dragToSelectEvent.start = startDate;
            dragToSelectEvent.end = endDate;
            dragToSelectEvent.color = {
              primary: result.strategyAdapter.strategy.color,
              secondary: result.strategyAdapter.strategy.color,
            };

            this.refresh();
            this.listenRemove();

            this.mapSettingsService
              .updateTimeslots(dragToSelectEvent, result.strategyAdapter)
              .subscribe((timeslotAdapter: TimeslotAdapter | void) => {});
          } else {
            const index: number = this.timeslotAdapters.findIndex(
              (timeslotAdapter: TimeslotAdapter) =>
                timeslotAdapter.id === dragToSelectEvent.id
            );

            if (index !== -1) {
              this.timeslotAdapters.splice(index, 1);
              this.refresh();
            }
          }
        }
      );
  }
}
