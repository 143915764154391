import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { IEntity } from '../../../../components/admin/models/i-entity';
import { UserGroup } from '../../../../api/models/user-group';
import { UserGroupService } from '../../../../core/services/entities/user-group.service';
import { EntityFormComponent } from '../../../../components/admin/entity-form/entity-form.component';
import { NotificationService } from '../../../../core/services/notification.service';
import { SessionService } from '../../../../core/services/session.service';
import { Customer } from '../../../../api/models/customer';
import { CustomerService } from '../../../../core/services/entities/customer.service';

@Component({
  selector: 'webclient-user-group-form',
  templateUrl: './user-group-form.component.html',
  styleUrls: ['./user-group-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class UserGroupFormComponent
  extends EntityFormComponent
  implements OnInit
{
  /**
   * Groupe d'utilisateurs
   */
  public usergroup: UserGroup | undefined;

  /**
   * Liste des clients
   */
  public customers: Customer[] = [];

  /**
   * Groupe parent
   */
  public parent: UserGroup | undefined;

  /**
   * Messages d'erreur
   */
  public validationMessages = {
    name: [
      {
        type: 'required',
        message: 'usergroup.validationMessages.name.required',
      },
    ],
    customer: [
      {
        type: 'required',
        message: 'user.validationMessages.customer.required',
      },
    ],
  };

  public compareUserGroup(ug1: UserGroup, ug2: UserGroup): boolean {
    return ug1 && ug2 ? ug1.id === ug2.id : ug1 === ug2;
  }

  constructor(
    protected override route: ActivatedRoute,
    protected override sessionService: SessionService,
    private usergroupService: UserGroupService,
    private customerService: CustomerService,
    private formBuilder: FormBuilder,
    protected override translateService: TranslateService,
    protected override notificationService: NotificationService,
    protected override location: Location
  ) {
    super(
      route,
      sessionService,
      translateService,
      notificationService,
      location
    );

    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      customer: [null, []],
      parent: [null, []],
    });

    if (this.id) {
      this.customerService.getAll().subscribe((customers: Customer[]) => {
        this.customers = customers;
      });

      this.usergroupService
        .getById(this.id)
        .subscribe((usergroup: UserGroup) => {
          this.usergroup = usergroup;
          this.parent = this.usergroup.parent;
        });
    } else if (this.isAdmin) {
      this.form.patchValue({ customer: this.currentUser?.customer });
    } else if (this.isSuperAdmin) {
      this.customerService.getAll().subscribe((customers: Customer[]) => {
        this.customers = customers;
      });
    }
  }

  ngOnInit(): void {
    const parentId: number = this.route.snapshot.params['parentid'];

    if (parentId) {
      this.usergroupService
        .getById(parentId)
        .subscribe((usergroup: UserGroup) => {
          this.parent = usergroup;

          if (this.form) {
            this.form.patchValue({
              parent: parentId,
              customer: this.parent?.customer,
            });
          }
        });
    }
  }

  /**
   * Création d'un groupe d'utilisateurs
   *
   * @param entity le groupe d'utilisateur à créer
   */
  public onCreate(entity: IEntity) {
    const usergroup: UserGroup = entity as UserGroup;

    this.usergroupService.create(usergroup).subscribe(
      (usergroup: UserGroup) => {
        this.displaySuccessSnackBar(
          usergroup.name,
          'usergroup.createSuccessMessage'
        );
      },
      (error) => {
        this.displayErrorSnackBar(
          usergroup.name,
          'usergroup.createErrorMessage'
        );
      }
    );
  }

  /**
   * Edition d'un groupe d'utilisateurs
   *
   * @param entity le groupe d'utilisateur à éditer
   */
  public onEdit(entity: IEntity) {
    const usergroup: UserGroup = entity as UserGroup;

    this.usergroupService.update(this.id, usergroup).subscribe(
      (usergroup: UserGroup) => {
        this.displaySuccessSnackBar(
          usergroup.name,
          'usergroup.editSuccessMessage'
        );
      },
      (error) => {
        this.displayErrorSnackBar(usergroup.name, 'usergroup.editErrorMessage');
      }
    );
  }

  /**
   * Indique si l'on peut changer le client
   */
  public canChangeCustomer(): boolean {
    if (this.isAdmin) {
      return false;
    } else if (this.isSuperAdmin && this.parent === undefined) {
      return true;
    } else if (this.isSuperAdmin && this.parent !== undefined) {
      return false;
    }

    return false;
  }
}
