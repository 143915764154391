import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export abstract class EntityService<T> {
  public abstract resourceUrl: string;

  constructor(protected http: HttpClient) {}

  public getAll(filter?: string): Observable<T[]> {
    const url =
      filter !== undefined ? this.resourceUrl + '?' + filter : this.resourceUrl;

    return this.http.get<T[]>(`${url}`).pipe(catchError(this.handleError));
  }

  public getById(id: number): Observable<T> {
    return this.http
      .get<T>(`${this.resourceUrl}/${id}`)
      .pipe(catchError(this.handleError));
  }

  public create(entity: T): Observable<T> {
    return this.http
      .post<T>(`${this.resourceUrl}`, entity)
      .pipe(catchError(this.handleError));
  }

  public update(id: number | undefined, entity: T): Observable<T> {
    return this.http
      .put<T>(`${this.resourceUrl}/${id}`, entity)
      .pipe(catchError(this.handleError));
  }

  public patch(id: number | undefined, entity: T): Observable<T> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/merge-patch+json',
      }),
    };
    return this.http
      .patch<T>(`${this.resourceUrl}/${id}`, entity, httpOptions)
      .pipe(catchError(this.handleError));
  }

  public delete(id: number): Observable<void> {
    return this.http
      .delete<void>(`${this.resourceUrl}/${id}`)
      .pipe(catchError(this.handleError));
  }

  protected handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    /*this.snackBar.open(errorMessage, 'Splash', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });*/
    console.error('ERROR ', error);
    return throwError(() => error);
  }
}
