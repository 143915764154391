<webclient-entity-list
  [entities]="robotAdapters"
  [displayedColumns]="displayedColumns"
  [urlForm]="'/pages/admin/robot'"
  [title]="'robot.robots'"
  [svgIcon]="'alf31'"
  [text]="text"
  (onRemoveEntity)="onRemove($event)"
>
  <!-- Colonne nom -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>
      {{ 'robot.name' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let robotAdapter">
      {{ robotAdapter.robot.name }}
    </mat-cell>
  </ng-container>

  <!-- Colonne client -->
  <ng-container matColumnDef="customer">
    <mat-header-cell *matHeaderCellDef>
      {{ 'robot.customer' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let robotAdapter">
      {{ robotAdapter.robot.customer.name }}
    </mat-cell>
  </ng-container>

  <!-- Colonne zone -->
  <ng-container matColumnDef="zone">
    <mat-header-cell *matHeaderCellDef>
      {{ 'robot.zone' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let robotAdapter">
      <span *ngIf="robotAdapter.robot.zone !== undefined">{{
        robotAdapter.robot.zone.name
      }}</span>
      <mat-icon *ngIf="robotAdapter.robot.zone === undefined" [color]="'warn'"
        >not_interested</mat-icon
      >
    </mat-cell>
  </ng-container>
</webclient-entity-list>
