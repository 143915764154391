import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { StrategyAdapter } from '../../../core/models/entities/strategy-adapter';
import { ReplaySubject, Subject } from 'rxjs';
import { BackStatus } from '../../../core/enums/back-status.enum';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { Strategy } from '../../../api/models/strategy';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { TriggerService } from '../../../core/services/entities/trigger.service';
import { TriggerConfService } from '../../../core/services/entities/trigger-conf.service';
import { TriggerAdapter } from '../../../core/models/entities/trigger-adapter';
import { TriggerConfAdapter } from '../../../core/models/entities/trigger-conf-adapter';
import { Trigger } from '../../../api/models/trigger';
import { StrategyMode } from '../../../core/enums/strategy-mode.enum';
import { MatDialog } from '@angular/material/dialog';
import { PatrolAdapter } from '../../../core/models/entities/patrol-adapter';
import { PatrolDialogComponent } from '../../patrol/patrol-dialog/patrol-dialog.component';
import { Utils } from '../../../core/utils/utils';
import { MapSettingsService } from '../../../core/services/map-settings.service';
import { RobotAdapter } from '../../../core/models/entities/robot-adapter';
import { MapPoint, MapPointService } from '@lelab31/ngx-rodotic';
import { TriggerConf } from '../../../api/models/trigger-conf';
import { TriggerItem } from '../../../api/models/trigger-item';
import { NotificationService } from '../../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { PatrolCheckpoint } from '../../../api/models/patrol-checkpoint';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { GenetecOutput } from '../../../core/models/interfaces/genetec-output';
import { GenetecInput } from '../../../core/models/interfaces/genetec-input';
import { Robot } from '../../../api/models/robot';
import { trigger } from '@angular/animations';
import { isArray } from '@angular/compiler-cli/src/ngtsc/annotations/common';

@Component({
  selector: 'webclient-strategy-list',
  templateUrl: './strategy-list.component.html',
  styleUrls: ['./strategy-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'webclient-strategy-list'
  }
})
export class StrategyListComponent implements OnInit, OnChanges, OnDestroy {

  /**
   * Ce Subject permet de fermer les subscribe pour eviter les fuites lors de la fermeture de la page
   */
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  /**
   * Liste des stratégies
   */
  @Input() public strategyAdapters: StrategyAdapter[] = [];

  /**
   * Liste des patrouilles
   */
  @Input() public patrolAdapters: PatrolAdapter[] = [];

  /**
   * Le robot
   */
  @Input() public robotAdapter: RobotAdapter | undefined;

  /**
   * Permet de réorganiser les stratégies
   */
  @Input() public canReorder = false;

  /**
   * Permet d'accéder aux commandes
   */
  @Input() public canCommand = true;

  /**
   * Permet d'accéder aux actions
   */
  @Input() public canAction = false;

  /**
   * Mode editable ou non
   */
  @Input() public editable = false;

  @Output() public mouseEnter = new EventEmitter();

  @Output() public mouseLeave = new EventEmitter();

  /**
   * Source des données
   */
  public dataSource: MatTableDataSource<StrategyAdapter> = new MatTableDataSource<StrategyAdapter>([]);

  /**
   * Nom des colonnes présent lors de l'édition
   */
  public editableColumnsName = ['indicator'];

  /**
   * Nom des colonnes pour la réorganisation des stratégies
   */
  public reorderColumnsName = ['reorder'];

  /**
   * Nom des colonnes pour les commandes
   */
  public commandColumnsName = ['commands'];

  /**
   * Nom des colonnes pour les actions
   */
  public actionColumnsName = ['actions'];

  /**
   * Colonnes affichées
   */
  public displayedColumns: string[] = ['number', 'name', 'randomMode', 'rotationNumber', 'patrolDuration', 'breakDuration', 'mode', 'trigger', 'activationState', 'monostableState'];

  /**
   * Détecte le changement de champs d'une stratégie
   */
  private propertyInput$: Subject<StrategyAdapter> = new Subject();

  /**
   * Statut en BDD
   */
  public BACK_STATUS = BackStatus;

  /**
   * Mode de la stratégie
   */
  public STRATEGY_MODE = StrategyMode;

  /**
   * Liste des déclencheurs
   */
  public triggerAdapters: TriggerAdapter[] = [];
  public triggerConfAdapters: TriggerConfAdapter[] = [];

  constructor(private mapSettingsService: MapSettingsService, private triggerService: TriggerService, private triggerConfService: TriggerConfService, private mapPointService: MapPointService, private dialog: MatDialog, private translateService: TranslateService, private notificationService: NotificationService, private breakpointObserver: BreakpointObserver) {
  }

  ngOnInit(): void {

    /*
    * Le breakpoint qui ne garde que le nom et les commandes en format Small et gardes les autres colonnes en formats plus larges
    */
    const breakpoint = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(result => {
        if (result.matches) {
          this.displayedColumns = ['name'];
        } else {
          this.displayedColumns = ['number', 'name', 'randomMode', 'rotationNumber', 'patrolDuration', 'breakDuration', 'mode', 'trigger', 'activationState', 'monostableState'];

        }
        this.addColumns();
      });

    //this.listenTriggerAdapters();
    this.listenChangeProperties();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['robotAdapter'] && changes['robotAdapter'].currentValue) {
      const robotAdapter = changes['robotAdapter'].currentValue;
      this.listenTriggerAdapters(robotAdapter);
    }


    if (changes['strategyAdapters'] && changes['strategyAdapters'].currentValue) {
      this.dataSource = new MatTableDataSource<StrategyAdapter>(this.strategyAdapters);
      this.createMapPoints(this.strategyAdapters);
    }

  }

  /**
   * Création des points de contrôle des patrouilles des stratégies sur la carte
   *
   * @param StrategyAdapters la liste des stratégies
   *
   * @private
   */
  private createMapPoints(strategyAdapters: StrategyAdapter[]): void {
    strategyAdapters.forEach((strategyAdapter: StrategyAdapter) => {
      strategyAdapter.strategy.patrol?.checkpoints.forEach((patrolCheckpoint: PatrolCheckpoint) => {
        if (patrolCheckpoint.pose && strategyAdapter.strategy.patrol && strategyAdapter.strategy.patrol.id) {
          const mapPoint: MapPoint = new MapPoint(patrolCheckpoint.pose, (patrolCheckpoint.id) ? patrolCheckpoint.id.toString() : null);
          mapPoint.group = strategyAdapter.strategy.patrol.id.toString();
          mapPoint.displayObject = Utils.createMapPatrolCheckpoint();
          mapPoint.displayObject.visible = false;
          this.mapPointService.addOrUpdatePoint(mapPoint);
        }
      });
    });
  }

  /**
   * Ajout des colonnes
   *
   * @private
   */
  private addColumns(): void {
    if (this.canReorder === true) {
      this.displayedColumns = [...this.reorderColumnsName, ...this.displayedColumns];
    }

    if (this.editable === true) {
      this.displayedColumns = [...this.editableColumnsName, ...this.displayedColumns];
    }

    if (this.canCommand === true) {
      this.displayedColumns = [...this.displayedColumns, ...this.commandColumnsName];
    }

    if (this.canAction === true) {
      this.displayedColumns = [...this.displayedColumns, ...this.actionColumnsName];
    }
  }

  private listenTriggerAdapters(adapter: RobotAdapter): void {
    this.triggerService.getAll()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (triggers: Trigger[]) => {
          this.triggerAdapters = triggers.map((trigger: Trigger) => new TriggerAdapter(trigger));
        },
        (error) => {
          console.error('Error fetching triggers:', error);
        }
      );

    this.triggerConfService.getAll()
      .pipe(
        takeUntil(this.destroyed$),
        map((triggerConfs: any[]) => {
          return triggerConfs.filter(triggerConf => {
            return triggerConf.robot === `/api/robots/${adapter.robot.id}`;
          });
        })
      )
      .subscribe(
        (filteredTriggerConfs: TriggerConf[]) => {
          this.triggerConfAdapters = filteredTriggerConfs.map((triggerConf: TriggerConf) => new TriggerConfAdapter(triggerConf));
        },
        (error) => {
          console.error('Error fetching triggerConfs:', error);
        }
      );
  }

  /**
   * Ecoute les changements des propriétés des stratégies
   *
   * @private
   */
  private listenChangeProperties(): void {
    this.propertyInput$
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(300)
      )
      .subscribe((strategyAdapter: StrategyAdapter) => {

        this.mapSettingsService.updateStrategies(strategyAdapter)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((adapters: StrategyAdapter | StrategyAdapter[]) => {
            console.log('UPDATED ADAPTERS', adapters)
          }, (httpErrorResponse: HttpErrorResponse) => {
            console.error('Error updating strategy:', httpErrorResponse);
            strategyAdapter.status = BackStatus.NOT_SAVED;
            const title: string = this.translateService.instant('entity.error');
            const message: string = this.translateService.instant(`${httpErrorResponse.error.detail}`);
            this.notificationService.displayErrorSnackBar(title, message);
          });
      });
  }

  protected onChangeTrigger(triggerConf: TriggerConf) {

  }

  /**
   * Changement d'une propriété d'une patrouille
   *
   * @param propriété à modifier
   * @param value valeur de la propriété à modifier
   * @param strategyAdapter la stratégie à mettre à jour
   */
  public onChangeProperty(property: string, value: string | boolean, strategyAdapter: StrategyAdapter) {
    strategyAdapter.strategy[property as keyof Strategy] = <never>value;

    if (property === 'mode') {
      if (value === StrategyMode.SCHEDULED) {
        // strategyAdapter.strategy.triggerItem ;
      } else if (value === StrategyMode.TRIGGERED) {
          strategyAdapter.strategy.timeslots = [];
        // strategyAdapter.strategy.triggerItem[0] = this.createTriggerItem();
      }

      this.mapSettingsService.changeStrategyMode(strategyAdapter);
    }

    this.propertyInput$.next(strategyAdapter);
  }

  /**
   * Suppression d'une stratégie
   *
   * @param patrolCheckpointAdapter le point de patrouille à supprimer
   */
  public onDeleteItem(strategyAdapter: StrategyAdapter) {
    strategyAdapter.deleted = true;

    this.strategyAdapters.map((strategyAdapter: StrategyAdapter) => strategyAdapter.status = BackStatus.PENDING);

    this.mapSettingsService.updateStrategies(strategyAdapter)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((strategyAdapter: StrategyAdapter | StrategyAdapter[]) => {
        this.strategyAdapters = strategyAdapter as StrategyAdapter[];
        this.dataSource.data = this.strategyAdapters;
      }, (httpErrorResponse: HttpErrorResponse) => {
        this.strategyAdapters.map((strategyAdapter: StrategyAdapter) => strategyAdapter.status = BackStatus.NOT_SAVED);
        const title: string = this.translateService.instant('entity.error');
        const message: string = this.translateService.instant(`${httpErrorResponse.error.detail}`);
        this.notificationService.displayErrorSnackBar(title, message);
      });
  }

  /**
   * Changement de position d'une stratégie
   *
   * @param event
   */
  public onDropTable(event: CdkDragDrop<StrategyAdapter[], any>) {
    if (this.editable) {
      moveItemInArray(this.strategyAdapters, event.previousIndex, event.currentIndex);
      this.refreshPosition();

      this.mapSettingsService.updateStrategies(...this.strategyAdapters)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((strategyAdapter: StrategyAdapter | StrategyAdapter[]) => {
        }, (httpErrorResponse: HttpErrorResponse) => {
          this.strategyAdapters.map((strategyAdapter: StrategyAdapter) => strategyAdapter.status = BackStatus.NOT_SAVED);
          const title: string = this.translateService.instant('entity.error');
          const message: string = this.translateService.instant(`${httpErrorResponse.error.detail}`);
          this.notificationService.displayErrorSnackBar(title, message);
        });
    }
  }

  /**
   * Recalcule la position des stratégies
   *
   * @private
   */
  private refreshPosition(): void {
    this.strategyAdapters.forEach((strategyAdapter: StrategyAdapter, index: number) => {
      strategyAdapter.strategy.position = index + 1;
    });

    this.dataSource._updateChangeSubscription();
  }

  compareTriggerConfs(triggerConfA: TriggerConf, triggerConfB: TriggerConf) {
    return triggerConfA.id === triggerConfB.id
  }

  updateStrategyTriggerConf(strategyAdapter: StrategyAdapter, triggerConf: TriggerConf) {
    if (!strategyAdapter.strategy.id) {
      return
    }
    strategyAdapter.strategy.triggerItem = {
      id: strategyAdapter.strategy.triggerItem?.id,
      launcher: triggerConf,
      activationState: true, // État fixe d'activation
      monostableState: false // État fixe de monostabilité
    }
    this.propertyInput$.next(strategyAdapter);
  }

  /**
   * Création d'une stratégie
   */
  public onAddStrategy() {

    if (this.patrolAdapters) {

      const dialogRef = this.dialog.open(PatrolDialogComponent, {
        disableClose: true,
        data: {
          patrolAdapters: this.patrolAdapters
        }
      });

      dialogRef.afterClosed().subscribe((result: { patrolAdapter: PatrolAdapter }) => {
        if (result !== undefined) {
          const position: number | undefined = this.strategyAdapters.length;

          const strategy = new Strategy();
          strategy.patrol = result.patrolAdapter.patrol;
          strategy.name = result.patrolAdapter.patrol.name;
          strategy.position = position ? position + 1 : position;
          strategy.mode = StrategyMode.TRIGGERED;
          strategy.color = Utils.generateColor();
          strategy.randomMode = false;
          strategy.rotationNumber = 0;
          strategy.patrolDuration = 0;
          strategy.breakDuration = 0;
          strategy.triggerItem = undefined;

          const strategyAdapter: StrategyAdapter = new StrategyAdapter(strategy);

          this.strategyAdapters.push(strategyAdapter);

          this.mapSettingsService.updateStrategies(strategyAdapter).subscribe((strategyAdapter: StrategyAdapter | StrategyAdapter[]) => {

          }, (httpErrorResponse: HttpErrorResponse) => {
            strategyAdapter.status = BackStatus.NOT_SAVED;
            const title: string = this.translateService.instant('entity.error');
            const message: string = this.translateService.instant(`${httpErrorResponse.error.detail}`);
            this.notificationService.displayErrorSnackBar(title, message);
          });

          this.dataSource._updateChangeSubscription();
        }
      });
    }
  }

  /**
   * Lors du survol d'une stratégie afficher les points de patrouille sur la carte
   *
   * @param strategyAdapter la stratégie survolée
   */
  public onMouseenter(strategyAdapter: StrategyAdapter) {
    if (strategyAdapter.uid) {
      this.mouseEnter.emit();
      this.mapPointService.setVisiblePoint(strategyAdapter.uid, true);
    }
  }

  /**
   * Lorsqu'on ne survol plus la stratégie cacher les points de de patrouille sur la carte
   *
   * @param strategyAdapter la stratégie survolée
   */
  public onMouseleave(strategyAdapter: StrategyAdapter): void {
    if (strategyAdapter.uid) {
      this.mapPointService.setVisiblePoint(strategyAdapter.uid, false);
      this.mouseLeave.emit();
    }
  }

  /**
   * Départ forcé d'une stratégie
   *
   * @param strategyAdapter la stratégie à démarrer
   * @param forcedDeparture départ ou arrêt forcé
   */
  public onForcedDeparture(strategyAdapter: StrategyAdapter, forcedDeparture: boolean): void {
    if (this.robotAdapter) {
      this.robotAdapter.forcedDepartureCommand(
        strategyAdapter.strategy,
        forcedDeparture
      );
    }
  }

  /**
   * Inhibition d'une stratégie
   *
   * @param strategyAdapter la stratégie à inhiber
   */
  public onInhibit(strategyAdapter: StrategyAdapter): void {
    if (this.robotAdapter) {
      this.robotAdapter.inhibitStrategyCommand(strategyAdapter.strategy);
    }
  }

  /**
   * Indique si l'on peut déplacer les lignes
   *
   * @param item la ligne
   * @param list la liste
   */
  public canDrop(item: CdkDrag, list: CdkDropList): boolean {
    return this.editable;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}


