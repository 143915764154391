import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'webclient-map-configuration-indicator',
  templateUrl: './map-configuration-indicator.component.html',
  styleUrls: ['./map-configuration-indicator.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'webclient-map-configuration-indicator',
  },
})
export class MapConfigurationIndicatorComponent implements OnInit {
  /**
   * Titre
   */
  @Input() public title: string = '';

  /**
   * Nombre
   */
  @Input() public number: number | undefined;

  constructor() {}

  ngOnInit(): void {}
}
