import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { MapConfigurationAdapter } from '../../../core/models/entities/map-configuration-adapter';
import { FormControl } from '@angular/forms';
import { MapSettingsService } from '../../../core/services/map-settings.service';
import { MapConfigurationDialogComponent } from '../map-configuration-dialog/map-configuration-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MapConfigurationService } from '../../../core/services/entities/map-configuration.service';
import { MapConfiguration } from '../../../api/models/map-configuration';
import { MapAdapter } from '../../../core/models/entities/map-adapter';
import { NotificationService } from '../../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { RobotAdapter } from '../../../core/models/entities/robot-adapter';
import { DialogConfirmComponent } from '../../info/dialog-confirm/dialog-confirm.component';
import { YesNo } from '../../../core/enums/yes-no.enum';
import { RobotService } from '../../../core/services/entities/robot.service';

@Component({
  selector: 'webclient-map-configuration-toolbar',
  templateUrl: './map-configuration-toolbar.component.html',
  styleUrls: ['./map-configuration-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'webclient-map-configuration-toolbar',
  },
})
export class MapConfigurationToolbarComponent implements OnInit, OnChanges {
  /**
   * Carte
   */
  @Input() public mapAdapter: MapAdapter | undefined;

  /**
   * Le robot
   */
  @Input() public robotAdapter: RobotAdapter | undefined;

  /**
   * Configuration sélectionnée
   */
  @Input() public selectedMapConfigurationAdapter:
    | MapConfigurationAdapter
    | undefined;

  /**
   * Liste des configurations
   */
  public mapConfigurationAdapters: MapConfigurationAdapter[] = [];

  /**
   * Contrôleur
   */
  public configurations = new FormControl('');

  constructor(
    private mapConfigurationService: MapConfigurationService,
    private robotService: RobotService,
    private mapSettingsService: MapSettingsService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.mapSettingsService.mapConfigurations$.subscribe(
      (mapConfigurationAdapters: MapConfigurationAdapter[]) => {
        this.mapConfigurationAdapters = mapConfigurationAdapters;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedMapConfigurationAdapter'].currentValue) {
      this.mapSettingsService.updateConfiguration(
        changes['selectedMapConfigurationAdapter'].currentValue
      );
    }
  }

  /**
   * Changement de configuration
   */
  public onChange() {
    if (this.selectedMapConfigurationAdapter) {
      this.mapSettingsService.updateConfiguration(
        this.selectedMapConfigurationAdapter
      );
    }
  }

  compareMapConfigurationAdapter(
    mca1: MapConfigurationAdapter,
    mca2: MapConfigurationAdapter
  ): boolean {
    return mca1.mapConfiguration.id === mca2.mapConfiguration.id;
  }

  /**
   * Indique si on peut uploder la configuration
   *
   * Si ce n'est pas la carte active du robot, on ne peut pas uploder sa configuration
   */
  public isUploadable(): boolean {
    if (
      this.mapAdapter &&
      this.robotAdapter &&
      this.robotAdapter.connected &&
      this.robotAdapter.robot.activeMap
    ) {
        return this.mapAdapter.map.id === this.robotAdapter.robot.activeMap.id;
    }

    return false;
  }

  /**
   * Indique si on peux supprimer la configuration
   *
   * Il faut empêcher de supprimer la dernière configuration
   */
  public canDelete(): boolean {
    if (this.mapConfigurationAdapters.length > 1) {
      return true;
    }

    return false;
  }

  /**
   * Création d'une configuration de carte
   */
  public onAddMapConfiguration() {
    const dialogRef = this.dialog.open(MapConfigurationDialogComponent, {
      disableClose: true,
      data: {
        mapAdapter: this.mapAdapter,
        title: 'mapConfiguration.create',
      },
    });

    dialogRef
      .afterClosed()
      .subscribe((result: { mapConfiguration: MapConfiguration }) => {
        if (result !== undefined) {
          this.mapConfigurationService
            .create(result.mapConfiguration)
            .subscribe(
              (mapConfiguration: MapConfiguration) => {
                this.mapConfigurationAdapters.push(
                  new MapConfigurationAdapter(mapConfiguration)
                );
                this.selectedMapConfigurationAdapter =
                  this.mapConfigurationAdapters[
                    this.mapConfigurationAdapters.length - 1
                  ];
                this.mapSettingsService.updateConfiguration(
                  this.selectedMapConfigurationAdapter
                );
                const title: string =
                  this.translateService.instant('entity.success');
                const message: string = this.translateService.instant(
                  `mapConfiguration.createSuccessMessage`,
                  { name: result.mapConfiguration.name }
                );
                this.notificationService.displaySuccessSnackBar(title, message);
              },
              (error) => {
                const title: string =
                  this.translateService.instant('entity.error');
                const message: string = this.translateService.instant(
                  `mapConfiguration.createErrorMessage`,
                  { name: result.mapConfiguration.name }
                );
                this.notificationService.displayErrorSnackBar(title, message);
              }
            );
        }
      });
  }

  /**
   * Upload de la configuration vers le robot
   */
  public onUploadMapConfiguration() {
    const title: string = this.translateService.instant('entity.error');
    const message: string = this.translateService.instant(
      `mapConfiguration.uploadErrorMessage`,
      { name: this.selectedMapConfigurationAdapter?.mapConfiguration.name }
    );

    if (this.selectedMapConfigurationAdapter?.mapConfiguration.id) {
      this.mapConfigurationService
        .getById(this.selectedMapConfigurationAdapter?.mapConfiguration.id)
        .subscribe(
          (mapConfiguration: MapConfiguration) => {
            if (this.robotAdapter) {
              this.robotAdapter.robot.activeMapConfiguration = mapConfiguration;

              this.robotService
                .update(this.robotAdapter.robot.id, this.robotAdapter.robot)
                .subscribe(
                  () => {
                    if (
                      this.robotAdapter &&
                      mapConfiguration.strategies &&
                      this.robotAdapter.robot.activeMap &&
                      this.robotAdapter.robot.activeMap.id
                    ) {
                      this.robotAdapter.setCheckpointsCommand(
                        mapConfiguration,
                        -1,
                        this.robotAdapter.robot.activeMap.id
                      );
                      this.robotAdapter.setStrategyCommand(
                        mapConfiguration.strategies,
                        -1,
                        this.robotAdapter.robot.activeMap.id
                      );

                      const title: string =
                        this.translateService.instant('entity.success');
                      const message: string = this.translateService.instant(
                        `mapConfiguration.uploadSuccessMessage`,
                        { name: mapConfiguration.name }
                      );
                      this.notificationService.displaySuccessSnackBar(
                        title,
                        message
                      );
                    } else {
                      this.notificationService.displayErrorSnackBar(
                        title,
                        message
                      );
                    }
                  },
                  () => {
                    this.notificationService.displayErrorSnackBar(
                      title,
                      message
                    );
                  }
                );
            }
          },
          () => {
            this.notificationService.displayErrorSnackBar(title, message);
          }
        );
    }
  }

  /**
   * Duplication de la configuration
   */
  public onDuplicateMapConfiguration() {
    const title: string = this.translateService.instant('entity.error');
    const message: string = this.translateService.instant(
      `mapConfiguration.duplicateErrorMessage`,
      { name: this.selectedMapConfigurationAdapter?.mapConfiguration.name }
    );

    const dialogRef = this.dialog.open(MapConfigurationDialogComponent, {
      disableClose: true,
      data: {
        mapAdapter: this.mapAdapter,
        title: 'mapConfiguration.duplicate',
        mapConfiguration:
          this.selectedMapConfigurationAdapter?.mapConfiguration,
      },
    });

    dialogRef
      .afterClosed()
      .subscribe((result: { mapConfiguration: MapConfiguration }) => {
        if (result !== undefined) {
          if (this.selectedMapConfigurationAdapter?.mapConfiguration.id) {
            this.mapConfigurationService
              .duplicate(result.mapConfiguration)
              .subscribe(
                (mapConfiguration: MapConfiguration) => {
                  if (this.robotAdapter) {
                    const title: string =
                      this.translateService.instant('entity.success');
                    const message: string = this.translateService.instant(
                      `mapConfiguration.duplicateSuccessMessage`,
                      {
                        name: this.selectedMapConfigurationAdapter
                          ?.mapConfiguration.name,
                      }
                    );
                    this.mapConfigurationAdapters.push(
                      new MapConfigurationAdapter(mapConfiguration)
                    );
                    this.selectedMapConfigurationAdapter =
                      this.mapConfigurationAdapters[
                        this.mapConfigurationAdapters.length - 1
                      ];
                    this.mapSettingsService.updateConfiguration(
                      this.selectedMapConfigurationAdapter
                    );
                    this.notificationService.displaySuccessSnackBar(
                      title,
                      message
                    );
                  } else {
                    this.notificationService.displayErrorSnackBar(
                      title,
                      message
                    );
                  }
                },
                () => {
                  this.notificationService.displayErrorSnackBar(title, message);
                }
              );
          }
        }
      });
  }

  /**
   * Edition de la configuration
   */
  public onEditMapConfiguration() {
    const dialogRef = this.dialog.open(MapConfigurationDialogComponent, {
      disableClose: true,
      data: {
        mapAdapter: this.mapAdapter,
        title: 'mapConfiguration.edit',
        mapConfiguration:
          this.selectedMapConfigurationAdapter?.mapConfiguration,
      },
    });

    dialogRef
      .afterClosed()
      .subscribe((result: { mapConfiguration: MapConfiguration }) => {
        if (result !== undefined) {
          this.mapConfigurationService
            .update(result.mapConfiguration.id, result.mapConfiguration)
            .subscribe(
              (mapConfiguration: MapConfiguration) => {
                const title: string =
                  this.translateService.instant('entity.success');
                const message: string = this.translateService.instant(
                  `mapConfiguration.editSuccessMessage`,
                  {
                    name: this.selectedMapConfigurationAdapter?.mapConfiguration
                      .name,
                  }
                );
                if (this.selectedMapConfigurationAdapter) {
                  this.selectedMapConfigurationAdapter.mapConfiguration.name =
                    mapConfiguration.name;
                }

                this.notificationService.displaySuccessSnackBar(title, message);
              },
              (error) => {
                const title: string =
                  this.translateService.instant('entity.error');
                const message: string = this.translateService.instant(
                  `mapConfiguration.editErrorMessage`,
                  {
                    name: this.selectedMapConfigurationAdapter?.mapConfiguration
                      .name,
                  }
                );
                this.notificationService.displayErrorSnackBar(title, message);
              }
            );
        }
      });
  }

  /**
   * Suppression d'une stratégie
   */
  public onDeleteMapConfiguration() {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      panelClass: 'dialog-confirm',
      data: {
        title: this.translateService.instant(`mapConfiguration.removeTitle`),
        text: this.translateService.instant(`mapConfiguration.removeMessage`, {
          name: this.selectedMapConfigurationAdapter?.mapConfiguration.name,
        }),
      },
    });

    dialogRef.afterClosed().subscribe((response: YesNo) => {
      if (
        response === YesNo.YES &&
        this.selectedMapConfigurationAdapter?.mapConfiguration.id
      ) {
        const configurationName = this.selectedMapConfigurationAdapter
          ?.mapConfiguration?.name
          ? this.selectedMapConfigurationAdapter?.mapConfiguration?.name
          : '';

        this.mapConfigurationService
          .delete(this.selectedMapConfigurationAdapter?.mapConfiguration.id)
          .subscribe(
            () => {
              const index: number = this.mapConfigurationAdapters.findIndex(
                (mca: MapConfigurationAdapter) =>
                  mca.mapConfiguration.id ===
                  this.selectedMapConfigurationAdapter?.mapConfiguration.id
              );
              this.mapConfigurationAdapters.splice(index, 1);
              this.selectedMapConfigurationAdapter =
                this.mapConfigurationAdapters[0];
              this.mapSettingsService.updateConfiguration(
                this.selectedMapConfigurationAdapter
              );
              this.notificationService.displaySuccessSnackBar(
                configurationName,
                this.translateService.instant(
                  `mapConfiguration.removeSuccessMessage`,
                  { name: configurationName }
                )
              );
            },
            (error) => {
              this.notificationService.displayErrorSnackBar(
                configurationName,
                this.translateService.instant(
                  `mapConfiguration.removeErrorMessage`,
                  { name: configurationName }
                )
              );
            }
          );
      }
    });
  }
}
