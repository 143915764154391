<div class="webclient-number-input-wrapper">
  <button
    mat-icon-button
    class="webclient-number-input-minus"
    (click)="onDecrement()"
    *ngIf="!disabled"
  >
    <mat-icon>remove_circle</mat-icon>
  </button>

  <input
    class="webclient-number-input-input"
    type="number"
    [value]="value"
    (input)="handleInput($event)"
    [readOnly]="!canEdit"
    *ngIf="!onlyIntegerDigit"
  />
  <input
    class="webclient-number-input-input"
    type="number"
    [value]="value"
    (input)="handleInput($event)"
    [readOnly]="!canEdit"
    *ngIf="onlyIntegerDigit"
  />

  <button
    mat-icon-button
    class="webclient-number-input-plus"
    (click)="onIncrement()"
    *ngIf="!disabled"
  >
    <mat-icon>add_circle</mat-icon>
  </button>
</div>
