import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatrolCheckpointListComponent } from './patrol-checkpoint-list/patrol-checkpoint-list.component';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormModule } from '../form/form.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { CardModule } from '../card/card.module';

@NgModule({
  declarations: [PatrolCheckpointListComponent],
  exports: [PatrolCheckpointListComponent],
  imports: [
    CommonModule,
    MatTableModule,
    TranslateModule,
    FormsModule,
    DragDropModule,
    FormModule,
    MatSlideToggleModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    CardModule,
  ],
})
export class PatrolCheckpointModule {}
