import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RobotSpeed } from '../../../core/enums/robot-speed.enum';

@Component({
  selector: 'webclient-robot-speed',
  templateUrl: './robot-speed.component.html',
  styleUrls: ['./robot-speed.component.scss'],
})
export class RobotSpeedComponent implements OnInit {
  /**
   * Etat de la vitesse
   */
  @Input() public mode: RobotSpeed | undefined;

  /**
   * Désactivation
   */
  @Input() public disabled: boolean = false;

  /**
   * Texte pour le mode lent
   */
  @Input() public slowText: string | undefined;

  /**
   * Texte pour le mode rapide
   */
  @Input() public fastText: string | undefined;

  /**
   * Evènement lors du click sur le bouton
   */
  @Output() public onMode = new EventEmitter<RobotSpeed>();

  /**
   * Mode
   */
  public ROBOT_SPEED = RobotSpeed;

  constructor() {}

  ngOnInit(): void {}

  public onClick(mode: RobotSpeed | undefined) {
    if (mode !== undefined) {
      this.onMode.emit(mode);
    }
  }
}
