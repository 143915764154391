<mat-card fxLayout="column">
  <div>
    <webclient-card-title
      [title]="'robot.settings'"
      [icon]="'settings'"
    ></webclient-card-title>
    <mat-divider></mat-divider>
  </div>

  <mat-card-content fxFlex fxLayout="column">
    <form [formGroup]="form" *ngIf="form" fxFlex class="admin-form">
      <fieldset>
        <legend [translate]="'robot.configuration.global'"></legend>
        <div
          fxLayout="row wrap"
          fxLayout.md="column"
          fxLayout.sm="column"
          fxLayout.xs="column"
          fxLayoutGap="20px"
        >
          <!-- Champ nom -->
          <mat-form-field>
            <mat-icon matPrefix>person</mat-icon>
            <mat-label> {{ 'robot.name' | translate }}</mat-label>
            <input
              matInput
              type="text"
              name="name"
              required="required"
              placeholder="{{ 'robot.name' | translate }}"
              formControlName="name"
            />
            <mat-error *ngFor="let validation of validationMessages.name">
              <ng-container
                *ngIf="
                  formControl?.name?.hasError(validation.type) &&
                  (formControl?.name?.dirty || formControl?.name?.touched)
                "
              >
                <span
                  [translate]="validation.message"
                  [translateParams]="validation.params ? validation.params : ''"
                ></span>
              </ng-container>
            </mat-error>
          </mat-form-field>

          <!-- Champ IP -->
          <mat-form-field *ngIf="isSuperAdmin">
            <mat-icon matPrefix>account_tree</mat-icon>
            <mat-label> {{ 'robot.ip' | translate }}</mat-label>
            <input
              matInput
              type="text"
              name="ip"
              required="required"
              placeholder="{{ 'robot.ip' | translate }}"
              formControlName="ip"
            />
            <mat-error *ngFor="let validation of validationMessages.ip">
              <ng-container
                *ngIf="
                  formControl.ip?.hasError(validation.type) &&
                  (formControl?.ip?.dirty || formControl?.ip?.touched)
                "
              >
                <span [translate]="validation.message"></span>
              </ng-container>
            </mat-error>
          </mat-form-field>

          <!-- Champ port -->
          <mat-form-field *ngIf="isSuperAdmin">
            <mat-label> {{ 'robot.port' | translate }}</mat-label>
            <input
              matInput
              type="number"
              name="port"
              placeholder="{{ 'robot.port' | translate }}"
              formControlName="port"
            />
            <mat-error *ngFor="let validation of validationMessages.port">
              <ng-container
                *ngIf="
                  formControl.port?.hasError(validation.type) &&
                  (formControl?.port?.dirty || formControl?.port?.touched)
                "
              >
                <span
                  [translate]="validation.message"
                  [translateParams]="validation.params ? validation.params : ''"
                ></span>
              </ng-container>
            </mat-error>
          </mat-form-field>

          <!-- Champ id -->
          <mat-form-field>
            <mat-label> {{ 'robot.id' | translate }}</mat-label>
            <input
              matInput
              type="number"
              name="id"
              placeholder="{{ 'robot.id' | translate }}"
              formControlName="id"
              readonly
            />
          </mat-form-field>
        </div>
      </fieldset>

      <fieldset>
        <legend [translate]="'robot.configuration.wifi'"></legend>
        <div
          fxLayout="row wrap"
          fxLayout.md="column"
          fxLayout.sm="column"
          fxLayout.xs="column"
          fxLayoutGap="20px"
        >
          <!-- Champ SSID wifi -->
          <mat-form-field>
            <mat-label> {{ 'robot.wifi.ssid' | translate }}</mat-label>
            <mat-icon matPrefix>wifi</mat-icon>
            <input
              matInput
              type="text"
              name="wifiSsid"
              required="required"
              placeholder="{{ 'robot.wifi.ssid' | translate }}"
              formControlName="wifiSsid"
            />
            <mat-error *ngFor="let validation of validationMessages.name">
              <ng-container
                *ngIf="
                  formControl.wifiSsid.hasError(validation.type) &&
                  (formControl?.wifiSsid?.dirty ||
                    formControl?.wifiSsid?.touched)
                "
              >
                <span
                  [translate]="validation.message"
                  [translateParams]="validation.params ? validation.params : ''"
                ></span>
              </ng-container>
            </mat-error>
          </mat-form-field>

          <!-- Champ mot de passe wifi -->
          <mat-form-field>
            <mat-label> {{ 'robot.wifi.password' | translate }}</mat-label>
            <mat-icon matPrefix>lock</mat-icon>
            <mat-icon
              matSuffix
              [svgIcon]="showWifiPassword ? 'eye-on' : 'eye-off'"
              (click)="showWifiPassword = !showWifiPassword"
            ></mat-icon>
            <input
              matInput
              [type]="showWifiPassword ? 'text' : 'password'"
              name="wifiPassword"
              placeholder="{{ 'robot.wifi.password' | translate }}"
              formControlName="wifiPassword"
            />
            <mat-error
              *ngFor="let validation of validationMessages.wifiPassword"
            >
              <ng-container
                *ngIf="
                  formControl.wifiPassword.hasError(validation.type) &&
                  (formControl?.wifiPassword?.dirty ||
                    formControl?.wifiPassword?.touched)
                "
              >
                <span
                  [translate]="validation.message"
                  [translateParams]="validation.params ? validation.params : ''"
                ></span>
              </ng-container>
            </mat-error>
          </mat-form-field>

          <!-- Champ IP wifi -->
          <mat-form-field>
            <mat-label> {{ 'robot.wifi.ip' | translate }}</mat-label>
            <mat-icon matPrefix>account_tree</mat-icon>
            <input
              matInput
              type="text"
              name="wifiIp"
              required="required"
              placeholder="{{ 'robot.wifi.ip' | translate }}"
              formControlName="wifiIp"
              mask="IP" [dropSpecialCharacters]="false"
            />
            <mat-error *ngFor="let validation of validationMessages.wifiIp">
              <ng-container
                *ngIf="
                  formControl.wifiIp.hasError(validation.type) &&
                  (formControl?.wifiIp?.dirty || formControl?.wifiIp?.touched)
                "
              >
                <span [translate]="validation.message"></span>
              </ng-container>
            </mat-error>
          </mat-form-field>

          <!-- Champ masque de sous réseau wifi -->
          <mat-form-field>
            <mat-label> {{ 'robot.wifi.mask' | translate }}</mat-label>
            <mat-icon matPrefix>account_tree</mat-icon>
            <input
              matInput
              type="text"
              name="wifiMask"
              required="required"
              placeholder="{{ 'robot.wifi.mask' | translate }}"
              formControlName="wifiMask"
              mask="IP" [dropSpecialCharacters]="false"
            />
            <mat-error *ngFor="let validation of validationMessages.wifiMask">
              <ng-container
                *ngIf="
                  formControl.wifiMask.hasError(validation.type) &&
                  (formControl?.wifiMask?.dirty ||
                    formControl?.wifiMask?.touched)
                "
              >
                <span [translate]="validation.message"></span>
              </ng-container>
            </mat-error>
          </mat-form-field>

          <!-- Champ IP du routeur wifi -->
          <mat-form-field>
            <mat-label> {{ 'robot.wifi.routerIp' | translate }}</mat-label>
            <mat-icon matPrefix>account_tree</mat-icon>
            <input
              matInput
              type="text"
              name="wifiRouterIp"
              required="required"
              placeholder="{{ 'robot.wifi.routerIp' | translate }}"
              formControlName="wifiRouterIp"
              mask="IP" [dropSpecialCharacters]="false"
            />
            <mat-error
              *ngFor="let validation of validationMessages.wifiRouterIp"
            >
              <ng-container
                *ngIf="
                  formControl.wifiRouterIp.hasError(validation.type) &&
                  (formControl?.wifiRouterIp?.dirty ||
                    formControl?.wifiRouterIp?.touched)
                "
              >
                <span [translate]="validation.message"></span>
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </fieldset>

      <mat-tab-group [preserveContent]="true">
        <!-- Alertes -->
        <mat-tab [label]="'robot.configuration.alerts' | translate">
          <div
            fxLayout="row"
            fxLayoutGap="20px"
            class="webclient-robot-form-tab-container"
          >
            <div
              fxFlex="15"
              fxLayout="column"
              fxLayoutGap="20px"
              class="webclient-robot-form-alerts"
            >
              <!-- Alerte par email -->
              <div fxLayout="row" fxLayoutGap="20px">
                <mat-slide-toggle
                  formControlName="emailAlert"
                  (change)="onChangeEmailAlert($event)"
                ></mat-slide-toggle>
                <span class="mat-slide-toggle-label">
                  {{ 'robot.alert.emailAlert' | translate }}
                </span>
              </div>

              <!-- Alerte par téléphone -->
              <div fxLayout="row" fxLayoutGap="20px">
                <mat-slide-toggle
                  formControlName="phoneAlert"
                  (change)="onChangePhoneAlert($event)"
                ></mat-slide-toggle>
                <span class="mat-slide-toggle-label">
                  {{ 'robot.alert.phoneAlert' | translate }}
                </span>
              </div>

              <!-- Alerte anomalies de patrouille -->
              <div fxLayout="row" fxLayoutGap="20px">
                <mat-slide-toggle
                  formControlName="patrolAnomaliesAlert"
                ></mat-slide-toggle>
                <span class="mat-slide-toggle-label">
                  {{ 'robot.alert.patrolAnomaliesAlert' | translate }}
                </span>
              </div>

              <!-- Alerte brouillage RF -->
              <div fxLayout="row" fxLayoutGap="20px">
                <mat-slide-toggle
                  formControlName="rfJammingAlert"
                ></mat-slide-toggle>
                <span class="mat-slide-toggle-label">
                  {{ 'robot.alert.rfJammingAlert' | translate }}
                </span>
              </div>

              <!-- Alerte incendie -->
              <div fxLayout="row" fxLayoutGap="20px">
                <mat-slide-toggle
                  formControlName="fireAlarm"
                ></mat-slide-toggle>
                <span class="mat-slide-toggle-label">
                  {{ 'robot.alert.fireAlarm' | translate }}
                </span>
              </div>
            </div>

            <div fxLayoutGap="20px">
              <!-- Champ email -->
              <mat-form-field>
                <mat-icon matPrefix>mail</mat-icon>
                <input
                  matInput
                  type="text"
                  name="email"
                  placeholder="{{ 'robot.email' | translate }}"
                  formControlName="email"
                />
                <mat-error *ngFor="let validation of validationMessages.email">
                  <ng-container
                    *ngIf="
                      formControl.email?.hasError(validation.type) &&
                      (formControl?.email?.dirty || formControl?.email?.touched)
                    "
                  >
                    <span
                      [translate]="validation.message"
                      [translateParams]="
                        validation.params ? validation.params : ''
                      "
                    ></span>
                  </ng-container>
                </mat-error>
              </mat-form-field>

              <!-- Champ téléphone -->
              <mat-form-field>
                <mat-icon matPrefix>phone</mat-icon>
                <input
                  matInput
                  type="text"
                  name="phone"
                  placeholder="{{ 'robot.phone' | translate }}"
                  formControlName="phone"
                />
                <mat-error *ngFor="let validation of validationMessages.email">
                  <ng-container
                    *ngIf="
                      formControl.phone?.hasError(validation.type) &&
                      (formControl?.phone?.dirty || formControl?.phone?.touched)
                    "
                  >
                    <span
                      [translate]="validation.message"
                      [translateParams]="
                        validation.params ? validation.params : ''
                      "
                    ></span>
                  </ng-container>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-tab>

        <!-- Module interfaces externes -->
        <mat-tab [label]="'robot.configuration.externalInterfaces' | translate">
          <div
            fxLayout="column"
            fxLayoutGap="20px"
            class="webclient-robot-form-tab-container"
          >
            <!-- Module interfaces externes -->
            <div fxLayout="row" fxLayoutGap="20px">
              <mat-slide-toggle
                formControlName="externalInterfaces"
                (change)="onChangeExternalInterfaces($event)"
              ></mat-slide-toggle>
              <span class="mat-slide-toggle-label">
                {{ 'robot.configuration.externalInterfaces' | translate }}
              </span>
            </div>

            <div
              class="outputs"
              [class.disabled]="!formControl.externalInterfaces.value"
              fxLayout="column"
              formArrayName="outputs"
            >
              <h2>{{ 'robot.externalInterfaces.outputs' | translate }}</h2>

              <div
                fxLayout="row"
                fxLayout.lg="column"
                fxLayoutAlign="center"
                fxLayoutGap="20px"
              >
                <div fxLayout="column" fxLayoutAlign="center" *ngFor="let externalOutput of externalOutputs">
                  <ngx-rodotic-led
                    [textLedPosition]="'top'"
                    [text]="externalOutput.controls.id.value"
                    [size]="'medium'"
                  ></ngx-rodotic-led>
                  <mat-form-field fxFlex="70" [formGroup]="externalOutput">
                    <mat-label>
                      {{ 'robot.alarmsAnomalies' | translate }}
                    </mat-label>
                    <mat-select
                      [formControl]="externalOutput.controls.alerts"
                      multiple
                      [disabled]="!formControl.externalInterfaces.value"
                    >
                      <mat-optgroup
                        *ngFor="let group of outputGroups"
                        label="{{ group.name | translate }}"
                      >
                        <mat-option
                          *ngFor="let value of group.values"
                          [value]="value"
                        >{{ group.prefix + value | translate }}
                        </mat-option
                        >
                      </mat-optgroup>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div
              class="inputs"
              [class.disabled]="formControl.externalInterfaces.value === false"
            >
              <h2>{{ 'robot.externalInterfaces.inputs' | translate }}</h2>

              <div fxLayout="row" fxLayoutAlign="space-around">
                <ng-container *ngIf="robotAdapter">
                  <ngx-rodotic-led
                    *ngFor="let bieValue of robotAdapter.bieValues"
                    [textLedPosition]="'bottom'"
                    [text]="bieValue.x.toString()"
                    [size]="'medium'"
                    [ledState]="
                      formControl.externalInterfaces.value === true
                        ? 'on'
                        : 'off'
                    "
                    [ledColor]="bieValue.y === true ? 'green' : 'red'"
                  ></ngx-rodotic-led>
                </ng-container>
              </div>
            </div>
          </div>
        </mat-tab>

        <!-- Module interfaces genetec -->
        <mat-tab [label]="'robot.configuration.genetecInterfaces' | translate">
          <div
            fxLayout="column"
            fxLayoutGap="20px"
            class="webclient-robot-form-tab-container"
          >

            <!-- Module interfaces genetec enable -->
            <div fxLayout="row" fxLayoutGap="20px">
              <mat-slide-toggle
                formControlName="genetec"
                (change)="onChangeGenetecInterfaces($event)"
              ></mat-slide-toggle>
              <span class="mat-slide-toggle-label">
                {{ 'robot.configuration.genetecInterfaces' | translate }}
              </span>
            </div>

            <div fxLayoutAlign="center center" flex fxLayout="column">
              <div fxLayoutAlign="center center" flex fxLayout="row">
                <!-- Champ genetec server ip -->
                <mat-form-field>
                  <mat-label>
                    Server Ip
                  </mat-label>
                  <mat-icon matPrefix>notifications</mat-icon>
                  <input
                    matInput
                    type="text"
                    name="genetecServerIp"
                    placeholder="{{ 'robot.genetecInterfaces.serverip' | translate }}"
                    formControlName="genetecServerIp"
                  />
                  <mat-error *ngFor="let validation of validationMessages.genetecServerIp">
                    <ng-container
                      *ngIf="formControl.genetecServerIp?.hasError(validation.type) &&
                        (formControl?.genetecServerIp?.dirty || formControl?.genetecServerIp?.touched)
                      "
                    >
                      <span [translate]="validation.message"></span>
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <!-- Champ genetec server port -->
                <mat-form-field>
                  <mat-label>
                    Server Port
                  </mat-label>
                  <mat-icon matPrefix>notifications</mat-icon>
                  <input
                    matInput
                    type="text"
                    name="genetecServerPort"
                    placeholder="{{ 'robot.genetecInterfaces.serverport' | translate }}"
                    formControlName="genetecServerPort"
                  />
                  <mat-error *ngFor="let validation of validationMessages.genetecServerPort">
                    <ng-container
                      *ngIf="
                        formControl.genetecServerPort?.hasError(validation.type) &&
                        (formControl?.genetecServerPort?.dirty || formControl?.genetecServerPort?.touched)
                      "
                    >
                    <span [translate]="validation.message"
                          [translateParams]="validation.params ? validation.params : ''"></span>
                    </ng-container>
                  </mat-error>
                </mat-form-field>
              </div>

              <div fxLayoutAlign="center center" flex fxLayout="row">
                <!-- Champ genetec server username -->
                <mat-form-field>
                  <mat-label>
                    Username
                  </mat-label>
                  <mat-icon matPrefix>notifications</mat-icon>
                  <input
                    matInput
                    type="text"
                    name="genetecUsername"
                    placeholder="{{ 'robot.genetecInterfaces.username' | translate }}"
                    formControlName="genetecUsername"
                  />
                  <mat-error *ngFor="let validation of validationMessages.genetecUsername">
                    <ng-container
                      *ngIf="
                        formControl.genetecUsername?.hasError(validation.type) &&
                        (formControl?.genetecUsername?.dirty || formControl?.genetecUsername?.touched)
                      "
                    >
                      <span [translate]="validation.message"></span>
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <!-- Champ genetec server password -->
                <mat-form-field>
                  <mat-label>
                    Password
                  </mat-label>
                  <mat-icon matPrefix>notifications</mat-icon>
                  <input
                    matInput
                    type="text"
                    name="genetecPassword"
                    placeholder="{{ 'robot.genetecInterfaces.password' | translate }}"
                    formControlName="genetecPassword"
                  />
                  <mat-error *ngFor="let validation of validationMessages.genetecPassword">
                    <ng-container
                      *ngIf="
                        formControl.genetecPassword?.hasError(validation.type) &&
                        (formControl?.genetecPassword?.dirty || formControl?.genetecPassword?.touched)
                      "
                    >
                      <span [translate]="validation.message"
                            [translateParams]="validation.params ? validation.params : ''"></span>
                    </ng-container>
                  </mat-error>
                </mat-form-field>
              </div>

              <div fxLayoutAlign="center center" flex fxLayout="row">
                <!-- Champ genetec server protocol -->
                <mat-form-field>
                  <mat-label>
                    Serveur Protocol
                  </mat-label>
                  <mat-icon matPrefix>notifications</mat-icon>
                  <mat-select formControlName="genetecProtocol">
                    <mat-option value="https">Https</mat-option>
                    <mat-option value="http">Http</mat-option>
                  </mat-select>
                  <mat-error *ngFor="let validation of validationMessages.genetecProtocol">
                    <ng-container
                      *ngIf="
                        formControl.genetecProtocol?.hasError(validation.type) &&
                        (formControl?.genetecProtocol?.dirty || formControl?.genetecProtocol?.touched)
                      "
                    >
                      <span [translate]="validation.message"></span>
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <!-- Champ genetec server certificate -->
                <mat-form-field>
                  <mat-label>
                    Certificate
                  </mat-label>
                  <mat-icon matPrefix>notifications</mat-icon>
                  <input
                    matInput
                    type="text"
                    name="genetecCertificate"
                    placeholder="{{ 'robot.genetecInterfaces.certificate' | translate }}"
                    formControlName="genetecCertificate"
                  />
                  <mat-error *ngFor="let validation of validationMessages.genetecCertificate">
                    <ng-container
                      *ngIf="
                        formControl.genetecCertificate?.hasError(validation.type) &&
                        (formControl?.genetecCertificate?.dirty || formControl?.genetecCertificate?.touched)
                      "
                    >
                      <span [translate]="validation.message"></span>
                    </ng-container>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <!-- Module interfaces genetec outputs -->
            <div
              class="outputs"
              [class.disabled]="formControl.genetec.value === false"
              fxLayout="column"
              formArrayName="genetecOutputs"
            >
              <h2>{{ 'robot.genetecInterfaces.outputs' | translate }}</h2>

              <div fxLayoutAlign="center center" flex fxLayout="column">

                <div *ngFor="let group of genetecOutputs; let i = index" [formGroup]="group" fxLayout="row"
                     fxLayoutAlign="center baseline">
                  <ngx-rodotic-led
                    [size]="'medium'"
                  ></ngx-rodotic-led>
                  <mat-form-field fxFlex="70">
                    <mat-label>
                      {{ 'robot.alarmsAnomalies' | translate }}
                    </mat-label>
                    <mat-select
                      [formControl]="group.controls.alerts"
                      multiple
                      [disabled]="!formControl.genetec.value"
                    >
                      <mat-optgroup
                        *ngFor="let outputGroup of outputGroups"
                        [label]="outputGroup.name | translate"
                      >
                        <mat-option
                          *ngFor="let value of outputGroup.values"
                          [value]="value"
                        >{{ outputGroup.prefix + value | translate }}
                        </mat-option
                        >
                      </mat-optgroup>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field fxFlex="70" [formControl]="group.controls.id">
                    <mat-label>
                      {{ 'ID' | translate }}
                    </mat-label>
                    <input matInput formControlName="id" placeholder="ID">
                  </mat-form-field>
                  <mat-form-field fxFlex="70" [formControl]="group.controls.name">
                    <mat-label>
                      {{ 'NAME' | translate }}
                    </mat-label>
                    <input matInput formControlName="name" placeholder="NAME">
                  </mat-form-field>
                  <button mat-icon-button color="warn" (click)="removeOutput(group)">
                    <mat-icon>remove_circle</mat-icon>
                  </button>
                </div>
              </div>

              <button mat-raised-button color="primary" (click)="addGenetecOutput()">
                {{ 'Ajouter une sortie' | translate }}
              </button>
            </div>

            <!-- Module interfaces genetec inputs -->
            <div
              class="inputs"
              [class.disabled]="formControl.genetec.value === false"
              fxLayout="column"
              formArrayName="genetecInputs"
            >
              <h2>{{ 'robot.genetecInterfaces.inputs' | translate }}</h2>

              <div fxLayoutAlign="center center" flex fxLayout="column">

                <div *ngFor="let group of genetecInputs; let i = index" [formGroup]="group" fxLayout="row"
                     fxLayoutAlign="center baseline">
                  <ngx-rodotic-led
                    [size]="'medium'"
                  ></ngx-rodotic-led>
                  <mat-form-field fxFlex="70">
                    <mat-label>
                      {{ 'ID' | translate }}
                    </mat-label>
                    <input matInput [formControl]="group.controls.value" placeholder="ID">
                  </mat-form-field>
                  <mat-form-field fxFlex="70">
                    <mat-label>
                      {{ 'NAME' | translate }}
                    </mat-label>
                    <input matInput [formControl]="group.controls.name" placeholder="NAME">
                  </mat-form-field>
                  <button mat-icon-button color="warn" (click)="removeRobotInput(group)">
                    <mat-icon>remove_circle</mat-icon>
                  </button>
                </div>
              </div>

              <button mat-raised-button color="primary" (click)="addGenetecInput()">
                {{ 'Ajouter une entrée' | translate }}
              </button>
            </div>

          </div>
        </mat-tab>

        <!-- Protocole ONVIF -->
        <mat-tab [label]="'robot.configuration.onvif' | translate">
          <div
            fxLayout="column"
            fxLayoutGap="20px"
            class="webclient-robot-form-tab-container"
          >
            <div fxLayout="row" fxLayoutGap="20px">
              <mat-slide-toggle
                formControlName="onvif"
                (change)="onChangeonvifProtocol($event)"
              ></mat-slide-toggle>
              <span class="mat-slide-toggle-label">
                {{ 'robot.configuration.onvif' | translate }}
              </span>
            </div>

            <!-- Champ nom d'hôte et ports onvif -->
            <div
              fxLayout="row"
              fxLayout.sm="column"
              fxLayout.xs="column"
              fxLayoutGap="20px"
            >
              <!-- Champ nom d'hôte -->
              <mat-form-field>
                <mat-label>
                  {{ 'robot.onvif.hostname' | translate }}
                </mat-label>
                <mat-icon matPrefix>monitor</mat-icon>
                <input
                  matInput
                  type="text"
                  name="onvifHostname"
                  placeholder="{{ 'robot.onvif.hostname' | translate }}"
                  formControlName="onvifHostname"
                  [readonly]="!isSuperAdmin"
                />
                <mat-error
                  *ngFor="let validation of validationMessages.onvifHostname"
                >
                  <ng-container
                    *ngIf="
                      formControl.onvifHostname.hasError(validation.type) &&
                      (formControl.onvifHostname.dirty ||
                        formControl.onvifHostname.touched)
                    "
                  >
                    <span
                      [translate]="validation.message"
                      [translateParams]="
                        validation.params ? validation.params : ''
                      "
                    ></span>
                  </ng-container>
                </mat-error>
              </mat-form-field>

              <!-- Champ port Rtsp onvif -->
              <mat-form-field>
                <mat-label>
                  {{ 'robot.onvif.rtspPort' | translate }}
                </mat-label>
                <mat-icon matPrefix>monitor</mat-icon>
                <input
                  matInput
                  type="number"
                  name="onvifRtspPort"
                  placeholder="{{ 'robot.onvif.rtspPort' | translate }}"
                  formControlName="onvifRtspPort"
                  [readonly]="!isSuperAdmin"
                />
                <mat-error
                  *ngFor="let validation of validationMessages.onvifRtspPort"
                >
                  <ng-container
                    *ngIf="
                      formControl.onvifRtspPort.hasError(validation.type) &&
                      (formControl.onvifRtspPort.dirty ||
                        formControl.onvifRtspPort.touched)
                    "
                  >
                    <span
                      [translate]="validation.message"
                      [translateParams]="
                        validation.params ? validation.params : ''
                      "
                    ></span>
                  </ng-container>
                </mat-error>
              </mat-form-field>

              <!-- Champ port Http onvif -->
              <mat-form-field>
                <mat-label>
                  {{ 'robot.onvif.httpPort' | translate }}
                </mat-label>
                <mat-icon matPrefix>monitor</mat-icon>
                <input
                  matInput
                  type="number"
                  name="onvifHttpPort"
                  placeholder="{{ 'robot.onvif.httpPort' | translate }}"
                  formControlName="onvifHttpPort"
                  [readonly]="!isSuperAdmin"
                />
                <mat-error
                  *ngFor="let validation of validationMessages.onvifHttpPort"
                >
                  <ng-container
                    *ngIf="
                      formControl.onvifHttpPort.hasError(validation.type) &&
                      (formControl.onvifHttpPort.dirty ||
                        formControl.onvifHttpPort.touched)
                    "
                  >
                    <span
                      [translate]="validation.message"
                      [translateParams]="
                        validation.params ? validation.params : ''
                      "
                    ></span>
                  </ng-container>
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Champ nom d'utilisateur et champ mot de passe onvif -->
            <div
              fxLayout="row"
              fxLayout.sm="column"
              fxLayout.xs="column"
              fxLayoutGap="20px"
            >
              <!-- Champ nom d'utilisateur onvif -->
              <mat-form-field>
                <mat-label>
                  {{ 'robot.onvif.username' | translate }}
                </mat-label>
                <mat-icon matPrefix>person</mat-icon>
                <input
                  matInput
                  type="text"
                  name="onvifUsername"
                  placeholder="{{ 'robot.onvif.username' | translate }}"
                  formControlName="onvifUsername"
                />
                <mat-error
                  *ngFor="let validation of validationMessages.onvifUsername"
                >
                  <ng-container
                    *ngIf="
                      formControl.onvifUsername.hasError(validation.type) &&
                      (formControl.onvifUsername.dirty ||
                        formControl.onvifUsername.touched)
                    "
                  >
                    <span
                      [translate]="validation.message"
                      [translateParams]="
                        validation.params ? validation.params : ''
                      "
                    ></span>
                  </ng-container>
                </mat-error>
              </mat-form-field>

              <!-- Champ mot de passe onvif -->
              <mat-form-field>
                <mat-label>
                  {{ 'robot.onvif.password' | translate }}
                </mat-label>
                <mat-icon matPrefix>lock</mat-icon>
                <mat-icon
                  matSuffix
                  [svgIcon]="showonvifPassword ? 'eye-on' : 'eye-off'"
                  (click)="showonvifPassword = !showonvifPassword"
                ></mat-icon>
                <input
                  matInput
                  [type]="showonvifPassword ? 'text' : 'password'"
                  name="sftpPassword"
                  placeholder="{{ 'robot.onvif.password' | translate }}"
                  formControlName="onvifPassword"
                />
                <mat-error
                  *ngFor="let validation of validationMessages.onvifPassword"
                >
                  <ng-container
                    *ngIf="
                      formControl.onvifPassword.hasError(validation.type) &&
                      (formControl.onvifPassword.dirty ||
                        formControl.onvifPassword.touched)
                    "
                  >
                    <span
                      [translate]="validation.message"
                      [translateParams]="
                        validation.params ? validation.params : ''
                      "
                    ></span>
                  </ng-container>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-tab>

        <!-- Module SFTP -->
        <mat-tab [label]="'robot.configuration.sftp' | translate">
          <div
            fxLayout="column"
            fxLayoutGap="20px"
            class="webclient-robot-form-tab-container"
          >
            <div fxLayout="row" fxLayoutGap="20px">
              <mat-slide-toggle
                formControlName="sftp"
                (change)="onChangeSftpModule($event)"
              ></mat-slide-toggle>
              <span class="mat-slide-toggle-label">
                {{ 'robot.configuration.sftp' | translate }}
              </span>
            </div>

            <!-- Champ nom d'hôte et port SFTP -->
            <div
              fxLayout="row"
              fxLayout.sm="column"
              fxLayout.xs="column"
              fxLayoutGap="20px"
            >
              <!-- Champ nom d'hôte -->
              <mat-form-field>
                <mat-label> {{ 'robot.sftp.hostname' | translate }}</mat-label>
                <mat-icon matPrefix>monitor</mat-icon>
                <input
                  matInput
                  type="text"
                  name="sftpHostname"
                  placeholder="{{ 'robot.sftp.hostname' | translate }}"
                  formControlName="sftpHostname"
                />
                <mat-error
                  *ngFor="let validation of validationMessages.sftpHostname"
                >
                  <ng-container
                    *ngIf="
                      formControl.sftpHostname.hasError(validation.type) &&
                      (formControl.sftpHostname.dirty ||
                        formControl.sftpHostname.touched)
                    "
                  >
                    <span
                      [translate]="validation.message"
                      [translateParams]="
                        validation.params ? validation.params : ''
                      "
                    ></span>
                  </ng-container>
                </mat-error>
              </mat-form-field>

              <!-- Champ port SFTP -->
              <mat-form-field>
                <mat-label> {{ 'robot.sftp.hostname' | translate }}</mat-label>
                <mat-icon matPrefix>monitor</mat-icon>
                <input
                  matInput
                  type="number"
                  name="sftpPort"
                  placeholder="{{ 'robot.sftp.port' | translate }}"
                  formControlName="sftpPort"
                />
                <mat-error
                  *ngFor="let validation of validationMessages.sftpPort"
                >
                  <ng-container
                    *ngIf="
                      formControl.sftpPort.hasError(validation.type) &&
                      (formControl.sftpPort.dirty ||
                        formControl.sftpPort.touched)
                    "
                  >
                    <span
                      [translate]="validation.message"
                      [translateParams]="
                        validation.params ? validation.params : ''
                      "
                    ></span>
                  </ng-container>
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Champ nom d'utilisateur et mot de passe SFTP -->
            <div
              fxLayout="row"
              fxLayout.sm="column"
              fxLayout.xs="column"
              fxLayoutGap="20px"
            >
              <!-- Champ nom d'utilisateur SFTP -->
              <mat-form-field>
                <mat-label> {{ 'robot.sftp.username' | translate }}</mat-label>
                <mat-icon matPrefix>person</mat-icon>
                <input
                  matInput
                  type="text"
                  name="sftpUsername"
                  placeholder="{{ 'robot.sftp.username' | translate }}"
                  formControlName="sftpUsername"
                />
                <mat-error
                  *ngFor="let validation of validationMessages.sftpUsername"
                >
                  <ng-container
                    *ngIf="
                      formControl.sftpUsername.hasError(validation.type) &&
                      (formControl.sftpUsername.dirty ||
                        formControl.sftpUsername.touched)
                    "
                  >
                    <span
                      [translate]="validation.message"
                      [translateParams]="
                        validation.params ? validation.params : ''
                      "
                    ></span>
                  </ng-container>
                </mat-error>
              </mat-form-field>

              <!-- Champ mot de passe SFTP -->
              <mat-form-field>
                <mat-label> {{ 'robot.sftp.password' | translate }}</mat-label>
                <mat-icon matPrefix>lock</mat-icon>
                <mat-icon
                  matSuffix
                  [svgIcon]="showSftpPassword ? 'eye-on' : 'eye-off'"
                  (click)="showSftpPassword = !showSftpPassword"
                ></mat-icon>
                <input
                  matInput
                  [type]="showSftpPassword ? 'text' : 'password'"
                  name="sftpConfirmPassword"
                  placeholder="{{ 'robot.sftp.password' | translate }}"
                  formControlName="sftpPassword"
                />
                <mat-error
                  *ngFor="let validation of validationMessages.sftpPassword"
                >
                  <ng-container
                    *ngIf="
                      formControl.sftpPassword.hasError(validation.type) &&
                      (formControl.sftpPassword.dirty ||
                        formControl.sftpPassword.touched)
                    "
                  >
                    <span
                      [translate]="validation.message"
                      [translateParams]="
                        validation.params ? validation.params : ''
                      "
                    ></span>
                  </ng-container>
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Champ nom répertoire distant SFTP -->
            <div
              fxLayout="row"
              fxLayout.sm="column"
              fxLayout.xs="column"
              fxLayoutGap="20px"
            >
              <mat-form-field>
                <mat-label>
                  {{ 'robot.sftp.remoteFolder' | translate }}
                </mat-label>
                <mat-icon matPrefix>folder</mat-icon>
                <input
                  matInput
                  type="text"
                  name="sftpRemoteFolder"
                  placeholder="{{ 'robot.sftp.remoteFolder' | translate }}"
                  formControlName="sftpRemoteFolder"
                />
                <mat-error
                  *ngFor="let validation of validationMessages.sftpRemoteFolder"
                >
                  <ng-container
                    *ngIf="
                      formControl.sftpRemoteFolder.hasError(validation.type) &&
                      (formControl.sftpRemoteFolder.dirty ||
                        formControl.sftpRemoteFolder.touched)
                    "
                  >
                    <span
                      [translate]="validation.message"
                      [translateParams]="
                        validation.params ? validation.params : ''
                      "
                    ></span>
                  </ng-container>
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Champs durée d'enregistrement et délai d'enregistrement SFTP -->
            <div
              fxLayout="row"
              fxLayout.sm="column"
              fxLayout.xs="column"
              fxLayoutGap="20px"
            >
              <!-- Champ durée d'enregistrement SFTP -->
              <mat-form-field>
                <mat-label>
                  {{ 'robot.sftp.recordDuration' | translate }}
                </mat-label>
                <mat-icon matPrefix>schedule</mat-icon>
                <input
                  matInput
                  type="number"
                  min="0"
                  name="sftpRecordDuration"
                  placeholder="{{ 'robot.sftp.recordDuration' | translate }}"
                  formControlName="sftpRecordDuration"
                />
                <mat-error
                  *ngFor="
                    let validation of validationMessages.sftpRecordDuration
                  "
                >
                  <ng-container
                    *ngIf="
                      formControl.sftpRecordDuration.hasError(
                        validation.type
                      ) &&
                      (formControl.sftpRecordDuration.dirty ||
                        formControl.sftpRecordDuration.touched)
                    "
                  >
                    <span [translate]="validation.message"></span>
                  </ng-container>
                </mat-error>
              </mat-form-field>

              <!-- Champ délai d'enregistrement SFTP -->
              <mat-form-field>
                <mat-label>
                  {{ 'robot.sftp.delayBetweenRecord' | translate }}
                </mat-label>
                <mat-icon matPrefix>history</mat-icon>
                <input
                  matInput
                  type="number"
                  min="0"
                  name="sftpDelayBetweenRecord"
                  placeholder="{{
                    'robot.sftp.delayBetweenRecord' | translate
                  }}"
                  formControlName="sftpDelayBetweenRecord"
                />
                <mat-error
                  *ngFor="
                    let validation of validationMessages.sftpDelayBetweenRecord
                  "
                >
                  <ng-container
                    *ngIf="
                      formControl.sftpDelayBetweenRecord.hasError(
                        validation.type
                      ) &&
                      (formControl.sftpDelayBetweenRecord.dirty ||
                        formControl.sftpDelayBetweenRecord.touched)
                    "
                  >
                    <span [translate]="validation.message"></span>
                  </ng-container>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
  </mat-card-content>

  <mat-card-actions fxLayout="row" fxLayoutAlign="end">
    <button
      mat-flat-button
      color="primary"
      (click)="onSubmit()"
      [disabled]="shouldDisableSubmit()"
    >
      {{ 'entity.save' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
