import { Component, Input, ViewEncapsulation } from '@angular/core';
import { RobotAdapter } from '../../../core/models/entities/robot-adapter';
import { RobotService } from '../../../core/services/entities/robot.service';
import { ActivatedRoute } from '@angular/router';
import { Robot } from '../../../api/models/robot';
import { RoslibService } from '@lelab31/ngx-rodotic';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { StrategyName } from '../../../core/enums/strategy-name.enum';
@Component({
  selector: 'webclient-robot-toolbar',
  templateUrl: './robot-toolbar.component.html',
  styleUrls: ['./robot-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'webclient-robot-toolbar',
  },
})
export class RobotToolbarComponent {

  /**
   * Robot
   */
  @Input() public robotAdapter: RobotAdapter | undefined;

  /**
   * Type de la stratégie EMERGENCY_STOP
   */
  public emergencyStrategyType = StrategyName.EMERGENCY_STOP;

  constructor(private route: ActivatedRoute, private robotService: RobotService, private rosLibService: RoslibService, private breakpointObserver: BreakpointObserver) {
    const id: number = this.route.snapshot.params['robotId'];

    this.robotService.getById(id).subscribe((robot: Robot) => {
      this.robotAdapter = new RobotAdapter(robot, this.rosLibService);
    });
  }
  /*
  *la fonction qui utilise un breakpoint pour redimensionner les icônes sur de petits écrans
  */
  public isSmallScreen(): boolean{
    return this.breakpointObserver.isMatched([Breakpoints.Small,Breakpoints.XSmall])
  }
  /**
   * Clique sur le bouton d'urgence
   *
   * @param value la valeur
   */
  public onEmergencyClick(value: boolean) {
    if (this.robotAdapter) {
      this.robotAdapter.emergencyCommand(!value);
    }
  }
}
