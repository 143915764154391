import { Component, Input, ViewEncapsulation } from '@angular/core';
import {
  EditStrategy,
  MapPointService,
  VisualizationStrategy,
} from '@lelab31/ngx-rodotic';
import { MapPointType } from '../../../core/enums/map-point-type.enum';
import { Utils } from '../../../core/utils/utils';
import { RobotAdapter } from '../../../core/models/entities/robot-adapter';

@Component({
  selector: 'webclient-map-control',
  templateUrl: './map-control.component.html',
  styleUrls: ['./map-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'webclient-map-control',
  },
})
export class MapControlComponent {
  /**
   * Le robot
   */
  @Input() public robotAdapter: RobotAdapter | undefined;

  constructor(private mapPointService: MapPointService) {}

  public onChangVisibility() {
    this.mapPointService.changeStrategy(new VisualizationStrategy());
  }

  public onChangeNicheMode() {
    this.mapPointService.changeStrategy(
      new EditStrategy(MapPointType.NICHE, true, true, Utils.createNiche())
    );
  }

  public onChangeCheckpointMode() {
    this.mapPointService.changeStrategy(
      new EditStrategy(
        MapPointType.CHECKPOINT,
        false,
        false,
        Utils.createMapCheckpoint()
      )
    );
  }
}
