import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { IEntityAdapter } from '../models/i-entity-adapter';
import { Role } from '../../../core/enums/role.enum';
import { BaseEntityListComponent } from '../base-entity-list/base-entity-list.component';

@Component({
  selector: 'webclient-entity-list',
  templateUrl: './entity-list.component.html',
  styleUrls: ['./entity-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'webclient-entity-list',
  },
})
export class EntityListComponent
  extends BaseEntityListComponent
  implements OnChanges
{
  /**
   * Source des données
   */
  @Input() public dataSource: MatTableDataSource<IEntityAdapter> =
    new MatTableDataSource<IEntityAdapter>([]);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['roles']?.currentValue) {
      this.roles.push(Role.ROLE_SUPER_ADMIN);
    }

    if (changes['entities']?.currentValue) {
      this.dataSource = new MatTableDataSource<IEntityAdapter>(this.entities);
    }

    if (changes['urlForm'] && changes['urlForm'].currentValue) {
      this.urlForm = this.urlForm + '/form/';
    }
  }
}
