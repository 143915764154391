import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardTitleComponent } from './card-title/card-title.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [CardTitleComponent],
  exports: [CardTitleComponent],
  imports: [CommonModule, MatIconModule, FlexLayoutModule, TranslateModule],
})
export class CardModule {}
