import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapDialogComponent } from './map-dialog/map-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { CardModule } from '../card/card.module';
import { MatSelectModule } from '@angular/material/select';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MapItemComponent } from './map-item/map-item.component';
import { FormModule } from '../form/form.module';
import { RouterModule } from '@angular/router';
import { Ros2dModule } from '@lelab31/ngx-rodotic';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MapControlComponent } from './map-control/map-control.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CoreModule } from '../../core/core.module';

@NgModule({
  declarations: [MapDialogComponent, MapItemComponent, MapControlComponent],
  exports: [MapDialogComponent, MapItemComponent, MapControlComponent],
  imports: [
    CommonModule,
    CardModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    MatIconModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule,
    FormModule,
    RouterModule,
    Ros2dModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatToolbarModule,
    MatButtonToggleModule,
    CoreModule,
  ],
})
export class MapModule {}
