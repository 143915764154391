<div
  fxLayout="row"
  fxLayout.sm="column"
  fxLayout.xs="column"
  fxLayoutAlign="start end"
  fxLayoutAlign.sm="start"
  fxLayoutAlign.xs="start"
  fxLayoutGap="20px"
  fxFlex="50"
>
  <div
    fxLayout="row"
    fxLayout.sm="column"
    fxLayout.xs="column"
    fxLayoutAlign="start end"
    fxLayoutAlign.sm="start"
    fxLayoutAlign.xs="start"
    fxLayoutGap="20px"
    fxFlex="50"
  >
    <ng-container *ngFor="let robot of data; let i = index">
      <mat-checkbox
        [checked]="isSelected(robot)"
        (change)="onToggle($event, robot)"
      >
        <span> {{ robot.name }} </span>
      </mat-checkbox>
    </ng-container>
  </div>
</div>
