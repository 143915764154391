<div
  fxLayout="row"
  fxLayout.md="column"
  fxLayout.sm="column"
  fxLayout.xs="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="20px"
>
  <webclient-robot-control
    fxFlex="40"
    [robotAdapter]="robotAdapter"
  ></webclient-robot-control>
  <webclient-robot-settings
    fxFlex="60"
    [robotAdapter]="robotAdapter"
  ></webclient-robot-settings>
</div>
