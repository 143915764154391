<webclient-entity-nested-list
  class="webclient-user-group-list"
  [displayedColumns]="displayedColumns"
  [urlForm]="'/pages/admin/usergroup'"
  [title]="'usergroup.usergroups'"
  [icon]="'groups'"
  [text]="text"
  (onRemoveEntity)="onRemove($event)"
  [entities]="userGroupAdapters"
  [roles]="[Role.ROLE_ADMIN]"
>
  <!-- Colonne nom -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>
      {{ 'usergroup.name' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let userGroupAdapter">
      <div>
        {{ userGroupAdapter?.name }}
      </div>
    </mat-cell>
  </ng-container>

  <!-- Colonne client -->
  <ng-container matColumnDef="customer">
    <mat-header-cell *matHeaderCellDef>
      {{ 'usergroup.customer' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let userGroupAdapter">
      <span> {{ userGroupAdapter.userGroup.customer.name }} </span>
    </mat-cell>
  </ng-container>

  <!-- Colonne nombre d'utilisateurs -->
  <ng-container matColumnDef="users">
    <mat-header-cell *matHeaderCellDef>
      {{ 'usergroup.users' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let userGroupAdapter">
      <span matBadge="{{ userGroupAdapter.userGroup.users?.length }}"></span>
    </mat-cell>
  </ng-container>

  <!-- Colonne nombre de robots -->
  <ng-container matColumnDef="robots">
    <mat-header-cell *matHeaderCellDef>
      {{ 'usergroup.robots' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let userGroupAdapter">
      <span matBadge="{{ userGroupAdapter.userGroup.robots?.length }}"></span>
    </mat-cell>
  </ng-container>

  <!-- Colonne nombre de zones -->
  <ng-container matColumnDef="zones">
    <mat-header-cell *matHeaderCellDef>
      {{ 'usergroup.zones' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let userGroupAdapter">
      <span matBadge="{{ userGroupAdapter.userGroup.zones?.length }}"></span>
    </mat-cell>
  </ng-container>

  <!-- Colonne groupes -->
  <ng-container matColumnDef="groups">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let userGroupAdapter">
      <a
        mat-icon-button
        title="{{ 'usergroup.add' | translate }}"
        routerLink="/pages/admin/usergroup/add/{{ userGroupAdapter?.id }}"
      >
        <mat-icon>group_add</mat-icon>
      </a>
    </mat-cell>
  </ng-container>
</webclient-entity-nested-list>
