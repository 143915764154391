import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { MessageType } from '../../../core/enums/message-type.enum';

@Component({
  selector: 'webclient-message-snack-bar',
  templateUrl: './message-snack-bar.component.html',
  styleUrls: ['./message-snack-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MessageSnackBarComponent {
  /**
   * Titre
   */
  public title: string;

  /**
   * Message
   */
  public message: string;

  /**
   * Type
   */
  public type: MessageType;

  /**
   * Liste des types de message
   */
  public MessageType = MessageType;

  constructor(
    public snackBarRef: MatSnackBarRef<MessageSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      title: string;
      message: string;
      type: MessageType;
      action: string;
    }
  ) {
    this.title = this.data.title;
    this.message = this.data.message;
    this.type = this.data.type;
  }
}
