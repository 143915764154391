import { Bitmap, ColorFilter, Ticker } from 'createjs-module';
import { ImageShapeOptions } from './image-shape-options';

export class ImageShape extends Bitmap {
  /**
   * Taille de l'image
   *
   * @private
   */
  private size: number;

  /**
   * Echelle
   *
   * @private
   */
  private scale = 0;

  /**
   * Filtre de couleur à appliquer
   *
   * @private
   */
  private colorFilter: ColorFilter | null;

  private originals: { rotation: number | null };

  constructor(options: ImageShapeOptions) {
    super(options.imageUrl);

    this.regX = options.regX ? options.regX : this.image.width;
    this.regY = options.regY ? options.regY : this.image.height;
    this.size = options.size || 10;
    this.alpha = options.alpha || 1;
    this.originals = { rotation: -0 };
    this.colorFilter = options.colorFilter || null;

    if (this.image instanceof HTMLImageElement) {
      this.image.crossOrigin = 'Anonymous';
    }

    this.image.onload = () => {
      if (this.colorFilter) {
        this.filters = [this.colorFilter];
        this.cache(0, 0, 100, 100);
      }

      return this.paintImage();
    };
  }

  /**
   * Dessin de l'image
   *
   * @private
   */
  private paintImage(): void {
    if (this.originals.rotation === null) {
      this.originals.rotation = this.rotation;
      this.rotation = this.originals.rotation + 90;
    }
  }
}
