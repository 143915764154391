import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Role } from '../../../core/enums/role.enum';

@Component({
  selector: 'webclient-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class SidebarComponent implements OnInit {
  /**
   * Liste des rôles
   */
  public Role = Role;

  constructor() {}

  ngOnInit(): void {}
}
