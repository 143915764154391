import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { RobotAdapter } from '../../../core/models/entities/robot-adapter';
import { MultiState } from '../../../core/enums/multi-state.enum';
import { filter } from 'rxjs';
import { StrategyAdapter } from '../../../core/models/entities/strategy-adapter';
import { RobotError } from '../../../core/enums/robot-error.enum';
import { RobotAlert } from '../../../core/enums/robot-alert.enum';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'webclient-robot-state',
  templateUrl: './robot-state.component.html',
  styleUrls: ['./robot-state.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'webclient-robot-state',
  },
})
export class RobotStateComponent implements OnChanges {

  /**
   * Le robot
   */
  @Input() public robotAdapter: RobotAdapter | undefined;

  /**
   * Afficher le nombre de rotation restantes
   */
  @Input() public displayRemainingRotations = true;

  /**
   * Afficher le temps restant avant la fin de la patrouille
   */
  @Input() public displayEndOfPatrol = true;

  /**
   * Etat
   */
  public MULTI_STATE = MultiState;

  /**
   * Etat du robot
   */
  public ROBOT_ERROR = RobotError;

  /**
   * Alerte du robot
   */
  public ROBOT_ALERT = RobotAlert;

  /**
   * Nom de la patrouille courante
   */
  public patrolName = 'patrol.none';
  /*
  * le breakpoint qui change la taille des icônes en petit sur des écrans de petite taille
  */
  constructor(private breakpointObserver: BreakpointObserver) {}
  public isXSscreen(): boolean{
    return this.breakpointObserver.isMatched([Breakpoints.XSmall,Breakpoints.Small])
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['robotAdapter'] && changes['robotAdapter'].currentValue) {
      changes['robotAdapter'].currentValue.currentStrategyAdapter$.pipe(filter((strategyAdapter: StrategyAdapter | null) => !!strategyAdapter)).subscribe((strategyAdapter: StrategyAdapter) => {
        if (strategyAdapter.strategy.patrol) {
          this.patrolName = strategyAdapter.strategy.patrol.name;
        }
      });
    }
  }
}
